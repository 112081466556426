import React, { Component } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { connect } from "react-redux";
import { isPatientOrMember } from "../security/roles";
import I18nProvider from "../services/I18nProvider";
import LocalizationWarning from "../containers/Full/LocalizationWarning";

class ModalPopup extends Component {
  getHeaderClass = () => {
    let { compact, patientApp } = this.props;
    let classes = "";
    if (patientApp) {
      classes = "patient-model-header";
    }
    if (compact) {
      classes += " py-2";
    }

    return classes;
  };

  getBodyClass = () => {
    let { compact } = this.props;
    let classes = "";
    if (compact) {
      classes += " py-2";
    }
    return classes;
  };

  render() {
    let {
      title,
      children,
      onClose,
      size = "lg",
      keyboard = true,
      backdrop = "static",
      className,
      style,
      editor,
      compact
    } = this.props;

    return (
      <Modal
        isOpen={true}
        toggle={onClose}
        size={size}
        keyboard={keyboard}
        backdrop={backdrop}
        className={className}
        dir={I18nProvider.isRTLLocale() ? "rtl" : "ltr"}
        style={style}
      >
        <ModalHeader className={this.getHeaderClass()} toggle={onClose}>
          {title}
        </ModalHeader>

        <LocalizationWarning show={!!editor} />
        <ModalBody className={this.getBodyClass()}>{children}</ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let userRole = state.user.role;

  return {
    patientApp: isPatientOrMember(userRole)
  };
};

export default connect(mapStateToProps)(ModalPopup);
