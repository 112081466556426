import React, { Component } from "react";
import Text from "../../../components/Text";
import Messages from "../../Messages";

class QuestionBoolean extends Component {
  render() {
    let { question, setData } = this.props;

    return (
      <div className="d-flex">
        <div className="d-flex mr-3">
          <input
            checked={
              question.answers &&
              question.answers[0] &&
              question.answers[0].valueBoolean
            }
            type="radio"
            onChange={() => {
              setData({
                valueBoolean: true,
                valueString: "true"
              });
            }}
            id="yes"
            style={{ height: 20, width: 20 }}
          />
          <label className="mb-0 ml-1" htmlFor="yes">
            <Text content={Messages.yes} />
          </label>
        </div>

        <div className="d-flex">
          <input
            checked={
              question.answers &&
              question.answers[0] &&
              !question.answers[0].valueBoolean
            }
            type="radio"
            onChange={() => {
              setData({
                valueBoolean: false,
                valueString: "false"
              });
            }}
            id="no"
            style={{ height: 20, width: 20 }}
          />
          <label className="mb-0 ml-1" htmlFor="no">
            <Text content={Messages.no} />
          </label>
        </div>
      </div>
    );
  }
}

export default QuestionBoolean;
