import React, { PureComponent } from "react";
import { Button, Col, Form, Row } from "reactstrap";
import UserOnboardingChallengeInvite from "./UserOnboardingChallengeInvite";
import StepAcceptTerms from "../Onboarding/StepAcceptTerms";
import { validatePhoneNumber } from "../../utils/phone";
import { getDomain } from "../../utils/utils";
import { getOnboardingAttributeValue, toCms, toKg } from "./Utils";
import {
  getObservationDataHolder,
  getValueFieldNameForFilter
} from "../../Shared/Packages/UiAttributes/Utils";
import moment from "moment";
import Messages from "../../Shared/Messages";
import Text from "../../components/Text";
import I18nProvider from "../../services/I18nProvider";
const intl = I18nProvider.getIntlProvider();

class UserOnboardingStepInvite extends PureComponent {
  constructor(props) {
    super(props);
    let cf = this.props.challengeFields || [];
    cf.forEach(attribute => {
      attribute.mandatory = true;

      let { value } = attribute;
      if (attribute.type === "DATE" && value && value.valueDate) {
        attribute.value.valueDate = moment(value.valueDate).format(
          "DD/MM/YYYY"
        );
      } else if (
        attribute.type === "OBSERVATION_DATE" &&
        value &&
        value.valueObservation &&
        value.valueObservation.value &&
        value.valueObservation.value.valueDate
      ) {
        attribute.value.valueObservation.value.valueDate = moment(
          value.valueObservation.value.valueDate
        ).format("DD/MM/YYYY");
      }
    });
    this.state = { challengeFields: cf || [] };
  }

  onUpdateData = (name, value) => {
    this.setState({ [name]: value });
  };

  validate = () => {
    this.setState({ errorTerms: false, fieldErrors: false });
    const { challengeFields: fields, termsAccepted } = this.state;

    let formValid = true;
    for (let i = 0; i < fields.length; i++) {
      let attribute = { ...fields[i] };
      let value = getOnboardingAttributeValue(attribute);
      if (attribute.unit && attribute.unit === "FEET") {
        value = toCms(value, attribute.valueInches);
        attribute.value = getValueFieldNameForFilter(attribute, {
          value: {
            [getObservationDataHolder(
              attribute.type || attribute.attributeType
            )]: value
          },
          code: { code: attribute.observationCode.code }
        });
      }

      if (attribute.unit && attribute.unit === "LBS") {
        value = toKg(value);
        attribute.value = getValueFieldNameForFilter(attribute, {
          value: {
            [getObservationDataHolder(
              attribute.type || attribute.attributeType
            )]: value
          },
          code: { code: attribute.observationCode.code }
        });
      }

      if (attribute.error) {
        formValid = false;
      } else {
        if (!value) {
          attribute.error = intl.formatMessage(Messages.var_is_required, {
            title: attribute.title
          });
          formValid = false;
        }
      }

      if (
        attribute.value &&
        (attribute.dataField === "PatientContactPhone" ||
          attribute.dataField === "PatientContactTel")
      ) {
        let newAV = JSON.parse(JSON.stringify(attribute.value));
        let phone = newAV.valueContactPoints[0].value;
        let cc = newAV.valueContactPoints[0].countryCode;

        if (
          !phone ||
          (phone && (!cc || !validatePhoneNumber(cc + " " + phone)))
        ) {
          attribute.error = intl.formatMessage(Messages.provide_valid_phone);
          formValid = false;
        }
      }
      fields[i] = { ...attribute };
    }

    this.setState({ challengeFields: [...fields] });

    // if (this.props.showTerms && !termsAccepted) {
    if (!this.props.consented && !termsAccepted) {
      this.setState({ errorTerms: true });
      formValid = false;
    }

    return formValid;
  };

  onSubmit = e => {
    e.preventDefault();
    let valid = this.validate();
    if (!valid) {
      return;
    }

    let challengeFields = JSON.parse(
      JSON.stringify(this.state.challengeFields)
    );
    challengeFields.forEach(attribute => {
      let aType = attribute.type || attribute.attributeType;
      let value = getOnboardingAttributeValue(attribute);

      if (value) {
        if (aType === "DATE") {
          attribute.value.valueString = moment(value, "DD/MM/YYYY").format(
            "MM/DD/YYYY"
          );
          attribute.value.valueDate = null;
        } else if (aType === "OBSERVATION_DATE") {
          attribute.value.valueObservation.value.valueString = moment(
            value,
            "DD/MM/YYYY"
          ).format("MM/DD/YYYY");
          attribute.value.valueObservation.value.valueDate = null;
        }
      }
    });

    this.props.confirmIdentity(challengeFields);
  };

  onUpdateTermsAccepted = (name, value) => {
    this.setState({ [name]: value });
  };

  render() {
    let {
      challengeFields,
      termsAccepted,
      errorTerms,
      fieldErrors
    } = this.state;
    let {
      showTerms,
      onboardingType,
      facilityName,
      consented,
      facilityInfo
    } = this.props;

    return (
      <Form onSubmit={this.onSubmit} noValidate>
        <h3 className="text-center mb-3 font-sm-size">
          <Text content={Messages.confirm_identity} />
        </h3>

        {onboardingType !== "signup" && (
          <p>
            <Text content={Messages.to_accept_your_invitation} />
          </p>
        )}

        {onboardingType === "signup" && (
          <p>
            <Text
              content={Messages.to_sign_up_service}
              data={{
                appName:
                  getDomain().indexOf("mystaffspace.com") !== -1
                    ? "myStaffSpace"
                    : "myPatientSpace"
              }}
            />
          </p>
        )}

        {fieldErrors && (
          <p className="text-muted">
            <span style={{ color: "red" }}>{fieldErrors}</span>
          </p>
        )}

        <UserOnboardingChallengeInvite
          onUpdateData={this.onUpdateData}
          challengeFields={challengeFields}
          error={this.props.error}
          supportEmail={this.props.supportEmail}
          defaultTelRegionOptions={facilityInfo.defaultTelRegionOptions}
        />

        {!consented && (
          <div>
            <StepAcceptTerms
              onUpdateData={this.onUpdateTermsAccepted}
              facilityInfo={this.props.facilityInfo}
              termsAccepted={termsAccepted}
            />
            {errorTerms && (
              <p className="text-muted">
                <span style={{ color: "red" }}>
                  <Text content={Messages.accept_pp_tc} />
                </span>
              </p>
            )}
          </div>
        )}

        <Row>
          <Col xs="12">
            <div className="text-right">
              <Button size="lg" color="primary" className="px-4" block>
                {onboardingType === "signup" ? (
                  <Text content={Messages.continue} />
                ) : (
                  <Text content={Messages.accept_invite} />
                )}
              </Button>
            </div>
          </Col>
        </Row>

        {onboardingType === "signup" && (
          <p className="text-muted mt-3">
            <Text content={Messages.note} />{" "}
            {facilityInfo.helpText ? (
              facilityInfo.helpText
            ) : (
              <Text
                content={Messages.contact_if_no_details}
                data={{ facilityName: facilityName }}
              />
            )}
          </p>
        )}
      </Form>
    );
  }
}

export default UserOnboardingStepInvite;
