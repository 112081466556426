import React, { PureComponent } from "react";
import fileService from "../../services/fileService";
import MPSBarLoader from "../MPSBarLoader";
import { getMobileOperatingSystem } from "../../Common/Onboarding/Utils";
import Text from "../Text";
import Messages from "../../Shared/Messages";

class PDFViewer extends PureComponent {
  state = {};
  componentDidMount() {
    this.setState({ loading: true });
    fileService
      .getFile(this.props.url)
      .then((file) => {
        if (getMobileOperatingSystem() !== "unknown") {
          const link = document.createElement("a");
          link.href = file;
          link.setAttribute(
            "download",
            `${
              this.props.fileName
                ? this.props.fileName.replaceAll(" ", "_")
                : new Date().getTime()
            }.pdf`
          );
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.setState({ downloaded: true });
        } else {
          this.setState({ dataUrl: file, loading: false });
        }
      })
      .catch((e) => {
        this.setState({ loading: false });
      });
  }

  render() {
    const { loading, dataUrl, downloaded } = this.state;

    if (getMobileOperatingSystem() !== "unknown") {
      if (downloaded) {
        return (
          <div className="font-italic">
            <Text content={Messages.pdf_downloaded} />
          </div>
        );
      } else {
        return (
          <div className="font-italic">
            <Text content={Messages.pdf_downloading} />
          </div>
        );
      }
    }

    if (loading) {
      return (
        <div style={{ margin: "100px auto", width: 100 }}>
          <MPSBarLoader />
        </div>
      );
    }

    return (
      <div className="d-flex justify-content-center align-items-center">
        <embed
          src={dataUrl}
          width={this.props.width || "95%"}
          height={this.props.height || "1000px"}
        />
      </div>
    );
  }
}

export default PDFViewer;
