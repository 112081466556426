import React from "react";

function toHHMMSS(secs) {
  let sec_num = parseInt(secs, 10);
  let hours = Math.floor(sec_num / 3600);
  let minutes = Math.floor(sec_num / 60) % 60;
  let seconds = sec_num % 60;

  return [hours, minutes, seconds]
    .map(v => (v < 10 ? "0" + v : v))
    .filter((v, i) => v !== "00" || i > 0)
    .join(":");
}

class CallTimer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { seconds: 0 };
  }

  componentDidMount() {
    let seconds = 0;
    this.callInterval = setInterval(() => {
      seconds++;
      this.props.setCallDuration(seconds);
      this.setState({ seconds });
    }, 1000);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.callEnded) {
      clearInterval(this.callInterval);
      this.callInterval = null;
    }
  }

  render() {
    let { seconds } = this.state;

    return (
      <div
        className="d-flex justify-content-center align-items-center font-lg"
        style={{
          fontWeight: 500
        }}
      >
        {`Call Duration: ${toHHMMSS(seconds)}`}
      </div>
    );
  }
}

export default CallTimer;
