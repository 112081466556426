import moment from "moment";
import { validateEmail, validateUserName } from "../../utils/utils";
import { doE164, validatePhoneNumber } from "../../utils/phone";
import userService from "../../services/userService";
import { getValueWhenInFilter } from "../../Shared/Packages/UiAttributes/Utils";
import Messages from "../../Shared/Messages";
import I18nProvider from "../../services/I18nProvider";
const intl = I18nProvider.getIntlProvider();

export function validateAttribute(index, attribute, that) {
  let value = getOnboardingAttributeValue(attribute);
  if (
    that.props.referrer !== "SELF_SIGNUP" &&
    that.props.referrer !== "DATA_ENTRY"
  ) {
    if (attribute.mandatory && !value) {
      attribute.error = intl.formatMessage(Messages.var_is_required, {
        title: attribute.title
      });
      that.changeFieldPostValidation(index, attribute);
    }
    return;
  }

  if (attribute.mandatory && !value) {
    attribute.error = intl.formatMessage(Messages.var_is_required, {
      title: attribute.title
    });
    that.changeFieldPostValidation(index, attribute);
    return;
  }

  if (attribute.name === "ConfirmEmail") {
    if (!value && that.email) {
      attribute.error = intl.formatMessage(Messages.confirm_email_req);
      that.changeFieldPostValidation(index, attribute);
      return;
    }

    if (
      value &&
      that.email &&
      value.toLowerCase() !== that.email.toLowerCase()
    ) {
      attribute.error = intl.formatMessage(Messages.same_confirm_email);
      that.changeFieldPostValidation(index, attribute);
      return;
    }
  }

  if (attribute.name === "ConfirmPhone") {
    let newAV = JSON.parse(JSON.stringify(attribute.value));
    let phone = newAV.valueContactPoints[0].value;
    let cc = newAV.valueContactPoints[0].countryCode;

    if (!phone && that.phone) {
      attribute.error = intl.formatMessage(Messages.confirm_phone_req);
      that.changeFieldPostValidation(index, attribute);
      return;
    }

    if (
      that.phone &&
      (phone !== that.phone.value || cc !== that.phone.countryCode)
    ) {
      attribute.error = intl.formatMessage(Messages.same_confirm_phone);
      that.changeFieldPostValidation(index, attribute);
      return;
    }
  }

  let validation = attribute.attributeValidation;
  if (validation) {
    let hint = validation.validationHint;
    if (hint === "PAST_DATE" && value) {
      let dateDiff = moment()
        .startOf("day")
        .diff(moment(value, "DD/MM/YYYY"), "days");
      if (dateDiff < 0) {
        attribute.error = intl.formatMessage(Messages.should_past_date, {
          field: attribute.title
        });
        that.changeFieldPostValidation(index, attribute);
      }
    } else if (hint === "FUTURE_DATE" && value) {
      let dateDiff = moment()
        .startOf("day")
        .diff(moment(value, "DD/MM/YYYY"), "days");
      if (dateDiff > 0) {
        attribute.error = intl.formatMessage(Messages.should_future_date, {
          field: attribute.title
        });
        that.changeFieldPostValidation(index, attribute);
      }
    } else if (hint === "DATE_RANGE") {
      let valueDate = moment(value, "DD/MM/YYYY");
      var startDate = moment(validation.fromDate);
      var endDate = moment(validation.toDate);
      if (!valueDate || !valueDate.isBetween(startDate, endDate)) {
        attribute.error = intl.formatMessage(Messages.date_bw, {
          fromDate: startDate.toDate().toLocaleDateString(),
          toDate: endDate.toDate().toLocaleDateString()
        });
        that.changeFieldPostValidation(index, attribute);
      }
    } else if (hint === "NUMBER_RANGE") {
      let { fromNumber, toNumber } = validation;
      let fromLabel = fromNumber;
      let toLabel = toNumber;
      if (attribute.unit && attribute.unit === "FEET") {
        value = toCms(value, attribute.valueInches);
        fromLabel = toFeet(fromLabel);
        fromLabel = fromLabel.feet + "'" + fromLabel.inches + '"';
        toLabel = toFeet(toLabel);
        toLabel = toLabel.feet + "'" + toLabel.inches + '"';
      }

      if (attribute.unit && attribute.unit === "LBS") {
        value = toKg(value);
        fromLabel = toLbs(fromLabel);
        toLabel = toLbs(toLabel);
      }

      if (isNaN(value) || value < fromNumber || value > toNumber) {
        attribute.error = intl.formatMessage(Messages.number_bw, {
          from: fromLabel,
          to: toLabel
        });
        that.changeFieldPostValidation(index, attribute);
      }
    } else if (hint === "VALIDATE_WITH_MASTERDATA") {
      validateExistence(index, attribute, "MASTERDATA_UNIQUE", null, that);
    }
  }

  if (
    attribute.dataField === "PatientContactEmail" &&
    attribute.name !== "ConfirmEmail"
  ) {
    if (value) {
      if (!validateEmail(value)) {
        attribute.error = intl.formatMessage(Messages.provide_valid_email);
        that.changeFieldPostValidation(index, attribute);
      } else {
        that.email = value;
        if (that.props.referrer === "SELF_SIGNUP") {
          validateExistence(index, attribute, "EMAIL", null, that);
        }
      }
    } else {
      that.props.onBlurForConfirmFields(
        attribute,
        index,
        that.props.sectionIndex,
        "EMAIL"
      );
    }
  }

  if (
    attribute.dataField === "PatientContactPhone" &&
    attribute.name !== "ConfirmPhone"
  ) {
    if (value) {
      let newAV = JSON.parse(JSON.stringify(attribute.value));
      let phone = newAV.valueContactPoints[0].value;
      let cc = newAV.valueContactPoints[0].countryCode;
      if (
        !phone ||
        (phone && (!cc || !validatePhoneNumber(cc + " " + phone)))
      ) {
        attribute.error = intl.formatMessage(Messages.provide_valid_phone);
        that.changeFieldPostValidation(index, attribute);
      } else {
        that.phone = { countryCode: cc, value: phone };
        if (that.props.referrer === "SELF_SIGNUP") {
          validateExistence(index, attribute, "PHONE", doE164(cc, phone), that);
        }
      }
    } else {
      that.phone = "";
      that.props.onBlurForConfirmFields(
        attribute,
        index,
        that.props.sectionIndex
      );
    }
  }
}

export function validateExistence(
  index,
  attribute,
  validationField,
  argValue,
  that
) {
  let value = argValue || getOnboardingAttributeValue(attribute);
  if (!value) {
    return;
  }
  let description = attribute.description || attribute.title;
  let validationData = {
    facilityCode: that.props.facilityCode,
    validationFields: [validationField],
    value
  };
  if (validationField === "MASTERDATA_UNIQUE") {
    validationData.attributeName = attribute.name;
  }
  userService.validateSignUpField(validationData).then(res => {
    let response = res[0];
    if (!response) {
      delete attribute.validationCode;
      delete attribute.error;
      delete attribute.info;
      if (validationField === "EMAIL" || validationField === "PHONE") {
        that.props.onBlurForConfirmFields(
          attribute,
          index,
          that.props.sectionIndex,
          validationField
        );
      }
      that.changeFieldPostValidation(index, attribute);
      return;
    }

    if (response.code) {
      attribute.validationCode = response.code;
    } else {
      delete attribute.validationCode;
    }
    if (!response.success) {
      attribute.error = getValidationMessage(
        response.code,
        response.message,
        description
      );
    } else {
      attribute.info = getValidationMessage(
        response.code,
        response.message,
        description
      );
    }
    that.changeFieldPostValidation(index, attribute);
  });
}

function getValidationMessage(code, message, field) {
  if (code === "USERNAME_NOT_UNIQUE") {
    return intl.formatMessage(Messages.un_taken);
  }
  if (code === "USER_HAS_USERNAME") {
    return intl.formatMessage(Messages.with_an_acount_alr, { field });
  }
  if (code === "INVALID_CODE") {
    return message || intl.formatMessage(Messages.not_valid_field, { field });
  }

  if (code === "CODE_NOT_UNIQUE") {
    return intl.formatMessage(Messages.field_used, { field });
  }
  return null;
}

export function getOnboardingAttributeValue(attribute) {
  let value = null;
  let aType = attribute.type || attribute.attributeType;
  if (aType === "DATE") {
    if (attribute.value) {
      value = attribute.value.valueDate;
    }
  } else if (aType === "OBSERVATION_DATE") {
    if (
      attribute.value &&
      attribute.value.valueObservation &&
      attribute.value.valueObservation.value
    ) {
      value = attribute.value.valueObservation.value.valueDate;
    }
  } else {
    value = getValueWhenInFilter(attribute);
  }
  return value;
}

export function toCms(feet, inches) {
  if (!feet) {
    return 0;
  }
  inches = inches ? parseInt(inches) : 0;
  let n = parseInt(feet) * 12 + inches;
  return Math.round(n * 2.54);
}

export function toFeet(cm) {
  let inches = Math.round(cm / 2.54);
  let feet = Math.floor(inches / 12);
  inches %= 12;
  return { feet, inches };
}

export function toKg(lbs) {
  if (!lbs) {
    return 0;
  }

  let kg = lbs * 0.45359237;
  return Math.round(kg);
}

export function toLbs(kg) {
  if (!kg) {
    return 0;
  }

  let lbs = kg / 0.45359237;
  return Math.round(lbs);
}

export function getUserNameError(username) {
  if (!username || username.length === 0) {
    return intl.formatMessage(Messages.username_emp_er);
  }
  if (username.length < 5 || username.length > 128) {
    return intl.formatMessage(Messages.username_len_er);
  }
  if (username.indexOf(" ") >= 0) {
    return intl.formatMessage(Messages.username_sp_er);
  }

  let valid = validateUserName(username);
  if (!valid) {
    let dots = 0;
    for (let i = 0; i < username.length; i++) {
      if (username[i] === ".") {
        dots++;
      }
    }
    if (dots > 1) {
      return intl.formatMessage(Messages.username_dot_er);
    }
    return intl.formatMessage(Messages.set_valid_un);
  }

  return null;
}
