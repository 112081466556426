import React, { Component } from "react";
import ImageUrl from "../../../components/ImageUrl/index";
import VideoPlayer from "../../Statuses/VideoPlayer";
import LottiePlayer from "../../Statuses/LottiePlayer";
import PDFViewer from "../../../components/Viewers/PDFViewer";
import AudioPlayer from "../../Statuses/AudioPlayer";
import BackgroundImagesWithThumbs from "../../../components/BackgroundImageUrl/BackgroundImagesWithThumbs";
import HtmlContent from "./HTMLContent";
import UnsupportedMedia from "./UnsupportedMedia";

class PreviewWithMedia extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let article = this.props.article;
    let contents = article.contents || [];
    if (!article || !contents) {
      return null;
    }
    let format = contents[0] && contents[0].format;
    let text = article.detailText;
    let DisplayComponent = null,
      className = "";
    if (format === "IMAGE") {
      DisplayComponent = ImageUrl;
      className = "fullSize";
    } else if (format === "VIDEO") {
      DisplayComponent = VideoPlayer;
    } else if (format === "AUDIO") {
      DisplayComponent = AudioPlayer;
    } else if (format === "PDF") {
      DisplayComponent = PDFViewer;
    } else if (format === "LOTTIE_JSON") {
      DisplayComponent = LottiePlayer;
    } else {
      DisplayComponent = UnsupportedMedia;
    }

    return (
      <div className="statusSingle">
        <div className="status" style={{ overflow: "auto" }}>
          {format === "IMAGE" && (
            <BackgroundImagesWithThumbs contents={contents} id={article.id} />
          )}

          {format !== "IMAGE" && (
            <div className="media justify-content-center">
              {contents.map((media, index) => {
                return (
                  <div style={{ margin: "0 5px", width: "100%" }}>
                    <DisplayComponent
                      key={index}
                      className={className}
                      url={media.contentURL}
                      thumbnailURL={media.thumbnailURL}
                      height={this.props.height || 780}
                      width={this.props.width ? this.props.width : "100%"}
                    />
                  </div>
                );
              })}
            </div>
          )}

          {text && (
            <div className="justify-content-center" style={{ display: "flex" }}>
              <HtmlContent
                html={article.detailText}
                style={{ marginTop: "20px" }}
              />
              {/*<div*/}
              {/*  style={{ marginTop: "20px" }}*/}
              {/*  dangerouslySetInnerHTML={{ __html: text }}*/}
              {/*/>*/}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default PreviewWithMedia;
