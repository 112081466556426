import React from "react";
import JointsHumanoidDataSummary from "./JointsHumanoidDataSummary";

class JointsHumanoid extends React.PureComponent {
  componentDidMount() {
    window.addEventListener("message", this.receiveDataMessage, false); // receive from external hmlt
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.receiveDataMessage, false);
  }

  receiveDataMessage = event => {
    const message = event.data.message;
    switch (message) {
      case "valueHumanoids":
        this.props.setData(
          event.data.value ? JSON.parse(event.data.value) : null
        );
        break;
      default:
        return;
    }
  };

  render() {
    let {
      submitted,
      valueHumanoids = [],
      setHumanoidScoreAsZero,
      readOnlyHumanoid
    } = this.props;

    if (submitted) {
      return (
        <JointsHumanoidDataSummary
          valueHumanoids={valueHumanoids}
          setHumanoidScoreAsZero={setHumanoidScoreAsZero}
          readOnlyHumanoid={readOnlyHumanoid}
        />
      );
    }

    return (
      <div>
        <iframe
          src="/JointsHumanoid.html?web=true&noCompleteBtn=true"
          style={{
            border: 0,
            height: setHumanoidScoreAsZero ? "50px" : "720px",
            width: "100%"
          }}
        />
        <JointsHumanoidDataSummary
          valueHumanoids={valueHumanoids}
          setHumanoidScoreAsZero={setHumanoidScoreAsZero}
          wip={true}
        />
      </div>
    );
  }
}

export default JointsHumanoid;
