import React, { Component } from "react";
import { Input } from "reactstrap";

class QuestionChoice extends Component {
  setData = (option) => {
    let { question, setData } = this.props;

    if (question.isMultipleAnswersPermitted) {
      let { answers = [] } = question;
      answers = [...answers];
      let index = this.getMultiIndex(answers, option);
      if (index !== -1) {
        answers.splice(index, 1);
      } else {
        answers.push(option);
      }
      setData(answers);
    } else {
      setData(option);
    }
  };

  getMultiIndex = (answers, option) => {
    for (let i = 0; i < answers.length; i++) {
      let answer = answers[i];
      if (answer) {
        // let selected = answer.score || answer.valueString;
        // if (option.score === selected || option.valueString === selected) {
        let selected = answer.valueString;
        if (option.valueString === selected) {
          return i;
        }
      }
    }
    return -1;
  };

  isOptionChecked = (option) => {
    let { question } = this.props;

    if (question.isMultipleAnswersPermitted) {
      let answers = question.answers || [];
      return this.getMultiIndex(answers, option) !== -1;
    } else {
      let answer = question.answers ? question.answers[0] : null;
      let selected = null;
      if (answer) {
        // selected = answer.score || answer.valueString;
        selected = answer.valueString;
      }
      // return option.score
      //   ? option.score === selected
      //   : option.valueString === selected;
      return option.valueString === selected;
    }
  };

  render() {
    let { question, validateQuestions } = this.props;
    let options = question.options || [];

    let oneRow = options.length <= 2;
    let id = question.id;
    validateQuestions();

    if (
      question.isMultipleAnswersPermitted ||
      question.showAsCheckbox ||
      options.length < 3
    ) {
      return options.map((option, index) => {
        let idField = option.valueString
          ? option.valueString.replace(/ /g, "-")
          : index;
        return (
          <div
            key={option.id}
            className={`mb-1 d-flex align-items-center survey-group-options ${
              oneRow ? "mr-3" : ""
            }`}
            onClick={() => this.setData(option)} // Add the click handler here
          >
            <input
              onClick={() => {
                this.setData(option);
              }} // Add the click handler here
              checked={this.isOptionChecked(option)}
              type={
                question.isMultipleAnswersPermitted || question.showAsCheckbox
                  ? "checkbox"
                  : "radio"
              }
              className={
                oneRow
                  ? "mr-1 survey-group-options"
                  : "mr-3 survey-group-options"
              }
              style={{ height: 20, width: 20 }}
              onChange={(e) => {
                e.stopPropagation();
              }} // Prevent default input toggle
              //onClick={(e) => e.stopPropagation()} // Prevent propagation to div
              id={id + "_" + idField}
            />
            <label
              className="mb-0 survey-group-options"
              htmlFor={id + "_" + idField}
              onClick={(e) => e.stopPropagation()} // Prevent label default behavior
            >
              {option.valueString}
            </label>
          </div>
        );
      });
    } else {
      return (
        <Input
          className="mb-3"
          type="select"
          id={id}
          onChange={({ target }) => {
            const selectedOption = options.find(
              (item) => item.id === target.value
            );
            this.setData(selectedOption);
          }}
        >
          <option selected disabled>
            Choose an option
          </option>
          {options.map((option) => (
            <option key={option.id} value={option.id}>
              {option.valueString}
            </option>
          ))}
        </Input>
      );
    }
  }
}

export default QuestionChoice;
