import React from "react";

export default () => (
  <i
    className="fa fa-user"
    style={{
      color: "#fff",
      padding: "10px",
      backgroundColor: "#ddd",
      borderRadius: "4px",
      fontSize: "6rem"
    }}
  />
);
