import React, { PureComponent } from "react";
import MPSButton from "../../components/Buttons/Buttons/MPSButton";

class ActionRenderer extends PureComponent {
  executeAction = e => {
    e.preventDefault();
    e.stopPropagation();
    let { index } = this.props;
    this.props.execute(index);
  };

  render() {
    let { action } = this.props;

    let { uiType, helpText, uiColor } = action;

    return (
      <div>
        <MPSButton
          type={uiType === "LINK" ? "link" : "button"}
          onClick={this.executeAction}
          appTheme={uiColor ? { themeColor: uiColor } : null}
          title={helpText || ""}
        >
          {action.title}
        </MPSButton>
      </div>
    );
  }
}

export default ActionRenderer;
