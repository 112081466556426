// @flow

import {
  ACCEPT_INVITE,
  ACCEPT_TERMS,
  CONFIRMATION_CODE,
  DISMISS_ERROR,
  FETCH_FACILITY_DETAILS,
  LOG_IN,
  LOG_IN_MFA,
  LOG_OUT,
  REJECT_WITHINGS_INTEGRATION,
  RESET_LOADING_USER,
  SET_CREDENTIALS,
  SET_USER_NAME,
  SET_WEBEX_FOR_STAFF,
  UPDATE_AVAILABILITY_STATUS
} from "./actionTypes";
import storageService from "../../services/storageService";
import { STORAGE_TYPE, store } from "../store/configureStore";
import userService from "../../services/userService";

export function onResetLoading() {
  return { type: RESET_LOADING_USER };
}

export function onAcceptInvite(
  inviteCode,
  email,
  phone,
  challengeFields,
  preValidationRequired
) {
  return {
    type: ACCEPT_INVITE,
    inviteCode,
    email,
    phone,
    challengeFields,
    preValidationRequired
  };
}
export function onAcceptTerms() {
  return { type: ACCEPT_TERMS };
}
export function onConfirmationCode(confirmationCode, inviteCode) {
  return { type: CONFIRMATION_CODE, confirmationCode, inviteCode };
}
export function onSetCredentials(loginUserId, password) {
  return { type: SET_CREDENTIALS, loginUserId, password };
}

export function onDismissError() {
  // console.log('DISMISS ERROR');
  return { type: DISMISS_ERROR };
}

export function onLogin(username, password, redirect, signup) {
  // console.log('actions onLogin', username, password);
  return { type: LOG_IN, username, password, redirect, signup };
}

export function onLoginWithMfa(code, mfaToken, mfaMechanism) {
  // console.log('actions onLogin', username, password);
  return {
    type: LOG_IN_MFA,
    code,
    mfaToken,
    mfaMechanism
  };
}

export function onLogout(message) {
  let loggedIn = store.getState().user.loggedIn;
  if (loggedIn) {
    userService.doLogout();
    storageService.clearSessionStorage();
  }
  storageService.removeFromLocalStorage(STORAGE_TYPE);
  return { type: LOG_OUT, message };
}

export function onFetchFacilityDetails() {
  return { type: FETCH_FACILITY_DETAILS };
}

export function onUpdateAvailabilityStatus(availabilityStatus) {
  return { type: UPDATE_AVAILABILITY_STATUS, availabilityStatus };
}

export function onSetOnboardingUserName(userName) {
  return { type: SET_USER_NAME, userName };
}

export function onRejectWithingsIntegration() {
  return {
    type: REJECT_WITHINGS_INTEGRATION
  };
}

export function setWebex(webex) {
  return {
    type: SET_WEBEX_FOR_STAFF,
    webex
  };
}

// export function onSwitchUserType(userType, organizationId) {
//   // console.log(' USER TYPE', userType, organizationId);
//   return { type: SWITCH_USER_TYPE, userType, organizationId };
// }
