import { Alert } from "reactstrap";
import React, { Component } from "react";
import { connect } from "react-redux";
import { closeToaster } from "../../State/actions/toaster";

class Toaster extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.processMessage(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.processMessage(nextProps);
  }

  processMessage = (props) => {
    let { message, type, duration = 5, width } = props;
    if (message) {
      setTimeout(() => {
        this.setState({ show: false });
        this.props.closeToaster();
      }, duration * 1000);

      this.setState({ message, type, show: true, width });
    }
  };

  closeToaster = () => {
    this.setState({
      message: null,
      type: null,
      duration: 0,
      show: false,
    });
  };

  render() {
    let { message, type, show, width } = this.state;

    let color = type || "info";
    if (color === "error") {
      color = "danger";
    }

    return (
      <div
        className="d-flex justify-content-center"
        style={{
          // position: "fixed",
          // zIndex: 9999,
          // top: "5px",
          // width: "100%"
          position: "absolute",
          top: "5px",
          width: "100%",
        }}
      >
        {show && (
          <Alert
            style={{
              padding: "0.75rem",
              width: width || "350px",
              marginBottom: 0,
              zIndex: 99999,
              position: "fixed",
              top: "5px",
            }}
            color={color}
            toggle={this.closeToaster}
            closeClassName="toaster-close-btn"
          >
            {message}
          </Alert>
        )}
      </div>
    );
  }
}

function propsMapper(state, props) {
  return {
    message: state.toaster.message,
    type: state.toaster.type,
    duration: state.toaster.duration,
    width: state.toaster.width,
  };
}
const actionsMapper = {
  closeToaster: closeToaster,
};

export default connect(propsMapper, actionsMapper)(Toaster);
