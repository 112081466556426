import React, { PureComponent } from "react";
import FileService from "../../services/fileService";
import MPSBarLoader from "../MPSBarLoader";
import ModalPopup from "../ModalPopup";

class ImageUrl extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    let { url } = this.props;

    this.setState({ loading: true });
    FileService.getFile(url)
      .then(imageURL => {
        this.setState({ url: imageURL, loading: false });
      })
      .catch(e => {
        this.setState({ loading: false });
      });
  }

  render() {
    const { url, loading, bigImage } = this.state;
    if (!url && !loading) {
      return null;
    }

    if (this.props.noSpinner && loading) {
      return null;
    }

    if (loading) {
      return (
        <div style={{ margin: "100px auto", width: 100 }}>
          <MPSBarLoader />
        </div>
      );
    }

    let { enablePreview } = this.props;

    return (
      <div
        className="imageUrl"
        style={{
          overflow: this.props.overflow || "hidden",
          cursor: enablePreview ? "pointer" : ""
        }}
        onClick={() => {
          if (enablePreview) {
            this.setState({ bigImage: true });
          }
        }}
        // onMouseEnter={() => {
        //   if (enablePreview) {
        //
        //   }
        // }}
        // onMouseLeave={() => {
        //   if (enablePreview) {
        //     this.setState({ bigImage: false });
        //   }
        // }}
      >
        {bigImage && (
          <ModalPopup
            onClose={() => {
              this.setState({ bigImage: false });
            }}
          >
            <div className="d-flex justify-content-center">
              <img
                alt=""
                src={this.state.url}
                height="100%"
                style={
                  this.props.imageStyle
                    ? this.props.imageStyle
                    : {
                        height: "350px",
                        // position: "absolute",
                        zIndex: 9999,
                        border: bigImage ? "1px solid #dddfe2" : "",
                        borderRadius: bigImage ? "4px" : ""
                      }
                }
              />
            </div>
          </ModalPopup>
        )}
        <img
          alt=""
          src={this.state.url}
          // height={this.props.height}
          width={this.props.width}
          style={
            this.props.imageStyle
              ? this.props.imageStyle
              : { height: this.props.height, borderRadius: 4 }
          }
        />
      </div>
    );
  }
}

export default ImageUrl;
