import React, { Component } from "react";

class MapWithPinnedLocation extends Component {
  componentDidMount() {
    let { position } = this.props;
    this.initMap(position);
  }

  initMap(position) {
    let google = window.google;
    let pos = {
      lat: Number.parseFloat(position.latitude),
      lng: Number.parseFloat(position.longitude)
    };
    let map = new google.maps.Map(
      document.getElementById(`map-w-location-${this.props.index}`),
      {
        center: pos,
        zoom: 10
      }
    );

    this.marker = new google.maps.Marker({
      map: map,
      position: pos,
      icon: "/img/markers/marker-icon-red.png"
    });

    this.map = map;
  }

  render() {
    let { style, index } = this.props;

    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <div
              style={style ? style : { height: "100px", width: "100px" }}
              id={`map-w-location-${index}`}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default MapWithPinnedLocation;
