import React, { PureComponent } from "react";

export default class CompleteActionView extends PureComponent {
  render() {
    let { actions = [] } = this.props;
    let executedAction = actions.filter(a => !!a.executed)[0];

    if (executedAction) {
      return (
        <span
          className="badge badge-info badge-pill"
          style={{
            position: "absolute",
            top: "10px",
            marginTop: "-10px",
            marginLeft: 15,
            borderRadius: ".125rem",
            padding: ".25em .417em",
            left: "0%"
          }}
        >
          {executedAction.action.postExecutionMessage}
        </span>
      );
    }
    return null;
  }
}
