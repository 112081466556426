import React, { Component } from "react";
import MPSButton from "../../../../components/Buttons/Buttons/MPSButton";
import Text from "../../../../components/Text";
import Messages from "../../../Messages";

class PlaceholderAttribute extends Component {
  static getDisplayValueAsLink(attribute, title, showPlaceholder) {
    let type = null;
    let predefinedItemCode = attribute.predefinedItemCode || attribute.name;
    if (predefinedItemCode === "MPS_SPACE_MEMBERS") {
      type = "members";
    } else if (predefinedItemCode === "GET_ALL_PATIENT_DOCUMENTS") {
      return (
        <div className="d-flex justify-content-between align-items-center">
          <MPSButton
            id="document-btn-add11"
            type="link"
            onClick={() => {
              showPlaceholder(
                "document_add",
                <Text content={Messages.addDocument} />
              );
            }}
          >
            <Text content={Messages.upload} />
          </MPSButton>

          <div className="mx-3">|</div>

          <MPSButton
            type="link"
            onClick={() => {
              showPlaceholder("documents", title);
            }}
          >
            <Text content={Messages.view} />
          </MPSButton>
        </div>
      );
      // type = "documents";
    } else if (predefinedItemCode === "GET_ALL_ASSIGNED_DEVICES") {
      type = "devices";
    } else if (predefinedItemCode === "MPS_PATIENT_CONTACTS") {
      type = "contacts";
    } else if (predefinedItemCode === "DIALYSIS_CENTERS") {
      type = "dialysis_centers";
    } else if (predefinedItemCode === "CHARGE_ITEMS") {
      type = "charge_items";
    }

    if (type) {
      return (
        <MPSButton
          type="link"
          onClick={() => {
            showPlaceholder(type, title);
          }}
        >
          <Text content={Messages.view} />
        </MPSButton>
      );
    } else {
      return null;
    }
  }

  static getDisplayValue(av) {
    return av.valueCode ? av.valueCode.display : null;
  }

  render() {
    return null;
  }
}

export default PlaceholderAttribute;
