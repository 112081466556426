import React from "react";
import { showLocalizedToaster } from "../../State/actions/toaster";
import UnsubscribeView from "./UnsubscribeView";
import MPSBarLoader from "../../components/MPSBarLoader";
import userService from "../../services/userService";
import { connect } from "react-redux";
import { parseURLQueryParams } from "../../utils/utils";

class UnsubscribeContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.setState({ loading: true });
    userService
      .unsubscribeNotifications(this.props.region, {
        unsubscribeCode: this.props.code
      })
      .then(response => {
        this.setState({ unsubscribed: true, loading: false, error: null });
      })
      .catch(error => {
        this.setState({
          error: error.message,
          unsubscribed: false,
          loading: false
        });
      });
  }

  render() {
    let { unsubscribed, loading, error } = this.state;

    if (loading) {
      return (
        <div style={{ margin: "100px auto", width: 100 }}>
          <MPSBarLoader />
        </div>
      );
    }

    return <UnsubscribeView unsubscribed={unsubscribed} error={error} />;
  }
}

const mapDispatchToProps = {
  showToaster: showLocalizedToaster
};

const mapStateToProps = (state, ownProps) => ({
  code: ownProps.match ? ownProps.match.params.code : null,
  region:
    ownProps.location && ownProps.location.search
      ? parseURLQueryParams(ownProps.location.search).region
      : null
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnsubscribeContainer);
