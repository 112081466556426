import {
  Button,
  Col,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  Row,
  FormGroup
} from "reactstrap";
import React from "react";
import classnames from "classnames";
import {
  validateEmail,
  validatePassword,
  validateText,
  validateUserName
} from "../../utils/utils";
import UserOnboardingChallengeInvite from "./UserOnboardingChallengeInvite";
import {
  getOnboardingAttributeValue,
  getUserNameError,
  toCms,
  toKg
} from "./Utils";
import {
  getObservationDataHolder,
  getValueFieldNameForFilter
} from "../../Shared/Packages/UiAttributes/Utils";
import moment from "moment";
import { doE164, validatePhoneNumber } from "../../utils/phone";
import { getSystemField } from "../../Shared/Utils";
import MPSBarLoader from "../../components/MPSBarLoader";
import Messages from "../../Shared/Messages";
import Text from "../../components/Text";
import I18nProvider from "../../services/I18nProvider";
const intl = I18nProvider.getIntlProvider();

class UserOnboardingStepSetCredentials extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      repeatPassword: "",
      useEmail: false,
      sections: props.patientDataProfile
        ? this.preProcessSections(props.patientDataProfile.sections)
        : []
    };
    this.attributesRef = {};
  }

  preProcessSections = (sections = []) => {
    sections = [...sections];
    for (let i = 0; i < sections.length; i++) {
      let items = sections[i].items;
      for (let j = 0; j < items.length; j++) {
        let attribute = items[j].attribute;
        let { dataField, value } = attribute;

        if (attribute.attributeType === "DATE" && value && value.valueDate) {
          attribute.value.valueDate = moment(value.valueDate).format(
            "DD/MM/YYYY"
          );
        } else if (
          attribute.attributeType === "OBSERVATION_DATE" &&
          value &&
          value.valueObservation &&
          value.valueObservation.value &&
          value.valueObservation.value.valueDate
        ) {
          attribute.value.valueObservation.value.valueDate = moment(
            value.valueObservation.value.valueDate
          ).format("DD/MM/YYYY");
        }

        if (
          dataField === "PatientContactPhone" ||
          dataField === "PatientContactTel"
        ) {
          value.valueContactPoints = value.valueContactPoints || [];
          value.valueContactPoints[0] = value.valueContactPoints[0] || {
            system: getSystemField(dataField),
            use: "WORK"
          };
          let countryCode = value.valueContactPoints[0].countryCode;
          value.valueContactPoints[0].countryCode = countryCode || "+353";
        }
      }
    }

    return sections;
  };

  onUpdateUsername = e => {
    this.setState({ username: e.target.value });
  };

  onUseEmailClicked = () => {
    let old = this.state.useEmail;
    this.setState({
      useEmail: !old,
      username: !old
        ? this.props.email
          ? this.props.email.toLowerCase()
          : ""
        : ""
    });
  };

  onUpdatePassword = e => {
    this.setState({ password: e.target.value });
  };

  onUpdateRepeatPassword = e => {
    this.setState({ repeatPassword: e.target.value });
  };

  onUpdateTermsAccepted = (name, value) => {
    this.setState({ [name]: value });
  };

  onSubmit = e => {
    e.preventDefault();
    this.setState({
      errorUsername: false,
      errorPassword: false,
      errorRepeatPassword: false
      // errorTerms: !termsAccepted
    });
    const { setCredentials, selfSignup } = this.props;
    let {
      username,
      password,
      repeatPassword,
      termsAccepted,
      sections
    } = this.state;

    const validUsername = validateUserName(username);
    const validPassword =
      validateText(password, 6) && validatePassword(password);
    const validRepeatPassword = repeatPassword === password;

    if (
      validUsername &&
      validPassword &&
      validRepeatPassword
      // (selfSignup || termsAccepted)
    ) {
      if (sections.length >= 1) {
        let valid = this.validateDataFields();
        if (!valid) {
          return;
        }
        sections = JSON.parse(JSON.stringify(sections));

        let attributes = [];
        sections.forEach(section => {
          let challengeFields = section.items || [];
          challengeFields.forEach(cf => {
            let attribute = cf.attribute;
            let value = getOnboardingAttributeValue(attribute);
            if (attribute.unit && attribute.unit === "FEET") {
              value = toCms(value, attribute.valueInches);
              attribute.value = getValueFieldNameForFilter(attribute, {
                value: {
                  [getObservationDataHolder(
                    attribute.type || attribute.attributeType
                  )]: value
                },
                code: { code: attribute.observationCode.code }
              });
            }
            if (attribute.unit && attribute.unit === "LBS") {
              value = toKg(value);
              attribute.value = getValueFieldNameForFilter(attribute, {
                value: {
                  [getObservationDataHolder(
                    attribute.type || attribute.attributeType
                  )]: value
                },
                code: { code: attribute.observationCode.code }
              });
            }
            if (value) {
              if (attribute.attributeType === "DATE") {
                attribute.value.valueString = moment(
                  value,
                  "DD/MM/YYYY"
                ).format("MM/DD/YYYY");
                attribute.value.valueDate = null;
              } else if (attribute.attributeType === "OBSERVATION_DATE") {
                attribute.value.valueObservation.value.valueString = moment(
                  value,
                  "DD/MM/YYYY"
                ).format("MM/DD/YYYY");
                attribute.value.valueObservation.value.valueDate = null;
              }
              if (attribute.dataField === "PatientContactPhone") {
                let av = attribute.value;
                let phone = av.valueContactPoints[0].value;
                let cc = av.valueContactPoints[0].countryCode;
                delete av.valueContactPoints[0].countryCode;
                av.valueContactPoints[0].value = doE164(cc, phone);
                attribute.value = av;
              }
              attributes.push({ ...attribute });
            }
          });
        });
        setCredentials(username, password, attributes);
      } else {
        setCredentials(username, password);
      }
    } else {
      this.setState({
        errorUsername: !validUsername,
        errorPassword: !validPassword,
        errorRepeatPassword: !validRepeatPassword
        // errorTerms: !termsAccepted
      });
    }
  };

  toggleViewPassword = () => {
    this.setState({ viewPassword: !this.state.viewPassword });
  };

  toggleViewConfirmPassword = () => {
    this.setState({ viewConfirmPassword: !this.state.viewConfirmPassword });
  };

  onUpdateData = (sectionIndex, name, attributes) => {
    let { sections } = this.state;
    let section = sections[sectionIndex];
    let items = section.items || [];
    items.forEach(item => {
      let attr = attributes.filter(att => att.name === item.attribute.name)[0];
      if (attr.focus) {
        attr.focus = false;
      }
      item.attribute = {
        ...attr
      };
    });
    section.items = [...items];
    this.setState({ sections: [...sections] });
  };

  setCredentialPresent = () => {};

  onBlurForConfirmFields = () => {};

  setAttributeRef = (name, ref) => {
    this.attributesRef[name] = ref;
  };

  validateDataFields = () => {
    let sections = this.state.sections || [];
    sections = [...sections];

    let formValid = true;
    let firstError = null;
    for (let a = 0; a < sections.length; a++) {
      let section = { ...sections[a] };
      let fields = section.items || [];
      for (let i = 0; i < fields.length; i++) {
        let field = { ...fields[i] };
        let attribute = { ...field.attribute };
        let value = getOnboardingAttributeValue(attribute);
        if (!attribute.hidden) {
          if (attribute.error) {
            formValid = false;
          } else {
            if (attribute.mandatory && !value) {
              attribute.error = intl.formatMessage(Messages.var_is_required, {
                title: attribute.title
              });
              formValid = false;
            }
          }
        }

        if (!firstError) {
          firstError = attribute;
        }

        field.attribute = attribute;
        fields[i] = field;
      }
      section.fields = [...fields];
    }
    this.setState({ sections: [...sections] });

    if (!formValid && firstError) {
      window.scrollTo(0, this.attributesRef[firstError.name].offsetTop);
    }

    return formValid;
  };

  onBlurPassword = () => {
    let { password } = this.state;
    const validPassword =
      validateText(password, 6) && validatePassword(password);

    this.setState({ errorPassword: !validPassword });
  };

  onBlurRepeatPassword = () => {
    let { repeatPassword, password } = this.state;
    this.setState({ errorRepeatPassword: repeatPassword !== password });
  };

  onBlurUsername = () => {
    let { username } = this.state;
    const validUsername = validateUserName(username);
    this.setState({ errorUsername: !validUsername });
  };

  render() {
    let { selfSignup, submitting } = this.props;
    let {
      username,
      password,
      repeatPassword,
      errorUsername,
      errorPassword,
      errorRepeatPassword,
      termsAccepted,
      errorTerms,
      viewPassword,
      viewConfirmPassword,
      sections
    } = this.state;

    return (
      <Form onSubmit={this.onSubmit}>
        {sections.length >= 1 && (
          <div>
            {sections.map((section, index) => {
              let challengeFields = [];
              if (section && section.items && section.items.length > 0) {
                challengeFields = section.items;
              }
              return (
                <div className="justify-content-center mb-2" key={index}>
                  <div className="mb-3 text-center">
                    <h5 style={{ marginBottom: 0 }}>{section.title}</h5>
                    <div>{section.subTitle}</div>
                  </div>

                  <UserOnboardingChallengeInvite
                    sectionIndex={index}
                    facilityId={this.props.facilityId}
                    onUpdateData={(name, attributes) => {
                      this.onUpdateData(index, name, attributes);
                    }}
                    referrer="DATA_ENTRY"
                    challengeFields={challengeFields.map(cf => {
                      if (cf.mandatory) {
                        cf.attribute.mandatory = true;
                      }
                      if (cf.readOnly) {
                        cf.attribute.readOnly = true;
                      }
                      if (cf.hidden) {
                        cf.attribute.hidden = true;
                      }
                      if (cf.helpText) {
                        cf.attribute.helpText = cf.helpText;
                      }
                      return cf.attribute;
                    })}
                    setCredentialPresent={this.setCredentialPresent}
                    onBlurForConfirmFields={this.onBlurForConfirmFields}
                    facilityCode={this.props.facilityCode}
                    setAttributeRef={this.setAttributeRef}
                    defaultTelRegionOptions={
                      this.props.facilityInfo.defaultTelRegionOptions
                    }
                  />
                  <hr style={{ borderTopColor: "1px solid #dddfe2" }} />
                </div>
              );
            })}
          </div>
        )}

        <div className="mb-3 text-center">
          {sections.length >= 1 ? (
            <h5 className="text-center mb-0 font-sm-size">
              <Text content={Messages.set_your_credentials} />
            </h5>
          ) : (
            <h3 className="text-center mb-0 font-sm-size">
              <Text content={Messages.set_your_credentials} />
            </h3>
          )}
          <div>
            <Text content={Messages.set_username_password} />
          </div>
        </div>

        <FormGroup className="d-flex flex-column">
          <div className="mb-1">
            <Text content={Messages.user_name} />
            <div>
              <small>
                <Text content={Messages.user_name_ht} />
              </small>
            </div>
          </div>

          {this.props.email && (
            <div className="my-2 d-flex">
              <div>
                <Input
                  type="checkbox"
                  className="ml-0 mr-3"
                  style={{ position: "relative", marginTop: "0px" }}
                  checked={this.state.useEmail}
                  onChange={this.onUseEmailClicked}
                />
              </div>
              <Text content={Messages.email_as_username} />
            </div>
          )}

          <InputGroup>
            {/*<InputGroupAddon addonType="prepend">*/}
            {/*  <InputGroupText*/}
            {/*    className={classnames(errorUsername ? "is-invalid" : "")}*/}
            {/*  >*/}
            {/*    <i className="fa fa-user" />*/}
            {/*  </InputGroupText>*/}
            {/*</InputGroupAddon>*/}

            <Input
              className={classnames(errorUsername ? "is-invalid" : "")}
              value={username}
              onChange={this.onUpdateUsername}
              type="text"
              autoCapitalize="none"
              onBlur={this.onBlurUsername}
            />
            <div className="invalid-feedback">
              {errorUsername && getUserNameError(username)}
              {/*<Text content={Messages.set_username} />*/}
            </div>
          </InputGroup>
        </FormGroup>

        <FormGroup className="d-flex flex-column">
          <div className="mb-1">
            <Text content={Messages.password} />
            <div>
              <small>
                <Text content={Messages.password_ht} />
              </small>
            </div>
          </div>
          <InputGroup>
            {/*<InputGroupAddon addonType="prepend">*/}
            {/*  <InputGroupText*/}
            {/*    className={classnames(errorPassword ? "is-invalid" : "")}*/}
            {/*  >*/}
            {/*    <i className="fa fa-lock" />*/}
            {/*  </InputGroupText>*/}
            {/*</InputGroupAddon>*/}
            <Input
              value={password}
              className={classnames(errorPassword ? "is-invalid" : "")}
              onChange={this.onUpdatePassword}
              type={viewPassword ? "text" : "password"}
              autoCapitalize="none"
              onBlur={this.onBlurPassword}
            />
            <InputGroupAddon addonType="append">
              <span
                className="input-group-text cursor-pointer"
                onClick={this.toggleViewPassword}
              >
                <i
                  className={`fa ${viewPassword ? "fa-eye-slash" : "fa-eye"}`}
                />
              </span>
            </InputGroupAddon>
            <div className="invalid-feedback">
              <Text content={Messages.set_valid_password} />
            </div>
          </InputGroup>
        </FormGroup>

        <FormGroup className="d-flex flex-column">
          <div className="mb-1">
            <Text content={Messages.confirm_password} />
          </div>

          <InputGroup>
            {/*<InputGroupAddon addonType="prepend">*/}
            {/*  <InputGroupText*/}
            {/*    className={classnames(errorPassword ? "is-invalid" : "")}*/}
            {/*  >*/}
            {/*    <i className="fa fa-lock" />*/}
            {/*  </InputGroupText>*/}
            {/*</InputGroupAddon>*/}
            <Input
              value={repeatPassword}
              className={classnames(errorRepeatPassword ? "is-invalid" : "")}
              onChange={this.onUpdateRepeatPassword}
              type={viewConfirmPassword ? "text" : "password"}
              autoCapitalize="none"
              onBlur={this.onBlurRepeatPassword}
            />
            <InputGroupAddon addonType="append">
              <span
                className="input-group-text cursor-pointer"
                onClick={this.toggleViewConfirmPassword}
              >
                <i
                  className={`fa ${
                    viewConfirmPassword ? "fa-eye-slash" : "fa-eye"
                  }`}
                />
              </span>
            </InputGroupAddon>
            <div className="invalid-feedback">
              <Text content={Messages.passwords_mustbe_same} />
            </div>
          </InputGroup>
        </FormGroup>

        {/*{!selfSignup && (*/}
        {/*  <div>*/}
        {/*    <StepAcceptTerms*/}
        {/*      onUpdateData={this.onUpdateTermsAccepted}*/}
        {/*      facilityInfo={this.props.facilityInfo}*/}
        {/*      termsAccepted={termsAccepted}*/}
        {/*    />*/}
        {/*    {errorTerms && (*/}
        {/*      <p className="text-muted">*/}
        {/*        <span style={{ color: "red" }}>*/}
        {/*          Please accept privacy policy and terms and conditions.*/}
        {/*        </span>*/}
        {/*      </p>*/}
        {/*    )}*/}
        {/*  </div>*/}
        {/*)}*/}

        <Row>
          <Col xs="12">
            <div className="d-flex justify-content-center">
              {submitting ? (
                <div className="d-flex">
                  <MPSBarLoader />
                </div>
              ) : (
                <Button color="primary" size="lg" className="px-4" block>
                  <Text content={Messages.create_account} />
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default UserOnboardingStepSetCredentials;
