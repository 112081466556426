import React, { PureComponent } from "react";
import { Button, Col, Row } from "reactstrap";
import libraryService from "../../services/contentLibraryService";
import ArticlePreview from "../../Shared/ContentLibrary/Preview/ArticlePreview";
import Messages from "../../Shared/Messages";
import Text from "../../components/Text";
import storageService from "../../services/storageService";
import { CONTINUE_TEXT, CONTINUE_TEXT_W_LANG } from "./IntroText";
import { ONBOARDING_LOCALE } from "../../services/userService";

class SignupIntro extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    let el = document.getElementById("signup-intro-video");
    if (el) {
      el.addEventListener("contextmenu", this.disableContextMenu);
    }

    if (
      this.props.facilityInfo &&
      this.props.facilityInfo.signupIntroLibraryId
    ) {
      this.setState({ loading: true });

      libraryService
        .loadLibraryItemForSignup(this.props.facilityInfo.signupIntroLibraryId)
        .then((response) => {
          this.setState({ signupIntroLibrary: response, loading: false });
        })
        .catch((e) => this.setState({ loading: false }));
    }
  }

  componentWillUnmount() {
    let el = document.getElementById("signup-intro-video");
    if (el) {
      el.removeEventListener("contextmenu", this.disableContextMenu);
    }
  }

  disableContextMenu = (e) => {
    e.preventDefault();
  };

  getContinueButton = (locale, block, withLang) => {
    return (
      <Button
        dir="auto"
        color="primary"
        size="lg"
        className="px-4 mx-1 my-1"
        block={!!block}
        onClick={() => {
          storageService.addToSessionStorage(ONBOARDING_LOCALE, locale);
          this.props.incrementStep();
        }}
      >
        {withLang ? CONTINUE_TEXT_W_LANG[locale] : CONTINUE_TEXT[locale]}
      </Button>
    );
  };

  generateContinueButtons = () => {
    let { facilityInfo } = this.props;
    let {
      defaultLocale,
      supportedLocales = [],
      provisionedFeatures,
    } = facilityInfo;
    if (provisionedFeatures.indexOf("EnableContentLocalization") === -1) {
      return this.getContinueButton(defaultLocale, true, false);
    } else {
      if (supportedLocales.length === 0) {
        return this.getContinueButton(defaultLocale || "en_US", true, false);
      } else if (
        supportedLocales.length === 1 &&
        supportedLocales[0] === "en_US"
      ) {
        return this.getContinueButton("en_US", true, false);
      } else {
        return supportedLocales.map((locale) => {
          return this.getContinueButton(locale, false, true);
        });
      }
    }
  };

  render() {
    let { facilityInfo, noTitle } = this.props;
    let {
      introductionTitle,
      introductionText,
      introductionContents,
      signupIntroLibraryId,
    } = facilityInfo;
    let introductionContent = null;
    if (introductionContents) {
      introductionContent = introductionContents[0];
    }

    return (
      <div>
        <Row>
          <Col xs="12">
            {!noTitle && <h5 className="text-center">{introductionTitle}</h5>}

            {signupIntroLibraryId ? (
              <ArticlePreview article={this.state.signupIntroLibrary} embed />
            ) : (
              <React.Fragment>
                {introductionContent && (
                  <div className="my-3">
                    {introductionContent.format === "IMAGE" && (
                      <div className="d-flex justify-content-center">
                        <img src={introductionContent.contentURL} width="75%" />
                      </div>
                    )}
                    {introductionContent.format === "VIDEO" && (
                      <video
                        id="signup-intro-video"
                        controls
                        src={introductionContent.contentURL}
                        width="100%"
                        preload="none"
                        poster={introductionContent.thumbnailURL}
                        controlsList="nodownload"
                      >
                        <Text content={Messages.embedded_videos} />
                      </video>
                    )}
                  </div>
                )}
                <div className="my-3">
                  <div dangerouslySetInnerHTML={{ __html: introductionText }} />
                </div>
              </React.Fragment>
            )}

            <div className="d-flex align-items-center flex-wrap justify-content-center">
              {this.generateContinueButtons()}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default SignupIntro;
