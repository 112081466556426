import React from "react";
import Text from "../../components/Text";
import Messages from "../../Shared/Messages";

const steps = {
  preValidationRequired: 1,
  invite: 1,
  setCredentials: 2,
  intro: 3
};

function getClassName(stepNo, currentStep) {
  if (stepNo < currentStep) {
    return "success";
  }

  if (stepNo === currentStep) {
    return "active";
  }
  return "";
}

export default class OnboardingStepsIndicator extends React.PureComponent {
  render() {
    let { step } = this.props;
    let currentStep = steps[step];

    return (
      <ol className="progress-list mb-5">
        <li className={getClassName(1, currentStep)}>
          <button type="button">
            <span className="progress-indicator" />
          </button>
          <span className="progress-label">
            <Text content={Messages.accept} />
          </span>
        </li>
        <li className={getClassName(2, currentStep)}>
          <button type="button">
            <span className="progress-indicator" />
          </button>
          <span className="progress-label">
            <Text content={Messages.register} />
          </span>
        </li>
        <li className={getClassName(3, currentStep)}>
          <button type="button">
            <span className="progress-indicator" />
          </button>
          <span className="progress-label">
            <Text content={Messages.install} />
          </span>
        </li>
      </ol>
    );
  }
}
