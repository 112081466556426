import { startCase } from "../utils/strings";
export const ROLE_MPS_ADMIN = "ROLE_MPS_ADMIN";
export const ROLE_FACILITY_ADMIN = "ROLE_FACILITY_ADMIN";
export const ROLE_DOCTOR = "ROLE_DOCTOR";
export const ROLE_NURSE = "ROLE_NURSE";
export const ROLE_STAFF = "ROLE_STAFF";
export const ROLE_PATIENT = "ROLE_PATIENT";
export const ROLE_PATIENT_MEMBER = "ROLE_PATIENT_MEMBER";
export const ROLE_MEMBER = "ROLE_MEMBER";
export const ROLE_ADMIN_MEMBER = "ROLE_ADMIN_MEMBER";
export const ROLE_GENERAL_STAFF = "ROLE_GENERAL_STAFF";

export const EDIT_VIEW_ROLES = [
  ROLE_DOCTOR,
  ROLE_NURSE,
  ROLE_STAFF,
  ROLE_PATIENT,
  ROLE_MEMBER,
  ROLE_ADMIN_MEMBER
];

export function displayRole(role) {
  return startCase(
    role
      .split("_")
      .join(" ")
      .replace("ROLE ", "")
  );
}

export function isSessionTimeoutRequired(role) {
  return isStaff(role) || isPatientOrMember(role);
}

export function isStaff(role) {
  return (
    role === ROLE_DOCTOR || role === ROLE_NURSE || role === ROLE_GENERAL_STAFF
  );
}

export function isPatientOrMember(role) {
  return (
    role === ROLE_PATIENT || role === ROLE_ADMIN_MEMBER || role === ROLE_MEMBER
  );
}

export function isPatient(role) {
  return role === ROLE_PATIENT;
}

export function isFacilityAdmin(role) {
  return role === ROLE_FACILITY_ADMIN;
}

export function isMpsAdmin(role) {
  return role === ROLE_MPS_ADMIN;
}

export function getRoleLabel(role) {
  switch (role) {
    case ROLE_MEMBER:
      return "Patient Member";
    case ROLE_ADMIN_MEMBER:
      return "Admin Member for Patient";
    case ROLE_PATIENT:
      return "Patient";
    case ROLE_GENERAL_STAFF:
      return "Staff";
    case ROLE_DOCTOR:
      return "Doctor";
    case ROLE_NURSE:
      return "Nurse";
    case ROLE_FACILITY_ADMIN:
      return "Facility Admin";
    case ROLE_MPS_ADMIN:
      return "MPS Admin";
    default:
      return [];
  }
}

export function getFacilityTypeText(facilityInternalType) {
  if (facilityInternalType === "DEVELOPMENT") {
    return "Development";
  } else if (facilityInternalType === "PACKAGE_HELPER") {
    return "Package Helper";
  } else if (facilityInternalType === "PRE_PRODUCTION") {
    return "Pre Production";
  } else if (facilityInternalType === "PRODUCTION") {
    return "Production";
  }
  return null;
}

export function getStaffRoles() {
  return [ROLE_DOCTOR, ROLE_NURSE, ROLE_GENERAL_STAFF];
}

export function getPatientOrMemberRoles() {
  return [ROLE_PATIENT, ROLE_ADMIN_MEMBER, ROLE_MEMBER];
}

export function getStaffAndPatientRoles() {
  return [
    ROLE_PATIENT,
    ROLE_GENERAL_STAFF,
    ROLE_NURSE,
    ROLE_DOCTOR,
    ROLE_MEMBER,
    ROLE_ADMIN_MEMBER
  ];
}
