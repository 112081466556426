export default class StorageService {
  static addToSessionStorage(key, value) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  static getFromSessionStorage(key) {
    let stored = sessionStorage.getItem(key);
    if (!stored) {
      return null;
    }
    return JSON.parse(stored);
  }

  static removeFromSessionStorage(key) {
    sessionStorage.removeItem(key);
  }

  static clearSessionStorage() {
    sessionStorage.clear();
  }

  static addToLocalStorage(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  static getFromLocalStorage(key) {
    let stored = localStorage.getItem(key);
    if (!stored) {
      return null;
    }
    return JSON.parse(stored);
  }

  static removeFromLocalStorage(key) {
    localStorage.removeItem(key);
  }

  static clearLocalStorage() {
    localStorage.clear();
  }
}
