import { Button, Card, CardBody } from "reactstrap";
import React from "react";
import Messages from "../../../Shared/Messages";
import Text from "../../../components/Text";

class JoinGroupClassConfirmation extends React.PureComponent {
  render() {
    let { cancel, doAction, dateTime, staffName, description } = this.props;

    return (
      <div>
        <div className="row">
          <div className="col-12">
            <Card>
              <CardBody>
                <div className="mb-2">
                  <Text
                    content={Messages.join_class_on}
                    data={{ description }}
                  />
                </div>
                <div>
                  <Text
                    content={Messages.appt_w_staff}
                    data={{ dateTime, staffName }}
                  />
                </div>
              </CardBody>
            </Card>

            <div
              style={{
                marginTop: 20,
                flex: 1,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center"
              }}
            >
              <Button onClick={cancel} className="btn btn-secondary mr-2">
                <Text content={Messages.cancel} />
              </Button>

              <Button color="primary" onClick={doAction}>
                <Text content={Messages.confirm} />
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default JoinGroupClassConfirmation;
