// @flow

import {
  PATIENT_TYPES_RESET_LOADING,
  PATIENT_TYPES_LOAD,
  PATIENT_TYPES_LOAD_DONE,
  PATIENT_TYPES_LOAD_ERROR,
  SET_PATIENT_TYPE_ID
} from "../actions/actionTypes";

export const initialCreate = {
  name: "",
  description: "",
  treeData: [],
  treeActiveId: false,
  treeActiveItem: false,
  // index used to preview sample from list in templates data
  previewSample: -1,
  id: -1
};

const initialState = {
  error: false,
  errorMsg: false,
  loading: false,
  // last page loaded
  page: 0,
  // is there need to load more, currently determined
  // to be false if last page listing returned no results
  loadMore: true,
  list: [],
  listLoaded: false
};

const patientTypesReducer = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case PATIENT_TYPES_RESET_LOADING:
      return initialState;

    case PATIENT_TYPES_LOAD:
      return { ...state, loading: true };

    case PATIENT_TYPES_LOAD_DONE:
      newState = {
        ...state,
        loading: false,
        page: action.page,
        loadMore: action.loadMore,
        listLoaded: true,
        list:
          action.page === 0
            ? action.list
            : state.list.slice(0).concat(action.list)
      };
      return newState;

    case PATIENT_TYPES_LOAD_ERROR:
      return { ...state, loading: false, error: true, errorMsg: action.error };

    case SET_PATIENT_TYPE_ID:
      return { ...state, patientTypeId: action.patientTypeId };

    default:
      return state;
  }
};

export default patientTypesReducer;
