import {
  LOG_OUT,
  CHANGE_MPS_PACKAGE,
  REMOVE_MPS_PACKAGE
} from "../actions/actionTypes";

import { REHYDRATE } from "redux-persist";

const initialState = {
  selectedPackage: null,
  packageId: null
};

const mpsPackageReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_MPS_PACKAGE:
      let { mpsPackage, packageId } = action;
      return { ...state, selectedPackage: { ...mpsPackage }, packageId };

    case REMOVE_MPS_PACKAGE:
      return initialState;

    case REHYDRATE:
      if (action.payload && action.payload.mpsPackage) {
        return action.payload.mpsPackage;
      }
      return state;

    case LOG_OUT:
      return initialState;

    default:
      return state;
  }
};

export default mpsPackageReducer;
