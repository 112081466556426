import React, { PureComponent } from 'react';
import fileService from '../../services/fileService';
import { Player, Controls } from '@lottiefiles/react-lottie-player';

class LottiePlayer extends PureComponent {
  static propTypes = {};

  static defaultProps = {
    ...PureComponent.defaultProps,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    fileService.getFile(this.props.thumbnailURL).then(data => {
      this.setState({ thumbnail: data });
    });
  }


  render() {
    const { dataUrl, thumbnail, loading } = this.state;
    const { url } = this.props;

    return (
      <div className="d-flex justify-content-center align-items-center">
        <Player
        autoplay
        speed={1}
        // loop
        src={url}
        style={{ height: "300px", width: "300px" }}
      >
        <Controls
          visible={true}
          buttons={[
            "play",
            // "repeat",
            // "frame",
            // "debug",
            // "snapshot",
            // "background"
          ]}
        />
      </Player>
      </div>
    );
  }
}

export default LottiePlayer;
