import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import FileService from "../../services/fileService";
import { ClipLoader } from "react-spinners";
import classnames from "classnames";

class BackgroundImageUrl extends PureComponent {
  static propTypes = {
    id: PropTypes.any,
    index: PropTypes.number,
    url: PropTypes.string.isRequired,
    size: PropTypes.number,
    className: PropTypes.string,
    active: PropTypes.bool,
    onClick: PropTypes.func,
    type: PropTypes.string
  };

  static defaultProps = {
    ...PureComponent.defaultProps,
    size: 35,
    className: "",
    active: false
  };

  constructor(props) {
    super(props);
    this.state = {
      img: null
    };
  }

  downloadFile = url => {
    FileService.getFile(url).then(data => {
      this.updateUrl(data);
    });
  };

  componentDidMount() {
    this.downloadFile(this.props.url);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.url !== nextProps.url) {
      this.downloadFile(nextProps.url);
    }
  }

  updateUrl = url => {
    var img = new Image();
    img.src = url;
    this.setState({ img: img });
  };

  onClick = e => {
    e.preventDefault();
    if (this.props.onClick) {
      this.props.onClick(this.props.index);
    }
  };

  render() {
    const { img } = this.state;
    const { className, active, id, type } = this.props;

    if (!img)
      return (
        <div
          key={id}
          className={classnames(
            "bgImageUrl d-flex justify-content-center align-items-center",
            className
          )}
        >
          <ClipLoader size={this.props.size} color={"rgb(54, 215, 183)"} />
        </div>
      );
    return (
      <div
        key={id}
        className={classnames(
          "bgImageUrl d-flex justify-content-center align-items-center",
          className
        )}
        style={{
          overflow: "hidden",
          backgroundImage: "url('" + img.src + "')"
        }}
        onClick={this.onClick}
      >
        {type === "VIDEO" && (
          <div
            style={{
              position: "absolute",
              top: 10,
              left: 10,
              right: 10,
              bottom: 10,
              border: "2px solid #ccc",
              borderRadius: 100
            }}
            className="d-flex justify-content-center align-items-center"
          >
            <span
              className="fas fa-play"
              style={{
                paddingLeft: 1,
                color: "#ccc"
              }}
            />
          </div>
        )}
        {active && <div className="active" />}
      </div>
    );
  }
}

export default BackgroundImageUrl;
