import React, { Component } from "react";
import Humanoid from "./Humanoid/Humanoid";

class QuestionHumanoid extends Component {
  constructor(props) {
    super(props);
    this.state = { question: props.question };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({ question: nextProps.question });
  }

  // onClickSpot = position => {
  //   let { question } = this.props;
  //   let { answers = [] } = question;
  //   let answer = answers[0] || {};
  //   let { valueHumanoids = [] } = answer;
  //
  //   let index = valueHumanoids.findIndex(h => h.position === position);
  //   if (index !== -1) {
  //     this.setState({ selected: valueHumanoids[index] });
  //   } else {
  //     this.setState({ selected: { position } });
  //   }
  // };
  //
  // cancelSelect = () => {
  //   this.setState({ selected: null });
  // };

  // setData = (value, add) => {
  //   let { question, setData } = this.props;
  //   let { answers = [] } = question;
  //   let answer = answers[0] || {};
  //   let { valueHumanoids = [] } = answer;
  //
  //   let index = valueHumanoids.findIndex(h => h.position === value.position);
  //   if (index !== -1) {
  //     if (add) {
  //       valueHumanoids[index] = value;
  //     } else {
  //       valueHumanoids.splice(index, 1);
  //     }
  //   } else {
  //     if (add) {
  //       valueHumanoids.push(value);
  //     }
  //   }
  //
  //   answers[0] = {
  //     valueHumanoids: [...valueHumanoids]
  //   };
  //   setData(answers);
  //   this.cancelSelect();
  // };

  setData = value => {
    let { question, setData } = this.props;
    let { answers = [] } = question;
    answers[0] = value;
    setData(answers);
    // this.setState({ submitted: true });
  };

  render() {
    let { question } = this.props;
    let { answers = [] } = question;

    let { humanoidType = "JOINTS" } = question;
    let answer = answers[0] || {};
    let { valueHumanoids, setHumanoidScoreAsZero } = answer;

    return (
      <div>
        <Humanoid
          humanoidType={humanoidType}
          setHumanoidScoreAsZero={setHumanoidScoreAsZero}
          valueHumanoids={valueHumanoids}
          selectable={true}
          setData={this.setData}
          // submitted={this.state.submitted}
        />

        {/*{selected && (*/}
        {/*  <ModalPopup*/}
        {/*    onClose={this.cancelSelect}*/}
        {/*    size="sm"*/}
        {/*    title={"Selected: " + selected.position}*/}
        {/*  >*/}
        {/*    <HumanoidForm*/}
        {/*      type={humanoidType}*/}
        {/*      position={selected}*/}
        {/*      setData={this.setData}*/}
        {/*      ans*/}
        {/*    />*/}
        {/*  </ModalPopup>*/}
        {/*)}*/}
      </div>
    );
  }
}

export default QuestionHumanoid;
