import React, { Component, Fragment } from "react";
import { Col, Container, Row } from "reactstrap";
import ExistingRoomContainer from "./ExistingRoomContainer";

class VideoCallViewForLinks extends Component {
  constructor(props) {
    super();
    this.state = {};
  }

  render() {
    return (
      <div className="app flex-column justify-content-center align-items-center">
        <Container>
          <div className="d-flex justify-content-between my-2">
            <div className="ob-logos">
              <div>
                <img
                  alt="myPatientSpace"
                  src="/img/logo-symbol.png"
                  height={24}
                />
              </div>
            </div>
          </div>

          <Row className="justify-content-center">
            <Col md="12" sm={12}>
              <ExistingRoomContainer {...this.props} />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default VideoCallViewForLinks;
