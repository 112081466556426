import React from "react";
import ReactDragList from "react-drag-list";
import { getFieldName } from "./Utils";
import MPSSelect from "../../../../components/MPSSelect";
import Select from "react-select";

class AttributesSelector extends React.PureComponent {
  onSort = (event, sorted) => {
    this.props.saveAttributes([...sorted]);
  };

  parseOptionsForDropDown = (options, type) => {
    let valueField = getFieldName(type);
    if (type === "CODEABLE_CONCEPT_CODE") {
      return options.map(option => {
        return {
          value: option.valueCode.code,
          label: option.valueCode.display
        };
      });
    }
    return options.map(option => {
      return {
        value: option[valueField],
        label: option[valueField]
      };
    });
  };

  getFilterFieldFromAttribute = (index, attribute) => {
    let { type, choiceList, options, showAsCheckbox } = attribute;
    let fieldType = type === "DATE" ? "date" : "text";
    let valueField = getFieldName(type);
    if (choiceList && options && options.length >= 1) {
      if (showAsCheckbox) {
        return (
          <div className="d-flex">
            {options.map((option, index) => (
              <div
                className={`d-flex align-items-center ${
                  index > 0 ? "ml-3" : ""
                }`}
              >
                <span style={{ fontSize: "0.9rem" }}>
                  {option[valueField]}{" "}
                </span>
                <input
                  style={{ height: "15px", width: "15px" }}
                  className="ml-1"
                  type="checkbox"
                  onChange={e => {
                    if (e.target.checked) {
                      attribute[valueField] = option[valueField];
                    } else {
                      attribute[valueField] = "";
                    }
                    this.onChangeField(index, attribute);
                  }}
                  checked={attribute[valueField] === option[valueField]}
                />
              </div>
            ))}
          </div>
        );
      }

      return (
        <Select
          value={attribute[valueField]}
          onChange={option => {
            attribute[valueField] = option ? option.value : null;
            this.onChangeField(index, attribute);
          }}
          options={this.parseOptionsForDropDown(options, type)}
        />
      );
    }

    if (type === "BOOLEAN") {
      return (
        <select
          style={{ border: "1px solid #cccccc", width: "100%" }}
          className="form-control"
          value={attribute[valueField]}
          onChange={e => {
            attribute[valueField] = e.target.value;
            this.onChangeField(index, attribute);
          }}
        >
          <option value={true}>Yes</option>
          <option value={false}>No</option>
        </select>
      );
    }

    return (
      <input
        style={{ border: "1px solid #cccccc", borderRadius: "4px" }}
        className="form-control"
        type={fieldType}
        autoCapitalize="sentences"
        value={attribute[valueField]}
        onChange={e => {
          attribute[valueField] = e.target.value;
          this.onChangeField(index, attribute);
        }}
      />
    );
  };

  onChangeField = (index, attribute) => {
    let { attributes } = this.props;
    let aIndex = attributes.findIndex(a => a.code.code === attribute.code.code);
    attributes[aIndex] = { ...attribute };
    this.props.saveAttributes([...attributes]);
  };

  render() {
    let {
      availableAttributes = [],
      attributes = [],
      saveAttributes,
      noNewAddition,
      containerStyle,
      containerClass
    } = this.props;

    return (
      <div
        className={`${containerClass ? containerClass : ""} d-flex flex-column`}
        style={containerStyle}
      >
        <div>
          {!noNewAddition && (
            <MPSSelect
              style={{
                borderBottomLeftRadius: attributes.length >= 1 ? 0 : 4,
                borderBottomRightRadius: attributes.length >= 1 ? 0 : 4
              }}
              options={availableAttributes.filter(
                a =>
                  attributes.findIndex(b => b.code.code === a.code.code) === -1
              )}
              onChange={options => {
                let cloned = [...attributes, ...options];
                saveAttributes(cloned);
              }}
              multi
            />
          )}

          {/*{availableAttributes*/}
          {/*  .filter(*/}
          {/*    a => attributes.findIndex(b => b.code.code === a.code.code) === -1*/}
          {/*  )*/}
          {/*  .map(attribute => (*/}
          {/*    <a*/}
          {/*      className="btn btn-sm btn-outline-secondary m-1"*/}
          {/*      style={{ border: "1px solid", cursor: "pointer" }}*/}
          {/*      onClick={() => {*/}
          {/*        let cloned = [...attributes, attribute];*/}
          {/*        saveAttributes(cloned);*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      {attribute.code.display}*/}
          {/*    </a>*/}
          {/*  ))}*/}
        </div>

        {attributes.length >= 1 && (
          <div
            className="py-1"
            style={{
              border: "1px solid #cccccc",
              borderTop: noNewAddition ? "1px solid #cccccc" : 0,
              ...(noNewAddition
                ? { borderRadius: 4 }
                : {
                    borderBottomLeftRadius: 4,
                    borderBottomRightRadius: 4
                  })
            }}
          >
            <ReactDragList
              disabled={this.props.disabled}
              ghostClass="dragGhost"
              dataSource={attributes}
              onUpdate={this.onSort}
              row={(attribute, index) => {
                let { type, editable } = attribute;
                let valueField = getFieldName(type);
                let value = attribute[valueField];
                if (!value && !editable) {
                  return null;
                }
                if (!editable && this.props.hideReadOnly) {
                  return null;
                }
                return (
                  <div
                    className="d-flex mx-1 justify-content-between align-items-center"
                    style={{
                      padding: "0 0.5rem",
                      borderBottom: 0,
                      // borderBottom: this.props.disabled
                      //   ? 0
                      //   : "1px solid #cccccc",
                      fontSize: "0.76562rem",
                      marginBottom: "2px"
                    }}
                  >
                    <div
                      style={{ width: "40%", fontSize: "0.9rem" }}
                      className="text-muted"
                    >
                      {attribute.code.display}
                    </div>
                    <div
                      className="d-flex align-items-center "
                      style={{
                        width: "60%"
                      }}
                    >
                      <div className="d-flex" style={{ width: "90%" }}>
                        {editable ? (
                          this.getFilterFieldFromAttribute(index, attribute)
                        ) : (
                          <span style={{ fontSize: "0.9rem", fontWeight: 600 }}>
                            {value}
                          </span>
                        )}
                      </div>
                      {editable && (
                        <a
                          className="ml-3"
                          onClick={() => {
                            let cloned = [...attributes];
                            cloned.splice(index, 1);
                            saveAttributes(cloned);
                          }}
                        >
                          <i className="fa fa-times" />
                        </a>
                      )}
                    </div>
                  </div>
                );
              }}
            />
          </div>
        )}
      </div>
    );
  }
}

export default AttributesSelector;
