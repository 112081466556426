import React from "react";
import LoginMFAOtp from "./LoginMFAOtp";

export default class LoginMFA extends React.PureComponent {
  onLoginWithMfa = userInput => {
    this.props.onLoginWithMfa(
      userInput,
      this.props.mfaToken,
      this.props.mfaMechanism
    );
  };

  render() {
    const COMPONENTS = {
      OTP_2FA: LoginMFAOtp
    };

    let Component = COMPONENTS[this.props.mfaMechanism];
    return <Component {...this.props} onLoginWithMfa={this.onLoginWithMfa} />;
  }
}
