import React, { Component } from "react";
import { Input } from "reactstrap";
import MPSSelect from "../../../../components/MPSSelect";

class StringAttribute extends Component {
  static getDisplayValue(av) {
    return av.valueString ? av.valueString : null;
  }

  render() {
    let { attributeValue, setData, choiceList, options } = this.props;

    let value = attributeValue.valueString || null;

    if (choiceList) {
      return (
        <MPSSelect
          className="ui-attribute-el-select"
          name="form-field-name"
          value={value}
          onChange={option => {
            // attributeValue.
            setData({ valueString: option ? option.value : null });
          }}
          options={options.map(option => {
            return {
              value: option.valueString,
              label: option.valueString
            };
          })}
          onBlur={this.props.onBlur}
        />
      );
    }

    return (
      <div className="inputHolder flex-row">
        <Input
          className="ui-attribute-el"
          value={value}
          onChange={e => {
            attributeValue.valueString = e.target.value;
            setData(attributeValue);
          }}
          type="text"
          onBlur={this.props.onBlur}
        />
      </div>
    );
  }
}

export default StringAttribute;
