import React, { PureComponent } from "react";
import { getValueFieldNameForFilter, getValueWhenInFilter } from "../Utils";
import Mandatory from "./Mandatory";
import classnames from "classnames";
import AttributeInfo from "./AttributeInfo";

export default class TextAttribute extends PureComponent {
  componentDidMount() {
    const input = document.getElementById("confirm-email");
    if (input) {
      input.onpaste = function(e) {
        e.preventDefault();
      };
    }

    if (this.props.attribute.focus && this.inputEl) {
      this.inputEl.focus();
    }
  }

  render() {
    let {
      placeholder,
      attribute,
      index,
      handleOnBlur,
      changeField
    } = this.props;
    let description = attribute.description || attribute.title;
    return (
      <div
        key={index}
        className="col-12 my-1 pb-2"
        style={{ backgroundColor: "#fff" }}
        ref={attrEl => {
          if (this.props.setAttributeRef) {
            this.props.setAttributeRef(attribute.name, attrEl);
          }
        }}
      >
        {!placeholder && (
          <label style={{ fontWeight: 500 }}>
            {description}
            <Mandatory mandatory={attribute.mandatory} />
          </label>
        )}
        <div className="d-flex flex-column">
          <input
            ref={attrEl => {
              this.inputEl = attrEl;
            }}
            onBlur={() => {
              handleOnBlur(index, attribute);
            }}
            placeholder={placeholder ? description : ""}
            type={
              attribute.dataField === "PatientContactEmail" ? "email" : "text"
            }
            value={getValueWhenInFilter(attribute)}
            onChange={e => {
              let entered = e.target.value;
              attribute.value = getValueFieldNameForFilter(attribute, entered);
              changeField(index, attribute);
            }}
            className={classnames(
              attribute.error
                ? "is-obform-invalid is-invalid form-control"
                : "form-control"
            )}
            id={attribute.attributeId}
            style={{ borderRadius: "4px" }}
          />
          {placeholder && <Mandatory mandatory={attribute.mandatory} />}
          <div className="ob-if invalid-feedback">{attribute.error}</div>
          <AttributeInfo info={attribute.info} />
        </div>
      </div>
    );
  }
}
