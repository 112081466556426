// @flow

import {
  MASTER_DATA_FETCH_ON_LOAD_DONE,
  MASTER_DATA_CREATE_NEW_DONE,
  MASTER_DATA_EDIT_DONE,
  MASTER_DATA_DELETE_DONE,
  LOG_OUT
} from "../actions/actionTypes";

const initialState = {
  masterDatas: [],
  // error INFO
  error: false,
  errorMsg: ""
};

const masterDataReducer = (state = initialState, action) => {
  let masterDatas = state.masterDatas || [];
  switch (action.type) {
    case MASTER_DATA_FETCH_ON_LOAD_DONE:
      return { ...state, masterDatas: action.data };

    case MASTER_DATA_CREATE_NEW_DONE:
      masterDatas.push(action.data);
      return { ...state, masterDatas: [...masterDatas] };

    case MASTER_DATA_EDIT_DONE:
      let index = masterDatas.findIndex(
        dataObj => dataObj.id === action.data.id
      );
      if (index !== -1) {
        masterDatas[index] = action.data;
      }
      return { ...state, masterDatas: [...masterDatas] };

    case MASTER_DATA_DELETE_DONE:
      let dIndex = masterDatas.findIndex(dataObj => dataObj.id === action.data);
      if (index !== -1) {
        masterDatas = [
          ...masterDatas.slice(0, dIndex),
          ...masterDatas.slice(dIndex + 1)
        ];
      }
      return { ...state, masterDatas: masterDatas };

    case LOG_OUT: {
      return initialState;
    }

    default:
      return state;
  }
};

export default masterDataReducer;
