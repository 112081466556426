import React from "react";

export default function AttributeInfo(props) {
  let { info } = props;
  if (!info) {
    return null;
  }

  return <div className="text-info small">{info}</div>;
}
