import React, { PureComponent } from "react";
import {
  getObservationDataHolder,
  getValueFieldNameForFilter,
  getValueWhenInFilter
} from "../Utils";
import { Input } from "reactstrap";
import classnames from "classnames";
import AttributeInfo from "./AttributeInfo";
import Select from "react-select";
import { toKg, toLbs } from "../../../../Common/UserOnboarding/Utils";
import Mandatory from "./Mandatory";

export default class WeightObservation extends PureComponent {
  onChangeUnit = option => {
    let { attribute, index, changeField } = this.props;
    let oldUnit = attribute.unit || "KG";
    let newUnit = option ? option.value : "KG";
    let value = getValueWhenInFilter(attribute);
    if (oldUnit !== newUnit) {
      if (newUnit === "LBS") {
        value = toLbs(value);
      } else if (newUnit === "KG") {
        value = toKg(value);
      }
    }

    attribute.unit = newUnit;
    attribute.value = getValueFieldNameForFilter(attribute, {
      value: {
        [getObservationDataHolder(
          attribute.type || attribute.attributeType
        )]: value
      },
      code: { code: attribute.observationCode.code }
    });

    changeField(index, attribute);
  };

  render() {
    let { attribute, index, handleOnBlur, changeField } = this.props;
    let description = attribute.description || attribute.title;
    let value = getValueWhenInFilter(attribute);
    if (value) {
      value = parseFloat(value);
      if (isNaN(value)) {
        value = "";
      } else {
        value.toFixed(0);
      }
    }

    return (
      <div
        key={index}
        className="col-12 my-1 pb-2"
        style={{ backgroundColor: "#fff" }}
        ref={attrEl => {
          if (this.props.setAttributeRef) {
            this.props.setAttributeRef(attribute.name, attrEl);
          }
        }}
      >
        <label style={{ fontWeight: 500 }}>
          {description}
          <Mandatory mandatory={attribute.mandatory} />
        </label>

        <div className="d-flex  flex-column">
          <div className="d-flex">
            <Input
              onBlur={() => {
                handleOnBlur(index, attribute);
              }}
              type="text"
              value={value}
              onChange={e => {
                let entered = e.target.value;
                if (entered) {
                  attribute.value = getValueFieldNameForFilter(attribute, {
                    value: {
                      [getObservationDataHolder(
                        attribute.type || attribute.attributeType
                      )]: entered
                    },
                    code: { code: attribute.observationCode.code }
                  });
                } else {
                  attribute.value = null;
                }
                changeField(index, attribute);
              }}
              className={classnames(
                attribute.error
                  ? "is-obform-invalid is-invalid form-control"
                  : "form-control"
              )}
              style={{
                borderRadius: "4px",
                width: "70%"
              }}
            />

            <div style={{ width: "30%" }} className="ml-2">
              <Select
                placeholder="Unit"
                options={[
                  { label: "kg", value: "KG" },
                  { label: "lbs", value: "LBS" }
                ]}
                value={attribute.unit || "KG"}
                onChange={this.onChangeUnit}
                onBlur={() => {
                  handleOnBlur(index, attribute);
                }}
                style={{ border: "1px solid #c0c0c0" }}
              />
            </div>
          </div>

          {attribute.error && (
            <div className="ob-if invalid-feedback d-block">
              {attribute.error}
            </div>
          )}

          <AttributeInfo info={attribute.info} />
        </div>
      </div>
    );
  }
}
