import React, { PureComponent } from "react";
import PatientsService from "../../../services/patientsService";
import MPSBarLoader from "../../../components/MPSBarLoader";
import statusService from "../../../services/statusesService";
import { connect } from "react-redux";
import { parseURLQueryParams } from "../../../utils/utils";

class PatientsSearch extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.recorded = false;
  }

  componentDidMount() {
    let { history, phoneNumber } = this.props;
    if (!phoneNumber) {
      this.setState({ error: "Invalid URL" });
      return;
    }

    if (phoneNumber.length < 7) {
      this.setState({ error: "No matching patient found." });
      return;
    }

    this.setState({ loading: true });

    phoneNumber = decodeURIComponent(phoneNumber);
    phoneNumber = phoneNumber.replace(/ /g, "");
    if (phoneNumber.charAt(0) === "0" || phoneNumber.charAt(0) === "+") {
      phoneNumber = phoneNumber.slice(1, phoneNumber.length);
    }

    let criteria = {
      uiFields: [
        {
          dataField: "PatientContactPhone",
          value: {
            valueContactPoints: [{ value: phoneNumber, system: "PHONE" }]
          }
        }
      ],
      mode: "GETPATIENTS_ALL"
    };

    PatientsService.load(0, criteria)
      .then((response = []) => {
        let patients = response.data || [];
        let patient = patients[0];
        if (!patient) {
          this.setState({
            error: "No matching patient found.",
            loading: false
          });
          return;
        }

        let patientId = patient.patientId;
        if (!this.recorded) {
          statusService
            .recordPhoneCall(patientId, {
              type: "STATUS_UPDATE",
              senderCategory: "STAFF",
              title: "Incoming Call",
              detailMessage: `Incoming call from ${
                patient.patientName ? patient.patientName.text : ""
              }, ${phoneNumber} to ${
                this.props.staffName ? this.props.staffName.text : ""
              } at ${new Date().toLocaleString()}`,
              visibility: "ALL"
            })
            .then(e => {
              this.recorded = true;
            })
            .catch(e => {});
        }
        history.push(`/patients/${patientId}?ref=all`);
      })
      .catch(e => {
        this.setState({ loading: false, error: "Unable to load the patient" });
      });
  }

  render() {
    const { loading, error } = this.state;

    if (error) {
      return (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <p className="text-danger">{error}</p>
        </div>
      );
    }

    return (
      <div style={{ margin: "100px auto", width: 100 }}>
        <MPSBarLoader />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let params = parseURLQueryParams(ownProps.location.search);
  return {
    staffName: state.user.data.loggedInUser,
    phoneNumber: params.phoneNumber || null
  };
};

export default connect(mapStateToProps)(PatientsSearch);
