// @flow

import {
  LOAD_STATUSES,
  LOG_OUT,
  STATUSES_LOADED,
  STATUS_ADD,
  STATUS_ADD_DONE,
  STATUS_ADD_FROM_SURVEY,
  STATUS_ADD_FROM_SURVEY_DONE,
  STATUS_RESET_LOADING,
  LOAD_STATUS_TEMPLATES,
  STATUS_TEMPLATES_LOADED,
  STAFF_NOTES_LOADED,
  STATUS_TEMPLATE_ADD_DONE,
  STATUS_TEMPLATE_ADD,
  STATUS_TEMPLATE_ADD_ERROR,
  DISMISS_ERROR,
  ADD_LIBRARY_STATUS_DONE,
  ADD_CONSENT_STATUS_DONE
} from "../actions/actionTypes";

const initialState = {
  patientId: -1,
  loading: false,
  // last page loaded
  page: 0,
  // is there need to load more, currently determined
  // to be false if last page listing returned no results
  loadMore: true,
  list: [],
  // status templates list
  pageTemplates: 0,
  loadMoreTemplates: true,
  templatesList: [],
  // error INFO
  error: false,
  errorMsg: ""
};

const statusesReducer = (state = initialState, action) => {
  let newState;

  switch (action.type) {
    case STATUS_RESET_LOADING:
      return initialState;

    case STATUS_ADD:
    case STATUS_TEMPLATE_ADD:
    case STATUS_ADD_FROM_SURVEY:
      return { ...state, loading: true };

    case STATUS_ADD_DONE:
    case STATUS_ADD_FROM_SURVEY_DONE:
    case ADD_LIBRARY_STATUS_DONE:
    case ADD_CONSENT_STATUS_DONE:
      return initialState;

    case STATUS_TEMPLATE_ADD_DONE:
      return { ...initialState, templateAdded: action.data };

    case STATUS_TEMPLATE_ADD_ERROR:
      return { ...state, loading: false, error: true, errorMsg: action.error };

    case DISMISS_ERROR:
      return { ...state, error: false, errorMsg: "" };

    case LOAD_STATUSES:
    case LOAD_STATUS_TEMPLATES:
      return { ...state, loading: true, patientId: action.patientId };

    case STATUSES_LOADED:
      // console.log('statuses loaded', action);
      newState = {
        ...state,
        loading: false,
        page: action.page,
        loadMore: action.loadMore,
        list: state.list
          .slice(0)
          .concat(
            action.list
              .slice(0)
              .filter(
                item => item.textMessage !== null || item.contentURL !== null
              )
          )
      };
      // console.log('REDUCER STATUSES ', newState);
      return newState;
    case STAFF_NOTES_LOADED:
      // console.log('statuses loaded', action);
      newState = {
        ...state,
        loadMore: false
      };
      // console.log('REDUCER STATUSES ', newState);
      return newState;

    case STATUS_TEMPLATES_LOADED:
      // console.log('statuses loaded', action);
      newState = {
        ...state,
        loading: false,
        pageTemplates: action.page,
        loadMoreTemplates: action.loadMore,
        templatesList: state.templatesList.slice(0).concat(action.list.slice(0))
      };
      // console.log('REDUCER STATUSES ', newState);
      return newState;

    case LOG_OUT:
      return initialState;
    default:
      return state;
  }
};

export default statusesReducer;
