import React, { Component } from "react";

class QuestionDecimal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: "",
    };
  }

  validate = () => {
    this.setState({ error: false });
    let { question, validateQuestions } = this.props;

    let value =
      question.answers && question.answers[0]
        ? question.answers[0].valueDouble
        : "";
    if (value && isNaN(value)) {
      this.setState({ error: true });
    }
    validateQuestions();
  };
  handleKeyUp = () => {
    let { question, validateQuestions } = this.props;
    if (question.answers && question.answers[0]) {
      validateQuestions();
    }
  };

  render() {
    let { question, setData, validateQuestions } = this.props;
    let { error } = this.state;

    return (
      <React.Fragment>
        <input
          value={
            question.answers && question.answers[0]
              ? question.answers[0].valueDouble
              : ""
          }
          type="text"
          onChange={(e) => {
            setData({
              valueDouble: e.target.value,
            });
          }}
          // onKeyUp={this.handleKeyUp}
          defaultValue={question.initialValue}
          className={"form-control"}
          onBlur={this.validate}
        />
        {error && (
          <div className="invalid-feedback" style={{ display: "block" }}>
            Answer is invalid
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default QuestionDecimal;
