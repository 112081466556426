import React, { PureComponent } from "react";
import fileService from "../../services/fileService";
import MPSBarLoader from "../../components/MPSBarLoader";
import PlayIcon from "./Icons/PlayIcon";

class VideoPlayer extends PureComponent {
  static propTypes = {};

  static defaultProps = {
    ...PureComponent.defaultProps
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    fileService.getFile(this.props.thumbnailURL).then(data => {
      this.setState({ thumbnail: data });
    });
  }

  onClick = () => {
    this.setState({ loading: true });
    fileService
      .getFile(this.props.url)
      .then(data => {
        this.setState({ dataUrl: data, loading: false });
      })
      .catch(e => {
        this.setState({ loading: false });
      });
  };

  render() {
    const { dataUrl, thumbnail, loading } = this.state;
    const { url } = this.props;

    if (!dataUrl) {
      return (
        <div
          style={{ width: "100%" }}
          className="d-flex justify-content-center align-items-center"
        >
          <div
            className={"d-flex justify-content-center align-items-center"}
            style={{
              height: "360px",
              width: "90%",
              backgroundImage: "url('" + thumbnail + "')",
              backgroundColor: "#f1f1f1",
              backgroundPosition: "center",
              // backgroundSize: "cover",
              backgroundSize: "contain",
              borderRadius: "3px",
              backgroundRepeat: "no-repeat"
            }}
          >
            {!loading && <PlayIcon onClick={this.onClick} />}
            {loading && (
              <div style={{ margin: "100px auto", width: 50 }}>
                <MPSBarLoader />
              </div>
            )}
          </div>
        </div>
      );
    }

    return (
      <div className="d-flex justify-content-center align-items-center">
        <video
          width={"90%"}
          height={this.props.height || "360px"}
          controls
          preload="none"
          poster={thumbnail}
          autoPlay
        >
          <source src={dataUrl} />
          Sorry, your browser doesn't support embedded videos.
        </video>
      </div>
    );
  }
}

export default VideoPlayer;
