import { Alert, Button } from "reactstrap";
import React from "react";

class AnonymousScreeningMessage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onHover = () => {
    this.setState({ hover: true });
  };

  exitOnHover = () => {
    this.setState({ hover: false });
  };

  render() {
    let {
      appointment,
      appTheme,
      screeningDone,
      allowScreening,
      submitted
    } = this.props;
    let { screening } = appointment;
    let screeningResult = screening ? screening.screeningResult : null;

    let themeColor = appTheme.themeColor
      ? appTheme.themeColor.replace("0x", "#")
      : "#20a8d8";
    let style = {
      border: "1px solid",
      backgroundColor: themeColor,
      borderColor: themeColor,
      color: "#fff"
    };

    if (this.state.hover) {
      style.opacity = 0.9;
    }

    if (!submitted && !this.props.screeningDone) {
      return (
        <div className="d-flex align-items-center justify-content-center">
          <Button
            onClick={this.props.showScreeningView}
            style={style}
            onMouseEnter={this.onHover}
            onMouseLeave={this.exitOnHover}
          >
            Complete Screening Now
          </Button>
        </div>
      );
    }

    return (
      <Alert
        color={screeningResult === "COMPLETED_NEGATIVE" ? "success" : "danger"}
      >
        <div className="d-flex align-items-center justify-content-center flex-column">
          <div className="mb-1 font-xl">
            <i className="fa fa-check-circle mr-1" /> Submitted.{" "}
          </div>

          {submitted && <div>Thanks for submitting the screening.</div>}

          {!submitted && screeningDone && (
            <div>
              You have already completed the screening for this appointment.
            </div>
          )}

          {screeningResult === "COMPLETED_POSITIVE" && (
            <div className="font-lg" style={{ fontWeight: 500 }}>
              Your answers require some clarification. Please phone reception.
            </div>
          )}

          {allowScreening && (
            <div className="d-flex align-items-center mt-2 justify-content-center">
              <Button
                onClick={this.props.showScreeningView}
                style={style}
                onMouseEnter={this.onHover}
                onMouseLeave={this.exitOnHover}
              >
                Submit Screening Again
              </Button>{" "}
            </div>
          )}
        </div>
      </Alert>
    );
  }
}

export default AnonymousScreeningMessage;
