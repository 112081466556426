// @flow

import {
  LOAD_MEMBERS,
  LOG_OUT,
  MEMBERS_LOADED,
  PATIENTS_MEMBERS_RESET_LOADING
} from "../actions/actionTypes";
import { REHYDRATE } from "redux-persist";

const initialState = {
  membersLoading: false,
  membersList: [],
  membersLoadMore: true,
  membersPage: 0
};

const membersReducer = (state = initialState, action) => {
  let list, index;
  switch (action.type) {
    case PATIENTS_MEMBERS_RESET_LOADING:
      return initialState;

    case LOAD_MEMBERS:
      return { ...state, membersLoading: true, loading: false };

    case MEMBERS_LOADED:
      // console.log('patients loaded', action);
      return {
        ...state,
        membersLoading: false,
        membersPage: action.membersPage,
        membersLoadMore: action.membersLoadMore,
        membersList:
          action.page === 0
            ? action.membersList
            : state.membersList.slice(0).concat(action.membersList)
      };

    case LOG_OUT:
      return initialState;

    case REHYDRATE:
      if (action.payload && action.payload.members) {
        return { ...state, ...action.payload.members, loading: false };
      }
      return state;

    default:
      return state;
  }
};

export default membersReducer;
