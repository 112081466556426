import React, { PureComponent } from "react";
import { Row, Col, Button, Input, InputGroup } from "reactstrap";
import classnames from "classnames";
import MPSBarLoader from "../../components/MPSBarLoader";
import Text from "../../components/Text";
import Messages from "../../Shared/Messages";
import I18nProvider from "../../services/I18nProvider";
const intl = I18nProvider.getIntlProvider();

class LoginMFAOtp extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      otp: ""
    };
  }

  onLogin = e => {
    e.preventDefault();
    this.props.onLoginWithMfa(this.state.otp);
  };

  onUpdateOtp = e => {
    this.setState({ otp: e.target.value });
  };

  render() {
    let { loading, loginError } = this.props;
    let disabled = loading;

    return (
      <form onSubmit={this.onLogin}>
        <div className="text-center font-lg my-3">
          <Text content={Messages.mfa_enter_otp} />
        </div>
        <InputGroup className="mb-3">
          <Input
            value={this.state.otp}
            onChange={this.onUpdateOtp}
            type="text"
            autoCapitalize="none"
            placeholder={intl.formatMessage(Messages.otp)}
            disabled={disabled}
            className={classnames(loginError ? "is-invalid" : "")}
            id="login-txt-otp"
            style={{
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0
            }}
          />
        </InputGroup>

        <Row>
          <Col
            xs="12"
            className="d-flex align-items-center justify-content-end mt-2"
          >
            {loading ? (
              <div
                className="d-flex align-items-center"
                style={{ width: 75, height: 37 }}
              >
                <MPSBarLoader width={75} />
              </div>
            ) : (
              <Button
                color="primary"
                className="px-4"
                disabled={disabled}
                id="login-btn-submit"
                style={{
                  width: "100%",
                  padding: "0.5rem 0.75rem",
                  fontWeight: 600
                }}
              >
                <Text content={Messages.submit} />
              </Button>
            )}
          </Col>
        </Row>
      </form>
    );
  }
}

export default LoginMFAOtp;
