import React, { PureComponent } from "react";
import MPSButton from "../../components/Buttons/Buttons/MPSButton";
import Text from "../../components/Text";
import Messages from "../../Shared/Messages";

class CredentialsPresentMessage extends PureComponent {
  getEmailAndPhone = () => {
    let { email, phone } = this.props;

    if (email && phone) {
      return `${email}/phone`;
    }

    if (email) {
      return `${email}`;
    }

    if (phone) {
      return `${phone}`;
    }

    return null;
  };

  render() {
    let { facilityName } = this.props;

    return (
      <div style={{ color: "#28623c" }} className="mb-4">
        <Text
          content={Messages.wc_back_associated}
          data={{
            facilityName: facilityName,
            emailAndPhone: this.getEmailAndPhone()
          }}
        />
        <br />
        <br />
        <Text content={Messages.click_if_dnk_pwd} />
        <MPSButton
          type="link"
          className="mx-1"
          onClick={() => {
            this.props.history.push("/forgot-password");
          }}
          target="_blank"
        >
          <Text content={Messages.here} />
        </MPSButton>
        <Text content={Messages.to_reset} />
      </div>
    );
  }
}

export default CredentialsPresentMessage;
