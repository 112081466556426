import {
  STORE_INFO_PANEL,
  REMOVE_INFO_PANEL,
  LOG_OUT,
  REMOVE_STAFF_INFO_PANEL,
  STORE_STAFF_INFO_PANEL,
  SET_FACILITY_ID,
  REHYDRATE_PTI_FROM_LS,
  REHYDRATE_SI_FROM_LS,
} from "../actions/actionTypes";

import StorageService from "../../services/storageService";

export const emptyInfoPanel = {
  details: {},
  sections: [],
};

const INFO_PANEL_STAFF_KEY = "STAFF_INFO_PANEL";
const INFO_PANEL_KEY = "INFO_PANEL";

const initialState = {
  infoPanel: null,
  staffInfoPanel: null,
  facilityId: null,
};

const infoPanelReducer = (state = initialState, action) => {
  switch (action.type) {
    case STORE_INFO_PANEL:
      let infoPanel = state.infoPanel;
      let newInfoPanel = { ...infoPanel, ...action.infoPanel };
      StorageService.addToLocalStorage(
        INFO_PANEL_KEY + "_" + state.facilityId + "_" + action.patientTypeId,
        newInfoPanel
      );
      return {
        ...state,
        infoPanel: newInfoPanel,
      };

    case STORE_STAFF_INFO_PANEL:
      let staffInfoPanel = state.staffInfoPanel;
      let newStaffInfoPanel = { ...staffInfoPanel, ...action.infoPanel };
      StorageService.addToLocalStorage(
        INFO_PANEL_STAFF_KEY + "_" + state.facilityId,
        newStaffInfoPanel
      );
      return {
        ...state,
        staffInfoPanel: newStaffInfoPanel,
      };

    case REMOVE_INFO_PANEL:
      StorageService.removeFromLocalStorage(
        INFO_PANEL_KEY + "_" + state.facilityId + "_" + action.patientTypeId
      );
      return { ...state, infoPanel: null };

    case REMOVE_STAFF_INFO_PANEL:
      StorageService.removeFromLocalStorage(
        INFO_PANEL_STAFF_KEY + "_" + state.facilityId
      );
      return { ...state, staffInfoPanel: null };

    // case REHYDRATE:
    //   if (action.payload && action.payload.infoPanel) {
    //     return action.payload.infoPanel;
    //   }
    //   return state;

    case SET_FACILITY_ID:
      return {
        ...state,
        facilityId: action.facilityId,
      };

    case REHYDRATE_PTI_FROM_LS:
      let infoPanelFromS = StorageService.getFromLocalStorage(
        INFO_PANEL_KEY + "_" + state.facilityId + "_" + action.patientTypeId
      );
      return {
        ...state,
        infoPanel: infoPanelFromS || null,
      };

    case REHYDRATE_SI_FROM_LS:
      let staffInfoPanelFromS = StorageService.getFromLocalStorage(
        INFO_PANEL_STAFF_KEY + "_" + state.facilityId
      );
      return {
        ...state,
        staffInfoPanel: staffInfoPanelFromS || null,
      };

    case LOG_OUT: {
      // return { infoPanel: null };
      return null;
      break;
    }

    default:
      return state;
  }
};

export default infoPanelReducer;
