import React from "react";
import Messages from "../../Shared/Messages";
import I18nProvider from "../../services/I18nProvider";
import { Button, Col, Row } from "reactstrap";
import Text from "../../components/Text";
import SignUpCredentialsForm from "./SignUpCredentialsForm";
import CredentialsPresentMessage from "../UserOnboarding/CredentialsPresentMessage";
import userService from "../../services/userService";

class SignupExistingPatient extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  setCredentialsData = (name, value) => {
    this.setState({ [name]: value });
  };

  onSubmit = () => {
    this.setState({ errorTerms: false });
    let valid = this.credForm.validate();
    if (!valid) {
      return;
    }

    userService
      .onboardingSetCredentialsV2(
        this.props.patientId,
        this.state.username,
        this.state.password
      )
      .then(response => {
        this.props.incrementStep(2);
      });
  };

  render() {
    let { username, password, confirmPassword } = this.state;
    let { facilityInfo } = this.props;

    //if patient already there - show a message
    //if no, ask them to setup credentials

    let { code } = this.props;

    if (code === "USER_HAS_USERNAME") {
      return (
        <Row>
          <Col xs="12">
            <CredentialsPresentMessage
              history={this.props.history}
              facilityName={facilityInfo.facilityName}
            />
          </Col>
        </Row>
      );
    } else if (code === "USER_HAS_USERNAME_NOT_ACTIVATED") {
      return (
        <Row>
          <Col xs="12">
            <div style={{ color: "#28623c" }} className="mb-4">
              <Text
                content={Messages.wc_activate}
                data={{ facilityName: facilityInfo.facilityName }}
              />
            </div>
          </Col>
        </Row>
      );
    }

    return (
      <div>
        <Row>
          <Col xs="12">
            <div className="mb-3 text-center">
              <h5 style={{ marginBottom: 0 }}>
                <Text content={Messages.login_creds} />
              </h5>
              <div>
                <Text content={Messages.set_un_pwd} />
              </div>
            </div>
            <SignUpCredentialsForm
              ref={credForm => {
                this.credForm = credForm;
              }}
              formErrors={this.state.credFormErrors}
              setCredentialsData={this.setCredentialsData}
              username={username}
              password={password}
              confirmPassword={confirmPassword}
              facilityCode={this.props.facilityCode}
              email={this.state.email}
            />

            <div className="d-flex justify-content-between">
              <div>
                <Button
                  color="secondary"
                  className="px-4"
                  block
                  onClick={this.props.goBack}
                >
                  <Text content={Messages.back} />
                </Button>
              </div>
              <div>
                <Button
                  size="lg"
                  color="primary"
                  className="px-4"
                  block
                  onClick={this.onSubmit}
                >
                  <Text content={Messages.submit} />
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default SignupExistingPatient;
