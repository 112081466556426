import React, { Component } from "react";

class MapWithLocationPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.initMap();
  }

  initMap() {
    let google = window.google;
    let pos = this.props.position;
    let map = new google.maps.Map(
      document.getElementById(`location-map-with-selector`),
      {
        center: pos,
        zoom: 10
      }
    );

    if (this.props.markerPosition) {
      this.marker = new google.maps.Marker({
        map: map,
        position: this.props.markerPosition,
        icon: "/img/markers/marker-icon-pink.png"
      });
    }

    google.maps.event.addListener(map, "click", event => {
      let latLng = event.latLng;

      if (this.marker) {
        this.marker.setMap(null);
        this.marker = null;
      }

      this.marker = new google.maps.Marker({
        map: this.map,
        position: latLng,
        icon: "/img/markers/marker-icon-pink.png"
      });

      let geocoder = new google.maps.Geocoder();

      geocoder.geocode({ location: latLng }, (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            this.setData(
              latLng.lat(),
              latLng.lng(),
              results[0].formatted_address
            );
          } else {
            this.setData(latLng.lat(), latLng.lng());
          }
        } else {
          this.setState(latLng.lat(), latLng.lng());
        }
      });
    });

    this.map = map;
  }

  setData = (lat, lng, location) => {
    this.props.setData(location, { latitude: lat, longitude: lng });
    this.setState({
      lat,
      lng,
      location
    });
  };

  render() {
    let { lat, lng, location } = this.state;

    return (
      <div>
        <div className="text-center my-2">
          Click on map to choose a location.
        </div>

        {lat && (
          <div className="my-3">
            <h6>
              {`Selected Location: Latitude: ${lat}, Longitude: ${lng} ${
                location ? `Location: ${location}` : ""
              }`}
            </h6>
          </div>
        )}

        <div className="row">
          <div className="col-md-12">
            <div
              style={{ height: "500px" }}
              id={`location-map-with-selector`}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default MapWithLocationPicker;
