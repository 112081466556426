import React, { PureComponent } from "react";
import Messages from "../../Shared/Messages";
import Text from "../../components/Text";
import { FEATURES } from "../../services/featuresService";
import I18nProvider from "../../services/I18nProvider";
const intl = I18nProvider.getIntlProvider();

class SignupConfirmation extends PureComponent {
  render() {
    let { postSubmitMessage, fromApp, facilityInfo, loginUserId } = this.props;

    let provisionedFeatures = facilityInfo.provisionedFeatures || [];
    let byPassOnboardEmailTelValidation = false;
    if (
      provisionedFeatures.indexOf(
        FEATURES.BYPASS_ONBOARD_EMAIL_TEL_VALIDATION
      ) != -1
    ) {
      byPassOnboardEmailTelValidation = true;
    }

    return (
      <div id="self-signup-confirmation">
        {byPassOnboardEmailTelValidation ? (
          <p>
            <Text
              content={Messages.acct_ready}
              data={{
                loginUserId: loginUserId
                  ? intl.formatMessage(Messages.username_is, {
                      userName: loginUserId,
                      bold: str => <strong>{str}</strong>
                    })
                  : ""
              }}
            />
          </p>
        ) : (
          <>
            {postSubmitMessage && (
              <p>
                <div dangerouslySetInnerHTML={{ __html: postSubmitMessage }} />
              </p>
            )}

            {!postSubmitMessage && (
              <p>
                <Text content={Messages.signup_conf_thank_u} />
              </p>
            )}
          </>
        )}

        {/*{noConfirmationEmail && (*/}
        {/*  <p>*/}
        {/*    <div*/}
        {/*      dangerouslySetInnerHTML={{*/}
        {/*        __html: welcomeMessageWithoutConfirmation*/}
        {/*      }}*/}
        {/*    />*/}
        {/*  </p>*/}
        {/*)}*/}

        {fromApp && (
          <div>
            <b>
              <Text content={Messages.signup_conf_app} />
            </b>
          </div>
        )}

        {!fromApp && (
          <div>
            <div className="mt-3">
              <Text content={Messages.signup_conf_ins} />
            </div>

            <div className="my-3 d-flex justify-content-center">
              <a href="https://itunes.apple.com/app/mypatientspace/id1443558006?mt=8">
                <img
                  src="https://www.mypatientspace.com/assets/img/download-appstore.png"
                  alt="iPhone/iPad"
                  style={{ width: "135px", height: "40px" }}
                />
              </a>

              <a href="https://play.google.com/store/apps/details?id=com.mypatientspace.mypatientspace">
                <img
                  src="https://www.mypatientspace.com/assets/img/download-playstore.jpeg"
                  alt="Android"
                  style={{ width: "135px", height: "40px", marginLeft: "10px" }}
                />
              </a>
            </div>
          </div>
        )}
        {/*{noConfirmationEmail && (*/}
        {/*  <div>*/}
        {/*    If you don't remember the username or password, click*/}
        {/*    <Link className="mx-1" to="/forgot-password">*/}
        {/*      here*/}
        {/*    </Link>*/}
        {/*    to reset.*/}
        {/*  </div>*/}
        {/*)}*/}
      </div>
    );
  }
}

export default SignupConfirmation;
