import { Card, CardBody } from "reactstrap";
import React from "react";
import { getMasterDataText } from "../../../StaffApp/Appointments/Utils";
import Messages from "../../../Shared/Messages";
import Text from "../../../components/Text";
import I18nProvider from "../../../services/I18nProvider";
const intl = I18nProvider.getIntlProvider();

class NewAppointmentConfirmationPage extends React.PureComponent {
  render() {
    let { staffName, slot, masterData } = this.props;
    return (
      <div className="app my-4">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center my-2">
            <div>
              <img
                alt="myPatientSpace"
                src="/img/logo-symbol.png"
                height={36}
              />
            </div>
          </div>
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "calc( 100vh - 85px)" }}
          >
            <div className="col-sm-12 col-md-8">
              <Card
                style={{
                  boxShadow: "0px 2px 8px rgba(0,0,0,0.5)",
                  borderRadius: "6px",
                  border: 0
                }}
              >
                <CardBody className="text-center">
                  <div className="font-lg">
                    {`${intl.formatMessage(Messages.appt_booked_for_slot, {
                      slot: slot
                    })} ${
                      staffName
                        ? intl.formatMessage(Messages.with) + " " + staffName
                        : ""
                    } ${
                      masterData.length >= 1
                        ? intl.formatMessage(Messages.for_text) +
                          " " +
                          getMasterDataText(masterData)
                        : ""
                    }.`}
                  </div>
                  <div className="font-lg mt-3">
                    <Text content={Messages.sent_appt_email} />
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NewAppointmentConfirmationPage;
