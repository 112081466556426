import { http } from "../utils/http";
import { API_ENDPOINTS, PAGE_SIZE } from "../config";

class PatientTypesService {
  static addAttribute(profileId, data) {
    return http.request(
      API_ENDPOINTS.addProfileAttribute(profileId),
      "post",
      data
    );
  }

  static updateAttribute(profileId, attributeId, data) {
    return http.request(
      API_ENDPOINTS.updateProfileAttribute(profileId, attributeId),
      "patch",
      data
    );
  }

  static deleteAttribute(profileId, attributeId) {
    return http.request(
      API_ENDPOINTS.deleteProfileAttribute(profileId, attributeId),
      "delete"
    );
  }

  static addProfileMarkup(profileId, data) {
    return http.request(
      API_ENDPOINTS.addProfileMarkup(profileId),
      "post",
      data
    );
  }

  static add(name, description, provisionedPackage) {
    const data = {
      patientType: name,
      patientTypeText: description,
      provisionedPackage: provisionedPackage,
    };
    return http
      .request(API_ENDPOINTS.addPatientType(), "post", data)
      .then((data) => {
        return data.id;
      });
  }

  static edit(patientTypeId, form) {
    form.surveyNameForDashboard = form.surveyName;
    return http.request(
      API_ENDPOINTS.editPatientType(patientTypeId),
      "patch",
      form
    );
  }

  static loadTemplateData() {
    return http.request(API_ENDPOINTS.loadPatientTypesTemplate());
  }

  static loadSingle(id) {
    return http.request(API_ENDPOINTS.loadSinglePatientType(id));
  }

  static loadTopicsFromMasterData(patientTypeId, type) {
    return http
      .request(API_ENDPOINTS.topicsFromMasterData(patientTypeId, type))
      .then((response) => {
        let topics = response ? (response.data ? response.data : []) : [];
        let allTopics = [];
        if (patientTypeId) {
          allTopics = topics.map((topic) => {
            return { value: topic.code, label: topic.display };
          });
        } else {
          let topicsMap = {};
          topics.forEach((t) => {
            let key = t.code + "-" + t.display;
            if (!topicsMap[key]) {
              topicsMap[key] = { value: t.code, label: t.display };
            }
          });
          allTopics = Object.values(topicsMap);
        }
        return allTopics;
      });
  }

  static load(page, all) {
    let endPoint =
      API_ENDPOINTS.loadPatientTypes() + `?page=${page}&size=${PAGE_SIZE}`;
    if (all) {
      endPoint = API_ENDPOINTS.loadPatientTypes() + `?page=0&size=1000`;
    }

    return http.request(endPoint).then((data) => {
      if (data && data._embedded) {
        return {
          data: page <= data.page.totalPages ? data._embedded.patientTypes : [],
          loadMore: data.page.totalPages > page + 1,
        };
      } else {
        return {
          data: [],
          loadMore: false,
        };
      }
    });
  }

  static loadAll() {
    return http
      .request(API_ENDPOINTS.loadPatientTypes() + `?page=0&size=500`)
      .then((data) => {
        return data && data._embedded ? data._embedded.patientTypes : [];
      });
  }

  //KLC changed 20 to 500: this is not a heavy payload, and we need to be able search all on the client-side
  //paging will still work if there are more than 500 attributes.
  static loadUIAttributes(patientTypeId, page, size = 500) {
    return http
      .request(
        API_ENDPOINTS.addProfileAttribute(patientTypeId) +
          `?page=${page}&size=${size}`
      )
      .then((response) => {
        if (response && response._embedded) {
          return {
            attributes: response._embedded.uIAttributes || [],
            pageInfo: response.page,
          };
        }
        return {
          attributes: [],
          pageInfo: { number: -1 },
        };
      });
  }

  static loadAllAttributes(patientTypeId) {
    return http
      .request(
        API_ENDPOINTS.addProfileAttribute(patientTypeId) + `?page=0&size=1000`
      )
      .then((data) => {
        return data && data._embedded ? data._embedded.uIAttributes : [];
      });
  }

  static loadUiAttributeDataFields(mps) {
    let url = API_ENDPOINTS.uiAttributeDataFields;
    if (mps) {
      url = API_ENDPOINTS.uiAttributeDataFieldsMPS;
    }
    return http.request(url).then((data) => {
      return data && data._embedded ? data._embedded.valueParameters : [];
    });
  }

  static deletePatientType(typeId) {
    return http.request(
      API_ENDPOINTS.addPatientType() + "/" + typeId,
      "delete"
    );
  }

  static copyAndCreatePackage(name, description, provisionedPackage) {
    const data = {
      patientType: name,
      patientTypeText: description,
      clonePatientTypeAndCreatePackage: true,
      packageToClone: provisionedPackage,
    };
    return http
      .request(API_ENDPOINTS.addPatientType(), "post", data)
      .then((data) => {
        return data.id;
      });
  }

  static exportPackage(patientTypeId) {
    return http
      .request(API_ENDPOINTS.exportPatientTypePackage(patientTypeId), "post")
      .then((data) => {
        return JSON.stringify(data);
      });
  }

  static importPackage(data) {
    let api = API_ENDPOINTS.importPatientTypePackage;
    let { replaceMessages, replaceStaffInfoPanel, replaceOnboardMechanism } =
      data;

    api +=
      `${replaceMessages ? "&replaceMessages=true" : ""}` +
      `${replaceStaffInfoPanel ? "&replaceStaffInfoPanel=true" : ""}` +
      `${replaceOnboardMechanism ? "&replaceOnboardMechanism=true" : ""}`;

    delete data.replaceMessages;
    delete data.replaceStaffInfoPanel;
    delete data.replaceOnboardMechanism;

    return http.request(api, "post", data).then((response) => {
      return response.id;
    });
  }

  static loadRules(patientTypeId) {
    return http
      .request(API_ENDPOINTS.rules(patientTypeId) + "?page=0&size=100")
      .then((data) => {
        if (data && data._embedded) {
          return data._embedded.ruleMaps;
        } else {
          return [];
        }
      });
  }

  static deleteRule(patientTypeId, ruleId) {
    return http.request(
      API_ENDPOINTS.rules(patientTypeId) + "/" + ruleId,
      "delete"
    );
  }

  static createNewRole(patientTypeId, formData) {
    return http.request(API_ENDPOINTS.rules(patientTypeId), "post", formData);
  }

  static loadRole(patientTypeId, ruleId) {
    return http.request(API_ENDPOINTS.rules(patientTypeId) + "/" + ruleId);
  }

  static editRule(patientTypeId, ruleId, ruleData) {
    return http.request(
      API_ENDPOINTS.rules(patientTypeId) + "/" + ruleId,
      "patch",
      ruleData
    );
  }

  static copyRule(patientTypeId, ruleId, ruleData) {
    return http.request(
      API_ENDPOINTS.ruleClone(patientTypeId, ruleId),
      "post",
      ruleData
    );
  }

  static runRuleMap(patientTypeId, ruleMapId, execute) {
    let api = API_ENDPOINTS.runRuleMap(patientTypeId, ruleMapId);
    if (execute) {
      api += "?execute=true";
    }
    return http.request(api);
  }

  static fetchAddPatientScreen(patientTypeId) {
    return http.request(API_ENDPOINTS.addPatientScreen(patientTypeId));
  }

  static fetchPatientInfoPanel(patientTypeId) {
    return http.request(API_ENDPOINTS.patientInfoPanelGenerated(patientTypeId));
  }

  static fetchCurrentPatientInfoPanel(patientTypeId, exportContent) {
    return http.request(
      API_ENDPOINTS.patientInfoPanel(patientTypeId, exportContent)
    );
  }

  static loadRuleLogs(patientTypeId, ruleId, page, size = 5) {
    return http
      .request(
        API_ENDPOINTS.ruleLogs(patientTypeId, ruleId) +
          `&page=${page}&size=${size}`
      )
      .then((response) => {
        if (response && response._embedded) {
          return {
            ruleLogs: response._embedded.ruleAuditLogs || [],
            pageInfo: response.page,
          };
        }
        return {
          ruleLogs: [],
          pageInfo: { number: -1 },
        };
      });
  }
}

const patientTypesService = PatientTypesService;

export default patientTypesService;
