import React, { PureComponent } from "react";
import classnames from "classnames";
import Mandatory from "./Mandatory";
import AttributeInfo from "./AttributeInfo";
import DateField from "../../../../components/Date/DateField";
import moment from "moment";
import { getMinValue } from "./DateAttribute";
import I18nProvider from "../../../../services/I18nProvider";
import Messages from "../../../Messages";
const intl = I18nProvider.getIntlProvider();

export default class DateObservation extends PureComponent {
  render() {
    let {
      placeholder,
      attribute,
      index,
      handleOnBlur,
      changeField
    } = this.props;
    let description = attribute.description || attribute.title;
    let value =
      attribute.value &&
      attribute.value.valueObservation &&
      attribute.value.valueObservation.value
        ? attribute.value.valueObservation.value.valueDate
        : null;

    return (
      <div
        key={index}
        className="col-12 my-1 pb-2"
        style={{ backgroundColor: "#fff" }}
        ref={attrEl => {
          if (this.props.setAttributeRef) {
            this.props.setAttributeRef(attribute.name, attrEl);
          }
        }}
      >
        <div className="d-flex flex-column">
          {!placeholder && (
            <div className="d-flex align-items-center justify-content-between">
              <label style={{ fontWeight: 500 }}>
                {description}
                <Mandatory mandatory={attribute.mandatory} />
              </label>
            </div>
          )}

          {placeholder && (
            <label style={{ marginBottom: 0 }} className="text-muted">
              {description}
            </label>
          )}
        </div>
        <div className="d-flex flex-column">
          <DateField
            tabIndex={index}
            style={{ height: "35px", borderRadius: "4px", width: "100%" }}
            className={classnames(
              attribute.error
                ? "is-obform-invalid is-invalid form-control"
                : "form-control"
            )}
            error={attribute.error}
            value={value}
            onChange={val => {
              if (val) {
                attribute.value = {
                  valueObservation: {
                    value: { valueDate: val },
                    code: { code: attribute.observationCode.code }
                  }
                };
              } else {
                attribute.value = null;
              }
              changeField(index, attribute);
            }}
            onBlur={() => {
              if (value) {
                let replaced = value.replace(/D/g, "");
                replaced = replaced.replace(/M/g, "");
                replaced = replaced.replace(/Y/g, "");
                if (replaced.length !== 10) {
                  attribute.error = intl.formatMessage(
                    Messages.enter_valid_date
                  );
                  changeField(index, attribute);
                  return;
                }

                let date = moment(value, "DD/MM/YYYY");
                if (!date.isValid()) {
                  attribute.error = intl.formatMessage(
                    Messages.enter_valid_date
                  );
                  changeField(index, attribute);
                  return;
                }
              }
              handleOnBlur(index, attribute);
            }}
            clearable={!!value}
            placeholder={description}
            mandatory={attribute.mandatory}
            min={getMinValue(attribute)}
          />

          {placeholder && <Mandatory mandatory={attribute.mandatory} />}
          {attribute.error && (
            <div className="ob-if invalid-feedback d-block">
              {attribute.error}
            </div>
          )}
          <AttributeInfo info={attribute.info} />
        </div>
      </div>
    );
  }
}
