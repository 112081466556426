import React, { Component } from "react";
import { FormGroup, Input } from "reactstrap";
import MPSSelect from "../../../../components/MPSSelect";

class DoubleObservation extends Component {
  static getDisplayValue(attributeValue, valuePlace) {
    if (
      attributeValue.valueObservation &&
      attributeValue.valueObservation.value
    ) {
      let value = attributeValue.valueObservation.value;
      if (valuePlace === "from") {
        return value.valueFromDouble;
      } else if (valuePlace === "to") {
        return value.valueToDouble;
      }
      // return attributeValue.valueObservation.value.valueDouble
      //   ? attributeValue.valueObservation.value.valueDouble.toFixed(2)
      //   : null;

      let valueDouble = value.valueDouble;
      if (valueDouble) {
        if (valueDouble.toFixed) {
          return valueDouble.toFixed(2);
        }
        return valueDouble;
      } else if (value.valueAsString) {
        return value.valueAsString;
      }
      return null;
    }
    return null;
  }

  static getOptionsList(options) {
    return options.map(option => {
      let val = option.valueObservation.value.valueDouble;
      return { value: val, label: val };
    });
  }

  render() {
    let {
      attributeValue,
      choiceList,
      options = [],
      setData,
      observationCode
    } = this.props;

    attributeValue.valueObservation = attributeValue.valueObservation || {};
    attributeValue.valueObservation.value =
      attributeValue.valueObservation.value || {};
    let obsVal = attributeValue.valueObservation.value.valueDouble || null;
    attributeValue.valueObservation.code = observationCode;

    if (choiceList) {
      return (
        <div className="inputHolder flex-row">
          <MPSSelect
            name="form-field-name"
            value={obsVal}
            onChange={option => {
              if (option) {
                attributeValue.valueObservation.value.valueDouble =
                  option.value;
                attributeValue.valueObservation.value.valueString = null;
              } else {
                attributeValue.valueObservation.value = {};
              }
              setData(attributeValue);
            }}
            options={options.map(option => {
              let val = option.valueObservation.value.valueDouble;
              return { value: val, label: val };
            })}
            className="ui-attribute-el-select"
            onBlur={this.props.onBlur}
          />
        </div>
      );
    } else {
      return (
        <div className="inputHolder flex-row">
          <Input
            className="ui-attribute-el"
            value={obsVal}
            onChange={e => {
              attributeValue.valueObservation.value.valueDouble =
                e.target.value;
              attributeValue.valueObservation.value.valueString = null;
              setData(attributeValue);
            }}
            type="text"
            onBlur={this.props.onBlur}
          />
        </div>
      );
    }
  }
}

export default DoubleObservation;
