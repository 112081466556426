export const CONTINUE_TEXT_W_LANG = {
  en_US: "Continue in English",
  ar_AE: "استمر بالعربية",
  es_ES: "Continuar en español",
  es_LA: "Continuar en español",
  fr_FR: "Continuer en français",
  pt_BR: "Continue em portugues",
  pt_PT: "Continue em portugues",
  iw_IL: "המשך בעברית"
};

export const CONTINUE_TEXT = {
  en_US: "Continue",
  ar_AE: "يكمل",
  es_ES: "Continuar",
  es_LA: "Continuar",
  fr_FR: "Continuez",
  pt_BR: "Continuar",
  pt_PT: "Continuar",
  iw_IL: "לְהַמשִׁיך"
};
