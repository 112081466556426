import React, { PureComponent } from "react";
import { Button } from "reactstrap";
import connect from "react-redux/es/connect/connect";

class MPSButton extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onHover = () => {
    this.setState({ hover: true });
  };

  exitOnHover = () => {
    this.setState({ hover: false });
  };

  render() {
    let {
      type,
      children,
      appTheme = {},
      className,
      style = {},
      size,
      color = "primary",
      onClick,
      id,
      title,
      rel
    } = this.props;
    let themeColor = appTheme.themeColor
      ? appTheme.themeColor.replace("0x", "#")
      : "#20a8d8";

    if (type === "link") {
      let classes = className ? className + " btn-link" : "btn-link";
      return (
        <a
          id={id}
          href={this.props.href}
          onClick={onClick ? onClick : null}
          className={classes}
          style={Object.assign({}, style, {
            color: themeColor,
            cursor: "pointer"
          })}
          target={this.props.target}
          title={title}
          rel={rel}
        >
          {children}
        </a>
      );
    } else if (type === "block") {
      style = Object.assign(
        {},
        { ...style },
        {
          border: "1px solid " + themeColor,
          color: themeColor,
          backgroundColor: "#fff"
        }
      );
      if (this.state.hover) {
        style.opacity = 0.8;
      }
      return (
        <Button
          id={id}
          className={className}
          size={size}
          color={color}
          onClick={onClick ? onClick : null}
          onMouseEnter={this.onHover}
          onMouseLeave={this.exitOnHover}
          style={style}
          title={title}
        >
          {children}
        </Button>
      );
    } else {
      style = Object.assign(
        { border: "1px solid" },
        { ...style },
        { backgroundColor: themeColor, borderColor: themeColor }
      );
      if (this.state.hover) {
        style.opacity = 0.8;
      }
      return (
        <Button
          id={id}
          className={className}
          size={size}
          color={color}
          onClick={onClick ? onClick : null}
          onMouseEnter={this.onHover}
          onMouseLeave={this.exitOnHover}
          style={style}
          title={title}
        >
          {children}
        </Button>
      );
    }
  }
}

const mapstatetoprops = (state, ownProps) => ({
  appTheme: ownProps.appTheme
    ? ownProps.appTheme
    : state.user.data
    ? state.user.data.appTheme
    : {}
});

export default connect(mapstatetoprops)(MPSButton);
