import React from "react";
import { connect } from "react-redux";
import { Alert } from "reactstrap";
import { locales } from "../../Shared/Facility/FacilityInfo/Utils";
import FeaturesService from "../../services/featuresService";

function showLocalizationWarning(pathName) {
  let pathNames = [
    "/content-library/add",
    "/surveys/add",
    "/patient-types/add",
    "/configuration/facility-messages",
    "/configuration/staff-info-panel",
    "/configuration/default-task-profile",
    "/configuration/quick-actions"
  ];

  if (pathNames.indexOf(pathName) !== -1) {
    return true;
  }

  let patterns = [
    /\/content-library\/[a-z0-9A-Z]+\/edit/,
    /\/patient-types\/edit\/[a-z0-9A-Z]+\/profile/,
    /\/patient-types\/edit\/[a-z0-9A-Z]+\/info-panel/,
    /\/patient-types\/edit\/[a-z0-9A-Z]+\/care-plan-templates\/new/,
    /\/care-plan\/[a-z0-9A-Z]+\/edit/,
    /\/patient-types\/edit\/[a-z0-9A-Z]+\/task-templates\/new/,
    /\/patient-types\/edit\/[a-z0-9A-Z]+\/task-templates\/[a-z0-9A-Z]+\/edit/,
    /\/patient-types\/edit\/[a-z0-9A-Z]+\/consent-documents\/create/,
    /\/patient-types\/edit\/[a-z0-9A-Z]+\/consent-documents\/[a-z0-9A-Z]+\/edit/,
    /\/patient-types\/edit\/[a-z0-9A-Z]+\/add-patient-screen/,
    /\/surveys\/[a-z0-9A-Z]+\/edit/,
    /\/patient-types\/edit\/[a-z0-9A-Z]+\/reports-config/
  ];
  for (let i = 0; i < patterns.length; i++) {
    let pattern = patterns[i];
    if (pattern.test(pathName)) {
      return true;
    }
  }
  return false;
}

const LocalizationWarning = ({
  preferredLocale,
  defaultLocale,
  pathname,
  show
}) => {
  if (preferredLocale === defaultLocale) {
    return null;
  }

  if (!show && !showLocalizationWarning(pathname)) {
    return null;
  }

  return (
    <Alert color={"warning"} className="d-flex align-items-center mb-2 p-2">
      <div className="mr-2">
        <i className="fa fa-info-circle mr-2 font-2xl" />
      </div>

      {FeaturesService.isLocalizationEnabled() ? (
        <div>
          <span>{`New Content can be created only for ${defaultLocale} as it the default language for the facility. 
        Please create content in ${defaultLocale} and then you can add translations for ${preferredLocale}. `}</span>
          <span>{`For ${preferredLocale}, only fields that are marked with`}</span>{" "}
          <i className="fa fa-globe mx-1" /> icon can be translated. Translation
          would not work for other fields.
        </div>
      ) : (
        <div>
          {`Content translation is not enabled for this facility. Content creation and updates can be done only for ${defaultLocale}.`}
        </div>
      )}
    </Alert>
  );
};

const mapstatetoprops = state => {
  let preferredLocale = state.user.data.preferredLocale;
  let defaultLocale = state.user.data.defaultLocale;

  locales.forEach(locale => {
    if (locale.value === preferredLocale) {
      preferredLocale = locale.label;
    }
    if (locale.value === defaultLocale) {
      defaultLocale = locale.label;
    }
  });

  return {
    preferredLocale,
    defaultLocale
  };
};

export default connect(
  mapstatetoprops,
  null
)(LocalizationWarning);
