import React, { PureComponent } from "react";
import { getValueWhenInFilter } from "../../Shared/Packages/UiAttributes/Utils";
import DateAttribute from "../../Shared/Packages/UiAttributes/Onboarding/DateAttribute";
import OptionsObservation from "../../Shared/Packages/UiAttributes/Onboarding/OptionsObservation";
import TextObservation from "../../Shared/Packages/UiAttributes/Onboarding/TextObservation";
import PhoneAttribute from "../../Shared/Packages/UiAttributes/Onboarding/PhoneAttribute";
import AttributeWithOptions from "../../Shared/Packages/UiAttributes/Onboarding/AttributeWithOptions";
import NameAttribute from "../../Shared/Packages/UiAttributes/Onboarding/NameAttribute";
import TextAttribute from "../../Shared/Packages/UiAttributes/Onboarding/TextAttribute";
import DateObservation from "../../Shared/Packages/UiAttributes/Onboarding/DateObservation";
import MPSTooltip from "../../components/MPSToolTip";
import {
  getOnboardingAttributeValue,
  validateAttribute,
  validateExistence
} from "./Utils";
import HeightObservation from "../../Shared/Packages/UiAttributes/Onboarding/HeightObservation";
import WeightObservation from "../../Shared/Packages/UiAttributes/Onboarding/WeightObservation";
import CCCOptionsObservation from "../../Shared/Packages/UiAttributes/Onboarding/CCCOptionsObservation";
import { doE164 } from "../../utils/phone";

class UserOnboardingChallengeInvite extends PureComponent {
  constructor(props) {
    super(props);

    let challengeFields = props.challengeFields || [];
    let referrer = props.referrer;
    challengeFields.forEach((attribute, index) => {
      let value = getOnboardingAttributeValue(attribute);
      if (value && attribute.hidden) {
        if (
          attribute.dataField === "PatientContactPhone" &&
          attribute.name !== "ConfirmPhone"
        ) {
          let newAV = JSON.parse(JSON.stringify(attribute.value));
          let phone = newAV.valueContactPoints[0].value;
          let cc = newAV.valueContactPoints[0].countryCode;
          this.phone = { countryCode: cc, value: phone };

          if (referrer === "SELF_SIGNUP") {
            validateExistence(
              index,
              attribute,
              "PHONE",
              doE164(cc, phone),
              this
            );
          }
        }
        if (
          attribute.dataField === "PatientContactEmail" &&
          attribute.name !== "ConfirmEmail"
        ) {
          this.email = value;
          if (referrer === "SELF_SIGNUP") {
            validateExistence(index, attribute, "EMAIL", value, this);
          }
        }
      }
    });
    this.state = {};
  }

  renderFields = attributes => {
    let { referrer, sectionIndex } = this.props;
    let placeholder = false;
    return attributes.map((attribute, index) => {
      if (attribute.hidden) {
        return null;
      }

      if (attribute.readOnly) {
        let description = attribute.description || attribute.title;
        let helpText = attribute.helpText;
        return (
          <div
            key={index}
            className="col-12 my-1 pb-2"
            style={{ backgroundColor: "#fff" }}
            ref={attrEl => {
              if (this.props.setAttributeRef) {
                this.props.setAttributeRef(attribute.name, attrEl);
              }
            }}
          >
            <div className="d-flex">
              <label style={{ fontWeight: 500 }}>{description}</label>
              <div className="ml-2">{getValueWhenInFilter(attribute)}</div>
              {helpText && (
                <MPSTooltip
                  id={`signup-field-${sectionIndex}-${index}-tt`}
                  className="ml-2"
                >
                  {helpText}
                </MPSTooltip>
              )}
            </div>
          </div>
        );
      }

      let type = attribute.type || attribute.attributeType;
      let Element = null;
      if (attribute.hidden) {
        return null;
      }
      if (
        type === "OBSERVATION" ||
        type === "OBSERVATION_INTEGER" ||
        type === "OBSERVATION_DOUBLE"
      ) {
        if (attribute.options && attribute.options.length > 0) {
          Element = OptionsObservation;
        } else {
          if (attribute.name.toLowerCase() === "height") {
            Element = HeightObservation;
          } else if (attribute.name.toLowerCase() === "weight") {
            Element = WeightObservation;
          } else {
            Element = TextObservation;
          }
        }
      } else if (type === "OBSERVATION_CODEABLE_CONCEPT_CODE") {
        if (attribute.options && attribute.options.length > 0) {
          Element = CCCOptionsObservation;
        } else {
          Element = TextObservation;
        }
      } else if (type === "OBSERVATION_DATE") {
        Element = DateObservation;
      } else {
        if (attribute.options && attribute.options.length >= 1) {
          Element = AttributeWithOptions;
        } else {
          if (type === "DATE") {
            Element = DateAttribute;
          } else if (
            attribute.dataField === "PatientContactPhone" ||
            attribute.dataField === "PatientContactTel"
          ) {
            Element = PhoneAttribute;
          } else if (
            attribute.dataField === "PatientName" &&
            (this.props.referrer === "SELF_SIGNUP" ||
              this.props.referrer === "DATA_ENTRY")
          ) {
            Element = NameAttribute;
          } else {
            Element = TextAttribute;
          }
        }
      }
      return (
        <div className="d-flex align-items-center" style={{ width: "100%" }}>
          <Element
            key={index}
            setAttributeRef={this.props.setAttributeRef}
            placeholder={placeholder}
            attribute={attribute}
            index={index}
            handleOnBlur={this.handleOnBlur}
            changeField={this.changeField}
            error={this.props.error}
            supportEmail={this.props.supportEmail}
            defaultTelRegionOptions={this.props.defaultTelRegionOptions}
          />
          {attribute.helpText && (
            <MPSTooltip
              id={`signup-field-${sectionIndex}-${index}-tt`}
              className="ml-2"
            >
              {attribute.helpText}
            </MPSTooltip>
          )}
        </div>
      );
    });
  };

  handleOnBlur = (index, attribute) => {
    if (attribute.hidden || this.props.noBlurValidation) {
      return;
    }
    delete attribute.error;
    delete attribute.info;
    delete attribute.validationCode;
    this.changeFieldPostValidation(index, attribute);
    validateAttribute(index, attribute, this);
  };

  changeField = (index, attribute) => {
    let { challengeFields, onUpdateData } = this.props;
    challengeFields[index] = { ...attribute };
    onUpdateData("challengeFields", [...challengeFields]);
  };

  changeFieldPostValidation = (index, attribute) => {
    let { challengeFields, onUpdateData } = this.props;
    challengeFields[index] = { ...attribute };
    onUpdateData("challengeFields", [...challengeFields]);
  };

  render() {
    const { challengeFields = [] } = this.props;

    return (
      <div>
        <div className="row">{this.renderFields(challengeFields)}</div>
      </div>
    );
  }
}

export default UserOnboardingChallengeInvite;
