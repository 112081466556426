import React, { PureComponent } from "react";
import {
  onLogin,
  onLogout,
  onSetOnboardingUserName
} from "../../State/actions/user";
import { connect } from "react-redux";
import SignupView from "./SignupView";
import userService from "../../services/userService";
import SignupFacilitySelector from "./SignupFacilitySelector";
import { isIE, parseURLQueryParams } from "../../utils/utils";
import UserOnboardingContainer from "../UserOnboarding/UserOnboardingContainer";
import MPSBarLoader from "../../components/MPSBarLoader";
import IENotSupported from "./IENotSupported";
import I18nProvider from "../../services/I18nProvider";
import Messages from "../../Shared/Messages";
import {
  getObservationDataHolder,
  getValueFieldNameForFilter,
  isObservation
} from "../../Shared/Packages/UiAttributes/Utils";
const intl = I18nProvider.getIntlProvider();

class SignupContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { facilityCode: this.props.code };
  }

  componentDidMount() {
    this.props.onLogout();
    this.init();
  }

  init = () => {
    let { facilityCode } = this.state;
    if (!facilityCode) {
      return;
    }
    this.loadFacilityInfo(facilityCode).catch(e => {});
  };

  loadFacilityInfo = facilityCode => {
    this.setState({ loading: true });
    return (
      userService
        // .fetchAddPatientProfileFromCode(facilityCode)
        .fetchSignUpProfileFromCode(facilityCode)
        .then(facilityInfo => {
          if (!facilityInfo) {
            throw new Error();
          }

          let { params } = this.props;
          if (params && Object.keys(params).length >= 1) {
            let { addPatientProfile = {} } = facilityInfo;
            let sections = addPatientProfile.sections || [];
            sections.forEach(section => {
              let items = section.items || [];
              items.forEach(item => {
                let attribute = item.attribute || {};
                if (attribute && attribute.name && params[attribute.name]) {
                  let value = decodeURIComponent(
                    (params[attribute.name] + "").replace(/\+/g, "%20")
                  );

                  if (isObservation(attribute.attributeType)) {
                    attribute.value = getValueFieldNameForFilter(attribute, {
                      value: {
                        [getObservationDataHolder(
                          attribute.attributeType
                        )]: value
                      },
                      code: { code: attribute.observationCode.code }
                    });
                  } else {
                    attribute.value = getValueFieldNameForFilter(
                      attribute,
                      value
                    );
                  }
                }
                item.attribute = { ...attribute };
              });
              section.items = [...items];
            });
            addPatientProfile.sections = [...sections];
          }

          this.setState({
            loading: false,
            facilityInfo,
            facilityError: false,
            facilityErrorMessage: null
          });

          return facilityInfo;
        })
        .catch(e => {
          this.setState({
            loading: false,
            facilityError: true,
            facilityErrorMessage:
              e.message || intl.formatMessage(Messages.invalid_link)
          });
          throw new Error();
        })
    );
  };

  doSignUp = (attributes, loginUserId, password) => {
    let formData = {
      facilityCode: this.state.facilityCode,
      patientAttributes: attributes,
      acceptedTermsAndCondition: true,
      loginUserId,
      password
    };

    let { facilityInfo } = this.state;
    if (facilityInfo && facilityInfo.preValidationRequired) {
      formData.acceptedPreValidationTC = true;
    }

    return userService.signupPatient(formData);
  };

  submitCode = facilityCode => {
    this.loadFacilityInfo(facilityCode)
      .then(response => {
        this.setState({ facilityCode });
        if (response.addPatientProfile) {
          this.props.history.push(`/signup/${facilityCode}`);
        } else if (response.challengeFields) {
          this.props.history.push(`/signup/${facilityCode}?type=onboarding`);
        }
      })
      .catch(e => {});
  };

  render() {
    let {
      facilityInfo,
      loading,
      facilityCode,
      facilityError,
      facilityErrorMessage
    } = this.state;

    if (isIE()) {
      return <IENotSupported />;
    }

    if (!facilityCode) {
      return (
        <SignupFacilitySelector
          submitCode={this.submitCode}
          facilityError={facilityError}
          facilityErrorMessage={facilityErrorMessage}
        />
      );
    }
    if (facilityError) {
      return (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: "100vh" }}
        >
          <p className="text-muted text-center">
            <span style={{ color: "red" }}>{facilityErrorMessage}</span>
          </p>
        </div>
      );
    }

    let { signupType } = this.props;

    if (loading) {
      return (
        <div style={{ margin: "100px auto", width: 100 }}>
          <MPSBarLoader />
        </div>
      );
    }

    if (!facilityInfo) {
      return null;
    }

    if (signupType !== "onboarding" && facilityInfo.addPatientProfile) {
      return (
        <div>
          <SignupView
            facilityError={facilityError}
            facilityErrorMessage={facilityErrorMessage}
            doSignUp={this.doSignUp}
            facilityInfo={facilityInfo}
            facilityCode={facilityCode}
            loading={loading}
            fromApp={this.props.fromApp}
            deviceType={this.props.deviceType}
          />
        </div>
      );
    }

    if (facilityInfo.challengeFields) {
      return (
        <UserOnboardingContainer
          inviteCode={facilityCode}
          facilityInfo={facilityInfo}
          onLogin={this.props.onLogin}
          onLogout={this.props.onLogout}
          onboardingType={"signup"}
          onSetOnboardingUserName={this.props.onSetOnboardingUserName}
        />
      );
    }
  }
}

const mapDispatchToProps = {
  onLogin,
  onLogout,
  onSetOnboardingUserName
};

const mapStateToProps = (state, ownProps) => {
  let params = null;
  if (ownProps.location && ownProps.location.search) {
    params = parseURLQueryParams(ownProps.location.search);
  }
  return {
    code: ownProps.match ? ownProps.match.params.code : null,
    fromApp: params ? params.fromMPSApp : null,
    deviceType: params ? params.deviceType : null,
    signupType: params ? params.type : null,
    params
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignupContainer);
