// @flow

import {
  LOG_OUT,
  LOAD_ORGANIZATION_ADMINS,
  LOAD_ORGANIZATION_ADMINS_DONE,
  ORGANIZATION_ADMINS_RESET_LOADING,
  ORGANIZATION_ADMIN_INVITE,
  ORGANIZATION_ADMIN_INVITE_DONE,
  ORGANIZATION_ADMIN_INVITE_ERROR,
  DISMISS_ERROR,
  ORGANIZATION_ADMIN_DELETE_ERROR,
  ORGANIZATION_ADMIN_DELETE_DONE,
  ORGANIZATION_ADMIN_DELETE
} from "../actions/actionTypes";
import { findWithAttr } from "../../utils/utils";

const initialState = {
  loading: false,
  // last page loaded
  page: 0,
  // is there need to load more, currently determined
  // to be false if last page listing returned no results
  loadMore: true,
  list: [],
  inviteSuccess: false,
  error: false,
  errorMsg: ""
};

const organizationAdminsReducer = (state = initialState, action) => {
  let index;

  switch (action.type) {
    case ORGANIZATION_ADMINS_RESET_LOADING:
      return {
        ...state,
        loading: false,
        error: false,
        errorMsg: "",

        inviteSuccess: false
      };

    case LOAD_ORGANIZATION_ADMINS:
      return { ...state, list: [], page: 0, loadMore: true, loading: true };

    case ORGANIZATION_ADMIN_INVITE:
    case ORGANIZATION_ADMIN_DELETE:
      return { ...state, loading: true };

    case LOAD_ORGANIZATION_ADMINS_DONE:
      // console.log('ADMINS', action);
      const newState = {
        ...state,
        loading: false,
        page: action.page,
        loadMore: action.loadMore,
        list:
          action.page === 0
            ? action.list
            : state.list.slice(0).concat(action.list)
      };
      return newState;

    case DISMISS_ERROR:
      return { ...state, error: false, errorMsg: "" };

    case ORGANIZATION_ADMIN_INVITE_ERROR:
    case ORGANIZATION_ADMIN_DELETE_ERROR:
      return { ...state, loading: false, error: true, errorMsg: action.error };

    case ORGANIZATION_ADMIN_DELETE_DONE:
      let list = state.list.slice(0);
      let deleteId = action.id || -1;
      index = findWithAttr(list, "id", deleteId);
      if (index !== -1) {
        list.splice(index, 1);
        return {
          ...state,
          loading: false,
          list,
          error: false,
          errorMsg: ""
        };
      }

      return { ...state, loading: false, error: false, errorMsg: "" };

    case ORGANIZATION_ADMIN_INVITE_DONE:
      console.log("ORGADMIN ", action);
      list = state.list.slice(0);

      const id = action.data.id || -1;
      index = findWithAttr(list, "id", id);

      // not found then return prev state
      if (index === -1)
        return {
          ...state,
          loading: false,
          list: [action.data].concat(list),
          inviteSuccess: true
        };

      // if found, replace whole object
      list[index] = action.data;

      return {
        ...state,
        loading: false,
        list: list,
        inviteSuccess: true
      };

    case LOG_OUT:
      return initialState;

    default:
      return state;
  }
};

export default organizationAdminsReducer;
