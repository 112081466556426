import React from "react";
import { Button } from "reactstrap";
import Text from "../../../components/Text";
import Messages from "../../../Shared/Messages";

export default ({ title, description, onIntroVisited }) => {
  return (
    <div className="row justify-content-center">
      <div className="col-sm-12 col-md-8">
        <h5 className="my-3 text-center">{title}</h5>

        <div dangerouslySetInnerHTML={{ __html: description }} />

        <div className="my-3 d-flex justify-content-end">
          <Button color="primary" size="md" onClick={onIntroVisited}>
            <Text content={Messages.book_now} />
          </Button>
        </div>
      </div>
    </div>
  );
};
