import React, { Component } from "react";
import DateTime from "../../../components/Date/DateTime";

class QuestionDateTime extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let { question, setData } = this.props;
    let answer = question.answers && question.answers[0];

    return (
      <DateTime
        dateTime={answer ? answer.valueDateTime : null}
        setDateTime={valueDateTime => {
          setData({
            valueDateTime: valueDateTime
          });
        }}
      />
    );
  }
}

export default QuestionDateTime;
