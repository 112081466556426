import React, { PureComponent } from "react";
import fileService from "../../services/fileService";
import MPSBarLoader from "../../components/MPSBarLoader";
import PlayIcon from "./Icons/PlayIcon";

class AudioPlayer extends PureComponent {
  static propTypes = {};

  static defaultProps = {
    ...PureComponent.defaultProps
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  onClick = () => {
    this.setState({ loading: true });
    fileService
      .getFile(this.props.url)
      .then(data => {
        this.setState({ dataUrl: data, loading: false });
      })
      .catch(e => {
        this.setState({ loading: false });
      });
  };

  render() {
    const { dataUrl, loading } = this.state;

    return (
      <div
        className="d-flex justify-content-center align-items-center b-a-2 p-4 mt-3"
        style={{ width: "100%", borderRadius: "4px", height: 110 }}
      >
        {dataUrl ? (
          <audio controls src={dataUrl} preload="none" autoPlay>
            Sorry, your browser doesn't support embedded videos.
          </audio>
        ) : (
          <>
            {!loading && <PlayIcon onClick={this.onClick} />}

            {loading && (
              <div style={{ margin: "100px auto", width: 50 }}>
                <MPSBarLoader />
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}

export default AudioPlayer;
