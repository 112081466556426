import React, { PureComponent } from "react";
import {
  getDataHolder,
  getValueWhenInFilter,
  getValueFieldNameForFilter
} from "../../Shared/Packages/UiAttributes/Utils";
import { Input } from "reactstrap";
import MPSBarLoader from "../../components/MPSBarLoader";
import Select from "react-select";

class OnboardingChallengeInvite extends PureComponent {
  renderFields = attributes => {
    return attributes.map((attribute, index) => {
      if (attribute.type === "DATE") {
        return (
          <div
            key={index}
            className="col-12 my-1 pb-2"
            style={{ backgroundColor: "#fff" }}
          >
            <label style={{ fontWeight: 500 }}>{attribute.description}</label>
            <div className="d-flex">
              <input
                style={{ width: "50%", padding: "0.375rem 0.5rem" }}
                className="form-control"
                type="date"
                value={getValueWhenInFilter(attribute)}
                onChange={e => {
                  attribute.value = getValueFieldNameForFilter(
                    attribute,
                    e.target.value
                  );
                  this.changeNonObsField(index, attribute);
                }}
              />
            </div>
          </div>
        );
      } else if (attribute.type === "OBSERVATION") {
        if (attribute.options && attribute.options.length > 0) {
          return (
            <div
              key={index}
              className="col-12 my-1 pb-2"
              style={{ backgroundColor: "#fff" }}
            >
              <label style={{ fontWeight: 500 }}>{attribute.description}</label>
              <div className="d-flex">
                <Select
                  className="mx-1"
                  style={{ border: "1px solid #e0e6e8" }}
                  name="form-field-name"
                  value={getValueWhenInFilter(attribute)}
                  onChange={option => {
                    attribute.value = getValueFieldNameForFilter(
                      attribute,
                      option ? option.value : null
                    );
                    this.changeObsField(index, attribute);
                  }}
                  valueKey="valueSelect"
                  options={attribute.options.map(option => {
                    let val = option.valueObservation.value.valueString;
                    return {
                      valueSelect: val,
                      value: option.valueObservation,
                      label: val
                    };
                  })}
                />
              </div>
            </div>
          );
        } else {
          return (
            <div
              key={index}
              className="col-12 my-1 pb-2"
              style={{ backgroundColor: "#fff" }}
            >
              <label style={{ fontWeight: 500 }}>{attribute.description}</label>
              <div className="d-flex">
                <Input
                  type="text"
                  value={getValueWhenInFilter(attribute)}
                  onChange={e => {
                    let entered = e.target.value;
                    if (entered) {
                      attribute.value = getValueFieldNameForFilter(attribute, {
                        value: { valueString: entered },
                        code: { code: attribute.observationCode.code }
                      });
                    } else {
                      attribute.value = null;
                    }
                    this.changeObsField(index, attribute);
                  }}
                />
              </div>
            </div>
          );
        }
      } else {
        let dh = getDataHolder(attribute.type);
        if (attribute.options) {
          return (
            <div
              key={index}
              className="col-12 my-1 pb-2"
              style={{ backgroundColor: "#fff" }}
            >
              <label style={{ fontWeight: 500 }}>{attribute.description}</label>
              <div className="d-flex">
                <Select
                  className="mx-1"
                  style={{ border: "1px solid #e0e6e8" }}
                  name="form-field-name"
                  value={getValueWhenInFilter(attribute)}
                  onChange={option => {
                    attribute.value = getValueFieldNameForFilter(
                      attribute,
                      option ? option.value : null
                    );
                    this.changeNonObsField(index, attribute);
                  }}
                  options={attribute.options.map(option => {
                    let val = option[dh].value || option[dh].code;
                    let label = option[dh].value || option[dh].display;
                    return { value: val, label: label };
                  })}
                />
              </div>
            </div>
          );
        } else {
          return (
            <div
              key={index}
              className="col-12 my-1 pb-2"
              style={{ backgroundColor: "#fff" }}
            >
              <label style={{ fontWeight: 500 }}>{attribute.description}</label>
              <div className="d-flex">
                <input
                  className="form-control"
                  type="text"
                  autoCapitalize="sentences"
                  value={getValueWhenInFilter(attribute)}
                  onChange={e => {
                    let entered = e.target.value;
                    attribute.value = getValueFieldNameForFilter(
                      attribute,
                      entered
                    );
                    this.changeNonObsField(index, attribute);
                  }}
                />
              </div>
            </div>
          );
        }
      }
    });
  };

  changeNonObsField = (index, attribute) => {
    let { challengeFields, onUpdateData } = this.props;
    challengeFields[index] = attribute;
    onUpdateData("challengeFields", [...challengeFields]);
  };

  changeObsField = (index, attribute) => {
    let { challengeFields, onUpdateData } = this.props;
    challengeFields[index] = attribute;
    onUpdateData("challengeFields", [...challengeFields]);
  };

  render() {
    const { challengeFields = [] } = this.props;

    if (this.props.challengeLoading) {
      return (
        <div style={{ margin: "100px auto", width: 50 }}>
          <MPSBarLoader />
        </div>
      );
    }

    return (
      <div>
        <div className="row">{this.renderFields(challengeFields)}</div>
      </div>
    );
  }
}

export default OnboardingChallengeInvite;
