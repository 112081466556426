import {
  LOAD_SURVEYS,
  SURVEYS_LOADED,
  SURVEY_ADD,
  SURVEY_ADD_DONE,
  SURVEY_ADD_ERROR,
  SURVEY_EDIT,
  SURVEY_EDIT_DONE,
  SURVEY_EDIT_ERROR
} from "../actions/actionTypes";
import { put, select, take } from "redux-saga/effects";

import SurveysService from "../../services/surveysService";
import history from "../../history";

export function* addSurvey() {
  while (true) {
    const action = yield take(SURVEY_ADD);
    yield put({ type: SURVEY_ADD });
    try {
      const { survey, popup, callback } = action;
      const result = yield SurveysService.add(survey);
      if (callback) {
        callback(result.id, result.title);
      }
      yield put({ type: SURVEY_ADD_DONE });
      if (!popup) {
        history.goBack();
      }
    } catch (error) {
      // console.log('ERROR', error);
      yield put({ type: SURVEY_ADD_ERROR, error });
    }
  }
}

export function* editSurvey() {
  while (true) {
    const action = yield take(SURVEY_EDIT);
    yield put({ type: SURVEY_EDIT });
    try {
      const { survey } = action;
      const result = yield SurveysService.edit(survey.id, survey);
      yield put({ type: SURVEY_EDIT_DONE });
      history.goBack();
    } catch (error) {
      // console.log('ERROR', error);
      yield put({ type: SURVEY_EDIT_ERROR, error });
    }
  }
}

export function* loadSurveys() {
  while (true) {
    const action = yield take(LOAD_SURVEYS);

    // get current page and load more
    const surveys = yield select(state => state.surveys);
    // check if loadMore is true or already loading
    // console.log('load surveys', surveys);
    if (!surveys.loading && "loadMore" in surveys && !surveys.loadMore) {
      // load more should not fire as loadMore is false
      return false;
    }
    // get page
    const page = surveys.page || 0;
    // start loading more

    // put will set loading flag
    yield put({ type: LOAD_SURVEYS });
    // load surveys
    try {
      const result = yield SurveysService.load(
        page,
        null,
        null,
        action.patientTypeId
      );
      // console.log('ORGTEST SAGE RESULT RETURNED ', result);
      yield put({
        type: SURVEYS_LOADED,
        list: result.data,
        page: page + 1,
        loadMore: result.loadMore
      });
    } catch (error) {
      // console.log('SAGE RESULT ERROR ');
      yield put({
        type: SURVEYS_LOADED,
        list: [],
        page: page + 1,
        loadMore: false
      });
      // console.log('ORGTEST ERROR', error);
    }
  }
}
