import React, { Component } from "react";
import VideoActions from "./VideoActions";
import CallTimer from "./CallTimer";

class VideoRoom extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let { localVideo } = this.props;
    return (
      <div className="d-flex flex-column justify-content-center align-items-center">
        {/*<div className="my-2">*/}
        {/*  <CallTimer />*/}
        {/*</div>*/}

        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-9">
            <div className="d-flex justify-content-center">
              <div className="vc-picture-in-picture d-flex justify-content-center align-items-center">
                <div
                  className="vc-caller"
                  ref={ref => {
                    this.props.setRemoteMedia(ref);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-12 col-lg-3">
            <div
              className="d-flex justify-content-center align-items-center flex-md-column"
              style={{ height: "100%" }}
            >
              <div
                className={localVideo ? "vc-me mb-3" : "vc-me"}
                ref={ref => {
                  this.props.setLocalMedia(ref);
                }}
                style={{
                  height: localVideo ? "" : 0,
                  width: localVideo ? "" : 0
                }}
              />

              <div className="d-flex flex-column">
                <div className="mb-2">
                  <CallTimer
                    setCallDuration={this.props.setCallDuration}
                    callEnded={this.props.callEnded}
                  />
                </div>

                <VideoActions
                  leaveRoom={this.props.leaveRoom}
                  activeRoom={this.props.activeRoom}
                />
              </div>
            </div>
          </div>
        </div>

        {/*<div className="col-sm-6 col-md-9">*/}
        {/*  <div*/}
        {/*    style={{*/}
        {/*      height: "600px",*/}
        {/*      width: "auto",*/}
        {/*      padding: "2x"*/}
        {/*    }}*/}
        {/*    ref={ref => {*/}
        {/*      this.props.setRemoteMedia(ref);*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    {!this.props.remoteVideo && (*/}
        {/*      <div*/}
        {/*        className="d-flex justify-content-center align-items-center"*/}
        {/*        style={{ height: "100%" }}*/}
        {/*      >*/}
        {/*        {!this.props.existing*/}
        {/*          ? `${this.props.otherParticipantName} notified, waiting for them to join.`*/}
        {/*          : `Call with ${this.props.otherParticipantName}, waiting for them to join.`}*/}
        {/*      </div>*/}
        {/*    )}*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/*<div className="col-sm-6 col-md-3 d-flex flex-column justify-content-center">*/}
        {/*  <div*/}
        {/*    style={{*/}
        {/*      padding: "2x"*/}
        {/*    }}*/}
        {/*    ref={ref => {*/}
        {/*      this.props.setLocalMedia(ref);*/}
        {/*    }}*/}
        {/*  />*/}

        {/*  <div*/}
        {/*    className="d-flex justify-content-center mt-3 align-items-center"*/}
        {/*    style={{*/}
        {/*      border: "1px solid rgb(221, 223, 226)",*/}
        {/*      height: "150px"*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    <VideoActions*/}
        {/*      leaveRoom={this.props.leaveRoom}*/}
        {/*      activeRoom={this.props.activeRoom}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    );
  }
}

export default VideoRoom;
