import React, { Component } from "react";
import { Input } from "reactstrap";
import MPSSelect from "../../../../components/MPSSelect";

class IntegerObservation extends Component {
  static getDisplayValue(attributeValue, valuePlace) {
    if (
      attributeValue.valueObservation &&
      attributeValue.valueObservation.value
    ) {
      if (valuePlace === "from") {
        return attributeValue.valueObservation.value.valueFromInteger;
      } else if (valuePlace === "to") {
        return attributeValue.valueObservation.value.valueToInteger;
      }
      return (
        attributeValue.valueObservation.value.valueInteger ||
        attributeValue.valueObservation.value.valueAsString
      );
    }
  }

  static getOptionsList(options) {
    return options.map(option => {
      let val = option.valueObservation.value.valueInteger;
      return { value: val, label: val };
    });
  }

  // setData = attributeValue => {
  //   let value = attributeValue.valueObservation.value.valueInteger;
  //   let { attributeValidation } = this.props;
  //   if (attributeValidation) {
  //     if (attributeValidation.validationHint === "NUMBER_RANGE") {
  //       if (
  //         value &&
  //         value >= attributeValidation.fromNumber &&
  //         value <= attributeValidation.toNumber
  //       ) {
  //         this.props.setData(attributeValue);
  //         this.setState({ error: "" });
  //       } else {
  //         //show error message
  //         this.setState({
  //           error:
  //             "Provide a valid number that is between " +
  //             attributeValidation.fromNumber +
  //             " and " +
  //             attributeValidation.toNumber
  //         });
  //       }
  //     }
  //   }
  // };

  render() {
    let {
      attributeValue,
      choiceList,
      options = [],
      setData,
      observationCode
    } = this.props;

    attributeValue.valueObservation = attributeValue.valueObservation || {};
    attributeValue.valueObservation.value =
      attributeValue.valueObservation.value || {};
    let obsVal = attributeValue.valueObservation.value.valueInteger;
    attributeValue.valueObservation.code = observationCode;

    if (choiceList) {
      return (
        <div className="inputHolder flex-row">
          <MPSSelect
            name="form-field-name"
            value={obsVal}
            onChange={option => {
              if (option) {
                attributeValue.valueObservation.value.valueInteger =
                  option.value;
                attributeValue.valueObservation.value.valueString = null;
              } else {
                attributeValue.valueObservation.value = {};
              }
              setData(attributeValue);
            }}
            options={options.map(option => {
              let val = option.valueObservation.value.valueInteger;
              return { value: val, label: val };
            })}
            className="ui-attribute-el-select"
            onBlur={this.props.onBlur}
          />
        </div>
      );
    } else {
      return (
        <div className="inputHolder flex-row">
          <Input
            className="ui-attribute-el"
            value={obsVal}
            onChange={e => {
              attributeValue.valueObservation.value.valueInteger =
                e.target.value;
              attributeValue.valueObservation.value.valueString = null;
              setData(attributeValue, e.target.value);
            }}
            type="text"
            onBlur={this.props.onBlur}
          />
        </div>
      );
    }
  }
}

export default IntegerObservation;
