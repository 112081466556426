import React, { Component } from "react";
import classNames from "classnames";
import fileService from "../../services/fileService";

class FacilityLogo extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.getLogoImg();
  }

  getLogoImg = () => {
    let { hospitalLogoImage } = this.props.appTheme || {};
    if (hospitalLogoImage) {
      fileService
        .getFile(hospitalLogoImage.contentURL)
        .then(logoImg => {
          this.setState({ logoImg });
        })
        .catch(e => {
          this.setState({ logoImg: null });
        });
    }
  };

  render() {
    let { logoImg } = this.state;
    if (!logoImg) {
      return null;
    }

    return (
      <img
        src={this.state.logoImg}
        className={classNames("bgImageUrl logo-in-header")}
        style={{ height: this.props.height || "45px" }}
      />
    );
  }
}

export default FacilityLogo;
