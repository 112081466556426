import React from "react";
import { Card, CardBody, CardGroup, Col, Container, Row } from "reactstrap";

class UnsubscribeView extends React.PureComponent {
  render() {
    let { unsubscribed, error } = this.props;
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <CardGroup>
                <Card className="p-4">
                  <div className="d-flex justify-content-center mb-4">
                    <img src="/img/logo-symbol.png" height={32} />
                  </div>
                  <CardBody>
                    {unsubscribed && (
                      <p className="text-center">
                        You have been unsubscribed from all notifications.
                      </p>
                    )}
                    {error && (
                      <p className="text-center text-danger">{error}</p>
                    )}
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default UnsubscribeView;
