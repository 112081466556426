import React from "react";
import StaffSelector from "../../../Shared/Selectors/Staff/StaffSelector";
import userService from "../../../services/userService";
import { showToaster } from "../../../State/actions/toaster";
import { connect } from "react-redux";
class AddStaffParticipants extends React.PureComponent {
  addMoreStaff = staffIds => {
    userService
      .addParticipantsToVideoCall({
        roomName: this.props.roomName,
        provider: this.props.provider,
        staffIds
      })
      .then(response => {
        this.props.showToaster(
          "Selected staff have been invited to the video call",
          "success"
        );
        this.props.onAddStaff(staffIds.map(s => ({ staffId: s })));
      });
  };

  render() {
    return (
      <StaffSelector
        onSelectStaff={this.addMoreStaff}
        exclude={this.props.currentParticipants || []}
      />
    );
  }
}


const mapDispatchToProps = {
  showToaster: showToaster
};

export default connect(
  null,
  mapDispatchToProps
)(AddStaffParticipants);
