import React, { PureComponent } from "react";
import { Input, InputGroupAddon, InputGroup, Button } from "reactstrap";
import Text from "../Text";
import Messages from "../../Shared/Messages";

class CopyText extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  copyURLToClipboard = e => {
    e.stopPropagation();
    e.preventDefault();
    let { index = 0 } = this.props;
    let copyText = document.getElementById(`text-to-copy-${index}`);
    copyText.select();
    document.execCommand("copy");
    this.setState({ copied: true });

    setTimeout(() => {
      this.setState({ copied: false });
    }, 3000);
  };

  render() {
    let { text, index = 0 } = this.props;

    return (
      <div style={{ width: "100%" }}>
        <div>
          <InputGroup className="mb-1">
            <Input
              readOnly
              value={text}
              style={{ backgroundColor: "#fff" }}
              id={`text-to-copy-${index}`}
            />
            <InputGroupAddon addonType="append">
              <Button color="secondary" onClick={this.copyURLToClipboard}>
                <i className="far fa-copy" />
              </Button>
            </InputGroupAddon>
          </InputGroup>
        </div>

        <div>
          {this.state.copied && (
            <span style={{ color: "green" }}>
              {this.props.message ? (
                this.props.message
              ) : (
                <Text content={Messages.copied} />
              )}
            </span>
          )}
        </div>
      </div>
    );
  }
}

export default CopyText;
