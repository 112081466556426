export const timezones = [
  { value: "Europe/Dublin", label: "Europe/Dublin" },
  { value: "Asia/Dubai", label: "Asia/Dubai" },
  { value: "Europe/London", label: "Europe/London" },
  { value: "Europe/Berlin", label: "Europe/Berlin" },
  { value: "Europe/Paris", label: "Europe/Paris" },
  { value: "Europe/Amsterdam", label: "Europe/Amsterdam" },
  { value: "Europe/Madrid", label: "Europe/Madrid" },
  { value: "Europe/Lisbon", label: "Europe/Lisbon" },
  { value: "Asia/Kolkata", label: "Asia/Kolkata" },
  { value: "Europe/Rome", label: "Europe/Rome" },
  { value: "Europe/Zurich", label: "Europe/Zurich" },
  { value: "Europe/Stockholm", label: "Europe/Stockholm" },
  { value: "Europe/Budapest", label: "Europe/Budapest" },
  { value: "Europe/Belfast", label: "Europe/Belfast" },
  { value: "Australia/Sydney", label: "Australia/Sydney" },
  { value: "Australia/Brisbane", label: "Australia/Brisbane" },
  { value: "Australia/Melbourne", label: "Australia/Melbourne" },
  { value: "Australia/Adelaide", label: "Australia/Adelaide" },
  { value: "Japan", label: "Japan" },
  { value: "Etc/UTC (UTC+00:00)", label: "Etc/UTC (UTC+00:00)" },
  { value: "America/Bogota", label: "America/Bogota" },
  { value: "Mexico/BajaNorte", label: "Mexico/BajaNorte" },
  { value: "Brazil/Acre", label: "Brazil/Acre" },
  { value: "America/Mexico_City", label: "America/Mexico City" },
  { value: "Asia/Jerusalem", label: "Israel" }
];

export const locales = [
  { value: "en_US", label: "English" },
  { value: "ar_AE", label: "Arabic" },
  { value: "fr_FR", label: "French" },
  { value: "es_ES", label: "Spanish" },
  { value: "pt_BR", label: "Portuguese - Brazil" },
  { value: "pt_PT", label: "Portuguese - Portugal" },
  { value: "es_LA", label: "Spanish - Latin America" },
  { value: "iw_IL", label: "Hebrew" }
];

export function getOpenHoursWithFormat(oh) {
  let time = "";
  if (oh.openTime.hour < 10) {
    time += "0" + oh.openTime.hour;
  } else {
    time += oh.openTime.hour;
  }

  time += ":";

  if (oh.openTime.minute < 10) {
    time += "0" + oh.openTime.minute;
  } else {
    time += oh.openTime.minute;
  }

  time += " - ";

  if (oh.closeTime.hour < 10) {
    time += "0" + oh.closeTime.hour;
  } else {
    time += oh.closeTime.hour;
  }

  time += ":";

  if (oh.closeTime.minute < 10) {
    time += "0" + oh.closeTime.minute;
  } else {
    time += oh.closeTime.minute;
  }

  return time;

  // return oh.openTime.hour < 10
  //   ? "0" + oh.openTime.hour
  //   : oh.openTime.hour + ":" + oh.openTime.minute < 10
  //     ? "0" + oh.openTime.minute
  //     : oh.openTime.minute + " - " + oh.closeTime.hour < 10
  //       ? "0" + oh.closeTime.hour
  //       : oh.closeTime.hour + ":" + oh.closeTime.minute < 10
  //         ? "0" + oh.openTime.minute
  //         : oh.closeTime.minute;
}

export function getTimeOptions() {
  let options = [];

  for (let i = 0; i <= 23; i++) {
    options.push({ label: i, value: i });
  }
  return options;
}

export function isIntegrationPasswordField(key) {
  if (!key) {
    return false;
  }
  key = key.toUpperCase();
  return key.indexOf("PASSWORD") > -1;
}
