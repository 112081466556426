import React from "react";
import { stripHTML, truncateText } from "../../utils/utils";

export function getStatusTitle(status) {
  let title = status.title;
  if (!title && status.detailMessage) {
    title = (
      <div
        dangerouslySetInnerHTML={{
          __html: truncateText(stripHTML(status.detailMessage))
        }}
      />
    );
  }
  if (!title && status.textMessage) {
    title = truncateText(status.textMessage);
  }

  if (!title && status.artifactLibrary && status.artifactLibrary.title) {
    title = truncateText(status.artifactLibrary.title);
  }

  return title;
}

export function getStatusContent(status) {
  if (status.detailMessage) {
    return truncateText(stripHTML(status.detailMessage), 80);
  }

  if (status.artifactLibrary && status.artifactLibrary.textContent) {
    return truncateText(status.artifactLibrary.textContent);
  }

  return null;
}
