import React from "react";

export default function({ onClick }) {
  return (
    <a
      className="d-flex justify-content-center align-items-center cursor-pointer"
      onClick={onClick}
      style={{
        backgroundColor: "#f1f1f1",
        padding: "15px",
        borderRadius: "50%"
      }}
    >
      <span
        className="fas fa-play"
        style={{
          paddingLeft: 1,
          color: "#333"
        }}
      />
    </a>
  );
}
