import { Card, CardBody, CardGroup, Col, Container, Row } from "reactstrap";
import React, { PureComponent } from "react";

class IENotSupported extends PureComponent {
  render() {
    return (
      <div className="app flex-column justify-content-center align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="6" sm={12}>
              <div className="ob-logos mb-3 justify-content-center my-4">
                <div className="">
                  <img
                    alt="myPatientSpace"
                    src="/img/logo-symbol.png"
                    height={32}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>

        <Container>
          <Row className="justify-content-center">
            <Col md="6" sm={12}>
              <CardGroup>
                <Card>
                  <CardBody>
                    <p className="font-lg">
                      {" "}
                      Internet Explorer is not supported.
                    </p>

                    <p>
                      Please use recent versions of one of the supported
                      browsers:
                      <ul className="mt-2">
                        <li>Google Chrome</li>
                        <li>Safari</li>
                        <li>Firefox</li>
                        <li>Microsoft Edge</li>
                      </ul>
                    </p>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default IENotSupported;
