import React, { Component } from "react";
import { Button } from "reactstrap";

class VideoActions extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  leaveRoom = () => {
    this.props.leaveRoom();
  };

  toggleMute = () => {
    let muted = this.state.muted;
    let { activeRoom } = this.props;
    if (muted) {
      activeRoom.localParticipant.audioTracks.forEach(function(audioTrack) {
        audioTrack.track.enable();
      });
      this.setState({ muted: false });
    } else {
      activeRoom.localParticipant.audioTracks.forEach(function(audioTrack) {
        audioTrack.track.disable();
      });
      this.setState({ muted: true });
    }
  };

  toggleVideoPause = () => {
    let { activeRoom } = this.props;
    let videoOff = this.state.videoOff;
    if (videoOff) {
      activeRoom.localParticipant.videoTracks.forEach(function(videoTrack) {
        videoTrack.track.enable();
      });
      this.setState({ videoOff: false });
    } else {
      activeRoom.localParticipant.videoTracks.forEach(function(videoTrack) {
        videoTrack.track.disable();
      });
      this.setState({ videoOff: true });
    }
  };

  render() {
    let { muted, videoOff } = this.state;

    return (
      <div>
        <div className="d-flex mx-2">
          <div className="mb-2">
            <Button
              color="secondary"
              onClick={this.toggleMute}
              className="mr-2"
              style={{
                backgroundColor: "#dddfe2",
                borderRadius: "50%"
              }}
            >
              {muted ? (
                <i
                  className="fa fa-microphone-slash
                "
                />
              ) : (
                <i className="fa fa-microphone" />
              )}
            </Button>
          </div>

          <div className="mb-2">
            <Button
              color="dark"
              onClick={this.toggleVideoPause}
              className="mr-2"
              style={{
                borderRadius: "50%"
              }}
            >
              {videoOff ? (
                <i className="fa fa-play" />
              ) : (
                <i className="fa fa-pause" />
              )}
            </Button>
          </div>
          <div className="mb-2">
            <Button color="danger" onClick={this.leaveRoom}>
              Hang Up
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default VideoActions;
