import React, { Component } from "react";

class QuestionInteger extends Component {
  handleKeyUpBlur = () => {
    let { question, validateQuestions } = this.props;
    console.log(question);
    if (question.answers && question.answers[0]) {
      validateQuestions();
    }
  };
  render() {
    let { question, setData, validateQuestions } = this.props;

    return (
      <input
        value={
          question.answers && question.answers[0]
            ? question.answers[0].valueInteger
            : ""
        }
        type="text"
        onChange={(e) => {
          setData({
            valueInteger: e.target.value,
          });
        }}
        // onKeyUp={this.handleKeyUpBlur}
        onBlur={this.handleKeyUpBlur}
        defaultValue={question.initialValue}
        className={"form-control"}
      />
    );
  }
}

export default QuestionInteger;
