import { take, put, select } from "redux-saga/effects";

import { LOAD_CLINICS, LOAD_CLINICS_DONE } from "../actions/actionTypes";

import AppointmentService from "../../services/appointmentsService";

export function* loadClinics() {
  while (true) {
    const action = yield take(LOAD_CLINICS);
    const clinics = yield select(state => state.clinics);
    if (!clinics.loading && "loadMore" in clinics && !clinics.loadMore) {
      return false;
    }

    const page = clinics.page || 0;

    yield put({ type: LOAD_CLINICS });

    try {
      const result = yield AppointmentService.fetchClinics(
        page,
        clinics.filter
      );
      yield put({
        type: LOAD_CLINICS_DONE,
        clinics: result.data,
        page: page + 1,
        loadMore: result.loadMore,
        total: result.total
      });
    } catch (error) {
      yield put({
        type: LOAD_CLINICS_DONE,
        clinics: [],
        page: page + 1,
        loadMore: false
      });
      console.log("ERROR", error);
    }
  }
}
