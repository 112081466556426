import React, { PureComponent } from "react";
import ActionRenderer from "./ActionRenderer";
import { isPatient } from "../../security/roles";
import { connect } from "react-redux";
import { Alert } from "reactstrap";
import PatientAppServices from "../../services/PatientAppServices";
import { showToaster } from "../../State/actions/toaster";

class ActionsRenderer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { actions: props.actions || [] };
  }

  executeAction = index => {
    let instance = this.state.actions[index];
    let { action } = instance;
    let { execution } = action;
    let { command } = execution;
    if (command === "ADD_USER_EVENT") {
      execution.event = {
        action: execution.event.action,
        artifactType: this.props.artifactType,
        artifactId: this.props.artifactId,
        artifactName: this.props.artifactName,
        artifactTitle: this.props.artifactTitle
      };
    }

    PatientAppServices.triggerAction({
      id: instance.id,
      action
    }).then(response => {
      if (response === "MAX_PARTICIPANTS_EXCEEDED") {
        this.props.showToaster(
          "There is no free space available at the moment. All slots are full."
        );
        return;
      }

      let { actions } = this.state;
      actions[index] = { ...instance, executed: true };
      this.setState({ actions: [...actions] });
    });
  };

  render() {
    let { userRole } = this.props;
    let { actions = [] } = this.state;
    let executedAction = actions.filter(a => !!a.executed)[0];

    if (executedAction) {
      return (
        <div className="d-flex justify-content-center align-items-center my-2">
          <Alert color="info">
            {executedAction.action.postExecutionMessage}
          </Alert>
        </div>
      );
    }

    if (!isPatient(userRole) || actions.length === 0) {
      return null;
    }

    return (
      <div
        className="d-flex justify-content-between align-items-center my-2"
        style={{ width: "100%" }}
      >
        {actions.map((action, index) => (
          <ActionRenderer
            {...this.props}
            index={index}
            action={action.action}
            execute={this.executeAction}
          />
        ))}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let userRole = state.user.role;

  return {
    userRole
  };
};

let actions = {
  showToaster: showToaster
};

export default connect(
  mapStateToProps,
  actions
)(ActionsRenderer);
