import React from "react";
import MPSSelect from "../../../components/MPSSelect";
import I18nProvider from "../../../services/I18nProvider";
import Messages from "../../Messages";
import Text from "../../../components/Text";
const intl = I18nProvider.getIntlProvider();

class CareTeamSelector extends React.PureComponent {
  render() {
    let {
      allCareTeams,
      careTeam,
      onChange,
      multi,
      loading,
      style,
      disabled,
      onBlur
    } = this.props;

    return (
      <MPSSelect
        name="form-field-name"
        value={careTeam}
        options={allCareTeams}
        onChange={onChange}
        clearable={false}
        multi={multi}
        isLoading={loading}
        style={style}
        disabled={disabled}
        placeholder={
          multi ? (
            <Text content={Messages.select_care_teams} />
          ) : (
            <Text content={Messages.select_care_team} />
          )
        }
        onBlur={onBlur}
      />
    );
  }
}

export default CareTeamSelector;
