import React from "react";
import { connect } from "react-redux";
import { Button } from "reactstrap";

function GoToHome({ userRole }) {
  // return (
  //   <div>
  //     <Link className="btn btn-secondary" to="/">
  //       {isStaff(userRole) ? "Return to Patients View" : "Go to home page"}
  //     </Link>
  //   </div>
  // );

  return (
    <div>
      <Button
        className="btn btn-secondary"
        onClick={() => {
          window.close();
        }}
      >
        Close
      </Button>
    </div>
  );
}

const mapstatetoprops = (state, ownProps) => {
  return {
    userRole: state.user.role
  };
};

export default connect(mapstatetoprops)(GoToHome);
