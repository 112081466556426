import React, { Component } from "react";
import QuestionImage from "./QuestionImage";

class QuestionChoiceWithImage extends Component {
  render() {
    let { question, setData } = this.props;

    let options = question.options || [];
    let value =
      question.answers && question.answers[0]
        ? question.answers[0].valueString
        : null;

    return (
      <div className="d-flex justify-content-between">
        {options.map((option, index) => {
          let linkStyle =
            value === option.valueImage.valueString
              ? {
                  padding: "5px",
                  border: "1px solid #45b1e6",
                  boxShadow: "rgb(69, 177, 230) 0px 0px 1px 0px"
                }
              : {};

          return (
            <div className="my-3" style={linkStyle}>
              <a
                className="cursor-pointer"
                onClick={e => {
                  setData({
                    valueString: option.valueImage.valueString,
                    score: option.score
                  });
                }}
              >
                <div className="mb-2 text-center" style={{ fontWeight: 600 }}>
                  {option.valueImage.text}
                </div>
                {value !== option.valueImage.valueString && (
                  <QuestionImage
                    contentURL={
                      option.valueImage.normalStateImage
                        ? option.valueImage.normalStateImage.contentURL
                        : null
                    }
                    index={index}
                  />
                )}
                {value === option.valueImage.valueString && (
                  <QuestionImage
                    contentURL={
                      option.valueImage.selectedStateImage
                        ? option.valueImage.selectedStateImage.contentURL
                        : option.valueImage.normalStateImage
                        ? option.valueImage.normalStateImage.contentURL
                        : null
                    }
                    index={index}
                  />
                )}
              </a>
            </div>
          );
        })}
      </div>
    );
  }
}

export default QuestionChoiceWithImage;
