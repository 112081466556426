import React, { PureComponent } from "react";
import { Card, CardBody, CardGroup, Col, Container, Row } from "reactstrap";
import MPSButton from "../../components/Buttons/Buttons/MPSButton";
import { getMobileOperatingSystem } from "../Onboarding/Utils";
import Messages from "../../Shared/Messages";
import Text from "../../components/Text";

class UserOboardingConfirmationMessage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { device: "unknown" };
  }

  componentDidMount() {
    this.setState({ device: getMobileOperatingSystem() });
  }

  render() {
    let { confirmSuccessMessage } = this.props;
    let { device } = this.state;
    return (
      <div className="app flex-column justify-content-center align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <div className="ob-logos my-4">
                <div className="p-3">
                  <img
                    alt="myPatientSpace"
                    src="/img/logo-symbol.png"
                    height={32}
                  />
                </div>
              </div>

              <CardGroup>
                <Card className="p-md-4 p-sm-3">
                  <CardBody>
                    <div>
                      {confirmSuccessMessage && (
                        <p>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: confirmSuccessMessage
                            }}
                          />
                        </p>
                      )}
                      {!confirmSuccessMessage && (
                        <div style={{ color: "#28623c", fontWeight: 500 }}>
                          <Text
                            content={Messages.e_p_confirmed}
                            data={{
                              linkType:
                                this.props.linkType === "email"
                                  ? "Email"
                                  : "Phone"
                            }}
                          />
                        </div>
                      )}
                      {device === "unknown" && (
                        <div>
                          <div className="mt-3">
                            <Text content={Messages.download_app} />
                          </div>

                          <div className="my-3 d-flex justify-content-center">
                            <a href="https://itunes.apple.com/app/mypatientspace/id1443558006?mt=8">
                              <img
                                src="https://www.mypatientspace.com/assets/img/download-appstore.png"
                                alt="iPhone/iPad"
                                style={{ width: "135px", height: "40px" }}
                              />
                            </a>

                            <a href="https://play.google.com/store/apps/details?id=com.mypatientspace.mypatientspace">
                              <img
                                src="https://www.mypatientspace.com/assets/img/download-playstore.jpeg"
                                alt="Android"
                                style={{
                                  width: "135px",
                                  height: "40px",
                                  marginLeft: "10px"
                                }}
                              />
                            </a>
                          </div>
                        </div>
                      )}
                      {(device === "Android" || device === "iOS") && (
                        <div>
                          <div className="mt-3 d-flex justify-content-center">
                            <a
                              href="https://links.mypatientspace.com/join/qbvQ"
                              className="btn btn-lg btn-primary"
                              style={{ fontWeight: 600 }}
                            >
                              <Text content={Messages.sign_in_uc} />
                            </a>
                          </div>
                        </div>
                      )}
                      <br />
                      <div className="text-center">
                        <Text content={Messages.dont_rem_pwd} />
                        <MPSButton
                          type="link"
                          className="mx-1"
                          onClick={() => {
                            this.props.history.push("/forgot-password");
                          }}
                          target="_blank"
                        >
                          <Text content={Messages.here} />
                        </MPSButton>
                        <Text content={Messages.to_reset} />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default UserOboardingConfirmationMessage;
