import React from "react";
import { RawIntlProvider } from "react-intl";
import { connect } from "react-redux";
import I18nProvider from "../services/I18nProvider";

class I18nProviderWrapper extends React.Component {
  render() {
    let { children, locale } = this.props;
    I18nProvider.loadMomentLocale(locale);
    let intl = I18nProvider.createIntlProvider(locale);
    return <RawIntlProvider value={intl}>{children}</RawIntlProvider>;
  }
}

const mapStateToProps = state => {
  return {
    locale: I18nProvider.getLocale()
  };
};

export default connect(mapStateToProps)(I18nProviderWrapper);
