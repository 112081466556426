import { SHOW_TOASTER, CLOSE_TOASTER } from "./actionTypes";
import I18nProvider from "../../services/I18nProvider";

export function showLocalizedToaster(message, type, duration) {
  const intl = I18nProvider.getIntlProvider();
  return showToaster(
    intl.formatMessage(message.content, message.data),
    type,
    duration
  );
}

export function showToaster(message, type, duration, width) {
  return {
    type: SHOW_TOASTER,
    data: {
      message: message,
      type,
      duration,
      width
    }
  };
}

export function closeToaster() {
  return { type: CLOSE_TOASTER };
}

export function showErrorToaster(error, defaultMessage, duration) {
  let { showError, message } = error;
  if (showError) {
    message = message || defaultMessage;
    return { type: SHOW_TOASTER, data: { message, type: "error", duration } };
  } else {
    return { type: CLOSE_TOASTER };
  }
}

export function showLocalizedErrorToaster(error, defaultMessage, duration) {
  const intl = I18nProvider.getIntlProvider();
  return showErrorToaster(
    error,
    intl.formatMessage(defaultMessage.content, defaultMessage.data),
    duration
  );
}
