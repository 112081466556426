import React from "react";
import { Button, Card, CardBody } from "reactstrap";
import { formatSlotDate } from "../../../utils/utils";
import MPSBarLoader from "../../../components/MPSBarLoader";
import Messages from "../../../Shared/Messages";
import Text from "../../../components/Text";

class CancelAppointmentView extends React.PureComponent {
  render() {
    let { appointment, cancelled, loading, submitting, error } = this.props;

    if (loading) {
      return (
        <div style={{ margin: "100px auto", width: 100 }}>
          <MPSBarLoader />
        </div>
      );
    }

    if (!appointment) {
      return null;
    }

    return (
      <div className="app my-4">
        <div className="container">
          <div className="d-flex justify-content-center my-4 pb-4">
            <div>
              <img
                alt="myPatientSpace"
                src="/img/logo-symbol.png"
                height={36}
              />
            </div>
          </div>

          <div className="py-4" />

          <div className="row d-flex justify-content-center mt-4">
            <div className="col-md-8">
              <Card>
                {error ? (
                  <CardBody>
                    <div className="mt-4 mb-2 font-lg text-center text-danger">
                      {error}
                    </div>
                  </CardBody>
                ) : cancelled ? (
                  <CardBody>
                    <div className="mt-4 mb-2 font-lg text-center">
                      <Text content={Messages.appointmentCancelled} />
                    </div>
                  </CardBody>
                ) : (
                  <CardBody>
                    <h4 className="text-center my-3">
                      <Text content={Messages.cancel_booking} />
                    </h4>
                    <div className="mt-4 mb-2 font-lg">
                      <strong>{appointment.appointmentDescription}</strong>{" "}
                      <Text content={Messages.scheduled_for} />{" "}
                      <strong>
                        {formatSlotDate(appointment.start, appointment.end)}
                      </strong>
                      ?
                    </div>

                    <div
                      style={{
                        marginTop: 20,
                        flex: 1,
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center"
                      }}
                    >
                      {submitting ? (
                        <div className="d-flex" style={{ width: 50 }}>
                          <MPSBarLoader />
                        </div>
                      ) : (
                        <Button
                          size="lg"
                          color="primary"
                          onClick={this.props.cancelAppointment}
                        >
                          <Text content={Messages.cancel_booking} />
                        </Button>
                      )}
                    </div>
                  </CardBody>
                )}
              </Card>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CancelAppointmentView;
