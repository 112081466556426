import React, { Component } from "react";
import { Input } from "reactstrap";
import CareTeamSelectorContainer from "../../../Selectors/CareTeamSelector";
import MPSSelect from "../../../../components/MPSSelect";

class CodeableConceptCode extends Component {
  static getDisplayValue(av) {
    return av && av.valueCode ? av.valueCode.display : null;
  }

  static getDisplayValueInFilter(av) {
    return av.valueCode ? av.valueCode.code : null;
  }

  static getOptionsList(options) {
    return options.map(option => {
      return {
        value: option.valueCode.code,
        label: option.valueCode.display
      };
    });
  }

  render() {
    let {
      attributeValue,
      choiceList,
      options,
      setData,
      dataField
    } = this.props;
    attributeValue.valueCode = attributeValue.valueCode || {};

    if (dataField === "CareTeam") {
      return (
        <CareTeamSelectorContainer
          careTeam={attributeValue.valueCode.code}
          onChange={option => {
            if (option) {
              let valueCode = attributeValue.valueCode || {};
              valueCode.code = option.value;
              valueCode.display = option.label;
              attributeValue.valueCode = valueCode;
            } else {
              attributeValue.valueCode = {};
            }
            setData(attributeValue);
          }}
          onBlur={this.props.onBlur}
          style={{ border: "1px solid #e0e6e8" }}
        />
      );
    }

    if (choiceList) {
      return (
        <div className="inputHolder flex-column">
          <MPSSelect
            className="ui-attribute-el-select"
            style={{
              minWidth: "150px",
              with: "auto",
              color: "#3e515b"
            }}
            name="form-field-name"
            value={attributeValue.valueCode.code}
            onChange={option => {
              if (option) {
                attributeValue.valueCode = option.value;
              } else {
                attributeValue.valueCode = {};
              }
              setData(attributeValue);
            }}
            options={options.map(option => {
              return {
                code: option.valueCode.code,
                value: option.valueCode,
                label: option.valueCode.display
              };
            })}
            valueKey="code"
            onBlur={this.props.onBlur}
          />
        </div>
      );
    } else {
      let cccCode = attributeValue.valueCode.code;
      let cccVal = attributeValue.valueCode.display;
      return (
        <div className="inputHolder flex-row">
          <Input
            className="mr-2 ui-attribute-el"
            placeholder="Code"
            value={cccCode}
            onChange={e => {
              attributeValue.valueCode.display = e.target.value;
              setData(attributeValue);
            }}
            type="text"
            onBlur={this.props.onBlur}
          />
          <Input
            placeholder="Value"
            value={cccVal}
            onChange={e => {
              attributeValue.valueCode.display = e.target.value;
              setData(attributeValue);
            }}
            type="text"
            onBlur={this.props.onBlur}
          />
        </div>
      );
    }
  }
}

export default CodeableConceptCode;
