import React, { Component } from "react";
import { Thumbs } from "../../Statuses";

class PreviewWithExternalContent extends Component {
  render() {
    let article = this.props.article;
    if (!article) {
      return null;
    }

    let text = article.detailText;

    return (
      <div className="status" style={{ overflow: "auto" }}>
        {article.contents && article.contents.length >= 1 && (
          <Thumbs content={article.contents} imgClass="image-100" />
        )}
        <div className="media justify-content-center">
          <a
            href={article.contentExternalURL}
            target="_blank"
            rel="noopener noreferrer"
          >
            {article.contentExternalURL}
          </a>
        </div>

        {text && (
          <div className="justify-content-center" style={{ display: "flex" }}>
            <div
              style={{ marginTop: "20px" }}
              dangerouslySetInnerHTML={{ __html: text }}
            />
          </div>
        )}
      </div>
    );
  }
}

export default PreviewWithExternalContent;
