import React from "react";
import { connect } from "react-redux";
import CancelAppointmentView from "../components/Appointments/CancelAppointmentView";
import appointmentsService from "../../services/appointmentsService";
import { parseURLQueryParams } from "../../utils/utils";

class CancelAppointmentContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.fetchAppointmentDetails();
  }

  fetchAppointmentDetails = () => {
    let { appointmentId, pId, uId } = this.props;
    this.setState({ loading: true });
    appointmentsService
      .fetchAppointmentAnonymous(appointmentId, pId, uId)
      .then(response => {
        this.setState({ appointment: response, loading: false });
      })
      .catch(e => {
        this.setState({ loading: false, error: e.message });
      });
  };

  cancelAppointment = () => {
    this.setState({ submitting: true });
    let { appointmentId, pId, uId } = this.props;
    appointmentsService
      .cancelAppointmentAnonymous(appointmentId, pId, uId)
      .then(response => {
        this.setState({ cancelled: true, submitting: false });
      })
      .catch(e => this.setState({ submitting: false }));
  };

  render() {
    let { loading, appointment, submitting, cancelled, error } = this.state;
    return (
      <CancelAppointmentView
        appointment={appointment}
        cancelAppointment={this.cancelAppointment}
        cancelled={cancelled}
        loading={loading}
        submitting={submitting}
      />
    );
  }
}

let data = (state, ownProps) => {
  let query = parseURLQueryParams(window.location.search);
  return {
    appointmentId: ownProps.match.params.appointmentId,
    pId: query.pid,
    uId: query.uid
  };
};

export default connect(data)(CancelAppointmentContainer);
