import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, CardGroup, Card, CardBody } from "reactstrap";
import userService from "../../services/userService";
import ForgotPasswordForm from "./ForgotPasswordForm";
import { showErrorToaster } from "../../State/actions/toaster";
import MPSBarLoader from "../../components/MPSBarLoader";
import { parseURLQueryParams } from "../../utils/utils";
import Text from "../../components/Text";
import I18nProvider from "../../services/I18nProvider";
import Messages from "../../Shared/Messages";
import RegionSelector from "../RegionSelector";
const intl = I18nProvider.getIntlProvider();

class ForgotPasswordView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  sendForgotPasswordReq = phone => {
    this.setState({ loading: true });
    userService
      .processForgotPasswordRequest(phone)
      .then(response => {
        this.setState({ loading: false });
        this.setState({ status: "succeeded" });
      })
      .catch(error => {
        this.setState({ loading: false });
        this.props.showErrorToaster(
          error,
          intl.formatMessage(Messages.password_reset_failed),
          7
        );
      });
  };

  render() {
    let { status, loading } = this.state;
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              {loading && <MPSBarLoader />}
              <CardGroup>
                <Card className="p-4">
                  <div className="d-flex justify-content-center mb-4">
                    <img src="/img/logo-symbol.png" height={42} />
                  </div>
                  <CardBody>
                    {!status && (
                      <ForgotPasswordForm
                        sendRequest={this.sendForgotPasswordReq}
                        linkType={this.props.linkType}
                      />
                    )}
                    {status === "succeeded" && (
                      <div className="text-center">
                        <Text content={Messages.check_your_sms_email} />
                      </div>
                    )}
                  </CardBody>
                </Card>
              </CardGroup>

              <div className="my-4 d-flex justify-content-center align-items-center">
                <div>
                  <RegionSelector
                    className="drop-up"
                    style={{
                      backgroundColor: "#f7f7f7",
                      border: 0,
                      width: 65,
                      height: 30
                    }}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const actions = {
  showErrorToaster
};

const data = (state, ownProps) => {
  let params = null;
  if (ownProps.location && ownProps.location.search) {
    params = parseURLQueryParams(ownProps.location.search);
  }

  return {
    linkType: params ? params.cp : null
  };
};

export default connect(
  data,
  actions
)(ForgotPasswordView);
