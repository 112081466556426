import React, { PureComponent } from "react";
import { showLocalizedToaster } from "../../../State/actions/toaster";
import { connect } from "react-redux";
import PatientAppServices from "../../../services/PatientAppServices";
import AnonymousAppointmentScreening from "./AnonymousAppointmentScreening";

class AnonymousAppointmentScreeningContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.loadAppointmentScreening();
  }

  loadAppointmentScreening = () => {
    let { mrn, appointmentRefNumber } = this.props;
    this.setState({ loading: true });
    PatientAppServices.getAppointmentScreening(appointmentRefNumber, mrn)
      .then(data => {
        this.setState({
          appointment: data.appointment,
          appTheme: data.appTheme,
          loading: false
        });
      })
      .catch(e => {
        this.setState({ loading: false, error: e.message });
      });
  };

  render() {
    let { mrn, appointmentRefNumber } = this.props;
    let { loading, appointment, appTheme, error } = this.state;

    return (
      <AnonymousAppointmentScreening
        loadAppointmentScreening={this.loadAppointmentScreening}
        loading={loading}
        appointment={appointment}
        appTheme={appTheme}
        mrn={mrn}
        appointmentRefNumber={appointmentRefNumber}
        error={error}
      />
    );
  }
}

const actions = {
  showToaster: showLocalizedToaster
};

const data = (state, ownProps) => {
  // let params = null;
  // if (ownProps.location && ownProps.location.search) {
  //   params = parseURLQueryParams(ownProps.location.search);
  // }

  let mrn = null;
  let appointmentRefNumber = null;
  let key = ownProps.match ? ownProps.match.params.key : null;
  if (key) {
    key = atob(key);
    key = key.split("_");
    mrn = key[0];
    appointmentRefNumber = key[1];
  }

  return {
    mrn,
    appointmentRefNumber
  };
};

export default connect(
  data,
  actions
)(AnonymousAppointmentScreeningContainer);
