import moment from "moment";
import Messages from "../../../Shared/Messages";
import I18nProvider from "../../../services/I18nProvider";

export function getStatsMap(stats) {
  let statsMap = {};
  stats.forEach(s => {
    statsMap[s.stat] = s.countStr;
  });
  return statsMap;
}
export function getAttributesMap(attributes) {
  let attributesMap = {};
  attributes.forEach(a => {
    attributesMap[a.code.code] = a;
  });
  return attributesMap;
}

// export function getSpeciality(attributes) {
//   let speciality = attributes["speciality"] || null;
//   if (speciality) {
//     speciality = speciality[getFieldName(speciality.type)];
//   }
//
//   return speciality;
// }
//
// export function getCallingRequired(attributes) {
//   let cr = attributes["calling_patients_required"] || null;
//   if (cr) {
//     cr = cr[getFieldName(cr.type)];
//   }
//   return cr;
// }

export function getConsultant(actors) {
  let consultants = actors.filter(a => a.spaceRole === "ROLE_DOCTOR") || null;
  let consultant = "";
  if (consultants && consultants.length) {
    consultants.forEach((c, index) => {
      consultant =
        consultant +
        `${c.displayHumanName.text} ${
          index < consultants.length - 1 ? ", " : ""
        }`;
    });
  }

  return consultant;
}

export function getCaller(actors) {
  let caller =
    actors.filter(a => a.spaceRole === "ROLE_GENERAL_STAFF")[0] || null;
  if (caller) {
    caller = caller.displayHumanName.text;
  }
  return caller;
}

export function getPatient(actors) {
  let patient = actors.filter(a => a.referenceType === "PATIENT")[0] || null;
  return patient;
}

export function getStatusBadgeClass(status) {
  if (status === "REQUESTED") {
    return "danger";
  } else if (status === "ACCEPTED") {
    return "warning";
  } else if (status === "COMPLETED") {
    return "success";
  }
  return "dark";
}

export function getScreeningTextAndColor(result) {
  const intl = I18nProvider.getIntlProvider();
  if (result === "COMPLETED_POSITIVE") {
    return {
      text: intl.formatMessage(Messages.clinic_sts_risk),
      color: "danger"
    };
  } else if (result === "COMPLETED_NEGATIVE") {
    return {
      text: intl.formatMessage(Messages.clinic_sts_ok),
      color: "success"
    };
  } else if (result === "NOT_REACHABLE") {
    return {
      text: intl.formatMessage(Messages.clinic_sts_nr),
      color: "warning"
    };
  } else if (result === "NOT_AVAILABLE") {
    return {
      text: intl.formatMessage(Messages.clinic_sts_na),
      color: "warning"
    };
  } else if (result === "NO_PHONE") {
    return {
      text: intl.formatMessage(Messages.clinic_sts_np),
      color: "warning"
    };
  } else if (result === "ALREADY_RESCHEDULED") {
    return {
      text: intl.formatMessage(Messages.clinic_sts_resch),
      color: "info"
    };
  } else if (result === "LEFT_MESSAGE") {
    return { text: intl.formatMessage(Messages.clinic_sts_lm), color: "info" };
  } else if (result === "NO_ANSWER") {
    return {
      text: intl.formatMessage(Messages.clinic_sts_noans),
      color: "warning"
    };
  } else if (result === "PATIENT_REQUEST_CANCEL") {
    return {
      text: intl.formatMessage(Messages.clinic_sts_pt_reqc),
      color: "info"
    };
  } else if (result === "PATIENT_PREVIOUSLY_CANCELLED") {
    return {
      text: intl.formatMessage(Messages.clinic_sts_pt_ca),
      color: "info"
    };
  }

  return {
    text: intl.formatMessage(Messages.clinic_sts_pending),
    color: "warning"
  };
}

export function shouldAllowScreening(start, end) {
  if (!start || !end) {
    return true;
  }
  let now = new Date().getTime();
  return now >= start && now <= end;
}

export function getScreeningPreHours(appointmentDate, effectiveDate) {
  return moment(appointmentDate).diff(moment(effectiveDate), "hours");
}

export function getAppointmentPriorityLabel(priority) {
  const intl = I18nProvider.getIntlProvider();
  let values = {
    LOW: intl.formatMessage(Messages.low),
    MEDIUM: intl.formatMessage(Messages.medium),
    HIGH: intl.formatMessage(Messages.high)
  };
  return values[priority];
}
