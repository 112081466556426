import React from "react";
// import Text from "../../components/Text";
// import Messages from "../../Shared/Messages";

export default function SignupFooter() {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center">
      <div className="p-1 d-flex justify-content-center align-items-center">
        <div className="mx-2">
          <img
            src="/img/ce-mark.png"
            height={30}
            alt="myPatientSpace - CE Mark"
          />
        </div>

        <div>
          <img
            className="ml-3"
            src="/img/mps-iso.png"
            height={30}
            alt="myPatientSpace - ISO"
          />
        </div>
      </div>
      {/*<div className="font-italic">*/}
      {/*  <Text content={Messages.class1_med_device} />*/}
      {/*</div>*/}
    </div>
  );
}
