import React from "react";

export const PRIVACY_HTML =
  "<p>Jan 20, 2022</p>\n" +
  "\n" +
  "\n" +
  "\n" +
  "<h2>myPatientSpace&#8217;s Privacy Policy</h2>\n" +
  '<p class="c12">Last revised Jan 2022</p>\n' +
  "<h3>Privacy Policy</h3>\n" +
  '<p class="c12"><span class="c2 c19">At </span><span class="c19 c24">myPatientSpace</span><span class="c2 c19">, we understand that the privacy and security of your information is important to you. To this end, we endeavour to safeguard the privacy of all information you entrust us with in order to protect and respect your privacy.</span><span class="c4 c2 c8"> </span></p>\n' +
  '<p class="c6"><span class="c0"></span></p>\n' +
  '<p class="c12"><span class="c4 c2 c8">myPatientSpace provides services to our customers (various types of healthcare providers and pharmaceuticals). In turn, they invite their staff and patients to use myPatientSpace during their care journeys. Patients and staff may also invite extended care takers (e.g. family members). In these circumstances in respect of your personal data provided by your healthcare provider to the myPatientSpace application, the healthcare provider is the data controller, and myPatientSpace is the data processor.</span></p>\n' +
  '<p class="c6"><span class="c0"></span></p>\n' +
  '<p class="c12"><span class="c2 c15">Our privacy policy describes which personal data we collect and how we use and share this data. We recommend that you read our privacy policy. If you have any questions regarding the way in which we protect your personal data, please contact us by writing to </span><span class="c2 c16"><a class="c13" href="mailto:support@MyPatientSpace.com">support@mypatientspace.com</a></span><span class="c4 c2 c8">.</span></p>\n' +
  '<p class="c6"><span class="c4 c2 c8"></span></p>\n' +
  '<p class="c12"><span class="c2 c15">Any reference to “I”, “we”, or “our” is a reference to myPatientSpace.</span></p>\n' +
  '<p class="c12"><span class="c4 c2 c8">Any reference to “HC” is a reference to the healthcare provider who has given you access to myPatientSpace and is the data controller. </span><span class="c0"></span></p>\n' +
  '<p class="c12">BY USING OUR SERVICES, YOU COMPLY WITH THE COLLECTION, USE, AND TRANSMITTAL OF YOUR PERSONAL DATA FOR PROCESSING IN THE EU AS DESCRIBED IN THIS PRIVACY POLICY.</p>\n' +
  '<p class="c12"><span class="c4 c8 c24"> DEPENDING ON THE LOCATION OF YOUR HEALTHCARE PROVIDER, YOUR DATA MAY BE STORED AND PROCESSED IN A DIFFERENT REGION. FOR HEALTHCARE PROVIDERS IN THE EU AND UK, THE DATA IS STORED IN THE EU </span></p>\n' +
  '<p class="c12"><span class="c4 c8 c24"> It is not our intention to transfer your data outside of the EEA, however where we are required to do so, we will ensure the recipients are in full compliance with the requirements of the GDPR. </span><span class="c0"></span></p>\n' +
  '<p class="c12"><strong><span class="c2 c17">1. About myPatientSpace</span></strong></p>\n' +
  '<p class="c12"><span class="c4 c2 c8">We are myPatientSpace Limited (&#8220;myPatientSpace&#8221;). We deliver a mobile application-based service in the form of the app: myPatientSpace or myStaffSpace and associated digital platform (web and server).</span><span class="c4 c2 c8"></span></p>\n' +
  '<p class="c12"><strong><span class="c4 c2 c17">2. Personal Data</span></strong></p>\n' +
  '<p class="c40"><span class="c0">Personal data is defined by the General Data Protection Regulation (EU Regulation 2016/679) (the “GDPR”) as ‘any information relating to an identifiable person who can be directly or indirectly identified in particular by reference to an identifier’.</span></p>\n' +
  '<p class="c33"><span class="c0">Personal data is, in simpler terms, any information about you that enables you to be identified. Personal data covers obvious information such as your name and contact details, but it also covers less obvious information such as identification numbers, electronic location data, and other online identifiers. We may also process certain special category data which may include health information. The personal data we collect is outlined in section 4 below.</span><span class="c4 c8 c32"></span></p>\n' +
  '<p class="c12"><strong><span class="c4 c2 c17">3. Your Rights</span></strong></p>\n' +
  '<p class="c34"><span class="c0">You have several rights in relation to how we use your information:</span></p>\n' +
  "<ol>\n" +
  '<li><span class="c0">The right to be informed: through this policy</span></li>\n' +
  '<li><span class="c2">Access your information</span><span class="c2">, </span><span class="c2">receive copies of the information he</span><span class="c2">ld</span><span class="c2"> about you</span><span class="c2">, have inaccurate information corrected and incomplete information updated or have your information deleted.</span><span class="c2"> </span><span class="c2 c27">In the event that you wish to exercise these rights, please contact your hospital.  </span></li>\n' +
  '<li><span class="c0">Object to particular uses of your personal data &#8211; However, doing so may have an impact on the services and products we can / are willing to provide.</span></li>\n' +
  '<li><span class="c0">Object to use of your personal data for direct marketing purposes. If you object to this use, we will stop using your data for direct marketing purposes. However, we do not currently engage in any direct marketing or advertising using any personal data.</span></li>\n' +
  '<li><span class="c0">Have your data deleted or its use restricted – you have a right to this under certain circumstances. For example, where you withdraw consent you gave us </span></li>\n' +
  '<li><span class="c0">Obtain a transferable copy of certain data which can be transferred to another provider, known as “the right to data portability”.</span></li>\n' +
  '<li><span class="c0"></span>Withdraw consent at any time, where any processing is based on consent</li>\n' +
  "</ol>\n" +
  '<p class="c34"><span class="c2 c4">If you have a cause for complaint, you can contact the Irish Data Protection Commission or other relevant supervisory authority.</span><span class="c4 c32 c26 c35"> </span><span class="c0"></span></p>\n' +
  '<p class="c12"><strong><span class="c2 c17">4. Data Collection</span></strong></p>\n' +
  '<p class="c18"><span class="c2">myPatientSpace collects data to conduct business and provide you with our services. We collect both personal data and other information for these purposes.</span></p>\n' +
  '<p class="c18"><span class="c2 c15">(a) Personal and sensitive personal data &#8211; &#8220;Personal data&#8221; is data that can be used directly or indirectly, alone or with other information, to identify you as an individual user of myPatientSpace. This is first and foremost data about your contact information, your health and information concerning your treatment journey. Some of the information collected will depend on what your HC has configured. The following are examples of personal data that you or your healthcare provider may submit when you create a user account and when you use myPatientSpace:</span></p>\n' +
  "<ol>\n" +
  '<li><span class="c2 c15">Information about your name, phone number, email address and password.</span></li>\n' +
  '<li><span class="c2 c15">Information about height, weight, body measurements, age, gender and other health data as configured by HC. </span></li>\n' +
  '<li><span class="c2 c15">Patient Data such as PROMs (Patient Reported Outcome Measures) e.g: Oxford knee and hip scores, spirometry or Sleep Data</span></li>\n' +
  '<li><span class="c2 c15">Information about doctor diagnosis and specific information about the condition and procedures</span></li>\n' +
  '<li><span class="c2 c15">Data concerning the number of completed daily tasks and how you rated symptoms such your pain levels.</span></li>\n' +
  '<li><span class="c2 c15">Your permanent physical address (location is NOT continuously tracked).</span></li>\n' +
  '<li><span class="c2 c15">Your biometric data (fingerprint or facial image) should you choose to use these with our services. We do not store this data but use the devices&#8217; in-built capabilities to ease login.</span></li>\n' +
  "</ol>\n" +
  '<p class="c18"><span class="c0">(b) Other Information &#8211; &#8220;Other information&#8221; is anonymous, aggregated, de-identified, or other types of information that do not reveal your identity. Some examples are age, sex, browser, operating system, number of PROMS taken or the amount of time spent on our services. We collect and use this information to understand how you, and our users in general, use our services to continuously improve, innovate, and produce products and services that satisfy our users&#8217; demands. We generally do not view other information as personal data. If we chain together other information in a way that makes you identifiable as an individual user, we will handle that information as personal data.</span></p>\n' +
  '<p class="c12"><strong><span class="c2 c17">5. How We Use Personal Data</span></strong></p>\n' +
  '<p class="c12"><span class="c2 c15">myPatientSpace uses the data we collect to conduct our business, deliver our products and services, improve existing products and services, develop new products and services, and to improve and personalize your user experience when you interact with us. We can use this data to:</span></p>\n' +
  '<p class="c12"><span class="c2 c15">(5.1) Deliver our services and maintain an effective operation of our IT-structure &#8211; We use the data we collect to deliver the services we offer. This includes operating, maintaining, and delivering all content and functions of our services to you. </span></p>\n' +
  '<p class="c12"><span class="c2 c15">(5.2) Communicate with you and respond to your requests </span></p>\n' +
  '<p class="c12"><span class="c2 c15">We can use your personal data to respond to your requests regarding technical support, online services, product information, or any other communication you wish to initiate with us. This may include access to your account to comply with requests for technical support. Please note that regardless of your email settings we can send you messages regarding the performance of services, such as our Terms of Service or this Privacy Policy, or other formal communications regarding our Products or Services that you have purchased or used. </span><span class="c2 c27 c11">You may opt-out from receiving communications from myPatientSpace (other than those necessary for myPatientSpace to provide the service for which you have subscribed) at anytime by emailing us at </span><span class="c23 c2 c27"><a class="c13" href="mailto:support@mypatientspace.com">support@mypatientspace.com</a></span><span class="c2 c3"> This will mean your account is no longer active and you will need to set up a new account with us to avail of our service.</span><span class="c0"></span></p>\n' +
  '<p class="c12"><strong><span class="c2 c17">6. How we handle personal data</span></strong></p>\n' +
  '<p class="c12"><span class="c4 c2 c8">myPatientSpace will only disclose your personal data with your knowledge and as stated in this Privacy Policy, or as indicated at the time of collection. This includes information that enables us to fulfil our obligations to you and conduct our business. We only share your personal data with third parties in certain individual cases, specifically with your consent, or as necessary to complete a transaction or provide a product, service, or feature that you have requested. We may disclose your personal data as described in this Privacy Policy and as described in the following ways:</span><span class="c0"></span></p>\n' +
  '<p class="c18"><span class="c2 c15">(6.1) </span><span class="c2 c19"> Health professionals, independent consultants and hospitals that work with myPatientSpace and offer the use of this application as part of the provision of medical treatment.</span></p>\n' +
  '<p class="c18"><span class="c2 c19">(6.2) IT service providers that either host or have access to our data as part of their product offering.</span></p>\n' +
  '<p class="c18"><span class="c2 c19">(6.3) The company/controller (HC) who has provided this service to you.</span></p>\n' +
  '<p class="c18"><span class="c2 c19">(6.4) Regulatory bodies such as national registries if you have consented to participate.</span></p>\n' +
  '<p class="c12"><span class="c2 c15">(6.5) As we grow and develop &#8211; Business transactions and reorganisations. Should myPatientSpace ever merge with another company or if myPatientSpace should decide to buy, sell, or reorganise parts of or all its business(es), we may disclose or transfer to the extent permitted by law and in accordance with applicable requirements to notify you your personal data to prospective or actual buyers or the subsequent business entity in connection with any of these transactions or reorganisations.</span></p>\n' +
  '<p class="c12"><span class="c2 c15">(6.6) As required by law and special circumstances We may be forced to disclose your personal data if: (i) it is reasonably necessary to comply with legal proceedings (such as a court order, search warrant, etc.) or other legal requirements of any public authority, (ii) such a disclosure would potentially reduce our liability in a real or potential trial, (iii) it is necessary to protect our legal rights or property, or (iv) it is necessary to protect the legal rights, property, or other parties&#8217; physical security or for the prevention or discovery of crime and such a disclosure is legitimate.</span></p>\n' +
  '<p class="c12"><span class="c2 c15">(6.7) We exchange anonymous information about the use of the myPatientSpace app and website with the following companies: [Oxford University, Aptible, AWS, Stripe, Google, Logentries] These companies help us monitor, evaluate and analyse our products’ performance, customer retention and reliability. We exchange your phone number with AWS, an SMS-messaging service. We exchange the number of questionnaires completed with Oxford but no specifics of your personal data. We may share the personal data described in this notice with service providers such as providers of cloud, IT infrastructure, emailing and website analytics services.</span></p>\n' +
  '<p class="c12"><span class="c2 c11 c27">(6.8) Any member of our group of companies, which means our subsidiaries, our ultimate holding company and its subsidiaries.</span><span class="c4 c2 c17"></span></p>\n' +
  '<p class="c12"><strong><span class="c2 c17">7. Information security</span></strong></p>\n' +
  '<p class="c12"><span class="c4 c2 c8">myPatientSpace is committed to protecting the security of your personal data. We use technical and organizational measures designed to protect your information from unauthorized access, theft, and loss. We also recommend that you take additional measures to protect yourself and your information, such as installing antivirus software, closing browsers after use, keeping your login information and passwords secret, and making sure you regularly update software and apps you have downloaded to ensure that you have activated the latest security features on your devices.</span></p>\n' +
  '<p class="c12"><strong><span class="c2 c17">8. Storing data</span></strong></p>\n' +
  '<p class="c12"><span class="c4 c2 c8">myPatientSpace stores your personal data as long as you have an Account, or as long as it is necessary to deliver our Services to you. We also keep and use your personal data, as necessary, to comply with legal obligations, resolve conflicts, and enforce our agreements. The duration of how long your data is stored may be specified by the HC who provides you access to our service. <br /><br />It is not the intention of myPatientSpace to transfer your data outside of the EEA however where we are required to do so then we will ensure the recipients are in full compliance with the requirements of the GDPR </span><span class="c0"></span></p>\n' +
  '<p class="c12"><strong><span class="c2 c17">9. Purpose for processing your data</span></strong></p>\n' +
  '<p class="c12"><span class="c2 c11">We will process any personal data that you provide to us for the following purposes:<br /></span></p>\n' +
  '<ul class="c22 lst-kix_list_2-0 start">\n' +
  '<li class="c30 c37"><span class="c2 c19">To provide you with the services you have ordered.</span></li>\n' +
  '<li class="c10"><span class="c2 c19">To carry out our obligations arising from any contracts entered into between you and HC.</span></li>\n' +
  '<li class="c10"><span class="c2 c19">To deliver and maintain a high-quality service and to improve and develop the service provided.</span></li>\n' +
  '<li class="c10"><span class="c2 c19">To allow you to access the myPatientSpace application and to participate in interactive features of our Service, when you choose to do so.</span></li>\n' +
  '<li class="c10"><span class="c2 c19">To provide you with information, products or services that you request from us or that we feel may interest you, where you have consented to be contacted for such purposes.</span></li>\n' +
  '<li class="c10"><span class="c2 c19">To respond to any communications you might send to us.</span></li>\n' +
  '<li class="c10"><span class="c2 c19">To inform you about changes to the Service, our Agreement with you or website that we believe will be of interest to you.</span></li>\n' +
  '<li class="c10"><span class="c2 c19">As the myPatientSpace application is being actively developed we might contact you to get feedback on the service provided.</span></li>\n' +
  '<li class="c10"><span class="c2 c19">To determine whether you are in compliance with our Agreement with you and to impose sanctions where necessary</span></li>\n' +
  '<li class="c10"><span class="c2 c19">As proof of identity before we disclose personal information to you that we hold about you (in order to establish that we are disclosing the personal information to you and not to someone pretending to be you).</span></li>\n' +
  '<li class="c10"><span class="c2 c19">To process payments from or to you if applicable;</span></li>\n' +
  '<li class="c10"><span class="c2 c19">Compare information for accuracy and verify it with third parties.</span></li>\n' +
  '<li class="c10"><span class="c2 c19">For safety purposes, to provide you with customer support and to resolve technical or other problems; and</span></li>\n' +
  '<li class="c30 c39"><span class="c2 c19">To determine whether you or other users are in compliance with this Agreement relating to the application, including the policies and terms and conditions of the application.</span></li>\n' +
  "</ul>\n" +
  '<p class="c12"><strong><span class="c2 c15">10</span><span class="c2 c17">. Cookies</span></strong></p>\n' +
  '<p class="c12"><span class="c2 c15">How we use data collection tools and online advertising &#8211; myPatientSpace currently does not use cookies but it may use cookies to collect data that helps us to personalize your use of our Services in the future.</span></p>\n' +
  '<p class="c12"><strong><span class="c2 c17">11. Children</span></strong></p>\n' +
  '<p class="c12"><span class="c2 c15">We do not knowingly collect personal data from children under </span><span class="c2 c15">1</span><span class="c4 c2 c8">6. If you become aware that a child has provided us with personal data without parental consent, please contact us at support@mypatientspace.com. If we become aware that a child under 16 has provided us with personal data without the parents’ consent, we will remove the information and delete the child&#8217;s account.</span><span class="c0"></span></p>\n' +
  '<p class="c12"><strong><span class="c2 c17">12.  </span><span class="c2 c17">Information</span><span class="c2 c17"> Sharing</span></strong></p>\n' +
  '<p class="c12"><span class="c4 c2 c8">Residents of the European Economic Area </span></p>\n' +
  '<p class="c12"><span class="c4 c2 c8">If you choose not to provide personal data &#8211; You may choose not to supply myPatientSpace with your personal data. To do this, please send an email to support@mypatientspace.com. If you choose not to provide your personal data, the app&#8217;s personalised care journey program will be disabled. </span><span class="c0"></span></p>\n' +
  '<p class="c12"><em><span class="c4 c2 c8"><strong>Third parties not under our control –</strong> </span></em></p>\n' +
  '<p class="c12"><span class="c4 c2 c8">Please note that this Privacy Policy does not apply to practices for companies we do not own or manage or people we do not employ or manage. For example, if you download one of our applications on your smartphone, the manufacturer of your smartphone may have a policy that applies to its own practices for collecting information through that smartphone. We recommend that you read the privacy policies for each device, website, and service you are using.</span><span class="c0"></span></p>\n' +
  '<p class="c12"><strong><span class="c2 c17">13. How to contact us</span></strong></p>\n' +
  '<p class="c12"><span class="c2 c15">If you have any questions, comments or concerns about the way we handle your personal data, please contact us by sending an email to </span><span class="c2 c23"><a class="c13" href="mailto:support@mypatientspace.com">support@mypatientspace.com</a></span><span class="c4 c2 c8">.</span><span class="c0"></span></p>\n' +
  '<p class="c12"><strong><span class="c2 c17">14. Updates to Privacy Policy</span></strong></p>\n' +
  '<p class="c12"><span class="c4 c2 c8">We may update this Privacy Policy from time to time without notice. You can see when this Policy was last modified by checking the Effective Date. We advise you periodically to review this privacy policy to be sure you understand our privacy practices. If you have a registered account, we may notify you of changes to our privacy policy by email and we may ask you to confirm, acknowledge, and agree to the changes the next time you use our Services.</span><span class="c0"></span></p>\n' +
  '<p class="c12"><span class="c2 c17"><strong>15. Access to Information and Updating and Verifying Personal Data</strong> </span></p>\n' +
  '<p class="c12"><span class="c2 c15">myPatientSpace keeps your personal data up to date. If you live in the European Economic Area, you may request access to, as well as modification, opposition, and deletion of your personal data that is in the system. To access your personal data, please contact myPatientSpace at support@mypatientspace.com. Subject to applicable law, we may charge for this service and will comply with reasonable requests as soon as possible and in any case within the deadlines prescribed by law. You may also contact your HCP to request access to, or request modifications to your personal data.</span><span class="c0"></span></p>\n' +
  '<p class="c12"><span class="c2 c17"><strong>16. Our Details</strong> </span></p>\n' +
  '<p class="c12"><span class="c2 c11">This application is owned and operated by myPatientSpace Limited.</span></p>\n' +
  '<p class="c12"><span class="c2 c11">We are registered in Ireland under </span><b>Company Registration Number 616748</b><span class="c4 c2 c11 c26"> and our registered office is at:</span></p>\n' +
  '<p class="c12"><span class="c24 c25">The View, Malahide, Co. Dublin, Ireland.</span></p>\n' +
  '<p class="c12"><span class="c4 c2 c11 c26">Our principal place of business is: <b>Malahide, Ireland</b>.</span><span class="c4 c2 c11"></span></p>\n' +
  '<p class="c12"><span class="c2 c11">You can contact us:</span></p>\n' +
  '<p class="c12"><span class="c2 c11">(a) by post, to the postal address given above;</span></p>\n' +
  '<p class="c12"><span class="c2 c11">(b) using our website contact form;</span></p>\n' +
  '<p class="c12"><span class="c2 c11">(c) by telephone, on <strong>(+353) 1 267 6625</strong>; or</span></p>\n' +
  '<p class="c12"><span class="c2 c11">(d) by email, to <a class="c13" href="mailto:support@mypatientspace.com">support@mypatientspace.com</a>.</span></p>\n' +
  '<p class="c12"><span class="c2 c11"> </span></p>\n' +
  "\n" +
  "\n" +
  "\n" +
  "<p>&nbsp;</p>";

export default class MYPPrivacyPolicy extends React.PureComponent {
  render() {
    return <div dangerouslySetInnerHTML={{ __html: PRIVACY_HTML }} />;
  }
}
