import React from "react";

export default () => (
  <svg width="13px" height="13px" viewBox="0 0 13 13" version="1.1">
    <title>Combined Shape</title>
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g
      id="4.0-Home-&amp;-Statuss"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      opacity="0.64"
    >
      <g
        id="4.1.6-Statuslist-white"
        transform="translate(-9.000000, -1210.000000)"
        fill="#6E7C99"
        fillRule="nonzero"
      >
        <g id="Group-8" transform="translate(5.000000, 810.000000)">
          <g id="Group-4" transform="translate(0.000000, 215.000000)">
            <path
              d="M10.5,198 C6.91014913,198 4,195.089851 4,191.5 C4,187.910149 6.91014913,185 10.5,185 C14.0898509,185 17,187.910149 17,191.5 C17,195.089851 14.0898509,198 10.5,198 Z M10.5,189.46875 C11.173097,189.46875 11.71875,188.923097 11.71875,188.25 C11.71875,187.576903 11.173097,187.03125 10.5,187.03125 C9.82690296,187.03125 9.28125,187.576903 9.28125,188.25 C9.28125,188.923097 9.82690296,189.46875 10.5,189.46875 Z M10.5,195.96875 C11.173097,195.96875 11.71875,195.423097 11.71875,194.75 C11.71875,194.076903 11.173097,193.53125 10.5,193.53125 C9.82690296,193.53125 9.28125,194.076903 9.28125,194.75 C9.28125,195.423097 9.82690296,195.96875 10.5,195.96875 Z M7.25,192.71875 C7.92309704,192.71875 8.46875,192.173097 8.46875,191.5 C8.46875,190.826903 7.92309704,190.28125 7.25,190.28125 C6.57690296,190.28125 6.03125,190.826903 6.03125,191.5 C6.03125,192.173097 6.57690296,192.71875 7.25,192.71875 Z M13.75,192.71875 C14.423097,192.71875 14.96875,192.173097 14.96875,191.5 C14.96875,190.826903 14.423097,190.28125 13.75,190.28125 C13.076903,190.28125 12.53125,190.826903 12.53125,191.5 C12.53125,192.173097 13.076903,192.71875 13.75,192.71875 Z"
              id="Combined-Shape"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
