import React from "react";
import userService from "../../../services/userService";
import CareTeamSelector from "./CareTeamSelector";

class CareTeamSelectorContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (this.props.allCareTeams) {
      return;
    }
    this.setState({ loading: true });
    userService
      .getCareTeams()
      .then((allCareTeams = []) => {
        allCareTeams = allCareTeams.map(t => ({
          value: t.id,
          label: t.title,
          title: t.title
        }));
        this.setState({ allCareTeams, loading: false });
        if (this.props.preselect && allCareTeams && allCareTeams.length === 1) {
          this.props.onChange(allCareTeams);
        }
      })
      .catch(e => {
        this.setState({ loading: false });
      });
  }

  render() {
    if (this.props.allCareTeams) {
      return <CareTeamSelector {...this.props} />;
    }

    let { allCareTeams, loading } = this.state;

    return (
      <CareTeamSelector
        allCareTeams={allCareTeams}
        {...this.props}
        loading={loading}
      />
    );
  }
}

export default CareTeamSelectorContainer;
