import React, { PureComponent } from "react";
import { Button, Col, Form, Row } from "reactstrap";
import OnboardingChallengeInvite from "./OnboardingChallengeInvite";
import StepInviteEmailPhone from "./StepInviteEmailPhone";
import StepAcceptTerms from "./StepAcceptTerms";
import Text from "../../components/Text";
import Messages from "../../Shared/Messages";

class StepInvite extends PureComponent {
  componentDidMount() {
    this.props.onLogout();
  }

  getTitleMessage = () => {
    let { linkType, challenge } = this.props;
    if (challenge) {
      return <Text content={Messages.enter_to_ai} />;
    }
    if (!linkType) {
      return <Text content={Messages.enter_e_p} />;
    }
    if (linkType && linkType === "email") {
      return <Text content={Messages.enter_email_inv} />;
    }
    if (linkType && linkType === "phone") {
      return <Text content={Messages.enter_cc_ph} />;
    }
  };

  render() {
    let {
      inputType,
      email,
      countryCode,
      phone,
      onSubmit,
      error,
      errorMsg,
      errorEmail,
      errorPhone,
      loading,
      linkType,
      challenge,
      challengeFields,
      challengeLoading,
      errorTerms,
      facilityInfo
    } = this.props;

    return (
      <Form onSubmit={onSubmit} noValidate>
        <h3 className="text-center mb-3 font-sm-size">
          <Text content={Messages.step_1_accept} />
        </h3>
        <p>
          {facilityInfo ? (
            <Text
              content={Messages.invited_to_join_w_fn}
              data={{
                facilityName: facilityInfo.facilityName
              }}
            />
          ) : (
            <Text content={Messages.invited_to_join} />
          )}
          {". "}
          {this.getTitleMessage()}
          {/*{`You’ve been invited to join ${*/}
          {/*  facilityInfo ? facilityInfo.facilityName + " on " : ""*/}
          {/*}myPatientSpace. ${this.getTitleMessage()}`}*/}
        </p>

        {error && (
          <p className="text-muted text-center">
            <span style={{ color: "red" }}>
              {errorMsg ||
                (inputType === "EMAIL" ? "email" : "phone") + " are wrong."}
            </span>
          </p>
        )}

        {errorTerms && (
          <p className="text-muted text-center">
            <span style={{ color: "red" }}>
              {/*Please accept privacy policy and terms and conditions.*/}
              <Text content={Messages.accept_pp_tc} />
            </span>
          </p>
        )}

        {challenge && (
          <OnboardingChallengeInvite
            onUpdateData={this.props.onUpdateData}
            challengeFields={challengeFields}
            challengeLoading={challengeLoading}
          />
        )}

        {!challenge && (
          <StepInviteEmailPhone
            onUpdateData={this.props.onUpdateData}
            inputType={inputType}
            email={email}
            countryCode={countryCode}
            phone={phone}
            errorEmail={errorEmail}
            errorPhone={errorPhone}
            loading={loading}
            linkType={linkType}
          />
        )}

        <StepAcceptTerms
          onUpdateData={this.props.onUpdateData}
          facilityInfo={facilityInfo}
          loading={loading}
          termsAccepted={this.props.termsAccepted}
        />

        {!challengeLoading && (
          <Row>
            <Col xs="12">
              <div className="text-right">
                <Button
                  size="lg"
                  color="primary"
                  className="px-4"
                  disabled={loading}
                >
                  <Text content={Messages.accept_invite} />
                </Button>
              </div>
            </Col>
          </Row>
        )}
      </Form>
    );
  }
}

export default StepInvite;
