import React, { PureComponent } from "react";
import { Form, FormGroup, Input, Label } from "reactstrap";
import MPSButton from "../../components/Buttons/Buttons/MPSButton";
import Select from "react-select";
import CountryCodes from "../../Shared/FHIR/CountryCodes";
import classnames from "classnames";
import {
  doE164,
  parsePhoneNumber,
  validatePhoneNumber
} from "../../utils/phone";
import { validateEmail } from "../../utils/utils";
import Messages from "../../Shared/Messages";
import Text from "../../components/Text";
import I18nProvider from "../../services/I18nProvider";
const intl = I18nProvider.getIntlProvider();

const labelStyle = { fontWeight: 500, width: "100%" };

class ContactVerificationPage extends PureComponent {
  constructor(props) {
    super(props);

    let parsed = null;
    if (props.phone) {
      parsed = parsePhoneNumber(this.props.phone);
    }
    this.state = {
      email: props.email ? props.email.toLowerCase() : "",
      phone: parsed ? parsed.phone : props.phone || "",
      countryCode: parsed ? parsed.countryCode : "+353",
      showCP: !!props.phone,
      showCE: !!props.email,
      confirmPhone: "",
      confirmEmail: ""
    };
  }

  componentDidMount() {
    this.disableEmailPaste();
    this.disablePhonePaste();
  }

  disableEmailPaste = () => {
    const emailInput = document.getElementById("veri-confirm-email");
    if (emailInput) {
      emailInput.onpaste = function(e) {
        e.preventDefault();
      };
    }
  };
  disablePhonePaste = () => {
    const phoneInput = document.getElementById("veri-confirm-phone");
    if (phoneInput) {
      phoneInput.onpaste = function(e) {
        e.preventDefault();
      };
    }
  };

  validate = () => {
    const {
      phone,
      countryCode,
      email,
      confirmPhone,
      confirmCountryCode,
      confirmEmail
    } = this.state;

    let errors = {};
    if (!phone && !email) {
      errors.phone = true;
      errors.email = true;
    } else {
      if (phone) {
        if (!countryCode || !validatePhoneNumber(countryCode + " " + phone)) {
          errors.phone = true;
        } else if (
          doE164(countryCode, phone) !==
          doE164(confirmCountryCode, confirmPhone)
        ) {
          errors.confirmPhone = true;
        }
      }

      if (email) {
        if (!validateEmail(email)) {
          errors.email = true;
        } else if (
          !confirmEmail ||
          email.toLowerCase() !== confirmEmail.toLowerCase()
        ) {
          errors.confirmEmail = true;
        }
      }
    }

    this.setState({ errors });
    return {
      valid:
        !errors.phone &&
        !errors.email &&
        !errors.confirmPhone &&
        !errors.confirmEmail,
      values: { email: email, phone: doE164(countryCode, phone) }
    };
  };

  handleOnBlurConfirmEmail = () => {
    const { errors = {}, email, confirmEmail } = this.state;
    errors.confirmEmail = false;
    this.setState({ errors: { ...errors } });
    if (!confirmEmail || email.toLowerCase() !== confirmEmail.toLowerCase()) {
      errors.confirmEmail = true;
      this.setState({ errors: { ...errors } });
    }
  };

  handleOnBlurConfirmPhone = () => {
    let {
      errors = {},
      phone,
      countryCode,
      confirmPhone,
      confirmCountryCode
    } = this.state;
    errors.confirmPhone = false;
    this.setState({ errors: { ...errors } });
    if (
      doE164(countryCode, phone) !== doE164(confirmCountryCode, confirmPhone)
    ) {
      errors.confirmPhone = true;
      this.setState({ errors: { ...errors } });
    }
  };

  onChangePhone = e => {
    this.setState({ phone: e.target.value });
  };

  onChangeCountryCode = cntrObj => {
    this.setState({ countryCode: cntrObj ? cntrObj.value : null });
  };

  onChangeEmail = e => this.setState({ email: e.target.value });

  onChangeConfirmPhone = e => {
    this.setState({ confirmPhone: e.target.value });
  };

  onChangeConfirmCountryCode = cntrObj => {
    this.setState({ confirmCountryCode: cntrObj ? cntrObj.value : null });
  };

  onChangeConfirmEmail = e => this.setState({ confirmEmail: e.target.value });

  onSubmit = e => {
    e.preventDefault();
    const telecom = this.validate();
    let valid = telecom.valid;
    if (valid) {
      let { phone, email } = telecom.values;
      this.props.submit({
        email: email ? email.toLowerCase() : "",
        phoneNumber: phone
      });
    }
  };

  showConfirmPhone = () => {
    let show = !!this.state.phone;
    this.setState({ showCP: show }, () => {
      if (show) {
        this.disablePhonePaste();
      }
    });
  };

  showConfirmEmail = () => {
    let show = !!this.state.email;
    this.setState({ showCE: show }, () => {
      if (show) {
        this.disableEmailPaste();
      }
    });
  };

  getCountryCodes = () => {
    let countryCodes = CountryCodes;
    let { defaultTelRegionOptions } = this.props;
    if (defaultTelRegionOptions && defaultTelRegionOptions.length >= 1) {
      countryCodes = CountryCodes.filter(
        c => defaultTelRegionOptions.indexOf(c.code) !== -1
      );
    }
    return countryCodes;
  };

  render() {
    let {
      email,
      phone,
      countryCode,
      showCP,
      showCE,
      confirmPhone,
      confirmCountryCode,
      confirmEmail
    } = this.state;

    let { errors = {} } = this.state;
    let countryCodes = this.getCountryCodes();

    return (
      <div>
        <Form onSubmit={this.onSubmit}>
          <h3 className="text-center mb-3 font-sm-size">
            <Text content={Messages.verify_contact_details} />
          </h3>
          <p className="text-center text-muted">
            <Text content={Messages.modify_your_contact_details} />
          </p>

          {errors.phone && errors.email && (
            <p className="text-center" style={{ color: "#f86c6b" }}>
              <Text content={Messages.set_phone_email} />
            </p>
          )}

          <FormGroup>
            <div className="inputHolder d-flex flex-column">
              <Label style={labelStyle}>
                <Text content={Messages.phone_cc} />
              </Label>
              <div className="d-flex flex-row">
                <Select
                  value={countryCode}
                  onChange={this.onChangeCountryCode}
                  placeholder={intl.formatMessage(Messages.country_code)}
                  options={countryCodes}
                  labelKey="country"
                  valueKey="value"
                  style={{ border: "1px solid #e0e6e8", borderRadius: 0 }}
                />
                <Input
                  style={{ width: "60%" }}
                  value={phone}
                  onChange={this.onChangePhone}
                  className={classnames(
                    errors.phone ? "ml-1 is-invalid" : "ml-1"
                  )}
                  placeholder={intl.formatMessage(Messages.phone_number_cc)}
                  autoComplete="nope"
                  onBlur={this.showConfirmPhone}
                />
              </div>
              {errors.phone && !errors.email && (
                <div className="d-flex invalid-feedback">
                  <Text content={Messages.set_valid_phone} />
                </div>
              )}
            </div>
          </FormGroup>

          {showCP && (
            <FormGroup>
              <div className="inputHolder d-flex flex-column">
                <Label style={labelStyle}>
                  <Text content={Messages.confirm_phone} />
                </Label>
                <div className="d-flex flex-row">
                  <Select
                    value={confirmCountryCode}
                    onChange={this.onChangeConfirmCountryCode}
                    placeholder={intl.formatMessage(Messages.country_code)}
                    options={countryCodes}
                    labelKey="country"
                    valueKey="value"
                    style={{ border: "1px solid #e0e6e8", borderRadius: 0 }}
                  />
                  <Input
                    id="veri-confirm-phone"
                    style={{ width: "60%" }}
                    value={confirmPhone}
                    onChange={this.onChangeConfirmPhone}
                    className={classnames(
                      errors.confirmPhone ? "ml-1 is-invalid" : "ml-1"
                    )}
                    placeholder={intl.formatMessage(Messages.phone_number_cc)}
                    autoComplete="nope"
                    onBlur={this.handleOnBlurConfirmPhone}
                  />
                </div>
                {errors.confirmPhone && (
                  <div className="d-flex invalid-feedback">
                    <Text content={Messages.same_confirm_phone} />
                  </div>
                )}
              </div>
            </FormGroup>
          )}

          <FormGroup>
            <div className="inputHolder d-flex flex-column">
              <Label style={labelStyle}>
                <Text content={Messages.e_mail} />
              </Label>
              <Input
                value={email}
                onChange={this.onChangeEmail}
                type="text"
                autoCapitalize="none"
                className={classnames(errors.email ? "is-invalid" : "")}
                autoComplete="nope"
                onBlur={this.showConfirmEmail}
              />
              {!errors.phone && errors.email && (
                <div className="d-flex invalid-feedback">
                  <Text content={Messages.set_valid_email} />
                </div>
              )}
            </div>
          </FormGroup>

          {showCE && (
            <FormGroup>
              <div className="inputHolder d-flex flex-column">
                <Label style={labelStyle}>
                  <Text content={Messages.confirm_e_mail} />
                </Label>
                <Input
                  id="veri-confirm-email"
                  value={confirmEmail}
                  onChange={this.onChangeConfirmEmail}
                  type="text"
                  autoCapitalize="none"
                  className={classnames(
                    errors.confirmEmail ? "is-invalid" : ""
                  )}
                  autoComplete="nope"
                  onBlur={this.handleOnBlurConfirmEmail}
                />
                {errors.confirmEmail && (
                  <div className="d-flex invalid-feedback">
                    <Text content={Messages.same_confirm_email} />
                  </div>
                )}
              </div>
            </FormGroup>
          )}

          <div
            style={{
              marginTop: 20,
              flex: 1,
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center"
            }}
          >
            <MPSButton
              color="primary"
              size={"md"}
              className="text-capitalize font-weight-bold"
            >
              <Text content={Messages.verify_update} />
            </MPSButton>
          </div>
        </Form>
      </div>
    );
  }
}

export default ContactVerificationPage;
