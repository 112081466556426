import { http } from "../utils/http";
import { API_ENDPOINTS } from "../config";
import { ROLE_MPS_ADMIN } from "../security/roles";

class OrganizationAdminsService {
  delete(orgId, adminId) {
    return http.request(API_ENDPOINTS.deleteAdmin(orgId, adminId), "delete");
  }

  invite(orgId, data) {
    const url = API_ENDPOINTS.inviteAdmin(
      orgId,
      data.hasOwnProperty("loginUserId")
    );
    return http.request(url, "post", data);
  }

  load(id, page) {
    return http
      .request(API_ENDPOINTS.loadAdmins(id) + `?page=0&size=1000`)
      .then(data => {
        if (data && data._embedded && data._embedded.staffs) {
          return {
            data: page <= data.page.totalPages ? data._embedded.staffs : [],
            loadMore: data.page.totalPages > page
          };
        } else {
          return {
            data: [],
            loadMore: false
          };
        }
      });
  }

  loadAvailablePackages() {
    return http.request(API_ENDPOINTS.loadAllPackages()).then(data => {
      return data && data._embedded ? data._embedded.provisionedPackages : [];
    });
  }

  editFacilityAdmin(facilityId, staffId, data) {
    return http.request(
      API_ENDPOINTS.editFacilityAdmin(facilityId, staffId),
      "patch",
      data
    );
  }

  loadAssignedPackages(facilityId) {
    return http
      .request(API_ENDPOINTS.facilityAssignedPackages(facilityId))
      .then(data => {
        return data && data._embedded ? data._embedded.provisionedPackages : [];
      });
  }

  addPackage(facilityId, packageId) {
    return http.request(
      API_ENDPOINTS.addPackage(facilityId, packageId),
      "post"
    );
  }

  loadAvailableFeatures() {
    return http
      .request(API_ENDPOINTS.availableFeatures + "?page=0&size=100")
      .then(data => {
        return data && data._embedded ? data._embedded.availableFeatures : [];
      });
  }

  updateProvisionedFeatures(facilityId, provisioned) {
    return http.request(
      API_ENDPOINTS.updateFacility(ROLE_MPS_ADMIN, facilityId),
      "patch",
      {
        provisionedFeatures: provisioned
      }
    );
  }

  sendOTPForFacilityDelete = facilityId => {
    return http.request(API_ENDPOINTS.deleteFacilityOTP(facilityId), "delete");
  };

  deleteFacility = (facilityId, OTP) => {
    return http.request(
      API_ENDPOINTS.deleteFacility(facilityId, OTP),
      "delete"
    );
  };

  editSupportStaff(staffId, data) {
    return http.request(API_ENDPOINTS.supportStaff(staffId), "patch", data);
  }

  deleteInactivePatientsAndStaff(facilityId, otp, note) {
    return http.request(
      API_ENDPOINTS.deleteInactiveUsers(facilityId, otp),
      "delete",
      note ? { note } : null
    );
  }
}

const organizationAdminsService = new OrganizationAdminsService();

export default organizationAdminsService;
