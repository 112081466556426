import React from "react";
import InputMask from "react-input-mask";

const currentYear = new Date().getFullYear();
const getFromYear = min => {
  if (min) {
    return new Date(min.getFullYear(), min.getMonth()).getFullYear();
  }
  return new Date(1900, 0).getFullYear();
};
const getToYear = max => {
  if (max) {
    return new Date(max.getFullYear(), max.getMonth()).getFullYear();
  }
  return new Date(currentYear + 50, 11).getFullYear();
};

function getDates() {
  let dates = [];
  for (let i = 1; i <= 31; i++) {
    dates.push({ label: i, value: i < 10 ? `0${i}` : `${i}` });
  }
  return dates;
}

function getMonths() {
  return [
    { label: "Jan", value: "01" },
    { label: "Feb", value: "02" },
    { label: "Mar", value: "03" },
    { label: "Apr", value: "04" },
    { label: "May", value: "05" },
    { label: "Jun", value: "06" },
    { label: "Jul", value: "07" },
    { label: "Aug", value: "08" },
    { label: "Sep", value: "09" },
    { label: "Oct", value: "10" },
    { label: "Nov", value: "11" },
    { label: "Dec", value: "12" }
  ];
}

function getYears(from, to) {
  let years = [];
  // for (let i = from; i <= to; i++) {
  //   years.push({ value: i, label: i });
  // }

  for (let i = to; i >= from; i--) {
    years.push({ value: i, label: i });
  }

  return years;
}

export default class DateField extends React.Component {
  constructor(props) {
    super(props);

    // let value = props.value;
    // if (value) {
    //   let date = new Date(value);
    //   let day = date.getDate();
    //   let month = date.getMonth() + 1;
    //   this.state = {
    //     day: day < 10 ? "0" + day : day,
    //     month: month < 10 ? "0" + month : month,
    //     year: date.getFullYear()
    //   };
    // } else {
    this.state = {};
    // }
  }

  onChangeField = (name, value) => {
    if (!value) {
      this.setState({ [name]: null });
      this.props.onChange(null);
      return;
    }
    this.setDate(name, value.value);
  };

  setDate = (name, value) => {
    let cloned = { ...this.state };
    cloned[name] = value;
    let { day, month, year } = cloned;
    if (day && month && year) {
      this.setState({ [name]: value });
      this.props.onChange(
        `${parseInt(month, 10)}/${parseInt(day, 10)}/${year}`
      );
    } else {
      this.setState({ [name]: value });
      this.props.onChange(null);
    }
  };

  onChangeDay = day => {
    this.onChangeField("day", day);
  };

  onChangeMonth = month => {
    this.onChangeField("month", month);
  };

  onChangeYear = year => {
    this.onChangeField("year", year);
  };

  onDayInputChange = inputValue => {
    console.group("Input Changed");
    console.log(inputValue);
    console.groupEnd();
  };

  allFocused = () => {
    return this.dayFocused && this.monthFocused && this.yearFocused;
  };

  onDateChange = e => {
    let value = e.target.value;
    // if (!value) {
    //   this.props.onChange(value);
    //   return;
    // }
    //
    // value = value.replace(/\//gm, "");
    // if (value.length >= 2) {
    //   value = value.substr(0, 2) + "/" + value.substr(2, value.length);
    // }
    // if (value.length >= 5) {
    //   value = value.substr(0, 5) + "/" + value.substr(5, value.length);
    // }

    // if (value === "__/__/____") {
    //   value = "";
    // } else if (value && value.indexOf("_") !== -1) {
    //   value = value.replace(/_/g, "");
    // }

    if (value === "") {
    } else if (value === "DD/MM/YYYY") {
      value = "";
    }
    // else {
    //   value = value.replace(/D/g, "");
    //   value = value.replace(/M/g, "");
    //   value = value.replace(/Y/g, "");
    // }

    this.props.onChange(value);
  };

  render() {
    let {
      error,
      onBlur,
      tabIndex,
      min,
      max,
      searchable = true,
      value
    } = this.props;
    let { day, month, year } = this.state;

    let style = {
      letterSpacing: "2px",
      ...this.props.style
    };
    return (
      <React.Fragment>
        <div className="d-flex justify-content-between" tabIndex={tabIndex}>
          {/*<input*/}
          {/*  style={style}*/}
          {/*  type={"text"}*/}
          {/*  onChange={this.onDateChange}*/}
          {/*  value={value}*/}
          {/*  placeholder="DD/MM/YYYY"*/}
          {/*  maxLength={10}*/}
          {/*/>{" "}*/}
          <InputMask
            style={style}
            mask="99/99/9999"
            onChange={this.onDateChange}
            value={value}
            maskPlaceholder="DD/MM/YYYY"
            onBlur={onBlur}
            className={this.props.className}
          />
        </div>
      </React.Fragment>
    );
  }
}

// <input
//     type="number"
//     min={getFromYear(min)}
//     max={getToYear(max)}
//     // defaultValue={1960}
//     style={{
//       width: "102px",
//       border: "1px solid #e0e6e8",
//       borderRadius: "4px"
//     }}
//     value={year}
//     onChange={e => {
//       let value = e.target.value;
//       if (isNaN(value)) {
//         return;
//       }
//       this.onChangeYear(value);
//     }}
// />
