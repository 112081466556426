import React from "react";

export const TERMS_HTML =
  "<h2>myPatientSpace Terms of Use</h2>\n" +
  "<h4>Last Updated: Dec 02, 2020</h4>\n" +
  '<div style="margin: 8px; align-content: flex-end;">\n' +
  "<p>This is an agreement between you (“You”) and myPatientSpace Ltd (“MPS”, “we” “us” or “our”).<br />\n" +
  "Before using our Service please read this Terms of Use, Licence and Warranty Agreement” and other applicable terms mentioned herein (collectively called “the Agreement”).<br />\n" +
  "Please see our “Privacy Notice” to understand how we process your personal information.<br />\n" +
  "<b>It is important that you read the terms of this Agreement before downloading, registering and/or using our Service.</b></p>\n" +
  "<p>MPS Services provides a software application and platform, that can be used by healthcare providers and/or life science companies (“Customers”) to engage with staff, patients, and families via the internet for the purpose of patient management and engagement across a patient’s care journey (collectively known as the &#8220;Services&#8221; ).<br />\n" +
  "Access to the Services for patients, staff and families is given by Customers by providing you with a secure access code.</p>\n" +
  "<p>Bluetooth Product(s) means a medical device with Bluetooth connectivity manufactured by a 3rd party provider which MPS may provide to healthcare providers who in turn may make it available to you to connect to the Services and in those circumstances the Bluetooth Product forms part of the Services for the purposes of these terms and conditions.</p>\n" +
  "<p>Some of the Services features may allow you to use third party services (“Third Party Service”). Please note that use of such Third Party Service via our Service is subject to the third parties terms of use and their respective privacy notices. We encourage you to read these third parties’ terms carefully before you enable them on our Services.</p>\n" +
  "<p>These terms and conditions (“Terms”) shall govern all Users of our Services and are designed to provide a secure framework for the use of the Services. Please read the terms carefully before using our Services, as they are a legally binding contract.</p>\n" +
  "</div>\n" +
  "<h3>1.  Terms of Use</h3>\n" +
  "<ol>\n" +
  "<li>By using our Services, you accept these terms and conditions in full; accordingly, if you disagree with these terms and conditions or any part of these terms and conditions, you must not use the “Services”.</li>\n" +
  "<li>When you register with the “Services”, submit any material to the application or use any of our services, we will ask you to expressly agree to these terms and conditions.</li>\n" +
  "<li>You must be at least eighteen (18) years of age to use our Services. If you are between the ages of 13-18, you may use the Services only with the supervision and consent of a parent or guardian. No individual under these age limits may use the Services, provide any Personal Data to us or otherwise submit Personal Data through the Services (e.g., a name, address, telephone number, or email address),<br />\n" +
  "unless a healthcare provider explicitly allows and provides access to the service to an individual below these age limits and has consent to do so from a parent or guardian.</li>\n" +
  "<li>Our Service currently does not use cookies but may in the future do so.</li>\n" +
  "<li>For information about how we collect, use, and share your Personal Data, please review our Privacy Notice. We take the collection, use and security of the Personal Data that our users provide to us very seriously.<br />\n" +
  "By using the Services you consent to the collection, use, processing and sharing of such Personal Data (as set forth in the Privacy Notice).</li>\n" +
  "</ol>\n" +
  "<h3>2.  License to use Services</h3>\n" +
  "<ol>\n" +
  "<li>Subject to the other provisions of these terms and conditions, <b>you may:</b>\n" +
  "<ul>\n" +
  "<li>view pages from our Services in a web browser or use the Services through an application,</li>\n" +
  "<li>download pages from our Services for caching in a web browser,</li>\n" +
  "<li>print pages from our Services,</li>\n" +
  "<li>stream audio and video files from our Services.</li>\n" +
  "</ul>\n" +
  "</li>\n" +
  "<li>Except as expressly permitted by Section 2 or the other provisions of these terms and conditions, you must not download any material from our Services or save any such material to your computer.</li>\n" +
  "<li>You may only use our Services for your own personal purposes.</li>\n" +
  "<li>Except as expressly permitted by these terms and conditions, you must not edit or otherwise modify any material on our Services.</li>\n" +
  "<li>Unless you own or control the relevant rights in the material, <b>you must not:</b>\n" +
  "<ul>\n" +
  "<li>republish material from our Services (including republication on another Services),</li>\n" +
  "<li>sell, rent or sub-license material from our Services,</li>\n" +
  "<li>show any material from our Services in public,</li>\n" +
  "<li>exploit material from our Services for a commercial purpose,</li>\n" +
  "<li>redistribute material from our Services.</li>\n" +
  "</ul>\n" +
  "</li>\n" +
  "<li>We reserve the right to restrict access to areas of our Services, or indeed our whole Services, at our discretion; you must not circumvent or bypass, or attempt to circumvent or bypass, any access restriction measures on our Services.</li>\n" +
  "<li>While we strive to make our applications available on many platforms, we can’t guarantee that our applications are compatible with your device. If you use our applications, your standard data and messaging rates will apply.</li>\n" +
  "<li>If you download our applications from a third-party app store (the “App Provider”), you acknowledge and agree that the Terms are an agreement between us, and not with the App Provider. As between us and the App Provider, we are solely responsible for the applications. To the maximum extent permitted by applicable law, the App Provider will have no warranty obligation whatsoever with respect to the application, and any other claims, losses, liabilities, damages, costs or expenses attributable to any failure to conform to any warranty will be, as between us and the App Provider, it will be our responsibility.</li>\n" +
  "<li>The App Provider is not responsible for addressing any claims you have relating to the application or your possession and use of the application. If a third party claims that an application infringes another party’s intellectual property rights, as between the App Provider and us, we will be responsible for the investigation, defense, settlement and discharge of any such intellectual property infringement claim to the extent required by these Terms.</li>\n" +
  "<li>The App Provider and its subsidiaries are third-party beneficiaries of these Terms as it relates to your license to the applications. Upon your acceptance of the terms and conditions of these Terms, the App Provider will have the right (and will be deemed to have accepted the right) to enforce these Terms as related to your license of the applications against you as a third-party beneficiary thereof; and You must also comply with all applicable third-party terms of service when using the applications.</li>\n" +
  "</ol>\n" +
  "<h3>3.  Acceptable use</h3>\n" +
  '<p style="text-align: left;">You must:</p>\n' +
  "<p>(a)  not use our Services in any way or take any action that causes, or may cause, damage to the Services or impairment of the performance, availability or accessibility of the Services;</p>\n" +
  "<p>(b)  not use our Services in any way that is unlawful, illegal, fraudulent or harmful, or in connection with any unlawful, illegal, fraudulent or harmful purpose or activity;</p>\n" +
  "<p>(c)  not use our Services to copy, store, host, transmit, send, use, publish or distribute any material which consists of (or is linked to) any spyware, computer virus, Trojan horse, worm, keystroke logger, rootkit or other malicious computer software;</p>\n" +
  "<p>(d)  not conduct any systematic or automated data collection activities (including without limitation scraping, data mining, data extraction and data harvesting) on or in relation to our Services without our express written consent;</p>\n" +
  "<p>(e)  not access or otherwise interact with our Services using any robot, spider or other automated means, except for the purpose of search engine indexing;</p>\n" +
  "<p>(f)  not violate the directives set out in the robots.txt file for our Services; or</p>\n" +
  "<p>(g)  not use data collected from our Services for any direct marketing activity (including without limitation email marketing, SMS marketing, telemarketing and direct mailing).</p>\n" +
  "<p>(h)  ensure that all the information you supply to us through our Services, or in relation to our Services, is true, accurate, current, complete and non-misleading.</p>\n" +
  "<p>(i)  ensure that the peakflow device is only used by you as it is a single-user device and therefore can only be used by one User (patient).</p>\n" +
  "<p>(j)  only assemble, handle, store and use the Peakflow device in accordance with the manufacturer’s instructions including manufacturer’s safety warnings.</p>\n" +
  "<p>(k)  only use the Peakflow Device under the care of a HP.</p>\n" +
  "<p>(l)  only use the Peakflow Device for the period of its shelf-life.</p>\n" +
  "<p>(m)  only use the Peakflow Device in conjunction with the Service.</p>\n" +
  "<h3>4.  Registration and Accounts</h3>\n" +
  "<p>(a)        You may register for an account to access and use the Services. When you create an account for any of our Services, you must provide us with accurate and complete information as prompted by the account creation and registration process and keep that information up to date. Otherwise, some of our Services may not operate correctly, and we may not be able to contact you with important notices or assist you with log in issues.</p>\n" +
  "<p>(b)        If you create an account, you are responsible for maintaining the confidentiality of any and all actions that take place while using your account, and you must notify us right away of any actual or suspected loss, theft, or unauthorized use of your account or account password. We are not responsible for any loss that results from unauthorized use of your username and password, with or without your knowledge.</p>\n" +
  "<p>(c)        If you are a resident of the European Union: You have the right to delete your account with us by contacting us. If you choose to permanently delete your account, the non-public Personal Data that we have associated with your account will also be deleted.  The HP providing you access to the service may require that certain Health Records are kept and not deleted.</p>\n" +
  "<p>(d)        Please let us know right away if you believe your account has been hacked or compromised. While we work hard to protect the security of your Personal Data, we cannot guarantee that unauthorized third parties will not be able to defeat our security measures. Please notify us immediately of any actual or suspected breach or unauthorized access or use of your account.</p>\n" +
  "<h3>5.  Service Updates Changes and Limitations</h3>\n" +
  "<p>(a)  Our Services are constantly evolving to be more useful for our users. With new products, services, and features launching all the time, we need the flexibility to make changes, impose limits, and occasionally suspend or terminate certain offerings. We may also update our Services, which might not work properly if you don’t install the updates. The Services change frequently, and their form and functionality may change without prior notice to you. We may also impose limits on certain Services or restrict your access to part or all of the Services without notice or liability.</p>\n" +
  "<p>(b)  We may also from time to time, as we see fit, develop and provide updates for certain Services. This may include upgrades, modifications, bug fixes, patches and other error corrections and/or new features (collectively, “Updates”). Certain portions of our Services may not properly operate if you do not install all Updates. These Updates may include updated versions of our applications, which may automatically electronically upgrade the versions used on your device, as well as updates to wearables and other connected products. You expressly consent to such automatic Updates. Further, you agree that the Terms (and any additional modifications of the same) will apply to any and all Updates to the Services. We have no obligation to provide any Updates or to continue to provide or enable any particular features or functionality of any Service.</p>\n" +
  "<p>(c)  As the Services grow and improve, we might have to make changes to these Terms or include additional terms that are specific to certain products.</p>\n" +
  "<p>(d)  We reserve the right to modify these Terms by (i) posting revised Terms on and/or through the Services, and/or (ii) providing advance notice to you of material changes to the Terms, generally via email where practicable, and otherwise through the Services. Modifications will not apply retroactively unless required by law.</p>\n" +
  "<p>(e)  We may sometimes ask you to review and to explicitly agree to or reject a revised version of the Terms. In such cases, modifications will be effective at the time of your agreement to the modified version of the Terms. If you do not agree at that time, you are not permitted to use the Services. In cases where we do not ask for your explicit agreement to a modified version of the Terms, the modified version of the Terms will become effective as of the date specified in the Terms. Your use of the Services following that date constitutes your acceptance of the terms and conditions of the Terms as modified. If you do not agree to the modifications, you are not permitted to use, and should discontinue your use of, the Services.</p>\n" +
  "<h3>6.  Monitoring, cancellation and suspension of account</h3>\n" +
  "<p>(a)  We reserve the right to refuse service to anyone, and can monitor, terminate or suspend our Services or your account at any time. We may change, suspend, or discontinue any or all of the Services at any time, including the availability of any product, feature, database, or content. We may also deactivate, terminate or suspend your account at any time: (1) if we, in our sole discretion, determine that you are or have been in violation of these Terms, (2) if we, in our sole discretion, determine that you have created risk or possible legal exposure for us or any other Users, (3) in response to requests by law enforcement or other government agencies, (4) upon discontinuance or material modification of any Services, or (5) due to unexpected technical issues or problems. We will endeavor to notify you by email or at the next time you attempt to access your account after any such deactivation, termination or suspension.</p>\n" +
  "<p>(b)   We reserve the right, but have no obligation, to monitor any accounts and/or activities conducted through or in any way related to the Services.</p>\n" +
  "<h3>7.  Your content: license</h3>\n" +
  '<ol style="list-style-type: lower-alpha;">\n' +
  "<li>        In these terms and conditions, &#8220;your content&#8221; means [all works and materials (including without limitation text, graphics, images, audio material, video material, audio-visual material, scripts, software and files) that you submit to us or our Services for storage or publication on, processing by, or transmission via, our Services].</li>\n" +
  "<li>        You grant to us a [worldwide, irrevocable, non-exclusive, royalty-free license] to [use, reproduce, store, adapt, publish, translate and distribute your content in any existing or future media] OR [reproduce, store and publish your content on and in relation to this Services and any successor Services] OR [reproduce, store and, with your specific consent, publish your content on and in relation to this Services].</li>\n" +
  "<li>        You grant to us the right to sub-license the rights licensed under Section 9.2.</li>\n" +
  "<li>        You grant to us the right to bring an action for infringement of the rights licensed under Section 9.2.</li>\n" +
  "<li>        You hereby waive all your moral rights in your content to the maximum extent permitted by applicable law; and you warrant and represent that all other moral rights in your content have been waived to the maximum extent permitted by applicable law.</li>\n" +
  "<li>        You may edit your content to the extent permitted using the editing functionality made available on our Services.</li>\n" +
  "<li>        Without prejudice to our other rights under these terms and conditions, if you breach any provision of these terms and conditions in any way, or if we reasonably suspect that you have breached these terms and conditions in any way, we may delete, unpublish or edit any or all of your content.</li>\n" +
  "</ol>\n" +
  "<h3>8.  Your content: rules</h3>\n" +
  "<p>You warrant and represent that your content will comply with these terms and conditions.</p>\n" +
  "<p>Your content must not be illegal or unlawful, must not infringe any person&#8217;s legal rights, and must not be capable of giving rise to legal action against any person (in each case in any jurisdiction and under any applicable law).</p>\n" +
  "<p>Your content, and the use of your content by us in accordance with these terms and conditions, must not:</p>\n" +
  "<p>(a)        be libellous or maliciously false;</p>\n" +
  "<p>(b)        be obscene or indecent;</p>\n" +
  "<p>(c)        infringe any copyright, moral right, database right, trade mark right, design right, right in passing off, or other intellectual property right;</p>\n" +
  "<p>(d)        infringe any right of confidence, right of privacy or right under data protection legislation;</p>\n" +
  "<p>(e)        constitute negligent advice or contain any negligent statement;</p>\n" +
  "<p>(f)        constitute an incitement to commit a crime [, instructions for the commission of a crime or the promotion of criminal activity];</p>\n" +
  "<p>(g)        be in contempt of any court, or in breach of any court order;</p>\n" +
  "<p>(h)        be in breach of racial or religious hatred or discrimination legislation;</p>\n" +
  "<p>(i)        be blasphemous;</p>\n" +
  "<p>(j)        be in breach of official secrets legislation;</p>\n" +
  "<p>(k)        be in breach of any contractual obligation owed to any person;</p>\n" +
  "<p>(l)        [depict violence [ in an explicit, graphic or gratuitous manner]];</p>\n" +
  "<p>(m)        [be pornographic [, lewd, suggestive or sexually explicit]];</p>\n" +
  "<p>(n)        [be untrue, false, inaccurate or misleading];</p>\n" +
  "<p>(o)        [consist of or contain any instructions, advice or other information which may be acted upon and could, if acted upon, cause illness, injury or death, or any other loss or damage];</p>\n" +
  "<p>(p)        [constitute spam];</p>\n" +
  "<p>(q)        [be offensive, deceptive, fraudulent, threatening, abusive, harassing, anti-social, menacing, hateful, discriminatory or inflammatory]; or</p>\n" +
  "<p>(r)        [cause annoyance, inconvenience or needless anxiety to any person].</p>\n" +
  "<p>Your content must be appropriate, civil and tasteful, and accord with generally accepted standards of etiquette and behaviour on the internet.</p>\n" +
  "<p>You must not use our Services to link to any Services or web page consisting of or containing material that would, were it posted on our Services, breach the provisions of these terms and conditions.</p>\n" +
  "<p>You must not submit to our Services any material that is or has ever been the subject of any threatened or actual legal proceedings or other similar complaint.</p>\n" +
  "<h3>9.  Medical information and Safe Use of the Services</h3>\n" +
  "<ol>\n" +
  "<li>Our Services may contains general information about medical conditions and treatments provided by your HP.</li>\n" +
  "<li>You must not rely on the information on our Services as an alternative to medical advice from your doctor or other professional healthcare provider.</li>\n" +
  "<li>If you have any specific questions about any medical matter, you should consult your doctor or other professional healthcare provider.</li>\n" +
  "<li>If you think you may be suffering from any medical condition, you should seek immediate medical attention.</li>\n" +
  "<li>You should never delay seeking medical advice, disregard medical advice or care instructions or discontinue medical treatment because of information on this Services.</li>\n" +
  "<li>The app must not be used independently of professional health care.</li>\n" +
  "<li>If you are a patient, you must only use the APP after you have been given access from you HP and been issued a link to use the app dedicated towards your specific care journey.</li>\n" +
  "<li>Should you have any health-related issues, please contact or visit your doctor or other healthcare professionals immediately. If you are in an emergency, call your doctor or nearest hospital service immediately.</li>\n" +
  "<li>If you are a Patient you may invite other close friends and families to have access to your details and your care plan.  This will only be done based on your explicit consent.  Anyone you invite will also be bound to these terms.    Anyone you invite will have access to the same information you have.</li>\n" +
  "<li>To the maximum extent permitted by applicable law, we are not responsible or liable, directly or indirectly, for any damage caused by your physical activities or your use or inability to use our Services or features of our Services.</li>\n" +
  "<li>By using myPatientSpace, you acknowledge that it is your own responsibility to respond to your body&#8217;s health signals and to contact your doctor or other healthcare professional if you are in doubt about anything using the app.</li>\n" +
  "<li>Staff should never invite any non-staff members to a patient’s space without the explicit consent of the patient.</li>\n" +
  "<li>With the patients and HP explicit consent, we may provide access to health and assessment details to third parties such as Insurers and National Bodies.</li>\n" +
  "</ol>\n" +
  "<h3>10.  Limited warranties</h3>\n" +
  "<ol>\n" +
  "<li>We reserve the right to discontinue or alter any or all of our Services, and to stop publishing our Services, at any time in our sole discretion without notice or explanation; and save to the extent expressly provided otherwise in these terms and conditions, you will not be entitled to any compensation or other payment upon the discontinuance or alteration of any Services .</li>\n" +
  "<li>EXCEPT WHERE PROHIBITED BY LAW, WE EXPRESSLY DISCLAIMS ALL WARRANTIES, REPRESENTATIONS AND GUARANTEES OF ANY KIND, WHETHER ORAL OR WRITTEN, EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT TO THE FULLEST EXTENT PERMISSIBLE UNDER THE LAW. THE SERVICES AND ALL CONTENT ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” WITH ALL FAULTS BASIS. Without limiting the foregoing, you understand that, to the maximum extent permitted by applicable law, we make no warranty regarding the quality, accuracy, timeliness, truthfulness, completeness or reliability of any of the Services. To the maximum extent permitted by applicable law, we do not warrant that (i) the Services will meet your requirements, (ii) the operation of the Services will be uninterrupted, virus- or error-free or free from other harmful elements or (iii) errors will be corrected. Any oral or written advice provided by our agents or us does not and will not create any warranty. You therefore expressly acknowledge and agree that use of the Services is at your sole risk and that the entire risk as to satisfactory quality, performance, accuracy and effort is with you.</li>\n" +
  "<li>MPS makes no representation or warranty in respect of the Peakflow Device and the Peakflow Device is provided subject to and with the benefit of the limited warranty provided by the manufacturer only. You acknowledge and accept that MPS will not be liable to you for any loss, damage, claim or liability howsoever arising from or related to the Peakflow Device other than an obligation replace any defective or recalled Peakflow Device</li>\n" +
  "</ol>\n" +
  "<h3>11.  Limitations and Exclusions of Liability</h3>\n" +
  "<ol>\n" +
  "<li>Nothing in these terms and conditions will limit or exclude any liability for death or personal injury resulting from negligence, any liability for fraud or fraudulent misrepresentation or any liabilities that may not be excluded under applicable law.</li>\n" +
  "<li>The limitations and exclusions of liability set out in this Section 12 and elsewhere in these terms and conditions are subject to Section 12.1; and govern all liabilities arising under these terms and conditions or relating to the subject matter of these terms and conditions, including liabilities arising in contract, in tort (including negligence) and for breach of statutory duty, except to the extent expressly provided otherwise in these terms and conditions.</li>\n" +
  "<li>To the extent that our Services and the information and services on our Services are provided free of charge, we will not be liable for any loss or damage of any nature.</li>\n" +
  "<li>We will not be liable to you in respect of any losses arising out of any event or events beyond our reasonable control.</li>\n" +
  "<li>We will not be liable to you in respect of any business losses, including (without limitation) loss of or damage to profits, income, revenue, use, production, anticipated savings, business, contracts, commercial opportunities or goodwill or any special, indirect or consequential loss or damage.</li>\n" +
  "<li>We will not be liable to you in respect of any loss or corruption of any data, database or software.</li>\n" +
  "<li>You accept that we have an interest in limiting the personal liability of our officers and employees and, having regard to that interest, you acknowledge that we are a limited liability entity; you agree that you will not bring any claim personally against our officers or employees in respect of any losses you suffer in connection with the Services or these terms and conditions (this will not, of course, limit or exclude the liability of the limited liability entity itself for the acts and omissions of our officers and employees).</li>\n" +
  "</ol>\n" +
  "<h3>12.  Indemnity</h3>\n" +
  "<p>You hereby indemnify us, and undertake to keep us indemnified, against any and all losses, damages, costs, liabilities and expenses (including without limitation legal expenses and any amounts paid by us to a third party in settlement of a claim or dispute) incurred or suffered by us and arising directly or indirectly out of your use of our Services or any breach by you of any provision of these terms and conditions.</p>\n" +
  "<h3>13.  Breaches of these terms and conditions</h3>\n" +
  "<p>Without prejudice to our other rights under these terms and conditions, if you breach these terms and conditions in any way, or if we reasonably suspect that you have breached these terms and conditions in any way, we may:</p>\n" +
  "<p>(a)        send you one or more formal warnings;</p>\n" +
  "<p>(b)        temporarily suspend your access to our Services;</p>\n" +
  "<p>(c)        permanently prohibit you from accessing our Services;</p>\n" +
  "<p>(d)        block computers using your IP address from accessing our Services;</p>\n" +
  "<p>(e)        commence legal action against you, whether for breach of contract or otherwise; and/or</p>\n" +
  "<p>(f)        suspend or delete your account on our Services.</p>\n" +
  "<p>(g)        Where we suspend or prohibit or block your access to our Services or a part of our Services, you must not take any action to circumvent such suspension or prohibition or blocking (including without limitation creating and/or using a different account).</p>\n" +
  "<h3>14.  Third party Services</h3>\n" +
  "<p>Our Services may include hyperlinks to other Services owned and operated by third parties; such hyperlinks are not recommendations.</p>\n" +
  "<p>We have no control over third party Services and their contents, and subject to Section 12.1 we accept no responsibility for them or for any loss or damage that may arise from your use of them.</p>\n" +
  "<h3>15.  Trade marks</h3>\n" +
  "<p>Our company and product names, our logos and our other registered and unregistered trade marks are trade marks belonging to us; we give no permission for the use of these trade marks, and such use may constitute an infringement of our rights.</p>\n" +
  "<p>The third party registered and unregistered trade marks or service marks on our Services are the property of their respective owners and, unless stated otherwise in these terms and conditions, we do not endorse and are not affiliated with any of the holders of any such rights and as such we cannot grant any license to exercise such rights.</p>\n" +
  "<h3>16.  Assignment</h3>\n" +
  "<p>You hereby agree that we may assign, transfer, sub-contract or otherwise deal with our rights and/or obligations under these terms and conditions.</p>\n" +
  "<p>You may not without our prior written consent assign, transfer, sub-contract or otherwise deal with any of your rights and/or obligations under these terms and conditions.</p>\n" +
  "<h3>17.  Severability</h3>\n" +
  "<p>If a provision of these terms and conditions is determined by any court or other competent authority to be unlawful and/or unenforceable, the other provisions will continue in effect.</p>\n" +
  "<p>If any unlawful and/or unenforceable provision of these terms and conditions would be lawful or enforceable if part of it were deleted, that part will be deemed to be deleted, and the rest of the provision will continue in effect.</p>\n" +
  "<h3>18.  Third party rights</h3>\n" +
  "<p>A contract under these terms and conditions is for our benefit  and your benefit and is not intended to benefit or be enforceable by any third party.</p>\n" +
  "<p>The exercise of the parties&#8217; rights under a contract under these terms and conditions is not subject to the consent of any third party.</p>\n" +
  "<h3>19.  Entire agreement</h3>\n" +
  "<p>Subject to Section 12.1, these terms and conditions, together with our privacy and cookies policy, shall constitute the entire agreement between you and us in relation to your use of our Services and shall supersede all previous agreements between you and us in relation to your use of our Services.</p>\n" +
  "<h3>20.  Law and jurisdiction</h3>\n" +
  "<p>These terms and conditions shall be governed by and construed in accordance with Irish law.</p>\n" +
  "<p>Any disputes relating to these terms and conditions shall be subject to the exclusive jurisdiction of the courts of Ireland.</p>\n" +
  "<h3>21.  Statutory and regulatory disclosures</h3>\n" +
  "<p>Our VAT number is <strong>IE 3521396OH</strong></p>\n" +
  "<h3>22.  Our details</h3>\n" +
  "<p>These Services are provided by myPatientSpace Limited and your HP</p>\n" +
  "<div>\n" +
  '<p style="text-align: left; text-indent: 1rem;">We are registered in Ireland under registration number 616748, and our registered office is at:</p>\n' +
  '<p style="text-align: left; text-indent: 4rem; color: navy;">The View, Malahide, Co. Dublin, Ireland.</p>\n' +
  '<p style="text-align: left; text-indent: 1rem;">Our principal place of business is at</p>\n' +
  '<p style="text-align: left; text-indent: 4rem; color: navy;">Malahide, Ireland.</p>\n' +
  '<p style="text-align: left; text-indent: 1rem;">You can contact us:</p>\n' +
  '<p style="text-align: left; text-indent: 4rem; color: navy;">(a) by telephone, on the contact number published on our Services from time to time; or</p>\n' +
  '<p style="text-align: left; text-indent: 4rem; color: navy;">(b) by email, using the email address published on our Services from time to time – info@mypatientspace.com</p>\n' +
  "</div>";

export default class MYPTermsAndConditions extends React.PureComponent {
  render() {
    return <div dangerouslySetInnerHTML={{ __html: TERMS_HTML }} />;
  }
}
