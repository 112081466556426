import React from "react";
import BackgroundImageUrl from "./index";
import { Thumbs } from "../../Shared/Statuses";

class BackgroundImagesWithThumbs extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currMedia: 0
    };
  }

  onThumbPress = index => {
    this.setState({
      currMedia: index
    });
  };

  render() {
    const { contents, id } = this.props;
    const { currMedia } = this.state;

    if (contents.length === 1) {
      // Only one thumbnail, don't render the thumbs section
      return (
        <div className="media flex-column">
          <BackgroundImageUrl
            key={currMedia}
            className="fullSize"
            url={contents[currMedia].contentURL}
          />
        </div>
      );
    }

    return (
      <div className="media flex-column">
        <BackgroundImageUrl
          key={currMedia}
          className="fullSize"
          url={contents[currMedia].contentURL}
        />

        <div className="thumbsHolder" style={{ position: "relative" }}>
          <Thumbs
            id={id}
            content={contents}
            active={currMedia}
            onClick={this.onThumbPress}
          />
        </div>
      </div>
    );
  }
}

export default BackgroundImagesWithThumbs;
