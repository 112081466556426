import React, { PureComponent } from "react";
import { Card, CardBody, CardGroup, Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import Text from "../../components/Text";
import Messages from "../../Shared/Messages";

class UserOboardingInviteError extends PureComponent {
  render() {
    return (
      <div className="app flex-column justify-content-center align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <div className="ob-logos my-4">
                <div className="p-3">
                  <img
                    alt="myPatientSpace"
                    src="/img/mps-logo.png"
                    height={32}
                  />
                </div>
              </div>

              <CardGroup>
                <Card className="p-md-4 p-sm-3">
                  <CardBody>
                    <div>
                      {this.props.errorCode === "mps.user.already.onboarded" ? (
                        <div>
                          <h5
                            className="text-muted text-center mb-3PatientStatsBox"
                            style={{ marginBottom: 0 }}
                          >
                            <span style={{ color: "red" }}>
                              <Text
                                content={Messages.already_accepted_invitation}
                              />{" "}
                            </span>
                          </h5>

                          <div className="mt-3">
                            <Text content={Messages.link_reset_password} />{" "}
                            {this.props.linkType === "email" ? (
                              <span>
                                {" "}
                                <Text content={Messages.email} />{" "}
                              </span>
                            ) : (
                              <span>
                                {" "}
                                <Text content={Messages.phone} />{" "}
                              </span>
                            )}{" "}
                            together with your username. Simply enter your{" "}
                            {this.props.linkType === "email" ? (
                              <span>
                                {" "}
                                <Text content={Messages.email_address} />{" "}
                              </span>
                            ) : (
                              <span>
                                {" "}
                                <Text content={Messages.phone_number} />{" "}
                              </span>
                            )}
                            <Link
                              to={`/forgot-password?cp=${this.props.linkType}`}
                            >
                              <Text content={Messages.here} />
                            </Link>
                            .
                          </div>
                        </div>
                      ) : (
                        <h5
                          className="text-muted text-center"
                          style={{ marginBottom: 0 }}
                        >
                          <span style={{ color: "red" }}>
                            {this.props.message}
                          </span>
                        </h5>
                      )}

                      {this.props.onboardingType === "confirm" ? (
                        <div className="mt-3">
                          <p>
                            <Text content={Messages.already_activated} />
                          </p>
                          <p>
                            <Text content={Messages.can_reset_pwd} />{" "}
                            <Link to="/forgot-password">
                              <Text content={Messages.here} />
                            </Link>
                            <Text content={Messages.send_you_username} />
                          </p>
                          <p>
                            <Text content={Messages.reach_us_on} />{" "}
                            <b>support@mypatientspace.com</b>{" "}
                            <Text content={Messages.or} />{" "}
                            <b>+353 1 267 6625</b>{" "}
                            <Text content={Messages.problems_signing_up} />
                          </p>
                        </div>
                      ) : (
                        this.props.errorCode !==
                          "mps.user.already.onboarded" && (
                          <p className="mt-3">
                            <Text content={Messages.reach_us_on} />{" "}
                            <b>support@mypatientspace.com</b>{" "}
                            <Text content={Messages.or} />{" "}
                            <b>+353 1 267 6625</b>{" "}
                            <Text content={Messages.contact_for_invitation} />
                          </p>
                        )
                      )}
                    </div>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default UserOboardingInviteError;
