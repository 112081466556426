import React from "react";
import moment from "moment";
import Truncate from "react-truncate";
import ImageIcon from "./Icons/ImageIcon";
import AudioIcon from "./Icons/AudioIcon";
import SurveyIcon from "./Icons/SurveyIcon";
import LibraryIcon from "./Icons/LibraryIcon";
import SurveyCompletedIcon from "./Icons/SurveyCompletedIcon";
import BackgroundImageUrl from "../../components/BackgroundImageUrl/index";
import { ROLE_PATIENT } from "../../security/roles";
import MPSButton from "../../components/Buttons/Buttons/MPSButton";
import StatusActions from "./StatusActions";
import { getStatusTitle } from "./Utils";
import Text from "../../components/Text";
import Messages from "../Messages";
import CompleteActionView from "../../PatientApp/components/CompleteActionView";
import { stripHTML } from "../../utils/utils";

export const Message = ({
  message,
  truncate,
  onClick,
  fontSize = "1.1rem",
  lineHeight = "1.8rem",
}) => (
  <div
    className="title status-row-title"
    onClick={onClick}
    style={{ fontSize: fontSize, lineHeight: lineHeight }}
  >
    {truncate ? <Truncate lines={3}>{message}</Truncate> : message}
    {/* <div dangerouslySetInnerHTML={{ __html: message }}/> */}
  </div>
);

export const HTMLMessage = ({
  message,
  truncate,
  onClick,
  fontSize = "1.1rem",
  lineHeight = "1.8rem",
}) => {
  return (
    <div
      className="title"
      onClick={onClick}
      style={{ fontSize: fontSize, lineHeight: lineHeight }}
    >
      {!truncate && <div dangerouslySetInnerHTML={{ __html: message }} />}
      {truncate && <Truncate lines={3}>{stripHTML(message)}</Truncate>}
    </div>
  );
};

export const Thumbs = ({ id, content, type, active, onClick, imgClass }) => (
  <div key={id} style={{ minHeight: 50, marginBottom: 10, marginTop: 5 }}>
    <div
      key={`thumbsHolder${id}`}
      className="imageThumbs d-flex justify-content-start align-items-start"
      style={{ height: 50 }}
    >
      {content.map((image, index) => (
        <BackgroundImageUrl
          key={index}
          index={index}
          id={"thumbs-" + id + "-" + index}
          onClick={onClick}
          active={index === active}
          url={image.thumbnailURL}
          type={type}
          size={20}
          className={imgClass}
        />
      ))}
    </div>
  </div>
);

export const TextStatus = ({ status, id, onClick, statusType }) => {
  return (
    <div>
      <div key={`${id}-circle`} className="textCircle" />
      <StatusContentHolder
        id={id}
        status={status}
        onClick={onClick}
        statusType={statusType}
      />
    </div>
  );
};

export const AppointmentGroupClassStatus = ({ status, id, onClick }) => {
  return (
    <div>
      <div key={`${id}-circle`} className="textCircle" />
      <StatusContentHolder id={id} status={status} onClick={onClick}>
        {status.contents && status.contents[0] && (
          <Thumbs
            id={id}
            content={status.contents}
            type={status.contents[0].format}
          />
        )}
      </StatusContentHolder>
    </div>
  );
};

export const AudioStatus = ({ status, id, onClick }) => (
  <div>
    <div key={`${id}-circle`} className="iconCircle">
      <AudioIcon className="icon" />
    </div>

    <StatusContentHolder id={id} status={status} onClick={onClick}>
      <div className="audioHolder d-flex justify-content-start">
        <div className="btnPlay d-flex justify-content-center align-items-center">
          <span className="fas fa-play" />
        </div>
      </div>
    </StatusContentHolder>
  </div>
);

export const SurveyStatus = ({
  status,
  id,
  onClick,
  userRole,
  history,
  patientId,
}) => (
  <div>
    <div key={`${id}-circle`} className="iconCircle">
      {status.artifactType === "SURVEY_RESPONSE" ? (
        <SurveyCompletedIcon className="icon" />
      ) : (
        <SurveyIcon className="icon" />
      )}
    </div>

    <StatusContentHolder id={id} status={status} onClick={onClick}>
      {status.artifactType === "SURVEY" && (
        <div
          className={
            "audioHolder d-flex justify-content-start " +
            (userRole === ROLE_PATIENT ? "align-items-center" : "")
          }
        >
          <Text content={Messages.survey_pending} />
          {userRole === ROLE_PATIENT && (
            <MPSButton
              color="primary"
              size="sm"
              className="ml-2"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                history.push(
                  `/messages/${status.id}/survey/${status.artifactId}`
                );
              }}
            >
              <Text content={Messages.sh_complete} />
            </MPSButton>
          )}
        </div>
      )}
      {status.artifactType === "SURVEY_RESPONSE" && (
        <div className="audioHolder d-flex justify-content-start">
          <Text content={Messages.survey_completed} />
        </div>
      )}
    </StatusContentHolder>
  </div>
);

export const LibraryStatus = ({ status, id, onClick }) => (
  <div>
    <div key={`${id}-circle`} className="iconCircle">
      <LibraryIcon className="icon" />
    </div>

    <StatusContentHolder id={id} status={status} onClick={onClick}>
      {status.artifactLibrary &&
      status.artifactLibrary.contents &&
      status.artifactLibrary.contents[0] ? (
        <Thumbs
          id={id}
          content={status.artifactLibrary.contents}
          type={status.artifactLibrary.contents[0].format}
        />
      ) : (
        <div className="audioHolder d-flex justify-content-start">
          <Text content={Messages.article} />
        </div>
      )}
    </StatusContentHolder>
  </div>
);

export const ConsentStatus = ({ status, id, onClick, userRole, history }) => (
  <div>
    <div key={`${id}-circle`} className="iconCircle">
      <LibraryIcon className="icon" />
    </div>

    <StatusContentHolder id={id} status={status} onClick={onClick}>
      {status.artifactType === "CONSENT" && (
        <div
          className={
            "audioHolder d-flex justify-content-start " +
            (userRole === ROLE_PATIENT ? "align-items-center" : "")
          }
        >
          <Text content={Messages.consent_pending} />

          {userRole === ROLE_PATIENT && (
            <MPSButton
              color="primary"
              size="sm"
              className="ml-2"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                history.push(
                  `/messages/${status.id}/consent/${status.artifactId}`
                );
              }}
            >
              <Text content={Messages.sh_complete} />
            </MPSButton>
          )}
        </div>
      )}
      {status.artifactType === "CONSENT_RESPONSE" && (
        <div className="audioHolder d-flex justify-content-start">
          <Text content={Messages.consent_completed} />
        </div>
      )}
    </StatusContentHolder>
  </div>
);

export const ImageStatus = ({ status, id, onClick }) => (
  <div>
    <div key={`${id}-circle`} className="iconCircle">
      <ImageIcon className="icon" />
    </div>
    <StatusContentHolder id={id} status={status} onClick={onClick}>
      <Thumbs id={id} content={status.contents} type={"IMAGE"} />
    </StatusContentHolder>
  </div>
);

export const VideoStatus = ({ status, id, onClick }) => (
  <div>
    <div key={`${id}-circle`} className="iconCircle">
      <ImageIcon className="icon" />
    </div>
    <StatusContentHolder id={id} status={status} onClick={onClick}>
      <Thumbs id={id} content={status.contents} type={"VIDEO"} />
    </StatusContentHolder>
  </div>
);

export const DocumentStatus = ({ status, id, onClick }) => (
  <div>
    <div key={`${id}-circle`} className="iconCircle">
      <i
        className="icon fa fa-file"
        style={{
          width: "8px",
          left: "-1px",
          height: "12px",
          fontSize: "12px",
        }}
      />
    </div>

    <StatusContentHolder id={id} status={status} onClick={onClick}>
      <div className="audioHolder d-flex justify-content-start">
        <Text content={Messages.document} />
      </div>
    </StatusContentHolder>
  </div>
);

export const DeviceStatus = ({ status, id, onClick }) => (
  <div>
    <div key={`${id}-circle`} className="iconCircle">
      <i
        className="icon fa fa-mobile"
        style={{
          width: "8px",
          left: "-1px",
          height: "12px",
          fontSize: "12px",
        }}
      />
    </div>

    <StatusContentHolder id={id} status={status} onClick={onClick}>
      <div className="audioHolder d-flex justify-content-start">
        <Text content={Messages.device} />
      </div>
    </StatusContentHolder>
  </div>
);

export const CarePlanStatus = ({ status, id, onClick }) => (
  <div>
    <div key={`${id}-circle`} className="iconCircle">
      <i
        className="icon fa fa-tasks"
        style={{
          width: "8px",
          left: "-1px",
          height: "12px",
          fontSize: "12px",
        }}
      />
    </div>

    <StatusContentHolder id={id} status={status} onClick={onClick}>
      <div className="audioHolder d-flex justify-content-start">
        <Text content={Messages.care_plan_uc} />
      </div>
    </StatusContentHolder>
  </div>
);

export const PDFStatus = ({ status, id, onClick }) => (
  <div>
    <div key={`${id}-circle`} className="iconCircle">
      <i className="fas fa-file-pdf" />
    </div>

    <StatusContentHolder id={id} status={status} onClick={onClick}>
      <div className="audioHolder d-flex justify-content-start">
        <div>
          <span className="fas fa-file-pdf fa-3x colorGray" />
        </div>
      </div>
    </StatusContentHolder>
  </div>
);

export const VideoCallStatus = ({ status, id, onClick }) => (
  <div>
    <div key={`${id}-circle`} className="iconCircle">
      <i
        className="icon fa fa-video"
        style={{
          width: "8px",
          left: "-1px",
          height: "12px",
          fontSize: "12px",
        }}
      />
    </div>

    <StatusContentHolder id={id} status={status} onClick={onClick}>
      <div className="d-flex justify-content-start">
        <Text content={Messages.video_call} />
      </div>
    </StatusContentHolder>
  </div>
);

export const StatusHolder = ({
  id,
  status,
  children,
  onClick,
  staffNotes,
  channel,
  patientApp,
}) => (
  <div
    key={id}
    className="item itemImage"
    onClick={() => {
      onClick(status, "open");
    }}
  >
    {!staffNotes && !channel && (
      <MessageReadStatus status={status} patientApp={patientApp} />
    )}
    {children}
  </div>
);

const MessageReadStatus = ({ status, patientApp }) => {
  let style = {
    position: "absolute",
    top: "3px",
    marginLeft: 0,
    borderRadius: ".125rem",
    padding: ".25em .417em",
    right: "0%",
  };

  if (patientApp && status.messageDeliveryStatus !== "READ") {
    return (
      <span className="badge badge-warning badge-pill" style={style}>
        <Text content={Messages.new_uc} />
      </span>
    );
  }

  if (status.messageReadByUsersWithReadByDate) {
    let patient = status.messageReadByUsersWithReadByDate.filter(
      (r) => r.roleInGroup === "PATIENT"
    )[0];

    if (!patient) {
      return (
        <span className="badge badge-warning badge-pill" style={style}>
          <Text content={Messages.not_viewed_by_patient} />
        </span>
      );
    } else {
      return null;
      // return (
      //   <span className="badge badge-success badge-pill" style={style}>
      //   Viewed By Patient
      // </span>
      // );
    }
  } else {
    if (!patientApp) {
      return (
        <span className="badge badge-warning badge-pill" style={style}>
          <Text content={Messages.not_viewed_by_patient} />
        </span>
      );
    }
    return null;
  }
};

export const LocationStatus = ({ status, id, onClick }) => {
  return (
    <div>
      <div key={`${id}-circle`} className="iconCircle">
        <i
          className="icon fa fa-map-marker-alt"
          style={{
            width: "9px",
            left: "0px",
            height: "12px",
            fontSize: "12px",
          }}
        />
      </div>

      <StatusContentHolder id={id} status={status} onClick={onClick}>
        <div className="audioHolder d-flex justify-content-start">
          <i className="fas fa-map-marker-alt" style={{ fontSize: "28px" }} />
        </div>
      </StatusContentHolder>
    </div>
  );
};

class StatusContentHolder extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  showActions = () => {
    this.setState({ actions: true });
  };

  hideActions = () => {
    this.setState({ actions: false });
  };
  render() {
    const { actions } = this.state;
    const { id, status, children, onClick, statusType } = this.props;
    return (
      <div
        key={`${id}-item`}
        className="status cursor-pointer"
        onMouseEnter={this.showActions}
        onMouseLeave={this.hideActions}
        onTouchStart={this.showActions}
        onTouchEnd={this.hideActions}
      >
        <CompleteActionView actions={status.actions} />
        <Message message={getStatusTitle(status)} />
        {children}

        <div className="d-flex justify-content-between align-items-center">
          <SenderInfo status={status} />

          {actions && (
            <StatusActions
              status={status}
              onClick={onClick}
              statusType={statusType}
            />
          )}
        </div>
      </div>
    );
  }
}

const SenderInfo = ({ status }) => (
  <div className="senderInfo">
    {status.senderName && (
      <Text
        content={Messages.by_sender}
        data={{
          senderName: status.senderName,
        }}
      />
    )}{" "}
    &middot; {moment(status.creationDate).fromNow()}
  </div>
);
