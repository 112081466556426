import { Col, Container, Row } from "reactstrap";
import React from "react";
import RegionSelector from "../RegionSelector";

export default () => (
  <Container>
    <Row className="justify-content-center">
      <Col md="8" sm={12}>
        <div className="d-flex justify-content-center align-items-center">
          <div>
            <RegionSelector
              className="drop-up"
              style={{
                backgroundColor: "transparent",
                border: 0,
                width: 65,
                height: 30
              }}
            />
          </div>
          <div className="mx-1">
            <img
              src="/img/ce-mark.png"
              height={20}
              alt="myPatientSpace - CE Mark"
            />
          </div>
          <div>
            <img
              src="/img/mps-iso.png"
              height={20}
              alt="myPatientSpace - ISO"
            />
          </div>
        </div>
      </Col>
    </Row>
  </Container>
);
