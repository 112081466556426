import React, { Component } from "react";
import { getHourOptions, getMinOptions } from "../../Shared/Utils";
import MPSSelect from "../MPSSelect";

const hourOptions = getHourOptions();
const minOptions = getMinOptions();

// function calculateDateTime(date, hour, minute) {
//   if (!date) {
//     date = moment().format("YYYY-MM-DD");
//   }
//
//   if (hour && minute) {
//     return new Date(date + ` ${hour}:${minute}`);
//   }
//   if (hour) {
//     return new Date(date + ` ${hour}:00`);
//   }
//   if (minute) {
//     return new Date(date + ` 00:${minute}`);
//   }
//   return new Date(date + ` 00:00`);
// }

class DateTime extends Component {
  render() {
    let { dateTime, setDateTime } = this.props;

    let { date, hour, minute } = dateTime || {};

    return (
      <div className="d-flex">
        <input
          type="date"
          value={date}
          onChange={e => {
            e.stopPropagation();
            setDateTime({
              date: e.target.value,
              hour,
              minute
            });
          }}
          className={"form-control mr-2"}
          style={{ borderRadius: "4px" }}
        />

        <MPSSelect
          options={hourOptions}
          onChange={option => {
            let value = option ? option.value : "00";
            setDateTime({
              date,
              hour: value,
              minute
            });
          }}
          placeholder="Hour"
          className="mr-2"
          value={hour}
          style={{ width: "100px", border: "1px solid #e0e6e8" }}
        />
        <MPSSelect
          onChange={option => {
            let value = option ? option.value : "00";
            setDateTime({
              date,
              hour,
              minute: value
            });
          }}
          placeholder="Minute"
          options={minOptions}
          value={minute}
          style={{ width: "100px", border: "1px solid #e0e6e8" }}
        />
      </div>
    );
  }
}

export default DateTime;
