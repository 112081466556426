export function getFieldName(type) {
  if (type === "INTEGER") {
    return "valueInteger";
  } else if (type === "DECIMAL") {
    return "valueDouble";
  } else if (type === "DATE") {
    return "valueDate";
  } else if (type === "BOOLEAN") {
    return "valueBoolean";
  } else {
    return "valueString";
  }
}
