import React from "react";
import { showLocalizedToaster } from "../../State/actions/toaster";
import { connect } from "react-redux";
import PatientAppServices from "../../services/PatientAppServices";
import NewAppointmentCalendar from "../components/Appointments/NewAppointmentCalendar";
import { parseURLQueryParams } from "../../utils/utils";
import { getSlotsAndResources } from "../../StaffApp/Appointments/Utils";

class NewAppointmentContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.setState({ loading: true });
    PatientAppServices.getAppointmentScheduleAnonymous(
      this.props.scheduleId,
      this.props.patientId,
      this.props.userId,
      this.props.templateId
    )
      .then(schedule => {
        this.setState({ schedule: { ...schedule }, loading: false });
      })
      .catch(e => {
        this.setState({ loading: false });
      });
    this.setState({ slotsLoading: true });
    this.loadSlots(this.props.scheduleId);
  }

  loadAppointmentsAndSlots = (start, end, countOnly) => {
    this.setState({ start, end, countOnly, slots: [] }, () => {
      this.loadSlots(this.props.scheduleId);
    });
  };

  loadSlots = scheduleId => {
    let { filter, start, end, countOnly } = this.state;

    PatientAppServices.getSlots(
      filter,
      scheduleId,
      start,
      end,
      countOnly,
      this.props.patientId,
      this.props.userId
    )
      .then((slots = []) => {
        this.setState({
          ...getSlotsAndResources(slots),
          slotsLoading: false
        });
      })
      .catch(e => {
        this.setState({ slotsLoading: false });
      });
  };

  createAppointment = (slots, staff, patientDetails, masterData) => {
    let { schedule } = this.state;

    let patientObj = {
      patientId: this.props.patientId,
      referenceType: "PATIENT"
    };

    if (patientDetails) {
      if (schedule.consentRequired) {
        patientObj.consentProvided = true;
        patientObj.consentDate = new Date().getTime();
        patientObj.displayHumanName = patientDetails.name
          ? {
              given: patientDetails.name,
              family: ""
            }
          : null;
        patientObj.phoneNumber = patientDetails.phoneNumber;
        patientObj.email = patientDetails.email;
      } else if (this.props.isPhoneMandatory) {
        patientObj.phoneNumber = patientDetails.phoneNumber;
      }
    }

    let participants = [patientObj];

    if (staff) {
      participants.push({
        ...staff
      });
    }
    if (masterData && masterData.length >= 1) {
      participants = participants.concat(masterData);
    }

    // if (schedule.appointmentLocationMDValue) {
    //   participants.push({
    //     referenceType: "MASTERDATA_KEY",
    //     masterDataValue: {
    //       code: schedule.appointmentLocationMDValue.code,
    //       display: schedule.appointmentLocationMDValue.display
    //     }
    //   });
    // }

    let appointmentData = {
      start: new Date(slots[0].start).getTime(),
      end: new Date(slots[slots.length - 1].end).getTime(),
      slotIds: slots.map(slot => slot.id),
      templateId: this.props.templateId,
      scheduleId: schedule.id,
      participants
    };

    if (
      schedule.appointmentLocationMDValue ||
      schedule.appointmentLocationMDLocation
    ) {
      appointmentData.appointmentLocationMDKey =
        schedule.appointmentLocationMDKey;
      appointmentData.appointmentLocationMDLocation =
        schedule.appointmentLocationMDLocation;
      appointmentData.appointmentLocationMDValue =
        schedule.appointmentLocationMDValue;
    }

    this.setState({
      submitting: true
    });
    PatientAppServices.createAppointmentAnonymous(
      appointmentData,
      this.props.patientId,
      this.props.userId
    )
      .then(response => {
        this.setState({
          created: true,
          submitting: false
        });
      })
      .catch(e => {
        this.setState({
          submitting: false
        });
      });
  };

  render() {
    let {
      loading,
      slotsLoading,
      schedule,
      submitting,
      created,
      slots = [],
      resources = []
    } = this.state;

    return (
      <NewAppointmentCalendar
        history={this.props.history}
        loading={loading || slotsLoading}
        templateId={this.props.templateId}
        isPhoneMandatory={this.props.isPhoneMandatory}
        schedule={schedule}
        slots={slots}
        submitting={submitting}
        createAppointment={this.createAppointment}
        created={created}
        resources={resources}
        loadAppointmentsAndSlots={this.loadAppointmentsAndSlots}
      />
    );
  }
}

const mapDispatchToProps = {
  showToaster: showLocalizedToaster
};

const mapStateToProps = (state, ownProps) => {
  let query = parseURLQueryParams(window.location.search);

  return {
    scheduleId: ownProps.match.params.scheduleId,
    templateId: ownProps.match ? ownProps.match.params.templateId : null,
    patientId: query.pid,
    userId: query.uid,
    isPhoneMandatory: query.isPhoneMandatory === "true"
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewAppointmentContainer);
