import { BarLoader } from "react-spinners";
import React, { PureComponent } from "react";
import connect from "react-redux/es/connect/connect";

class MPSBarLoader extends PureComponent {
  render() {
    let { appTheme = {}, width = 100 } = this.props;
    let themeColor = appTheme.themeColor
      ? appTheme.themeColor.replace("0x", "#")
      : "#20a8d8";

    return <BarLoader color={themeColor} width={width} widthUnit="px" />;
  }
}

const mapstatetoprops = state => ({
  appTheme: state.user.data ? state.user.data.appTheme : {}
});

export default connect(mapstatetoprops)(MPSBarLoader);
