// @flow

import React from "react";
import {
  onLogin,
  onLogout,
  onSetOnboardingUserName
} from "../../State/actions/user";
import UserOnboardingContainer from "./UserOnboardingContainer";
import { connect } from "react-redux";
import { parseURLQueryParams } from "../../utils/utils";

const mapDispatchToProps = {
  onLogin,
  onLogout,
  onSetOnboardingUserName
};

const mapStateToProps = (state, ownProps) => {
  console.log("ownProps", state, ownProps);

  let params = null;
  if (ownProps.location && ownProps.location.search) {
    params = parseURLQueryParams(ownProps.location.search);
  }
  return {
    loading: state.user.loading,
    onboardingType: ownProps.match
      ? ownProps.match.params.onboardingType
      : null,
    inviteCode: ownProps.match ? ownProps.match.params.inviteCode : null,
    spaceDetails: state.user.spaceDetails,
    error: state.user.onboarding.error,
    errorMsg: state.user.onboarding.errorMsg,
    linkType: params ? params.cp : null,
    selfSignup: params ? params.selfSignup : null
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserOnboardingContainer);
