import React from "react";
import classnames from "classnames";
import fileService from "../../../services/fileService";

export default class QuestionImage extends React.PureComponent {
  componentDidMount() {
    let { contentURL, index } = this.props;
    if (!contentURL) {
      return;
    }
    fileService.getFile(contentURL).then(iconImg => {
      let el = document.getElementById("survey_q_choice_image_" + index);
      if (el) {
        el.style.backgroundImage = "url('" + iconImg + "')";
      }
    });
  }

  render() {
    let { index } = this.props;

    return (
      <div
        className={classnames(
          "bgImageUrl d-flex justify-content-center align-items-center"
        )}
        id={"survey_q_choice_image_" + index}
        style={{
          overflow: "hidden",
          width: "100px",
          height: "100px",
          backgroundPosition: "50% 50%",
          backgroundSize: "cover"
        }}
      />
    );
  }
}
