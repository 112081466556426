import React, { PureComponent } from "react";
import { Button, Col, Row } from "reactstrap";
import UserOnboardingChallengeInvite from "../UserOnboarding/UserOnboardingChallengeInvite";
import { validateAttribute } from "../UserOnboarding/Utils";
import StepAcceptTerms from "../Onboarding/StepAcceptTerms";
import Messages from "../../Shared/Messages";
import Text from "../../components/Text";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import userService from "../../services/userService";

class SignupPreValidation extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { preValidationAttribute: props.preValidationAttribute };
  }

  onUpdateData = (sectionIndex, name, attribute) => {
    let { preValidationAttribute } = this.state;
    preValidationAttribute.attribute = attribute;
    this.setState({ preValidationAttribute: { ...preValidationAttribute } });
  };

  enterSignup = () => {
    let { preValidationAttribute } = this.state;
    if (!preValidationAttribute) {
      if (!this.state.termsAccepted) {
        if (this.props.inviteCode && this.props.onboardingType === "invite") {
          userService.addOnboardingEvent(
            this.props.inviteCode,
            " User tried to continue without accepting the terms."
          );
        }
        this.setState({ errorTerms: true });
        return;
      }
      this.setState({ errorTerms: false });
      this.props.incrementStep();
      return;
    }
    let attribute = preValidationAttribute.attribute;
    attribute.mandatory = true;
    delete attribute.error;
    delete attribute.info;
    delete attribute.validationCode;

    this.setState({
      preValidationAttribute: {
        ...preValidationAttribute,
        attribute: { ...attribute }
      }
    });

    validateAttribute(0, attribute, this);
  };

  changeFieldPostValidation = (index, attribute) => {
    if (!attribute.error) {
      if (!this.state.termsAccepted) {
        this.setState({ errorTerms: true });
        return;
      }
      this.setState({ errorTerms: false });
      this.props.incrementStep();
      return;
    }

    let { preValidationAttribute } = this.state;

    this.setState({
      preValidationAttribute: {
        ...preValidationAttribute,
        attribute: { ...attribute }
      }
    });
  };

  onUpdateTermsAccepted = (name, value) => {
    this.setState({ [name]: value });
  };

  render() {
    let { preValidationAttribute, termsAccepted, errorTerms } = this.state;
    let { preValidationTitle, preValidationText } = this.props;

    return (
      <div>
        <Row>
          <Col xs="12">
            {preValidationTitle && (
              <h5 className="text-center">{preValidationTitle}</h5>
            )}
            {preValidationText && (
              <div className="my-3">{preValidationText}</div>
            )}

            {preValidationAttribute && (
              <UserOnboardingChallengeInvite
                sectionIndex={0}
                facilityId={this.props.facilityId}
                onUpdateData={(name, attributes) => {
                  this.onUpdateData(0, name, attributes[0]);
                }}
                referrer="SELF_SIGNUP"
                challengeFields={[preValidationAttribute].map(cf => {
                  cf.attribute.mandatory = true;
                  return cf.attribute;
                })}
                facilityCode={this.props.facilityCode}
                setAttributeRef={this.setAttributeRef}
                noBlurValidation={true}
              />
            )}

            <div>
              <StepAcceptTerms
                onUpdateData={this.onUpdateTermsAccepted}
                termsAccepted={termsAccepted}
                errorTerms={errorTerms}
                mps
              />
              <ReactCSSTransitionGroup
                transitionName="example"
                transitionEnterTimeout={1000}
                transitionLeaveTimeout={500}
              >
                {errorTerms && (
                  <p className="text-muted">
                    <span style={{ color: "red" }}>
                      <Text content={Messages.accept_pp_tc} />
                    </span>
                  </p>
                )}
              </ReactCSSTransitionGroup>
            </div>

            <Button
              color="primary"
              size="lg"
              className="px-4 mt-2"
              block
              onClick={this.enterSignup}
            >
              <Text content={Messages.continue} />
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default SignupPreValidation;
