import { take, put, select } from "redux-saga/effects";
import OrganizationAdminsService from "../../services/organizationAdminsService";
import {
  LOAD_ORGANIZATION_ADMINS,
  LOAD_ORGANIZATION_ADMINS_DONE,
  ORGANIZATION_ADMIN_INVITE,
  ORGANIZATION_ADMIN_INVITE_DONE,
  ORGANIZATION_ADMIN_INVITE_ERROR,
  ORGANIZATION_ADMIN_DELETE_DONE,
  ORGANIZATION_ADMIN_DELETE,
  ORGANIZATION_ADMIN_DELETE_ERROR
} from "../actions/actionTypes";

export function* deleteAdmin() {
  while (true) {
    const action = yield take(ORGANIZATION_ADMIN_DELETE);
    yield put({ type: ORGANIZATION_ADMIN_DELETE });
    try {
      const result = yield OrganizationAdminsService.delete(
        action.orgId,
        action.adminId
      );
      // console.log('DELETE ORGANIZATION_ADMIN ', result);
      yield put({
        type: ORGANIZATION_ADMIN_DELETE_DONE,
        id: action.adminId
      });
      //history.push('/organizations/' + result.facilityId + '/org admins');
    } catch (error) {
      // console.log('ERROR', error);
      yield put({ type: ORGANIZATION_ADMIN_DELETE_ERROR, error });
    }
  }
}
export function* inviteAdmin() {
  while (true) {
    const action = yield take(ORGANIZATION_ADMIN_INVITE);
    yield put({ type: ORGANIZATION_ADMIN_INVITE });
    try {
      const result = yield OrganizationAdminsService.invite(
        action.organizationId,
        action.data
      );
      // console.log('INVITE ORGANIZATION_ADMIN ', result);
      yield put({ type: ORGANIZATION_ADMIN_INVITE_DONE, data: result });
      //history.push('/organizations/' + result.facilityId + '/org admins');
    } catch (error) {
      // console.log('ERROR', error);
      yield put({ type: ORGANIZATION_ADMIN_INVITE_ERROR, error });
    }
  }
}

export function* loadAdmins() {
  while (true) {
    const action = yield take(LOAD_ORGANIZATION_ADMINS);

    // get current page and load more
    const organizationAdmins = yield select(state => state.organizationAdmins);
    // check if loadMore is true or already loading
    // console.log('load organizationAdmins', organizationAdmins);
    if (
      !organizationAdmins.loading &&
      "loadMore" in organizationAdmins &&
      !organizationAdmins.loadMore
    ) {
      // load more should not fire as loadMore is false
      return false;
    }
    // get page
    const page = organizationAdmins.page || 0;
    // start loading more

    // put will set loading flag
    yield put({ type: LOAD_ORGANIZATION_ADMINS });
    // load organizationAdmins
    try {
      const result = yield OrganizationAdminsService.load(
        action.organizationId,
        page
      );
      // console.log('ADMINS SAGE RESULT RETURNED ', result);
      yield put({
        type: LOAD_ORGANIZATION_ADMINS_DONE,
        list: result.data,
        page: page + 1,
        loadMore: result.loadMore
      });
    } catch (error) {
      // console.log('SAGE RESULT ERROR ');
      yield put({
        type: LOAD_ORGANIZATION_ADMINS_DONE,
        list: [],
        page: page + 1,
        loadMore: false
      });
      // console.log('ADMINS ERROR', error);
    }
  }
}
