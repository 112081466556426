import masterDataService from "../../services/masterDataService";
import {
  MASTER_DATA_FETCH_ON_LOAD,
  MASTER_DATA_FETCH_ON_LOAD_DONE,
  MASTER_DATA_CREATE_NEW,
  MASTER_DATA_CREATE_NEW_DONE,
  MASTER_DATA_EDIT,
  MASTER_DATA_EDIT_DONE,
  SHOW_TOASTER,
  MASTER_DATA_DELETE,
  MASTER_DATA_DELETE_DONE
} from "../actions/actionTypes";

import { put, select, take } from "redux-saga/effects";
import { showToaster, showErrorToaster } from "../actions/toaster";

export function* masterDataOnLoad() {
  while (true) {
    const action = yield take(MASTER_DATA_FETCH_ON_LOAD);
    yield put({ type: MASTER_DATA_FETCH_ON_LOAD });
    try {
      const result = yield masterDataService.loadData();
      yield put({
        type: MASTER_DATA_FETCH_ON_LOAD_DONE,
        data: result
      });
    } catch (error) {
      showErrorToaster("Error in showing master data");
    }
  }
}

export function* addNewMasterData() {
  while (true) {
    const action = yield take(MASTER_DATA_CREATE_NEW);
    yield put({ type: MASTER_DATA_CREATE_NEW });
    try {
      const result = yield masterDataService.addNewData(action.newData);
      yield put({
        type: MASTER_DATA_CREATE_NEW_DONE,
        data: result
      });
      yield put(showToaster("Master data added", "success", 5));
    } catch (error) {
      console.log("Error in adding data", "error", 5);
    }
  }
}

export function* editMasterData() {
  while (true) {
    const action = yield take(MASTER_DATA_EDIT);
    try {
      const result = yield masterDataService.editMasterData(
        action.editData,
        action.id
      );
      yield put({
        type: MASTER_DATA_EDIT_DONE,
        data: result
      });
      yield put({
        type: SHOW_TOASTER,
        data: { message: "Master data edited", type: "success" }
      });
    } catch (error) {
      console.log("Error in editing data", "error", 5);
    }
  }
}

export function* deleteMasterData() {
  while (true) {
    const action = yield take(MASTER_DATA_DELETE);
    try {
      const result = yield masterDataService.deleteMasterData(action.dataId);
      yield put({
        type: MASTER_DATA_DELETE_DONE,
        data: action.dataId
      });
      yield put({
        type: SHOW_TOASTER,
        data: { message: "Master data deleted", type: "success" }
      });
    } catch (error) {
      console.log("Error in editing data", "error", 5);
    }
  }
}
