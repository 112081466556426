import {
  CLINICS_RESET_LOADING,
  LOAD_CLINICS,
  LOAD_CLINICS_DONE,
  LOG_OUT,
  SET_CLINICS_FILTER,
  TOGGLE_CLINICS_FILTER
} from "../actions/actionTypes";
import moment from "moment";

let initialState = {
  filterOpen: false,
  filter: {
    clinicDateFrom: moment().valueOf(),
    clinicDateTo: moment()
      .startOf("day")
      .add(3, "days")
      .valueOf()
  },
  list: [],
  loading: false,
  page: 0,
  loadMore: true,
  error: false,
  total: 0
};

const clinicsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLINICS_RESET_LOADING:
      let { filterOpen, filter } = state;
      return { ...initialState, filterOpen, filter };

    case TOGGLE_CLINICS_FILTER:
      let current = state.filterOpen;
      return { ...state, filterOpen: !current };

    case SET_CLINICS_FILTER:
      return {
        ...state,
        filter: action.filter
      };

    case LOAD_CLINICS:
      return { ...state, loading: true };

    case LOAD_CLINICS_DONE:
      return {
        ...state,
        list:
          action.page === 0
            ? action.list
            : state.list.slice(0).concat(action.clinics),
        loading: false,
        page: action.page,
        loadMore: action.loadMore,
        total: action.total || 0
      };

    case LOG_OUT: {
      return initialState;
    }

    default:
      return state;
  }
};

export default clinicsReducer;
