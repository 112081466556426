import React, { useState } from "react";
import { Tooltip } from "reactstrap";

const MPSTooltip = props => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <div className={props.className ? props.className : ""}>
      {!props.noIcon && (
        <i
          className={`fa fa-${props.info ? "info-circle" : "question-circle"}`}
          id={props.id}
        />
      )}
      <Tooltip
        placement="right"
        isOpen={tooltipOpen}
        target={props.id}
        toggle={toggle}
      >
        {props.children}
      </Tooltip>
    </div>
  );
};

export default MPSTooltip;
