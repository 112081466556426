import React, { PureComponent } from "react";
import {
  Container,
  Row,
  Col,
  CardGroup,
  Card,
  CardBody,
  CardImg,
  Button
} from "reactstrap";
import Text from "../../components/Text";
import Messages from "../../Shared/Messages";
import LoginForm from "./LoginForm";
import LoginFooter from "./LoginFooter";
import I18nProvider from "../../services/I18nProvider";
import LoginMFA from "./LoginMFA";
const intl = I18nProvider.getIntlProvider();

class Login extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let {
      loginError,
      invited,
      facilityName,
      logoutMessage,
      errorMessage,
      mfaRequired
    } = this.props;

    if (facilityName) {
      facilityName = facilityName.replace(/-/g, " ");
    }

    if (errorMessage === "Invalid Credentials") {
      errorMessage = intl.formatMessage(Messages.error_logging_in);
    }

    return (
      <div
        className="app flex-column align-items-center"
        style={{
          background: "linear-gradient(0deg, #52c3d3, #45b1e7)",
          height: "100vh"
        }}
      >
        <div
          className="d-flex flex-row align-items-center flex-column justify-content-center"
          style={{ width: "100%", height: "100%" }}
        >
          <Container>
            <Row className="justify-content-center">
              <Col md="8" sm={12}>
                <CardGroup>
                  <Card
                    className="d-flex flex-row"
                    style={{
                      border: 0,
                      boxShadow:
                        "rgb(31 35 38 / 20%) 0px 4px 24px 0px, rgb(31 35 38 / 12%) 0px 1px 1px -1px, rgb(31 35 38 / 14%) 0px 1px 2px 0px"
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#29363d",
                        borderTopLeftRadius: 4,
                        borderBottomLeftRadius: 4
                      }}
                      className="d-none d-mg-flex d-lg-flex align-items-center"
                    >
                      <CardImg
                        bottom
                        style={{ width: "350px" }}
                        src="/img/home_rep_c.png"
                        alt="myPatientSpace"
                      />
                    </div>
                    <CardBody className="p-4">
                      <div className="d-flex justify-content-center mb-4">
                        <img
                          alt="myPatientSpace"
                          src="/img/logo-symbol.png"
                          height={42}
                        />
                      </div>
                      {logoutMessage && (
                        <p style={{ color: "#28623c" }}>{logoutMessage}</p>
                      )}
                      <div className="d-flex mb-3 justify-content-center">
                        <h4>
                          <Text content={Messages.login_to_account} />
                        </h4>
                      </div>
                      {invited && (
                        <div style={{ color: "#28623c" }} className="mb-4">
                          <Text
                            content={Messages.welcome_back}
                            data={{
                              facilityName: facilityName
                            }}
                          />
                        </div>
                      )}
                      {loginError && (
                        <p className="text-danger">{errorMessage}</p>
                      )}
                      {!!mfaRequired ? (
                        <LoginMFA {...this.props} />
                      ) : (
                        <LoginForm {...this.props} />
                      )}

                      <Row className="mt-3">
                        <Col
                          xs="12"
                          className="d-flex align-items-center justify-content-center"
                        >
                          <Button
                            id="login-link-fpwd"
                            color="link"
                            className="px-0"
                            onClick={e => {
                              e.preventDefault();
                              e.stopPropagation();
                              this.props.history.push("/forgot-password");
                            }}
                          >
                            <Text content={Messages.forgot_password} />
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </CardGroup>

                <div className="mt-3">
                  <LoginFooter />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default Login;
