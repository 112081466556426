import React from "react";

export default function Mandatory({ mandatory, fontSize }) {
  if (!mandatory) {
    return null;
  }
  return (
    <sup
      style={{
        color: "red",
        // position: "absolute",
        right: 0,
        top: 3,
        fontSize: fontSize || "1.2rem"
      }}
    >
      *
    </sup>
  );
}
