import React from "react";
import { Card, CardBody, Col, FormGroup, Input, Label, Row } from "reactstrap";
import Select from "react-select";
import CountryCodes from "../../../Shared/FHIR/CountryCodes";
import classnames from "classnames";
import { doE164, validatePhoneNumber } from "../../../utils/phone";
import { validateEmail } from "../../../utils/utils";
import NewAppointmentPhoneForm from "./NewAppointmentPhoneForm";
import Messages from "../../../Shared/Messages";
import Text from "../../../components/Text";

const labelStyle = { fontWeight: 500, width: "100%" };
class NewAppointmentConsent extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      contactType: "existing",
      email: props.email ? props.email.toLowerCase() : "",
      phone: "",
      countryCode: "+353",
      showCP: !!props.phone,
      showCE: !!props.email,
      confirmPhone: "",
      confirmEmail: ""
    };
  }

  validate = () => {
    const {
      phone,
      countryCode,
      email,
      confirmPhone,
      confirmCountryCode,
      confirmEmail,
      contactType,
      consentAccepted
    } = this.state;

    let errors = {};
    if (contactType === "new") {
      if (this.props.isPhoneMandatory && !phone) {
        errors.phone = true;
      } else if (!phone && !email) {
        errors.phone = true;
        errors.email = true;
      } else {
        if (
          phone &&
          (!countryCode || !validatePhoneNumber(countryCode + " " + phone))
        ) {
          errors.phone = true;
        } else {
          if (
            doE164(countryCode, phone) !==
            doE164(confirmCountryCode, confirmPhone)
          ) {
            errors.confirmPhone = true;
          }
        }

        if (email && !validateEmail(email)) {
          errors.email = true;
        } else {
          if (
            email &&
            (!confirmEmail ||
              email.toLowerCase() !== confirmEmail.toLowerCase())
          ) {
            errors.confirmEmail = true;
          }
        }
      }
    }

    if (!consentAccepted) {
      errors.errorConsent = true;
    }

    this.setState({ errors });
    return {
      valid:
        !errors.phone &&
        !errors.email &&
        !errors.confirmPhone &&
        !errors.confirmEmail &&
        !errors.errorConsent,
      values: { email: email, phone: doE164(countryCode, phone) }
    };
  };

  getConsentInfo = () => {
    const telecom = this.validate();
    let valid = telecom.valid;
    if (valid) {
      let { phone, email } = telecom.values;
      if (this.state.contactType === "new") {
        return {
          name: this.state.name,
          email: email ? email.toLowerCase() : "",
          phoneNumber: phone
        };
      } else {
        if (this.props.isPhoneMandatory) {
          return this.phoneRef.getPhoneInfo();
        } else {
          return {};
        }
      }
    }
  };

  onAcceptClicked = () => {
    this.setState({ consentAccepted: !this.state.consentAccepted });
  };

  disableEmailPaste = () => {
    const emailInput = document.getElementById("veri-confirm-email");
    if (emailInput) {
      emailInput.onpaste = function(e) {
        e.preventDefault();
      };
      emailInput.focus();
    }
  };

  disablePhonePaste = () => {
    const phoneInput = document.getElementById("veri-confirm-phone");
    if (phoneInput) {
      phoneInput.onpaste = function(e) {
        e.preventDefault();
      };
      phoneInput.focus();
    }
  };

  handleOnBlurConfirmEmail = () => {
    const { errors = {}, email, confirmEmail } = this.state;
    errors.confirmEmail = false;
    this.setState({ errors: { ...errors } });
    if (!confirmEmail || email.toLowerCase() !== confirmEmail.toLowerCase()) {
      errors.confirmEmail = true;
      this.setState({ errors: { ...errors } });
    }
  };

  handleOnBlurConfirmPhone = () => {
    let {
      errors = {},
      phone,
      countryCode,
      confirmPhone,
      confirmCountryCode
    } = this.state;
    errors.confirmPhone = false;
    this.setState({ errors: { ...errors } });
    if (
      doE164(countryCode, phone) !== doE164(confirmCountryCode, confirmPhone)
    ) {
      errors.confirmPhone = true;
      this.setState({ errors: { ...errors } });
    }
  };

  onChangeName = e => {
    this.setState({ name: e.target.value });
  };

  onChangePhone = e => {
    this.setState({ phone: e.target.value });
  };

  onChangeCountryCode = cntrObj => {
    this.setState({ countryCode: cntrObj ? cntrObj.value : null });
  };

  onChangeEmail = e => this.setState({ email: e.target.value });

  onChangeConfirmPhone = e => {
    this.setState({ confirmPhone: e.target.value });
  };

  onChangeConfirmCountryCode = cntrObj => {
    this.setState({ confirmCountryCode: cntrObj ? cntrObj.value : null });
  };

  onChangeConfirmEmail = e => this.setState({ confirmEmail: e.target.value });

  showConfirmPhone = () => {
    let show = !!this.state.phone;
    this.setState({ showCP: show }, () => {
      if (show) {
        this.disablePhonePaste();
      }
    });
  };

  showConfirmEmail = () => {
    let show = !!this.state.email;
    this.setState({ showCE: show }, () => {
      if (show) {
        this.disableEmailPaste();
      }
    });
  };

  render() {
    let {
      contactType,
      name,
      email,
      countryCode,
      confirmCountryCode,
      phone,
      confirmPhone,
      confirmEmail,
      consentAccepted,
      errors = {},
      showCP,
      showCE
    } = this.state;
    let { schedule } = this.props;
    let { consentText } = schedule;

    return (
      <div>
        <Row>
          <Col xs="12">
            <hr />
            <div className="my-3">
              <h5>Consent</h5>
              <div className="mb-1">
                {errors.errorConsent && (
                  <span style={{ color: "red" }}>Please accept consent.</span>
                )}
              </div>
              <div className="d-flex">
                <label className="d-flex">
                  <div>
                    <input
                      style={{ height: "17px", width: "17px" }}
                      className="mr-1"
                      type="checkbox"
                      checked={consentAccepted}
                      onChange={this.onAcceptClicked}
                    />
                  </div>
                  <div dangerouslySetInnerHTML={{ __html: consentText }} />
                </label>
              </div>
            </div>

            <hr />
            <div className="mb-3">
              <h5>
                <Text content={Messages.how_to_contact_me} />
              </h5>
              <div className="d-flex">
                <div className="d-flex mr-4 align-items-center">
                  <label>
                    <input
                      className="mr-1"
                      type="radio"
                      checked={contactType === "existing"}
                      onChange={() => {
                        this.setState({ contactType: "existing" });
                      }}
                    />
                    <Text content={Messages.contact_in_app} />
                  </label>
                </div>

                <div className="d-flex align-items-center">
                  <label>
                    <input
                      className="mr-1"
                      type="radio"
                      checked={contactType === "new"}
                      onChange={() => {
                        this.setState({ contactType: "new" });
                      }}
                    />
                    <Text content={Messages.new_contact_dets} /><Text content={Messages.contact_in_app} />
                  </label>
                </div>
              </div>
            </div>
            {contactType === "new" && (
              <div className="row d-flex justify-content-center">
                <div className="col-sm-12 col-md-12">
                  <Card>
                    <CardBody>
                      {errors.phone && errors.email && (
                        <div className="d-flex invalid-feedback">
                          Please set a valid email or phone number
                        </div>
                      )}

                      <FormGroup>
                        <div className="inputHolder d-flex flex-column">
                          <Label style={labelStyle}>Name</Label>
                          <div className="d-flex flex-row">
                            <Input value={name} onChange={this.onChangeName} />
                          </div>
                        </div>
                      </FormGroup>

                      <FormGroup>
                        <div className="inputHolder d-flex flex-column">
                          <Label style={labelStyle}>Phone</Label>
                          <div className="d-flex flex-row">
                            <Select
                              value={countryCode}
                              onChange={this.onChangeCountryCode}
                              placeholder="Country code"
                              options={CountryCodes}
                              labelKey="country"
                              valueKey="value"
                              style={{
                                border: "1px solid #e0e6e8",
                                borderRadius: 0
                              }}
                            />
                            <Input
                              style={{ width: "60%" }}
                              value={phone}
                              onChange={this.onChangePhone}
                              className={classnames(
                                errors.phone ? "ml-1 is-invalid" : "ml-1"
                              )}
                              placeholder="Phone Number"
                              autoComplete="nope"
                              onBlur={this.showConfirmPhone}
                            />
                          </div>
                          {errors.phone && !errors.email && (
                            <div className="d-flex invalid-feedback">
                              Please set a valid phone number with country code.
                            </div>
                          )}
                        </div>
                      </FormGroup>

                      {showCP && (
                        <FormGroup>
                          <div className="inputHolder d-flex flex-column">
                            <Label style={labelStyle}>Confirm Phone</Label>
                            <div className="d-flex flex-row">
                              <Select
                                value={confirmCountryCode}
                                onChange={this.onChangeConfirmCountryCode}
                                placeholder="Country code"
                                options={CountryCodes}
                                labelKey="country"
                                valueKey="value"
                                style={{
                                  border: "1px solid #e0e6e8",
                                  borderRadius: 0
                                }}
                              />
                              <Input
                                id="veri-confirm-phone"
                                style={{ width: "60%" }}
                                value={confirmPhone}
                                onChange={this.onChangeConfirmPhone}
                                className={classnames(
                                  errors.confirmPhone
                                    ? "ml-1 is-invalid"
                                    : "ml-1"
                                )}
                                placeholder="Phone Number"
                                autoComplete="nope"
                                onBlur={this.handleOnBlurConfirmPhone}
                              />
                            </div>
                            {errors.confirmPhone && (
                              <div className="d-flex invalid-feedback">
                                Phone and Confirm phone should be the same.
                              </div>
                            )}
                          </div>
                        </FormGroup>
                      )}

                      <FormGroup>
                        <div className="inputHolder d-flex flex-column">
                          <Label style={labelStyle}>E-mail</Label>
                          <Input
                            value={email}
                            onChange={this.onChangeEmail}
                            type="text"
                            autoCapitalize="none"
                            className={classnames(
                              errors.email ? "is-invalid" : ""
                            )}
                            autoComplete="nope"
                            onBlur={this.showConfirmEmail}
                          />
                          {!errors.phone && errors.email && (
                            <div className="d-flex invalid-feedback">
                              Please set a valid email.
                            </div>
                          )}
                        </div>
                      </FormGroup>

                      {showCE && (
                        <FormGroup>
                          <div className="inputHolder d-flex flex-column">
                            <Label style={labelStyle}>Confirm E-mail</Label>
                            <Input
                              id="veri-confirm-email"
                              value={confirmEmail}
                              onChange={this.onChangeConfirmEmail}
                              type="text"
                              autoCapitalize="none"
                              className={classnames(
                                errors.confirmEmail ? "is-invalid" : ""
                              )}
                              autoComplete="nope"
                              onBlur={this.handleOnBlurConfirmEmail}
                            />
                            {errors.confirmEmail && (
                              <div className="d-flex invalid-feedback">
                                Email and Confirm Email should be the same.
                              </div>
                            )}
                          </div>
                        </FormGroup>
                      )}
                    </CardBody>
                  </Card>
                </div>
              </div>
            )}

            {contactType === "existing" && this.props.isPhoneMandatory && (
              <NewAppointmentPhoneForm
                ref={ref => {
                  this.phoneRef = ref;
                }}
              />
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

export default NewAppointmentConsent;
