import { Card, CardBody } from "reactstrap";
import moment from "moment";
import AttributesContainer from "../../../FacilityAdminApp/AppointmentsConfig/Schedules/attributes/AttributesContainer";
import React from "react";
import ModalPopup from "../../../components/ModalPopup";
import AnonymousArticleView from "./AnonymousArticleView";
import AnonymousScreeningMessage from "./AnonymousScreeningMessage";

class AnonymousAppointmentView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  openLocationModal = () => {
    this.setState({ locationModal: true });
  };

  closeLocationModal = () => {
    this.setState({ locationModal: false });
  };

  render() {
    let {
      appointment,
      appTheme,
      screeningDone,
      submitted,
      allowScreening
    } = this.props;

    let themeColor = appTheme.themeColor
      ? appTheme.themeColor.replace("0x", "#")
      : "#20a8d8";
    let style = {
      border: "1px solid",
      backgroundColor: themeColor,
      borderColor: themeColor,
      color: "#fff"
    };

    if (this.state.hover) {
      style.opacity = 0.9;
    }

    return (
      <Card>
        <CardBody>
          <h5 className="">Appointment</h5>
          <div className="mt-2">
            <div className="">
              <div>{appointment.appointmentDescription}</div>
              <h5 className="mt-2">
                {moment(appointment.start).format("LLL")}
              </h5>

              <AnonymousScreeningMessage
                appointment={appointment}
                appTheme={appTheme}
                submitted={submitted}
                screeningDone={screeningDone}
                allowScreening={allowScreening}
                showScreeningView={this.props.showScreeningView}
              />

              <hr style={{ margin: "0.5rem 0" }} />

              <AttributesContainer
                attributes={
                  appointment.attributes
                    ? appointment.attributes.map(a => {
                        a.editable = false;
                        return a;
                      })
                    : []
                }
                disabled={true}
                noNewAddition={true}
                containerStyle={{
                  border: 0
                }}
              />

              {appointment.appointmentLocationLibraryId && (
                <div className="d-flex justify-content-center mt-3">
                  <a
                    style={{ color: themeColor }}
                    className="btn-link cursor-pointer"
                    onClick={this.openLocationModal}
                  >
                    Further Information
                  </a>
                </div>
              )}
            </div>
          </div>
        </CardBody>

        {this.state.locationModal && (
          <ModalPopup
            title="Appointment Location"
            onClose={this.closeLocationModal}
          >
            <AnonymousArticleView
              libraryId={appointment.appointmentLocationLibraryId}
            />
          </ModalPopup>
        )}
      </Card>
    );
  }
}

export default AnonymousAppointmentView;
