import React, { Component } from "react";
import { connect } from "react-redux";
import VideoComponent from "./VideoComponent";
import { Col, Container, Row } from "reactstrap";
import userService from "../../services/userService";
import MPSBarLoader from "../MPSBarLoader";
import MPSButton from "../Buttons/Buttons/MPSButton";
import moment from "moment";
import {
  POLLING_DURATION_IN_SECONDS,
  POLLING_DURATION_INTERVAL_IN_MS
} from "./Utils";
import CallWaitTimer from "./CallWaitTimer";
import PatientWebexMeeting from "./WebEx/Patient/PatientWebexMeeting";

const ERROR =
  "Unable to join the video call. May be the call is not scheduled yet. Please contact your health care provider.";
const PARTICIPANT_ERROR =
  "Other person has not joined the call. Please try again later";
function getOtherParticipantName(participants) {
  let filtered = participants.filter(p => p.referenceType !== "PATIENT");
  if (filtered && filtered[0]) {
    return filtered[0].displayHumanName.text;
  }
  return "Care Provider";
}

class AnonymousVideoCallContainer extends Component {
  constructor(props) {
    super();
    this.state = {};
  }

  onStartAnonymousCall = e => {
    let { roomName } = this.props;
    this.setState({ loading: true });
    this.pollingStart = moment();
    this.doPollingToJoinCall(roomName);
  };

  doPollingToJoinCall = roomName => {
    userService
      .joinVideoRoom(roomName)
      .then(response => {
        let { roomName, jwt: token, participants, provider, roomNameAndUserId } = response;
        if (provider === "WEBEX") {
          this.setState({
            callNotReady: false,
            provider,
            roomName, //sipAddress
            participants,
            loading: false,
            roomNameAndUserId
          });
        } else {
          if (token) {
            this.setState({
              callNotReady: false,
              token,
              roomName,
              participants,
              loading: false
            });
          } else {
            this.setState({ callNotReady: true, loading: false, participants });
            setTimeout(() => {
              let diff = moment().diff(this.pollingStart, "second");
              if (diff <= POLLING_DURATION_IN_SECONDS) {
                this.doPollingToJoinCall(roomName);
              } else {
                this.setState({ error: PARTICIPANT_ERROR });
              }
            }, POLLING_DURATION_INTERVAL_IN_MS);
          }
        }
      })
      .catch(this.handleError);
  };

  handleError = error => {
    this.setState({
      loading: false,
      error: error.message || ERROR
    });
  };

  getView = () => {
    let {
      loading,
      token,
      error,
      roomName,
      participants,
      callNotReady,
      provider,
      roomNameAndUserId
    } = this.state;

    if (loading) {
      return (
        <div style={{ margin: "100px auto", width: 100 }}>
          <MPSBarLoader />
        </div>
      );
    }

    if (error) {
      return (
        <div className="d-flex justify-content-center align-items-center my-4">
          <p className="text-danger">{error}</p>
        </div>
      );
    }

    if (callNotReady) {
      return (
        <div className="d-flex flex-column justify-content-center align-items-center my-4">
          <p className="">
            {`Call with ${getOtherParticipantName(
              participants
            )}, waiting for them to join.`}
          </p>
          <div className="my-3">
            <CallWaitTimer />
          </div>
        </div>
      );
    }

    if (provider === "WEBEX") {
      return <PatientWebexMeeting sipAddress={roomName} roomNameAndUserId={roomNameAndUserId}  />;
    }
    if (token) {
      return (
        <VideoComponent
          token={token}
          roomName={roomName}
          participants={participants}
          otherParticipantName={getOtherParticipantName(participants)}
          existing={true}
        />
      );
    }
    return (
      <div className="d-flex justify-content-center">
        <MPSButton color="primary" onClick={this.onStartAnonymousCall}>
          Join Video Call
        </MPSButton>
      </div>
    );
  };

  render() {
    return (
      <div className="app flex-column justify-content-center align-items-center">
        <Container fluid={true}>
          <Row className="justify-content-center">
            <Col md="12" sm={12}>
              {this.getView()}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapstatetoprops = (state, ownProps) => {
  return {
    roomName:
      ownProps.match && ownProps.match.params && ownProps.match.params.roomName
        ? ownProps.match.params.roomName
        : null
  };
};

export default connect(mapstatetoprops)(AnonymousVideoCallContainer);
