export function getInitials(str) {
  if (!str) {
    return "";
  }
  if (str.match(/\b\w/g)) {
    str = str
      .match(/\b\w/g)
      .join("")
      .toUpperCase();
    if (str.length > 3) {
      return str.substring(0, 3);
    }
    return str;
  }
  return str;
}

export function startCase(str) {
  return str.substr(0, 1).toUpperCase() + str.substr(1).toLowerCase();
}
