import React from "react";
import libraryService from "../../../services/contentLibraryService";
import ArticlePreview from "../../../Shared/ContentLibrary/Preview/ArticlePreview";
import MPSBarLoader from "../../../components/MPSBarLoader";

class AnonymousArticleView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (this.props.libraryId) {
      this.setState({ loading: true });
      libraryService
        .loadLibraryItemForSignup(this.props.libraryId)
        .then(response => {
          this.setState({ library: response, loading: false });
        })
        .catch(e => this.setState({ loading: false }));
    }
  }

  render() {
    let { loading, library } = this.state;

    if (loading) {
      return (
        <div style={{ margin: "100px auto", width: 100 }}>
          <MPSBarLoader />
        </div>
      );
    }

    if (!this.props.libraryId || !library) {
      return null;
    }

    return <ArticlePreview article={this.state.library} embed />;
  }
}

export default AnonymousArticleView;
