import {
  Button,
  Col,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  FormGroup
} from "reactstrap";
import React from "react";
import classnames from "classnames";
import Messages from "../../Shared/Messages";
import Text from "../../components/Text";

class StepSetCredentials extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { useEmail: false };
  }

  onUseEmailClicked = () => {
    let old = this.state.useEmail;
    this.setState({ useEmail: !old });
    this.props.onUpdateData("username", !old ? this.props.email : "");
  };

  onUpdateUsername = e => {
    this.props.onUpdateData("username", e.target.value);
  };
  onUpdatePassword = e => {
    this.props.onUpdateData("password", e.target.value);
  };
  onUpdateRepeatPassword = e => {
    this.props.onUpdateData("repeatPassword", e.target.value);
  };

  render() {
    let {
      username,
      password,
      repeatPassword,
      onSubmit,
      error,
      errorUsername,
      errorPassword,
      errorRepeatPassword,
      loading
    } = this.props;

    return (
      <Form onSubmit={onSubmit}>
        <h3 className="text-center mb-3 font-sm-size">
          <Text content={Messages.step_2_creds} />
        </h3>
        <p className="text-muted">
          <Text content={Messages.set_username_password} />
        </p>

        {error && (
          <p className="text-muted text-center">
            <span style={{ color: "red" }}>
              <Text content={Messages.error_un_pwd} />
            </span>
          </p>
        )}

        <FormGroup className="d-flex flex-column">
          <div className="mb-1">
            <Text content={Messages.user_name} />
            <div>
              <small>
                <Text content={Messages.user_name_ht} />
              </small>
            </div>
          </div>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText
                className={classnames(
                  error || errorUsername ? "is-invalid" : ""
                )}
              >
                <i className="fa fa-user" />
              </InputGroupText>
            </InputGroupAddon>

            <Input
              className={classnames(error || errorUsername ? "is-invalid" : "")}
              value={username}
              onChange={this.onUpdateUsername}
              type="text"
              autoCapitalize="none"
              disabled={loading}
            />
            <div className="invalid-feedback">
              <Text content={Messages.set_username} />
            </div>
          </InputGroup>
          {this.props.email && (
            <div className="my-2 d-flex">
              <div>
                <Input
                  type="checkbox"
                  className="ml-0 mr-3"
                  style={{ position: "relative", marginTop: "0px" }}
                  checked={this.state.useEmail}
                  onChange={this.onUseEmailClicked}
                  disabled={loading}
                />
              </div>
              <Text content={Messages.email_as_username} />
            </div>
          )}
        </FormGroup>

        <FormGroup className="d-flex flex-column">
          <div className="mb-1">
            <Text content={Messages.password} />
            <div>
              <small>
                <Text content={Messages.password_ht} />
              </small>
            </div>
          </div>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText
                className={classnames(
                  error || errorPassword ? "is-invalid" : ""
                )}
              >
                <i className="fa fa-lock" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              value={password}
              className={classnames(error || errorPassword ? "is-invalid" : "")}
              onChange={this.onUpdatePassword}
              type="password"
              disabled={loading}
              autoCapitalize="none"
            />
            <div className="invalid-feedback">
              <Text content={Messages.set_valid_password} />
            </div>
          </InputGroup>
        </FormGroup>

        <FormGroup className="d-flex flex-column">
          <div className="mb-1">
            <Text content={Messages.confirm_password} />
          </div>

          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText
                className={classnames(
                  error || errorPassword ? "is-invalid" : ""
                )}
              >
                <i className="fa fa-lock" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              value={repeatPassword}
              className={classnames(
                error || errorRepeatPassword ? "is-invalid" : ""
              )}
              onChange={this.onUpdateRepeatPassword}
              type="password"
              disabled={loading}
              autoCapitalize="none"
            />
            <div className="invalid-feedback">
              <Text content={Messages.passwords_mustbe_same} />
            </div>
          </InputGroup>
        </FormGroup>

        <Row>
          <Col xs="12">
            <div className="text-right">
              <Button
                color="primary"
                size="lg"
                className="px-4"
                disabled={loading}
              >
                <Text content={Messages.create_account} />
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default StepSetCredentials;
