// @flow

import { connect } from "react-redux";
import Login from "./Login";
import {
  onLogin,
  onLoginWithMfa,
  onResetLoading
} from "../../State/actions/user";

const mapDispatchToProps = {
  onLogin,
  onLoginWithMfa,
  onResetLoading
};
const mapStateToProps = (state, ownProps) => ({
  loading: state.user.loading,
  loginError: state.user.loginError,
  invited: ownProps.match ? ownProps.match.params.invited : null,
  facilityName: ownProps.match ? ownProps.match.params.facilityName : null,
  logoutMessage: state.user.logoutMessage,
  errorMessage: state.user.errorMessage,
  mfaRequired: state.user.mfaRequired,
  mfaToken: state.user.mfaToken,
  mfaMechanism: state.user.mfaMechanism
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
