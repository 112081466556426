import { Input } from "reactstrap";
import React from "react";
import MPSButton from "../../components/Buttons/Buttons/MPSButton";
import ModalPopup from "../../components/ModalPopup";
import TermsAndConditions from "./TermsAndConditions";
import PrivacyPolicy from "./PrivacyPolicy";
import Text from "../../components/Text";
import Messages from "../../Shared/Messages";
import I18nProvider from "../../services/I18nProvider";
const intl = I18nProvider.getIntlProvider();

class StepAcceptTerms extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // onShowSpaceTerms = () => {
  //   this.setState({ showSpaceTerms: true });
  // };
  //
  // onHideSpaceTerms = () => {
  //   this.setState({ showSpaceTerms: false });
  // };

  onShowTerms = () => {
    this.setState({ showTerms: true });
  };

  onHideTerms = () => {
    this.setState({ showTerms: false });
  };

  onShowPrivacy = () => {
    this.setState({ showPrivacy: true });
  };

  onHidePrivacy = () => {
    this.setState({ showPrivacy: false });
  };

  onTermsClicked = () => {
    this.props.onUpdateData("termsAccepted", !this.props.termsAccepted);
  };

  getTermsNode() {
    let { mps, facilityInfo } = this.props;
    let termsAndConditionsLibrary = null;
    let privacyPolicyLibrary = null;
    if (facilityInfo) {
      termsAndConditionsLibrary = facilityInfo.termsAndConditionsLibrary;
      privacyPolicyLibrary = facilityInfo.privacyPolicyLibrary;
    }

    if (
      (termsAndConditionsLibrary && privacyPolicyLibrary) ||
      (!termsAndConditionsLibrary && !privacyPolicyLibrary)
    ) {
      return (
        <div style={{ width: "100%" }} className="mx-3">
          <Text
            content={Messages.terms_pp_w_link}
            data={{
              name: mps
                ? "myPatientSpace"
                : intl.formatMessage(Messages.creating_acc),
              terms: str => (
                <MPSButton
                  type="link"
                  className="ml-1"
                  onClick={this.onShowTerms}
                >
                  {str}
                </MPSButton>
              ),
              pp: str => (
                <MPSButton
                  type="link"
                  className="ml-1"
                  onClick={this.onShowPrivacy}
                >
                  {str}
                </MPSButton>
              )
            }}
          />
        </div>
      );
    }

    return (
      <div style={{ width: "100%" }} className="mx-3">
        <Text content={Messages.have_read_accept} />
        {termsAndConditionsLibrary && (
          <>
            <MPSButton type="link" className="ml-1" onClick={this.onShowTerms}>
              <Text content={Messages.terms_conds} />
            </MPSButton>{" "}
          </>
        )}
        {privacyPolicyLibrary && (
          <>
            <MPSButton
              type="link"
              className="ml-1"
              onClick={this.onShowPrivacy}
            >
              <Text content={Messages.privacy_policy} />
            </MPSButton>{" "}
          </>
        )}
        <Text content={Messages.for_text} />{" "}
        {mps ? "myPatientSpace" : <Text content={Messages.creating_acc} />}.
      </div>
    );

    // return (
    //   <div style={{ width: "100%" }}>
    //     I have read and accept the
    //     {termsContent &&
    //       facilityName && (
    //         <span>
    //           <MPSButton
    //             type="link"
    //             className="ml-1"
    //             onClick={this.onShowSpaceTerms}
    //           >
    //             {facilityName + "  terms & conditions"}
    //           </MPSButton>
    //           ,
    //         </span>
    //       )}
    //     <MPSButton type="link" className="ml-1" onClick={this.onShowTerms}>
    //       myPatientSpace terms &amp; conditions
    //     </MPSButton>
    //     <span className="ml-1">and</span>
    //     <MPSButton type="link" className="ml-1" onClick={this.onShowPrivacy}>
    //       privacy policy
    //     </MPSButton>
    //     .
    //   </div>
    // );
  }

  render() {
    let { loading, termsAccepted, facilityInfo, errorTerms } = this.props;

    let termsAndConditionsLibrary = null;
    let privacyPolicyLibrary = null;
    if (facilityInfo) {
      termsAndConditionsLibrary = facilityInfo.termsAndConditionsLibrary;
      privacyPolicyLibrary = facilityInfo.privacyPolicyLibrary;
    }

    return (
      <div>
        <div className="mb-3 d-flex align-items-center">
          <Input
            type="checkbox"
            className={`mx-0 `}
            style={{
              position: "relative",
              marginTop: "0px",
              width: "24px",
              height: "24px",
              outline: errorTerms ? "2px solid red" : ""
            }}
            checked={termsAccepted}
            onChange={this.onTermsClicked}
            disabled={loading}
          />
          {this.getTermsNode()}
        </div>

        {/*{this.state.showSpaceTerms && (*/}
        {/*  <ModalPopup*/}
        {/*    title={facilityName + " Terms and Conditions"}*/}
        {/*    onClose={this.onHideSpaceTerms}*/}
        {/*    className="myp-terms-modal"*/}
        {/*  >*/}
        {/*    <div style={{ height: "calc(100vh - 220px)", overflow: "auto" }}>*/}
        {/*      <TermsContent termsContent={termsContent} />*/}
        {/*    </div>*/}
        {/*    <div className="d-flex justify-content-center mt-2">*/}
        {/*      <a*/}
        {/*        className="btn btn-dark btn-lg"*/}
        {/*        style={{ color: "#fff", width: "200px" }}*/}
        {/*        onClick={this.onHideSpaceTerms}*/}
        {/*      >*/}
        {/*        Close*/}
        {/*      </a>*/}
        {/*    </div>*/}
        {/*  </ModalPopup>*/}
        {/*)}*/}

        {this.state.showTerms && (
          <ModalPopup
            title={
              termsAndConditionsLibrary
                ? intl.formatMessage(Messages.terms_conds_cc)
                : intl.formatMessage(Messages.mps_terms_conds)
            }
            onClose={this.onHideTerms}
            className="myp-terms-modal"
          >
            <div style={{ height: "calc(100vh - 220px)", overflow: "auto" }}>
              <TermsAndConditions
                termsAndConditionsLibrary={termsAndConditionsLibrary}
              />
            </div>
            <div className="d-flex justify-content-center mt-2">
              <a
                className="btn btn-dark btn-lg"
                style={{ color: "#fff", width: "200px" }}
                onClick={this.onHideTerms}
              >
                <Text content={Messages.close} />
              </a>
            </div>
          </ModalPopup>
        )}

        {this.state.showPrivacy && (
          <ModalPopup
            title={intl.formatMessage(Messages.privacy_policy_cc)}
            onClose={this.onHidePrivacy}
            className="myp-terms-modal"
          >
            <div style={{ height: "calc(100vh - 220px)", overflow: "auto" }}>
              <PrivacyPolicy privacyPolicyLibrary={privacyPolicyLibrary} />
            </div>
            <div className="d-flex justify-content-center mt-2">
              <a
                className="btn btn-dark btn-lg"
                style={{ color: "#fff", width: "200px" }}
                onClick={this.onHidePrivacy}
              >
                <Text content={Messages.close} />
              </a>
            </div>
          </ModalPopup>
        )}
      </div>
    );
  }
}

// const TermsContent = ({ termsContent }) => (
//   <div id="placeholders" dangerouslySetInnerHTML={{ __html: termsContent }} />
// );

export default StepAcceptTerms;
