import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import { getAppURL } from "../../config";
import { showLocalizedToaster } from "../../State/actions/toaster";
import Messages from "../../Shared/Messages";
import CopyText from "../Forms/CopyText";

class UnsupportedVideoClients extends Component {
  // copyURL = () => {
  //   let copyText = document.getElementById(`url-text-field`);
  //   copyText.type = "text";
  //   copyText.select();
  //   document.execCommand("copy");
  //   copyText.type = "hidden";
  //   this.props.showToaster({ content: Messages.copied_cp_safari }, "success");
  // };

  render() {
    return (
      <div className="app">
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "calc(100vh - 48px)" }}
        >
          <div className="d-flex flex-column">
            <Container>
              <Row className="justify-content-center">
                <Col md="12" sm={12}>
                  <div className="ob-logos mb-4">
                    <div className="px-3 pb-3">
                      <img
                        alt="myPatientSpace"
                        src="/img/logo-symbol.png"
                        height={32}
                      />
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className="justify-content-center">
                <Col md="12" sm={12}>
                  <div className="font-xl text-center">
                    {/*<a*/}
                    {/*  className="btn btn-info btn-sm"*/}
                    {/*  style={{*/}
                    {/*    // color: "#20a8d8",*/}
                    {/*    fontWeight: 500,*/}
                    {/*    cursor: "pointer",*/}
                    {/*    fontSize: "1.25rem"*/}
                    {/*  }}*/}
                    {/*  onClick={this.copyURL}*/}
                    {/*>*/}
                    Video Call on this browser is not supported.
                    <br />
                    Please copy and paste the URL in Safari browser to join the
                    video call.
                    {/*</a>{" "}*/}
                  </div>

                  <div className="d-flex mt-3 justify-content-center">
                    {/*<input*/}
                    {/*  id="url-text-field"*/}
                    {/*  value={`${getAppURL()}/join-video-call/${*/}
                    {/*    this.props.roomName*/}
                    {/*  }`}*/}
                    {/*  type="hidden"*/}
                    {/*/>*/}

                    <CopyText
                      text={`${getAppURL()}/join-video-call/${
                        this.props.roomName
                      }`}
                      message="Copied to clipboard, please paste into Safari browser."
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    );
  }
}

const actions = {
  showToaster: showLocalizedToaster
};

const mapstatetoprops = (state, ownProps) => {
  return {
    roomName:
      ownProps.match && ownProps.match.params && ownProps.match.params.roomName
        ? ownProps.match.params.roomName
        : null
  };
};

export default connect(
  mapstatetoprops,
  actions
)(UnsupportedVideoClients);
