import React, { PureComponent } from "react";
import { injectIntl } from "react-intl";

class Text extends PureComponent {
  render() {
    let { content, data } = this.props;
    return this.props.intl.formatMessage(content, data);
  }
}

export default injectIntl(Text);
