import React from "react";
import moment from "moment";
import { formatSlotDate } from "../../../utils/utils";
import MPSBarLoader from "../../../components/MPSBarLoader";
import { Alert, Button } from "reactstrap";
import ModalPopup from "../../../components/ModalPopup";
import NewAppointmentConfirmation from "./NewAppointmentConfirmation";
import NewAppointmentConfirmationPage from "./NewAppointmentConfirmationPage";
import MPSSelect from "../../../components/MPSSelect";
import JoinGroupClassIntro from "./JoinGroupClassIntro";
import {
  dayTitleAccessor,
  getAdjacentSlots,
  getMasterDataText,
  getStaffName,
  titleAccessor
} from "../../../StaffApp/Appointments/Utils";
import AppointmentsSlotsCalendar from "../../../components/Calendar/AppointmentsSlotsCalendar";
import Messages from "../../../Shared/Messages";
import I18nProvider from "../../../services/I18nProvider";
import Text from "../../../components/Text";
const intl = I18nProvider.getIntlProvider();

class NewAppointmentCalendar extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onSelectSlot = slot => {
    this.setState({ error: null });
    let actors = slot.actors || [];
    let staff = actors.filter(a => a.referenceType === "STAFF");
    let masterData = actors.filter(a => a.referenceType === "MASTERDATA_KEY");

    let selectedStaffObj = null;
    if (staff.length === 1) {
      selectedStaffObj = staff[0];
    }

    let { slots, schedule } = this.props;
    let { noOfSlotsPerAppointment = 1 } = schedule;

    if (noOfSlotsPerAppointment === 1) {
      this.setState({
        selectedSlots: [slot],
        selectedStaffObj,
        slotMasterData: masterData
      });
    } else {
      let slotDuration = schedule.standardSlotDuration;

      let selectedSlots = getAdjacentSlots(
        slots,
        slot,
        slotDuration,
        noOfSlotsPerAppointment
      );
      if (selectedSlots.length === 0) {
        this.setState({
          selectedSlots: [],
          error: intl.formatMessage(Messages.no_n_slots, {
            noOfSlots: noOfSlotsPerAppointment
          })
        });
      } else {
        this.setState({
          selectedSlots,
          selectedStaffObj,
          slotMasterData: masterData
        });
      }
    }
  };

  slotStyleGetter = (event, start, end, isSelected) => {
    let isFilled =
      this.state.selectedSlots &&
      this.state.selectedSlots.findIndex(s => s.id === event.id) !== -1;
    let style = {
      borderColor: isFilled ? "#265985" : "#468153",
      backgroundColor: isFilled ? "#265985" : "#468153",
      color: "#fff",
      cursor: isFilled ? "default" : "pointer"
    };
    return {
      style: style
    };
  };

  submitSlot = patientDetails => {
    this.props.createAppointment(
      this.state.selectedSlots,
      this.state.selectedStaffObj,
      patientDetails,
      this.state.slotMasterData
    );
    this.closeConfirmation();
  };

  showConfirmation = () => {
    // if (!this.state.selectedStaffObj) {
    //   this.setState({ staffError: true });
    //   return;
    // }
    this.setState({ staffError: false, confirmation: true });
  };

  closeConfirmation = () => {
    this.setState({ confirmation: false });
  };

  onChangeSelectStaff = option => {
    this.setState({
      selectedStaff: option.value,
      selectedStaffObj: option.staffObject
    });
  };

  getNameTitle = () => {
    let { templateId, schedule } = this.props;
    if (!schedule) {
      return null;
    }
    let { publishedLinks, name } = schedule;
    let keys = Object.keys(publishedLinks);

    let title = name;
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];
      if (publishedLinks[key] === templateId) {
        title = key;
        break;
      }
    }

    return title;
  };

  onIntroVisited = () => {
    this.setState({ introVisited: true });
  };

  render() {
    let {
      selectedSlots,
      confirmation,
      selectedStaff,
      staffError,
      introVisited,
      error
    } = this.state;

    let {
      isPhoneMandatory,
      loading,
      schedule = {},
      submitting,
      created,
      slots = [],
      resources = []
    } = this.props;

    if (loading) {
      return (
        <div style={{ margin: "100px auto", width: 100 }}>
          <MPSBarLoader />
        </div>
      );
    }

    let { hospitalLogoImage } = schedule;
    if (created) {
      return (
        <NewAppointmentConfirmationPage
          staffName={
            this.state.selectedStaffObj
              ? getStaffName([this.state.selectedStaffObj])
              : ""
          }
          slot={formatSlotDate(
            this.state.selectedSlots[0].start,
            this.state.selectedSlots[this.state.selectedSlots.length - 1].end
          )}
          appTheme={{ hospitalLogoImage }}
          masterData={
            this.state.selectedSlots[0].actors
              ? this.state.selectedSlots[0].actors.filter(
                  a => a.referenceType === "MASTERDATA_KEY"
                )
              : []
          }
        />
      );
    }

    slots = JSON.parse(JSON.stringify(slots));
    slots = slots.filter(s => moment().isBefore(moment(s.start)));
    slots = slots.map(slot => {
      return { ...slot, start: new Date(slot.start), end: new Date(slot.end) };
    });
    slots.sort((a, b) => a.start - b.start);

    return (
      <div className="app my-4">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center my-2">
            <div>
              <img
                alt="myPatientSpace"
                src="/img/logo-symbol.png"
                height={36}
              />
            </div>
          </div>

          {schedule.description && !introVisited ? (
            <div className="mt-4">
              <JoinGroupClassIntro
                title={schedule.title}
                description={schedule.description}
                onIntroVisited={this.onIntroVisited}
              />
            </div>
          ) : (
            <React.Fragment>
              <h5 className="mb-2 font-lg text-center">
                {this.getNameTitle()}
              </h5>

              {(!selectedSlots || selectedSlots.length === 0) && (
                <div className="d-flex justify-content-center mb-2">
                  <div className="font-lg">
                    <Text content={Messages.select_slot_w_green} />
                  </div>
                </div>
              )}

              {error ? (
                <Alert color="danger">{error}</Alert>
              ) : (
                <Actions
                  selectedSlots={selectedSlots}
                  submitting={submitting}
                  showConfirmation={this.showConfirmation}
                  onChangeSelectStaff={this.onChangeSelectStaff}
                  selectedStaff={selectedStaff}
                  staffError={staffError}
                />
              )}

              <div>
                <div style={{ height: "500px" }}>
                  <AppointmentsSlotsCalendar
                    currentView="work_week"
                    agendaLength={14}
                    onSelectEvent={this.onSelectSlot}
                    events={slots}
                    eventTitleAccessor={titleAccessor}
                    eventTitleDayAccessor={dayTitleAccessor}
                    selectable={false}
                    selectedEvent={selectedSlots}
                    eventStyleGetter={this.slotStyleGetter}
                    views={["month", "week", "work_week", "day"]}
                    resources={resources}
                    loadAppointmentsAndSlots={
                      this.props.loadAppointmentsAndSlots
                    }
                  />
                </div>
              </div>

              {error ? (
                <Alert color="danger">{error}</Alert>
              ) : (
                <Actions
                  selectedSlots={selectedSlots}
                  submitting={submitting}
                  showConfirmation={this.showConfirmation}
                  onChangeSelectStaff={this.onChangeSelectStaff}
                  selectedStaff={selectedStaff}
                  staffError={staffError}
                />
              )}
            </React.Fragment>
          )}
        </div>

        {confirmation && (
          <ModalPopup
            // onClose={this.closeConfirmation}
            title={intl.formatMessage(Messages.confirm_appointment)}
          >
            <NewAppointmentConfirmation
              dateTime={formatSlotDate(
                selectedSlots[0].start,
                selectedSlots[selectedSlots.length - 1].end
              )}
              staffName={
                this.state.selectedStaffObj
                  ? getStaffName([this.state.selectedStaffObj])
                  : ""
              }
              doAction={this.submitSlot}
              cancel={this.closeConfirmation}
              schedule={schedule}
              isPhoneMandatory={isPhoneMandatory}
              masterData={
                this.state.selectedSlots[0].actors
                  ? this.state.selectedSlots[0].actors.filter(
                      a => a.referenceType === "MASTERDATA_KEY"
                    )
                  : []
              }
            />
          </ModalPopup>
        )}
      </div>
    );
  }
}

class Actions extends React.PureComponent {
  render() {
    let {
      selectedSlots,
      submitting,
      showConfirmation,
      onChangeSelectStaff,
      selectedStaff,
      staffError
    } = this.props;

    if (!selectedSlots || selectedSlots.length === 0) {
      return null;
    }
    let actors = selectedSlots[0].actors || [];
    let staff = actors.filter(a => a.referenceType === "STAFF");
    let masterData = actors.filter(a => a.referenceType === "MASTERDATA_KEY");
    return (
      <div>
        {staffError && (
          <p className="text-muted text-center">
            <span style={{ color: "red" }}>
              <Text content={Messages.select_a_staff} />
            </span>
          </p>
        )}

        <div
          className="d-flex flex-column flex-md-row justify-content-between align-items-center my-3"
          style={{
            border: "1px solid #dddfe2",
            borderRadius: "4px",
            padding: "1rem",
            backgroundColor: "#fff"
          }}
        >
          <div
            className="d-flex align-items-center mb-2 mb-md-0"
            style={{ fontWeight: 500, flex: 1 }}
          >
            <div className="text-center">
              <span className="font-lg">
                {formatSlotDate(
                  selectedSlots[0].start,
                  selectedSlots[selectedSlots.length - 1].end
                )}
              </span>
            </div>
          </div>

          <div
            className="d-flex justify-content-between align-items-center"
            style={{ width: "100%", flex: 1 }}
          >
            {staff.length >= 1 && (
              <div className="mx-2 d-flex align-items-center">
                <span className="mr-1">
                  <Text content={Messages.with_w_col} />
                </span>
                {staff.length === 1 && (
                  <span className="font-lg">{getStaffName(staff)}</span>
                )}
                {staff.length > 1 && (
                  <MPSSelect
                    style={{ width: "200px" }}
                    options={staff.map(actor => {
                      return {
                        value: actor.staffId,
                        label: actor.displayHumanName.text,
                        staffObject: actor
                      };
                    })}
                    onChange={onChangeSelectStaff}
                    value={selectedStaff}
                    clearable={false}
                  />
                )}
              </div>
            )}

            {masterData.length >= 1 && (
              <div className="d-flex align-items-center">
                <span>
                  {" "}
                  <Text content={Messages.for_text} />{" "}
                </span>
                <span className="font-lg ml-1">
                  {getMasterDataText(masterData)}
                </span>
              </div>
            )}

            {!submitting && (
              <Button color="primary" size="md" onClick={showConfirmation}>
                <Text content={Messages.book} />{" "}
                <span className="text-hidden-xs">
                  <Text content={Messages.appointment} />
                </span>
              </Button>
            )}
            {submitting && (
              <div className="d-flex align-items-center" style={{ width: 100 }}>
                <MPSBarLoader />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default NewAppointmentCalendar;
