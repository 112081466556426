import UserOnboardingChallengeInvite from "../UserOnboarding/UserOnboardingChallengeInvite";
import React from "react";
import moment from "moment";
import { getOnboardingAttributeValue } from "../UserOnboarding/Utils";
import Messages from "../../Shared/Messages";
import I18nProvider from "../../services/I18nProvider";
import { Button, Col, Row } from "reactstrap";
import Text from "../../components/Text";
import userService from "../../services/userService";
import SignupExistingPatient from "./SignupExistingPatient";
const intl = I18nProvider.getIntlProvider();

class SignupPatientCheck extends React.PureComponent {
  constructor(props) {
    super(props);
    let challengeFields = [];
    let { facilityInfo } = props;
    if (facilityInfo && facilityInfo.patientIdentificationAttributes) {
      let cf = facilityInfo.patientIdentificationAttributes;
      cf.forEach(attribute => {
        attribute.mandatory = true;
      });
      challengeFields = cf;
    }
    this.state = { challengeFields };
  }

  onUpdateData = (name, value) => {
    this.setState({ [name]: value });
  };

  validate = () => {
    const { challengeFields: fields } = this.state;

    let formValid = true;
    for (let i = 0; i < fields.length; i++) {
      let attribute = { ...fields[i] };
      let value = getOnboardingAttributeValue(attribute);
      if (attribute.error) {
        formValid = false;
      } else {
        if (!value) {
          attribute.error = intl.formatMessage(Messages.var_is_required, {
            title: attribute.title
          });
          formValid = false;
        }
      }
      fields[i] = { ...attribute };
    }

    this.setState({ challengeFields: [...fields] });
    return formValid;
  };

  onSubmit = e => {
    e.preventDefault();
    this.setState({ code: null });
    let valid = this.validate();
    if (!valid) {
      return;
    }

    let challengeFields = JSON.parse(
      JSON.stringify(this.state.challengeFields)
    );
    challengeFields.forEach(attribute => {
      let aType = attribute.type || attribute.attributeType;
      let value = getOnboardingAttributeValue(attribute);

      if (value) {
        if (aType === "DATE") {
          attribute.value.valueString = moment(value, "DD/MM/YYYY").format(
            "MM/DD/YYYY"
          );
          attribute.value.valueDate = null;
        } else if (attribute.attributeType === "OBSERVATION_DATE") {
          attribute.value.valueObservation.value.valueString = moment(
            value,
            "DD/MM/YYYY"
          ).format("MM/DD/YYYY");
          attribute.value.valueObservation.value.valueDate = null;
        }
      }

      if (
        attribute.value &&
        (attribute.dataField === "PatientContactPhone" ||
          attribute.dataField === "PatientContactTel")
      ) {
        let newAV = attribute.value;
        let phone = newAV.valueContactPoints[0].value;

        if (phone) {
          phone = phone.replace(/ /g, "");
          if (phone.charAt(0) === "0" || phone.charAt(0) === "+") {
            phone = phone.slice(1, phone.length);
          }
          newAV.valueContactPoints[0].value = phone;
        }
      }
    });

    userService
      .identifySelfSignupPatient(this.props.facilityCode, challengeFields)
      .then(response => {
        if (response && response[0]) {
          let { code, patientId } = response[0];
          if (
            code === "USER_IN_SYSTEM_NOT_ONBOARDED" ||
            code === "USER_HAS_USERNAME" ||
            code === "USER_HAS_USERNAME_NOT_ACTIVATED"
          ) {
            this.setState({ code, patientId });
          } else {
            this.props.setIdentificationFields(
              challengeFields,
              this.props.incrementStep
            );
          }
        }
        console.log(response);
      });

    //todo make call to backend api and check if the patient is alredy there
    //if not there increment step by 2 so that it goes to self sign up form. Exclude fields which are present here.
    // if there increment step by 1 and pass in the details.

    // this.props.incrementStep(2);
    // this.props.incrementStep();
  };

  render() {
    let { challengeFields, error, code, patientId } = this.state;
    let { facilityInfo, facilityCode } = this.props;
    if (!!code) {
      return (
        <div>
          <SignupExistingPatient
            incrementStep={this.props.incrementStep}
            facilityCode={facilityCode}
            facilityInfo={facilityInfo}
            code={code}
            patientId={patientId}
            goBack={() => {
              this.setState({ code: null });
            }}
          />
        </div>
      );
    }
    return (
      <div>
        <Row>
          <Col xs="12">
            <h5 className="text-center mb-2">
              {facilityInfo.patientIdentificationTitle || "Enter Your Details"}
            </h5>

            {facilityInfo.patientIdentificationText && (
              <div className="my-2">
                {facilityInfo.patientIdentificationText}
              </div>
            )}

            <UserOnboardingChallengeInvite
              onUpdateData={this.onUpdateData}
              challengeFields={challengeFields}
              defaultTelRegionOptions={facilityInfo.defaultTelRegionOptions}
              referrer={"DATA_ENTRY"}
            />
            <div className="d-flex justify-content-between">
              <div>
                <Button
                  color="secondary"
                  className="px-4"
                  block
                  onClick={this.props.decrementStep}
                >
                  <Text content={Messages.back} />
                </Button>
              </div>
              <div>
                <Button
                  color="primary"
                  className="px-4"
                  block
                  onClick={this.onSubmit}
                >
                  <Text content={Messages.next} />
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default SignupPatientCheck;
