import { createIntl, createIntlCache } from "react-intl";
import { store } from "../State/store/configureStore";
import moment from "moment";
import StorageService from "./storageService";
import { ONBOARDING_LOCALE } from "./userService";

const cache = createIntlCache();
let intl = null;

const LOCALES = {
  ar_AE: ["ar", "ar_ae"],
  es_LA: [
    "es_la",
    "es_419",
    "es_ar",
    "es_bo",
    "es_cl",
    "es_co",
    "es_cr",
    "es_do",
    "es_ec",
    "es_sv",
    "es_gt",
    "es_hn",
    "es_mx",
    "es_ni",
    "es_pa",
    "es_py",
    "es_pe",
    "es_pr",
    "es_uy",
    "es_ve"
  ],
  es_ES: ["es", "es_ES", "es_es"],
  fr_FR: ["fr", "fr_fr"],
  iw_IL: ["iw_IL", "iw_il", "he"],
  pt_BR: ["pt_BR", "pt_br"],
  pt_PT: ["pt_PT", "pt_pt"]
};

String.prototype.replaceAt = function(index, replacement) {
  return (
    this.substr(0, index) +
    replacement +
    this.substr(index + replacement.length)
  );
};

export function getBrowserLocale() {
  let browLocale = navigator.language;
  browLocale = browLocale.replace(/-/g, "_");
  return browLocale;
}

export function getLocaleMessages(messages, locale) {
  let endUserLabel = store.getState().user.data.endUserLabel;

  if (
    locale === "en" &&
    endUserLabel &&
    endUserLabel.toLowerCase() !== "patient"
  ) {
    endUserLabel = endUserLabel.toLowerCase();
    let allSmallEL = endUserLabel;
    let camelEL = endUserLabel.replaceAt(
      0,
      endUserLabel.charAt(0).toUpperCase()
    );

    Object.keys(messages).forEach(key => {
      let message = messages[key];
      if (message.toLowerCase().indexOf("patient") !== -1) {
        let ch = message.charAt(message.toLowerCase().indexOf("patient"));
        if (ch === "P") {
          messages[key] = message.replace(/Patient/gi, camelEL);
        } else if (ch === "p") {
          messages[key] = message.replace(/patient/gi, allSmallEL);
        }
      }
    });
  }

  return messages;
}

function getProvider() {
  if (!intl) {
    let locale = getLocale();
    createProvider(locale);
  }
  return intl;
}

function createProvider(locale) {
  let messages = {};
  try {
    messages = require(`./../Locales/${locale}.json`);
  } catch (e) {
    // console.log(e);
    //fallback to English
  }
  messages = getLocaleMessages(messages, locale);
  locale = locale.replace(/_/g, "-");
  intl = createIntl(
    {
      locale,
      messages
    },
    cache
  );
  return intl;
}

function resetProvider() {
  intl = null;
}

function loadMomentLocale(locale) {
  let mLocale = locale.split("_")[0];
  if (mLocale === "iw") {
    mLocale = "he";
  }
  if (mLocale !== "en") {
    moment.locale(mLocale);
    try {
      require(`moment/locale/${mLocale}`);
    } catch (e) {
      // console.log(e);
      //fallback to English
    }
  }
}

function getLocale() {
  let locale =
    store.getState().user.data.preferredLocale ||
    StorageService.getFromSessionStorage(ONBOARDING_LOCALE);
  if (locale) {
    return locale;
  }

  let browLocale = getBrowserLocale();
  browLocale = browLocale.toLowerCase();
  let keys = Object.keys(LOCALES);
  for (let i = 0; i < keys.length; i++) {
    let key = keys[i];
    let values = LOCALES[key];
    if (values.indexOf(browLocale) !== -1) {
      return key;
    }
  }
  return "en_US";
}

export function isRTLLocale() {
  let locale = getLocale();
  let rtls = ["ar_AE", "he_IL", "iw_IL"];
  return rtls.indexOf(locale) !== -1;
}

export default {
  getLocale: getLocale,
  createIntlProvider: createProvider,
  getIntlProvider: getProvider,
  resetIntlProvider: resetProvider,
  loadMomentLocale: loadMomentLocale,
  isRTLLocale
};
