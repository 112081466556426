import React from "react";
import { showLocalizedToaster } from "../../State/actions/toaster";
import { connect } from "react-redux";
import PatientAppServices from "../../services/PatientAppServices";
import { parseURLQueryParams } from "../../utils/utils";
import GroupClassAppointmentCalendar from "../components/Appointments/GroupClassAppointmentCalendar";

class GroupClassAppointmentContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.setState({ loading: true });
    PatientAppServices.getAppointmentScheduleAnonymous(
      this.props.scheduleId,
      this.props.patientId,
      this.props.userId
    )
      .then(schedule => {
        this.setState({ schedule, loading: false });
      })
      .catch(e => {
        this.setState({ loading: false });
      });

    this.setState({ classesLoading: true });
    PatientAppServices.getGroupCallAppointments(
      this.props.userId,
      this.props.patientId,
      this.props.scheduleId
    )
      .then(appointments => {
        this.setState({
          appointments: [...appointments],
          classesLoading: false
        });
      })
      .catch(e => this.setState({ classesLoading: false }));
  }

  joinAppointment = appointment => {
    let appointmentData = {
      id: appointment.id
    };

    this.setState({ submitting: true });
    PatientAppServices.joinGroupClass(
      appointmentData,
      this.props.userId,
      this.props.patientId
    )
      .then(response => {
        this.setState({ created: true, submitting: false });
      })
      .catch(e => {
        this.setState({ submitting: false });
      });
  };

  render() {
    let {
      loading,
      schedule,
      appointments,
      submitting,
      created,
      classesLoading
    } = this.state;

    loading = loading || classesLoading;

    return (
      <GroupClassAppointmentCalendar
        schedule={schedule}
        history={this.props.history}
        loading={loading}
        appointments={appointments}
        submitting={submitting}
        joinAppointment={this.joinAppointment}
        created={created}
      />
    );
  }
}

const mapDispatchToProps = {
  showToaster: showLocalizedToaster
};

const mapStateToProps = (state, ownProps) => {
  let query = parseURLQueryParams(window.location.search);

  return {
    scheduleId: ownProps.match.params.scheduleId,
    templateId: ownProps.match ? ownProps.match.params.templateId : null,
    patientId: query.pid,
    userId: query.uid
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupClassAppointmentContainer);
