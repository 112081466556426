import React from "react";
import MPSCalendar from "../../../components/Calendar";
import moment from "moment";
import { formatSlotDate } from "../../../utils/utils";
import MPSBarLoader from "../../../components/MPSBarLoader";
import { Button } from "reactstrap";
import ModalPopup from "../../../components/ModalPopup";
import JoinGroupClassConfirmation from "./JoinGroupClassConfirmation";
import JoinGroupCallConfirmationPage from "./JoinGroupCallConfirmationPage";
import JoinGroupClassIntro from "./JoinGroupClassIntro";
import { getNoOfFreeSlots } from "../../../Shared/Utils";
import Text from "../../../components/Text";
import Messages from "../../../Shared/Messages";
import I18nProvider from "../../../services/I18nProvider";
const intl = I18nProvider.getIntlProvider();

function getStaffName(actors) {
  return actors
    .filter(a => a.referenceType === "STAFF")
    .map(a => (a.displayHumanName ? a.displayHumanName.text : ""))
    .join(", ");
}

function titleAccessor(e) {
  // return `${moment(e.start).format("HH:mm")} - ${moment(e.end).format(
  //   "HH:mm"
  // )}/${e.slotStatus.toLowerCase()}`;
  let freeSpaces = getNoOfFreeSlots(e);
  return `${e.appointmentDescription} ${
    freeSpaces === 1 ? `1 space free` : `${freeSpaces} spaces free`
  }`;
}

function dayTitleAccessor(e) {
  // return `${e.slotStatus}`;

  let participants = e.participants || [];
  let freeSpaces = getNoOfFreeSlots(e);
  return `${e.appointmentDescription + " | " + getStaffName(participants)} ${
    freeSpaces === 1 ? "1 space free" : `${freeSpaces} spaces free`
  }`;
}

class GroupClassAppointmentCalendar extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onSelectSlot = slot => {
    this.setState({ selectedAppointment: slot });
  };

  slotStyleGetter = (event, start, end, isSelected) => {
    let backgroundColor = "#" + event.hexColor;
    let isFilled =
      this.state.selectedAppointment &&
      this.state.selectedAppointment.id === event.id;
    let style = {
      borderColor: isFilled ? "#265985" : "#468153",
      backgroundColor: isFilled ? "#265985" : "#468153",
      color: "#fff",
      cursor: isFilled ? "default" : "pointer"
    };
    return {
      style: style
    };
  };

  joinAppointment = () => {
    this.props.joinAppointment(this.state.selectedAppointment);
    this.closeConfirmation();
  };

  showConfirmation = () => {
    this.setState({ confirmation: true });
  };

  closeConfirmation = () => {
    this.setState({ confirmation: false });
  };

  onIntroVisited = () => {
    this.setState({ introVisited: true });
  };

  render() {
    let { selectedAppointment, confirmation, introVisited } = this.state;

    let {
      loading,
      schedule = {},
      appointments = [],
      submitting,
      created
    } = this.props;

    if (loading) {
      return (
        <div style={{ margin: "100px auto", width: 100 }}>
          <MPSBarLoader />
        </div>
      );
    }

    let { hospitalLogoImage } = schedule;
    if (created) {
      return (
        <JoinGroupCallConfirmationPage
          staffName={getStaffName(this.state.selectedAppointment.participants)}
          slot={formatSlotDate(
            this.state.selectedAppointment.start,
            this.state.selectedAppointment.end
          )}
          description={this.state.selectedAppointment.appointmentDescription}
          appTheme={{ hospitalLogoImage }}
        />
      );
    }

    appointments = JSON.parse(JSON.stringify(appointments));
    appointments = appointments.filter(s => moment().isBefore(moment(s.start)));
    appointments = appointments.map(appointment => {
      return {
        ...appointment,
        start: new Date(appointment.start),
        end: new Date(appointment.end)
      };
    });
    appointments.sort((a, b) => a.start - b.start);

    let firstAppointment = appointments[0];
    let currentDate =
      firstAppointment && firstAppointment.start
        ? moment(firstAppointment.start).toDate()
        : null;

    return (
      <div className="app my-4">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center my-2">
            <div>
              <img
                alt="myPatientSpace"
                src="/img/logo-symbol.png"
                height={36}
              />
            </div>
          </div>

          {schedule.description && !introVisited ? (
            <div className="mt-4">
              <JoinGroupClassIntro
                title={schedule.title || "Group Class"}
                description={schedule.description}
                onIntroVisited={this.onIntroVisited}
              />
            </div>
          ) : (
            <React.Fragment>
              <h5 className="mb-2 font-lg text-center">
                {schedule.title || "Group Class"}
              </h5>

              {!selectedAppointment && (
                <div className="d-flex justify-content-center mb-2">
                  <div className="font-lg">
                    <Text content={Messages.select_class_w_green} />
                  </div>
                </div>
              )}

              <Actions
                selectedAppointment={selectedAppointment}
                submitting={submitting}
                showConfirmation={this.showConfirmation}
              />

              <div>
                <div style={{ height: "500px" }}>
                  <MPSCalendar
                    currentView="work_week"
                    agendaLength={14}
                    onSelectEvent={this.onSelectSlot}
                    events={appointments}
                    eventTitleAccessor={titleAccessor}
                    eventTitleDayAccessor={dayTitleAccessor}
                    selectable={false}
                    selectedEvent={selectedAppointment}
                    eventStyleGetter={this.slotStyleGetter}
                    views={["month", "week", "work_week", "day"]}
                    currentDate={currentDate}
                  />
                </div>
              </div>

              <Actions
                selectedAppointment={selectedAppointment}
                submitting={submitting}
                showConfirmation={this.showConfirmation}
              />
            </React.Fragment>
          )}
        </div>

        {confirmation && (
          <ModalPopup
            onClose={this.closeConfirmation}
            title={intl.formatMessage(Messages.confirm_appointment)}
          >
            <JoinGroupClassConfirmation
              dateTime={formatSlotDate(
                this.state.selectedAppointment.start,
                this.state.selectedAppointment.end
              )}
              description={selectedAppointment.appointmentDescription}
              staffName={getStaffName(
                this.state.selectedAppointment.participants
              )}
              doAction={this.joinAppointment}
              cancel={this.closeConfirmation}
            />
          </ModalPopup>
        )}

        {/*{selectedAppointment && (*/}
        {/*  <ModalPopup*/}
        {/*    title={"New Appointment"}*/}
        {/*    onClose={this.hideAppointmentModal}*/}
        {/*  >*/}
        {/*    <AppointmentForm*/}
        {/*      slot={selectedAppointment}*/}
        {/*      submitting={this.props.submitting}*/}
        {/*      submit={this.createAppointment}*/}
        {/*    />*/}
        {/*  </ModalPopup>*/}
        {/*)}*/}
      </div>
    );
  }
}

function Actions({ selectedAppointment, submitting, showConfirmation }) {
  if (!selectedAppointment) {
    return null;
  }
  return (
    <div
      className="d-flex justify-content-between align-items-center my-2"
      style={{
        border: "1px solid #dddfe2",
        borderRadius: "4px",
        padding: "1.2rem",
        backgroundColor: "#fff"
      }}
    >
      {selectedAppointment && (
        <div className="text-center mr-2" style={{ fontWeight: 500 }}>
          <span
            className="d-none d-md-inline
           font-weight-normal"
          >
            <Text content={Messages.class_on} />{" "}
          </span>
          <span className="font-md-lg">
            {formatSlotDate(selectedAppointment.start, selectedAppointment.end)}
            {` (${
              getNoOfFreeSlots(selectedAppointment) === 1
                ? intl.formatMessage(Messages.free_sp_one)
                : intl.formatMessage(Messages.free_sp_count, {
                    count: getNoOfFreeSlots(selectedAppointment)
                  })
            })`}
          </span>{" "}
          {/*with{" "}*/}
          {/*<span className="font-lg">*/}
          {/*  {getStaffName(selectedAppointment.participants)}*/}
          {/*</span>*/}
        </div>
      )}

      {selectedAppointment && (
        <div>
          {!submitting && (
            <Button color="primary" size="md" onClick={showConfirmation}>
              <Text content={Messages.join} />{" "}
              <span className="text-hidden-xs">
                <Text content={Messages.class} />
              </span>
            </Button>
          )}

          {submitting && (
            <div className="d-flex align-items-center" style={{ width: 100 }}>
              <MPSBarLoader />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default GroupClassAppointmentCalendar;
