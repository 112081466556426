import React, { Component } from "react";
import MPSBarLoader from "../../../components/MPSBarLoader";
import MPSButton from "../../../components/Buttons/Buttons/MPSButton";
import SurveyGroupView from "./SurveyGroupView";
import ModalPopup from "../../../components/ModalPopup";
import history from "../../../history";
import {
  validateQuestion,
  getSurveyDataReadyForSubmission,
  setDataInGroup,
  getReferenceRanges,
} from "./Utils";
import Text from "../../../components/Text";
import Messages from "../../Messages";
import I18nProvider from "../../../services/I18nProvider";
const intl = I18nProvider.getIntlProvider();

export function setInitialValueToValue(survey) {
  if (!survey) {
    return;
  }
  let groups = survey.items;
  groups.forEach((group) => {
    let questions = group.items;
    questions.forEach((question) => {
      let answers = question.answers || [];
      if (question.initialValue) {
        answers[0] = question.initialValue;
      } else {
        let min = question.scale ? question.scale.minimum : null;
        if (question.type === "CHOICE_WITH_SCALE" && (min || min == 0)) {
          answers[0] = {
            valueInteger: min,
            score: min,
          };
        }
      }
      question.answers = answers;
    });
  });
  return survey;
}

class SurveyRenderer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      survey: this.props.survey,
      currentGroup: 0,
      invalidAnswer: false,
      invalidField: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ survey: nextProps.survey });
  }

  setData = (gIndex, qIndex, value) => {
    this.setState({
      survey: {
        ...this.state.survey,
        items: setDataInGroup(this.state.survey.items, gIndex, qIndex, value),
      },
    });
  };
  setRanges = (rMin, rMax) => {
    this.setState({ refRangeMin: rMin, refRangeMax: rMax });
  };

  startSubmitting = () => {
    this.setState({ error: false });

    let valid = this.validateQuestions();
    if (valid) {
      if (this.props.embedded) {
        this.submitAnswers();
      } else {
        this.setState({ submitConfirmation: true });
      }
    } else {
      this.setState({ invalidAnswer: true });
    }
  };

  closeSubmitting = () => {
    this.setState({ submitConfirmation: false });
  };

  submitAnswers = () => {
    let { survey } = this.state;
    getSurveyDataReadyForSubmission(survey);
    this.props
      .submitAnswers({
        referencedSurveyId: survey.id,
        items: survey.items,
      })
      .then((response) => {
        this.closeSubmitting();
      });
  };

  goToPrevGroup = () => {
    this.setState({ currentGroup: this.state.currentGroup - 1 });
  };

  goToNextGroup = () => {
    let valid = this.validateQuestions();
    if (valid) {
      this.setState({
        currentGroup: this.state.currentGroup + 1,
        error: false,
      });
    }
  };

  validateQuestions = () => {
    this.setState({ error: null, pefError: null, fevError: null });

    let currentGroup = this.state.currentGroup;
    let { survey } = this.state;
    let groups = survey.items;
    let group = { ...groups[currentGroup] };
    let questions = [...group.items];
    let valid = true;
    let invalidQ = "";
    for (let i = 0; i < questions.length; i++) {
      let question = questions[i];
      valid = valid && validateQuestion(survey, group, question, i, this);
      if (!valid && Object.hasOwn(question, "answers")) {
        const [refMin, refMax] = getReferenceRanges(survey, group, question);
        if (
          (question.type === "INTEGER" || question.type === "DECIMAL") &&
          refMin !== Number.MAX_VALUE
        ) {
          this.setRanges(refMin, refMax);
        } else {
          this.setRanges(null, null);
        }
        this.setState({ invalidAnswer: true });
        this.setState({ invalidField: question.text });
        return false;
      } else if (!valid) {
        invalidQ = question.text;
      }
    }
    this.setState({ invalidAnswer: false });
    this.setState({ invalidField: invalidQ });
    return valid;
  };

  cancel = () => {
    history.goBack();
  };

  render() {
    let { loading, submitting, embedded, popUp } = this.props;
    let {
      survey,
      currentGroup,
      submitConfirmation,
      error,
      pefError,
      fevError,
    } = this.state;

    if (loading) {
      return (
        <div style={{ margin: "100px auto", width: 100 }}>
          <MPSBarLoader />
        </div>
      );
    }

    if (!survey) {
      return null;
    }

    let groups = survey.items;
    let noOfGroups = groups.length;

    return (
      <div>
        {/*<h5 className="my-2 mb-4">Complete Survey</h5>*/}
        {!embedded && !popUp && (
          <MPSButton type="link" onClick={this.cancel} className="text-right">
            <Text content={Messages.cancel} />
          </MPSButton>
        )}
        {embedded ? (
          <div
            style={{
              fontWeight: 600,
              fontSize: "0.9rem",
            }}
            className="text-center my-2 mb-4"
          >
            {survey.title}
          </div>
        ) : (
          <h5 className="text-center mb-2">{survey.title}</h5>
        )}
        <div className="row no-gutters">
          <div
            className={
              embedded
                ? "col-md-12 col-sm-12"
                : "col-md-8 offset-md-2 col-sm-12"
            }
          >
            {/* {this.state.invalidAnswer ? (
              <p style={{ color: "red" }}>
                Please enter a valid answer for {this.state.invalidField}
              </p>
            ) : null} */}
            <p
              style={{
                color: this.state.invalidAnswer ? "red" : "white",
                opacity: this.state.invalidAnswer ? "100%" : "0%",
              }}
            >
              Please enter a valid answer for {this.state.invalidField}{" "}
              {this.state.refRangeMin !== null &&
                `(${this.state.refRangeMin} - 
                  ${this.state.refRangeMax})`}
            </p>
            <div>
              <SurveyGroupView
                group={groups[currentGroup]}
                gIndex={currentGroup}
                setData={this.setData}
                embedded={embedded}
                validateQuestions={this.validateQuestions}
              />
            </div>

            <div className="text-center my-1">
              {error && (
                <div style={{ color: "red" }}>
                  <Text content={Messages.answer_mandatory_qs} />
                </div>
              )}

              {pefError && (
                <div style={{ color: "red" }}>
                  <Text content={Messages.value_bw_100and800} />
                </div>
              )}

              {fevError && (
                <div style={{ color: "red" }}>
                  <Text content={Messages.value_bw_1and6} />
                </div>
              )}
            </div>

            {/* {this.state.invalidAnswer ? (
              <p style={{ color: "red" }}>
                Please enter a valid answer for {this.state.invalidField}
              </p>
            ) : (
              <p> </p>
            )} */}
            <p
              style={{
                color: this.state.invalidAnswer ? "red" : "white",
                opacity: this.state.invalidAnswer ? "100%" : "0%",
              }}
            >
              Please enter a valid answer for {this.state.invalidField}
            </p>
            <div className="d-flex justify-content-between align-items-center my-2">
              {currentGroup > 0 && (
                <MPSButton
                  color="primary"
                  size={"md"}
                  type="block"
                  className={`text-capitalize mr-2 ${
                    embedded ? "" : "font-weight-bold"
                  }`}
                  onClick={this.goToPrevGroup}
                >
                  <Text content={Messages.back} />
                </MPSButton>
              )}

              {currentGroup === 0 && <div />}

              <div>
                {!submitting && (
                  <MPSButton
                    color="primary"
                    size={
                      currentGroup === noOfGroups - 1
                        ? embedded
                          ? "md"
                          : "lg"
                        : "md"
                    }
                    className={`text-capitalize ${
                      embedded ? "" : "font-weight-bold"
                    }`}
                    onClick={
                      currentGroup === noOfGroups - 1
                        ? this.startSubmitting
                        : this.goToNextGroup
                    }
                  >
                    {currentGroup === noOfGroups - 1 ? (
                      <Text content={Messages.submit_assessment} />
                    ) : (
                      <Text content={Messages.next} />
                    )}
                  </MPSButton>
                )}
                {submitting && (
                  <div className="d-flex" style={{ width: 50 }}>
                    <MPSBarLoader />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {submitConfirmation && (
          <ModalPopup
            title={intl.formatMessage(Messages.submit_survey_q)}
            onClose={this.closeSubmitting}
          >
            <p>
              <Text content={Messages.sure_submit_survey} />
            </p>

            <div className="d-flex justify-content-end align-items-center">
              <a onClick={this.closeSubmitting} className="text-cancel">
                <Text content={Messages.cancel} />
              </a>

              <div>
                {!submitting && (
                  <MPSButton
                    color="primary"
                    className="text-capitalize font-weight-bold"
                    onClick={this.submitAnswers}
                  >
                    <Text content={Messages.submit} />
                  </MPSButton>
                )}

                {submitting && (
                  <div className="d-flex" style={{ width: 50 }}>
                    <MPSBarLoader />
                  </div>
                )}
              </div>
            </div>
          </ModalPopup>
        )}
      </div>
    );
  }
}

export default SurveyRenderer;
