import React, { PureComponent } from "react";
import { Button, Col, Row } from "reactstrap";
import StepAcceptTerms from "../Onboarding/StepAcceptTerms";
import Messages from "../../Shared/Messages";
import Text from "../../components/Text";

class SignupConsent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onUpdateTermsAccepted = (name, value) => {
    this.setState({ [name]: value });
  };

  onNext = () => {
    if (!this.state.termsAccepted) {
      this.setState({ errorTerms: true });
    } else {
      this.setState({ errorTerms: false });

      let { facilityInfo } = this.props;
      let { patientIdentificationAttributes = [] } = facilityInfo;
      if (patientIdentificationAttributes.length >= 1) {
        this.props.incrementStep(1);
      } else {
        this.props.incrementStep(2);
      }
    }
  };

  render() {
    let { facilityInfo } = this.props;
    let { termsAccepted, errorTerms } = this.state;
    let { consentText } = facilityInfo;
    return (
      <div>
        <Row>
          <Col xs="12">
            <div className="my-3">
              <details>
                <summary className="my-3">
                  <Text content={Messages.dp_consent} />
                </summary>
                <div dangerouslySetInnerHTML={{ __html: consentText }} />
              </details>
            </div>

            <div>
              <StepAcceptTerms
                onUpdateData={this.onUpdateTermsAccepted}
                facilityInfo={this.props.facilityInfo}
                termsAccepted={termsAccepted}
              />
              {errorTerms && (
                <p className="text-muted">
                  <span style={{ color: "red" }}>
                    <Text content={Messages.accept_pp_tc} />
                  </span>
                </p>
              )}
            </div>

            <div className="d-flex justify-content-between">
              <div>
                <Button
                  color="secondary"
                  className="px-4"
                  block
                  onClick={this.props.decrementStep}
                >
                  <Text content={Messages.back} />
                </Button>
              </div>
              <div>
                <Button
                  color="primary"
                  className="px-4"
                  block
                  onClick={this.onNext}
                >
                  <Text content={Messages.next} />
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default SignupConsent;
