import React from "react";

export default () => (
  <svg width="11px" height="11px" viewBox="0 0 11 11" version="1.1">
    <g
      id="4.0-Home-&amp;-Statuss"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="4.1.6-Statuslist-white"
        transform="translate(-10.000000, -143.000000)"
        fill="#09D6C8"
      >
        <g id="Group" transform="translate(5.000000, 138.000000)">
          <g id="camera-square-57" transform="translate(5.000000, 5.000000)">
            <path
              d="M9.53333333,11 L1.46666667,11 C0.656333333,11 0,10.3436667 0,9.53333333 L0,1.46666667 C0,0.656333333 0.656333333,0 1.46666667,0 L9.53333333,0 C10.3436667,0 11,0.656333333 11,1.46666667 L11,9.53333333 C11,10.3436667 10.3436667,11 9.53333333,11 Z M5.5,8.06666667 C6.91753086,8.06666667 8.06666667,6.91753086 8.06666667,5.5 C8.06666667,4.08246914 6.91753086,2.93333333 5.5,2.93333333 C4.08246914,2.93333333 2.93333333,4.08246914 2.93333333,5.5 C2.93333333,6.91753086 4.08246914,8.06666667 5.5,8.06666667 Z M1.83333333,2.75 L2.93333333,2.75 C3.23708995,2.75 3.48333333,2.50375661 3.48333333,2.2 C3.48333333,1.89624339 3.23708995,1.65 2.93333333,1.65 L1.83333333,1.65 C1.52957672,1.65 1.28333333,1.89624339 1.28333333,2.2 C1.28333333,2.50375661 1.52957672,2.75 1.83333333,2.75 Z"
              id="Combined-Shape"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
