import { Button, Card, CardBody } from "reactstrap";
import React from "react";
import NewAppointmentConsent from "./NewAppointmentConsent";
import NewAppointmentPhoneForm from "./NewAppointmentPhoneForm";
import { getMasterDataText } from "../../../StaffApp/Appointments/Utils";
import Text from "../../../components/Text";
import Messages from "../../../Shared/Messages";

class NewAppointmentConfirmation extends React.PureComponent {
  confirmAppointment = () => {
    let { schedule } = this.props;
    if (schedule.consentRequired) {
      let details = this.consentRef.getConsentInfo();
      if (details) {
        this.props.doAction(details);
      }
    } else {
      if (this.props.isPhoneMandatory) {
        let details = this.phoneRef.getPhoneInfo();
        if (details) {
          this.props.doAction(details);
        }
      } else {
        this.props.doAction();
      }
    }
  };

  render() {
    let { cancel, dateTime, staffName, schedule, masterData } = this.props;

    return (
      <div>
        <div className="row">
          <div className="col-12">
            <Card>
              <CardBody>
                <div className="mb-2">
                  <Text content={Messages.scheduling_appt_for} />
                </div>
                <div>
                  <span style={{ fontWeight: 500 }} className="font-lg">
                    {dateTime}
                  </span>

                  {staffName && (
                    <>
                      {" "}
                      <Text content={Messages.with_text} />{" "}
                      <span style={{ fontWeight: 500 }} className="font-lg">
                        {staffName}
                      </span>
                    </>
                  )}
                  {masterData.length >= 1 && (
                    <>
                      <span>
                        <Text content={Messages.for_text} />{" "}
                      </span>
                      <span style={{ fontWeight: 500 }} className="font-lg">
                        {getMasterDataText(masterData)}
                      </span>
                    </>
                  )}
                </div>

                {schedule.consentRequired ? (
                  <NewAppointmentConsent
                    isPhoneMandatory={this.props.isPhoneMandatory}
                    schedule={schedule}
                    ref={ref => {
                      this.consentRef = ref;
                    }}
                  />
                ) : (
                  <div>
                    {this.props.isPhoneMandatory && (
                      <div className="mt-3">
                        <NewAppointmentPhoneForm
                          ref={ref => {
                            this.phoneRef = ref;
                          }}
                        />
                      </div>
                    )}
                  </div>
                )}
              </CardBody>
            </Card>

            <div
              style={{
                marginTop: 20,
                flex: 1,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center"
              }}
            >
              <Button onClick={cancel} className="btn btn-secondary mr-2">
                <Text content={Messages.cancel} />
              </Button>

              <Button color="primary" onClick={this.confirmAppointment}>
                <Text content={Messages.confirm} />
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NewAppointmentConfirmation;
