import {
  STORE_CARE_PLAN,
  REMOVE_CARE_PLAN,
  STORE_PATIENT_TYPE_ID
} from "../actions/actionTypes";

import { REHYDRATE } from "redux-persist";

export const emptyCarePlan = {
  title: "",
  description: "",
  mileStones: {},
  keys: []
};

const initialState = {
  carePlan: emptyCarePlan,
  patientTypeId: null
};

const carePlanReducer = (state = initialState, action) => {
  switch (action.type) {
    case STORE_CARE_PLAN:
      let carePlan = state.carePlan;

      return { ...state, carePlan: { ...carePlan, ...action.carePlan } };

    // return Object.assign(
    //   {},
    //   { carePlan: Object.assign(carePlan, action.carePlan) }
    // );

    case STORE_PATIENT_TYPE_ID:
      return { ...state, patientTypeId: action.patientTypeId };

    case REMOVE_CARE_PLAN:
      return { carePlan: emptyCarePlan };

    case REHYDRATE:
      if (action.payload && action.payload.carePlan) {
        // let carePlan = action.payload.carePlan.carePlan;
        return action.payload.carePlan;
      }
      return state;

    default:
      return state;
  }
};

export default carePlanReducer;
