import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import Text from "../../components/Text";
import Messages from "../../Shared/Messages";

class CredentialsPresentMessage extends PureComponent {
  render() {
    let { facilityName } = this.props;

    return (
      <div style={{ color: "#28623c" }} className="mb-4">
        <Text
          content={Messages.wc_download_app}
          data={{ facilityName: facilityName }}
        />
        <br />
        <br />
        <Text content={Messages.dont_rem_pwd} />
        <Link type="link" className="mx-1" to="/forgot-password">
          <Text content={Messages.here} />
        </Link>
        <Text content={Messages.to_reset} />
      </div>
    );
  }
}

export default CredentialsPresentMessage;
