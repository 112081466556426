import React, { Component } from "react";
import MapWithLocationPicker from "../../../components/Maps/MapWithLocationPicker";

class QuestionLocation extends Component {
  setData = (location, position) => {
    this.props.setData({
      valueLocation: {
        name: location,
        position: position
      }
    });
  };

  render() {
    let { question } = this.props;
    let pos = { lat: 53.3239919, lng: -6.5258808 };
    if (
      question &&
      question.initialValue &&
      question.initialValue.valueLocation &&
      question.initialValue.valueLocation.position &&
      question.initialValue.valueLocation.position.latitude
    ) {
      pos = {
        lat: Number.parseFloat(
          question.initialValue.valueLocation.position.latitude
        ),
        lng: Number.parseFloat(
          question.initialValue.valueLocation.position.longitude
        )
      };
    }

    return (
      <div>
        <MapWithLocationPicker position={pos} setData={this.setData} />
      </div>
    );
  }
}

export default QuestionLocation;
