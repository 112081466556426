import React from 'react';
import PropTypes from 'prop-types';

/**
 * Show SVG Icon
 *
 * @param {object} props  Data passed to the component
 * @param {number} props.size  Width and height of the icon
 * @param {string} props.className  Additional className for the component
 * @param {object} props.style  Inline style object for the component
 * @returns {object} JSX of the icon
 *
 */
export default function ShowPwdIcon({size, className, style}) {
  return (
    <svg width={size} height={size} viewBox="0 0 16 8" xmlns="http://www.w3.org/2000/svg" className={`wxc-icon ${className}`} style={style}>
      <path d="M13.3968 3.69556C13.2811 3.54468 10.5248 -0.000244141 6.99988 -0.000244141C3.474 -0.000244141 0.718629 3.54468 0.602904 3.69556C0.536159 3.78292 0.5 3.88982 0.5 3.99976C0.5 4.1097 0.536159 4.21659 0.602904 4.30396C0.718629 4.45484 3.474 7.99976 6.9999 7.99976C10.5248 7.99976 13.2812 4.45484 13.3969 4.30396C13.4636 4.21659 13.4998 4.1097 13.4998 3.99975C13.4998 3.88981 13.4636 3.78292 13.3968 3.69556ZM6.99988 6.99976C4.52673 6.99976 2.37243 4.81763 1.65222 3.99976C2.37243 3.18189 4.52672 0.999756 6.99988 0.999756C9.47351 0.999756 11.6278 3.18287 12.348 4.00026C11.6293 4.81812 9.48034 6.99976 6.99988 6.99976Z" />
      <path d="M6.99988 1.99976C6.60432 1.99976 6.21764 2.11705 5.88874 2.33682C5.55984 2.55658 5.3035 2.86894 5.15212 3.23439C5.00075 3.59984 4.96114 4.00197 5.03831 4.38994C5.11548 4.7779 5.30596 5.13426 5.58567 5.41397C5.86537 5.69367 6.22174 5.88416 6.6097 5.96133C6.99766 6.0385 7.39979 5.99889 7.76525 5.84752C8.1307 5.69614 8.44306 5.43979 8.66282 5.1109C8.88258 4.782 8.99988 4.39532 8.99988 3.99976C8.99935 3.46949 8.78847 2.96109 8.41351 2.58613C8.03855 2.21117 7.53015 2.00029 6.99988 1.99976ZM6.99988 4.99976C6.8021 4.99976 6.60876 4.94111 6.44431 4.83123C6.27986 4.72134 6.15169 4.56517 6.076 4.38244C6.00031 4.19971 5.98051 3.99865 6.01909 3.80467C6.05768 3.61068 6.15292 3.4325 6.29277 3.29265C6.43262 3.1528 6.61081 3.05756 6.80479 3.01897C6.99877 2.98039 7.19984 3.00019 7.38256 3.07588C7.56529 3.15156 7.72147 3.27974 7.83135 3.44419C7.94123 3.60863 7.99988 3.80197 7.99988 3.99976C7.99954 4.26487 7.89407 4.51902 7.70661 4.70648C7.51914 4.89394 7.26499 4.99941 6.99988 4.99976Z" />
    </svg>
  );
}

ShowPwdIcon.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
  style: PropTypes.shape(),
};

ShowPwdIcon.defaultProps = {
  size: 16,
  className: '',
  style: {},
};
