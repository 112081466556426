import moment from "moment";
import { isItCountSlot } from "../../Shared/Utils";
import Messages from "../../Shared/Messages";
import I18nProvider from "../../services/I18nProvider";

const intl = I18nProvider.getIntlProvider();

export function isAppointmentTimeValid(date, start, end, diff = 15) {
  if (!date || !start || !end) {
    return false;
  }
  let startObj = new Date(date + " " + start);
  let endObj = new Date(date + " " + end);
  return moment(endObj).diff(moment(startObj), "minute") >= diff;
}

function compareRT(a, b) {
  return a.resourceTitle.localeCompare(b.resourceTitle, undefined, {
    numeric: true,
    sensitivity: "base"
  });
}
// let art = parseTitle(a.resourceTitle);
// let brt = parseTitle(b.resourceTitle);
//
// if (art < brt) {
//   return -1;
// }
// if (art > brt) {
//   return 1;
// }
// return 0;
// function parseTitle(title) {
//   if (!title) {
//     return title;
//   }
//
//   let replaced = title.replace(/^\D+/g, "");
//   if (replaced) {
//     try {
//       return parseInt(replaced);
//     } catch (e) {}
//   }
//   return title;
// }

export function getSlotsAndResources(slots) {
  slots = JSON.parse(JSON.stringify(slots));
  slots = slots.filter(s => {
    if (!s.slotStatus) {
      //appointment
      return true;
    }
    return s.slotStatus === "FREE" && moment().isBefore(moment(s.start));
  });

  let resourceMap = {};
  slots = slots.map(slot => {
    let resource = getFirstResourceFromSlot(slot);
    if (resource) {
      resourceMap[resource.masterDataValue.code] =
          resource.masterDataValue.display;
    } else {
      resourceMap["others"] = "Others";
    }
    return {
      ...slot,
      start: new Date(slot.start),
      end: new Date(slot.end),
      resourceId:
          resource && resource.masterDataValue
              ? resource.masterDataValue.code
              : "others"
    };
  });
  let resources = null;
  if (Object.keys(resourceMap).length > 1) {
    let others = !!resourceMap["others"];
    delete resourceMap["others"];
    resources = Object.keys(resourceMap).map(r => ({
      resourceId: r,
      resourceTitle: resourceMap[r] || ""
    }));
    if (others) {
      resources.push({ resourceId: "others", resourceTitle: "Others" });
    }
  }
  if (resources) {
    resources.sort(compareRT);
  }
  return { slots, resources };
}

function getFirstResourceFromSlot(slot) {
  let actors = slot.actors || slot.participants || [];
  return actors.filter(a => a.referenceType === "MASTERDATA_KEY")[0];
}

export function getAdjacentSlots(slots, firstSlot, slotDuration, noOfSlots) {
  let fsResource = getFirstResourceFromSlot(firstSlot);
  slots = slots.sort((a, b) => a.date - b.date);

  // if (!slotDuration) {
  //   let { start, end } = firstSlot;
  //   slotDuration = moment(end).diff(moment(start), "minutes");
  // }

  slotDuration = moment(firstSlot.end).diff(moment(firstSlot.start), "minutes");

  // if (fsStaff) {
  //   slots = slots.filter(s => {
  //     let sStaff = getFirstStaffFromSlot(s);
  //     return !!(sStaff && sStaff.staffId === fsStaff.staffId);
  //   });
  // } else {
  //   slots = slots.filter(s => !getFirstStaffFromSlot(s));
  // }

  let newSlots = [firstSlot];
  for (let i = 0; i < slots.length; i++) {
    let slot = slots[i];
    let sResource = getFirstResourceFromSlot(slot);
    if (
        moment(slot.start).isBefore(moment(firstSlot.start)) ||
        (fsResource &&
            fsResource.masterDataValue &&
            (!sResource ||
                !sResource.masterDataValue ||
                sResource.masterDataValue.code !==
                fsResource.masterDataValue.code)) ||
        (!fsResource && sResource)
    ) {
      continue;
    }

    let lastSlot = newSlots[newSlots.length - 1];
    let ls = moment(lastSlot.start).format("YYYY-MM-DD,HH:mm");
    let cs = moment(slot.start).format("YYYY-MM-DD,HH:mm");
    if (ls !== cs) {
      newSlots.push(slot);
    }
  }
  slots = newSlots;

  let index = slots.findIndex(s => s.id === firstSlot.id);
  let nextFewSlots = slots.slice(index + 1, index + noOfSlots);
  if (nextFewSlots.length + 1 !== noOfSlots) {
    return [];
  }

  let start = moment(firstSlot.start).format("YYYY-MM-DD");
  let prev = firstSlot;
  for (let i = 0; i < nextFewSlots.length; i++) {
    let ns = nextFewSlots[i];
    let d = moment(ns.end).diff(moment(ns.start), "minutes");
    let ds = moment(ns.start).format("YYYY-MM-DD");

    if (
        ns.slotStatus !== "FREE" ||
        d !== slotDuration ||
        start !== ds ||
        moment(prev.end).format("HH:mm") !== moment(ns.start).format("HH:mm")
    ) {
      return [];
    }
    prev = ns;
  }

  return [firstSlot, ...nextFewSlots];
}

export function getStaffName(actors) {
  return actors
      .filter(a => a.referenceType === "STAFF")
      .map(a => (a.displayHumanName ? a.displayHumanName.text : ""))
      .join(", ");
}

export function getMasterDataText(allMasterData) {
  let text = "";
  for (let i = 0; i < allMasterData.length; i++) {
    let m = allMasterData[i];

    text +=
        (i === 0 ? "" : i === allMasterData.length - 1 ? ", and " : ", ") +
        (m.masterDataValue ? m.masterDataValue.display : "");
  }
  return text;
  // return allMasterData
  //   .map(m => (m.masterDataValue ? m.masterDataValue.display : ""))
  //   .join(", ");
}

export function titleAccessor(e) {
  if (isItCountSlot(e)) {
    return e.title;
  }
  return `${moment(e.start).format("HH:mm")}-${moment(e.end).format("HH:mm")}`;
}

export function dayTitleAccessor(e) {
  let actors = e.actors || [];
  return getStaffName(actors);
}

export function getSlotDurationOptions() {
  return [
    {
      value: 15,
      label: intl.formatMessage(Messages.fa_slot_interval_15)
    },
    {
      value: 20,
      label: intl.formatMessage(Messages.fa_slot_interval_20)
    },
    {
      value: 30,
      label: intl.formatMessage(Messages.fa_slot_interval_30)
    },
    {
      value: 45,
      label: intl.formatMessage(Messages.fa_slot_interval_45)
    },
    {
      value: 60,
      label: intl.formatMessage(Messages.fa_slot_interval_60)
    },
    {
      value: 90,
      label: intl.formatMessage(Messages.fa_slot_interval_90)
    },
    {
      value: 120,
      label: intl.formatMessage(Messages.fa_slot_interval_120)
    },
    {
      value: 150,
      label: intl.formatMessage(Messages.fa_slot_interval_150)
    },
    {
      value: 180,
      label: intl.formatMessage(Messages.fa_slot_interval_180)
    }
  ];
}