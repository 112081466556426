import React from "react";

export default function QuestionInformation({ question }) {
  return null;
  // return (
  //   <div>
  //     <div>{question.text}</div>
  //   </div>
  // );
}
