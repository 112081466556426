import axiosLib from "axios";
import { store } from "../State/store/configureStore";
import { showToaster } from "../State/actions/toaster";
import { LOG_OUT } from "../State/actions/actionTypes";
import StorageService from "../services/storageService";
export const API_URL_KEY = "API_URL";

export class AxiosInstance {
  constructor() {
    this.axios = axiosLib.create();
    this.axios.defaults.timeout = 200000;
    // this.axios.defaults.baseURL = API_URL;
  }

  setToken(token) {
    this.axios.defaults.headers.common.Authorization = "Bearer " + token;
  }

  setBaseURL(url) {
    this.axios.defaults.baseURL = url;
  }

  removeToken() {
    delete this.axios.defaults.headers.common.Authorization;
  }

  request(
    url,
    method = "get",
    data,
    headers,
    fileUploadProgress,
    timeout,
    responseType,
    noAlert
  ) {
    let options = {
      url: url,
      method
    };
    if (data) {
      options.data = data;
    }
    if (headers) {
      options.headers = headers;
    }

    if (timeout) {
      options.timeout = 120000;
    }

    if (fileUploadProgress) {
      options.onUploadProgress = fileUploadProgress;
    }

    if (responseType) {
      options.responseType = "blob";
    }

    return new Promise((resolve, reject) => {
      this.axios
        .request(options)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          let response = error.response;
          if (response) {
            let status = response.status;
            if (status === 401) {
              store.dispatch({ type: LOG_OUT });
              store.dispatch(
                showToaster(
                  "Invalid/Expired Session. Please login to continue.",
                  "error",
                  7
                )
              );
              resolve();
            } else {
              if (response.data && response.data.error === "mfa_required") {
                return resolve(response.data);
              } else {
                let showError = true;
                let msg = response.data
                    ? response.data.errorMessage ||
                    response.data.message ||
                    response.data.error_description ||
                    response.data
                    : null;

                let errorCode = response.data ? response.data.errorCode : null;
                if (noAlert) {
                  reject({ showError: true, message: msg, errorCode });
                  return;
                }
                if (msg && status === 500) {
                  showError = false;
                  store.dispatch(
                    showToaster("Error occurred: " + msg, "error", 7)
                  );
                } else if (msg && status === 400) {
                  showError = false;
                  store.dispatch(showToaster(msg, "error", 7));
                }
                reject({ showError, message: msg, errorCode });
              }
            }
          } else if (error.message) {
            store.dispatch(showToaster(error.message, "error", 7));
            reject({ showError: false });
          } else {
            reject({ showError: true });
          }
        });
    });
  }
}

let axiosInstance = new AxiosInstance();
let apiURL = StorageService.getFromLocalStorage(API_URL_KEY);
axiosInstance.setBaseURL(apiURL);
// export let axiosInstance = axos;
// have to export the raw axios from axios because of a bug in karma test server with moxios
// a.k.a. axios in test should be just axios (we don't need x-bearer at least for starters)
export let http = axiosInstance;
