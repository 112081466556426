import { take, put, select } from "redux-saga/effects";
import StaffService from "../../services/staffService";
import {
  LOAD_STAFF,
  LOAD_STAFF_DONE,
  STAFF_INVITE,
  STAFF_INVITE_DONE,
  STAFF_INVITE_ERROR,
  STAFF_DELETE_DONE,
  STAFF_DELETE,
  STAFF_DELETE_ERROR
} from "../actions/actionTypes";

export function* deleteStaff() {
  while (true) {
    const action = yield take(STAFF_DELETE);
    yield put({ type: STAFF_DELETE });
    try {
      const result = yield StaffService.delete(action.staffId);
      // console.log('DELETE STAFF ', result);
      yield put({
        type: STAFF_DELETE_DONE,
        id: action.staffId
      });
      //history.push('/organizations/' + result.facilityId + '/org admins');
    } catch (error) {
      // console.log('ERROR', error);
      yield put({ type: STAFF_DELETE_ERROR, error });
    }
  }
}
export function* inviteStaff() {
  while (true) {
    const action = yield take(STAFF_INVITE);
    yield put({ type: STAFF_INVITE });
    try {
      const result = yield StaffService.invite(action.data);
      // console.log('INVITE STAFF ', result);
      yield put({ type: STAFF_INVITE_DONE, data: result });
      //history.push('/organizations/' + result.facilityId + '/org admins');
    } catch (error) {
      // console.log('ERROR', error);
      yield put({ type: STAFF_INVITE_ERROR, error });
    }
  }
}

export function* loadStaff() {
  while (true) {
    const action = yield take(LOAD_STAFF);

    // get current page and load more
    const staff = yield select(state => state.staff);
    // check if loadMore is true or already loading
    // console.log('load staff', staff);
    if (!STAFF_DELETE_ERROR.loading && "loadMore" in staff && !staff.loadMore) {
      // load more should not fire as loadMore is false
      return false;
    }
    // get page
    const page = staff.page || 0;
    // start loading more

    // put will set loading flag
    yield put({ type: LOAD_STAFF });
    // load staff
    try {
      const result = yield StaffService.load(
        page,
        !!action.showAll,
        action.showInactive,
        false,
        action.searchTerm
      );
      // console.log('STAFF SAGE RESULT RETURNED ', result);
      yield put({
        type: LOAD_STAFF_DONE,
        list: result.data,
        page: page + 1,
        loadMore: result.loadMore,
        totalStaff: result.totalStaff
      });
    } catch (error) {
      // console.log('SAGE RESULT ERROR ');
      yield put({
        type: LOAD_STAFF_DONE,
        list: [],
        page: page + 1,
        loadMore: false,
        totalStaff: 0
      });
      // console.log('STAFF ERROR', error);
    }
  }
}
