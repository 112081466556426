import { fork } from "redux-saga/effects";

import {
  acceptInvite,
  acceptTerms,
  confirmationCode,
  loadUserFacility,
  loginFlow,
  loginMfaFlow,
  setCredentials
} from "./userSagas";
import {
  addOrganization,
  loadOrganization,
  loadOrganizations,
  editOrganization,
  updateOrganizationBranding
} from "./organizationsSagas";
import {
  addPatient,
  loadPatient,
  loadPatients,
  updatePatient,
  loadSavedSearches
} from "./patientsSagas";
import { loadMembers } from "./memberSagas";
import {
  loadStatuses,
  loadStatusTemplates,
  addStatusTemplate
} from "./statusesSagas";
import { addSurvey, editSurvey, loadSurveys } from "./surveysSagas";
import {
  deleteAdmin,
  inviteAdmin,
  loadAdmins
} from "./organizationAdminsSagas";
import { deleteStaff, inviteStaff, loadStaff } from "./staffSagas";

import { loadPatientTypes } from "./patientTypesSagas";

import {
  loadLibrary,
  addLibraryContent,
  editLibraryContent,
  loadLibraryTopics,
  addTopic
} from "./contentLibrarySagas";

import {
  editMasterData,
  addNewMasterData,
  masterDataOnLoad,
  deleteMasterData
} from "./masterDataSagas";
import { loadClinics } from "./clinicsSagas";

export default function* root() {
  yield fork(loginFlow);
  yield fork(loginMfaFlow);
  yield fork(acceptInvite);
  yield fork(acceptTerms);
  yield fork(confirmationCode);
  yield fork(setCredentials);

  yield fork(loadOrganization);
  yield fork(loadOrganizations);
  yield fork(editOrganization);
  yield fork(updateOrganizationBranding);
  yield fork(addOrganization);

  yield fork(loadAdmins);
  yield fork(inviteAdmin);
  yield fork(deleteAdmin);

  yield fork(loadStaff);
  yield fork(inviteStaff);
  yield fork(deleteStaff);

  yield fork(loadPatient);
  yield fork(loadPatients);
  yield fork(loadSavedSearches);
  yield fork(loadMembers);
  yield fork(updatePatient);
  yield fork(addPatient);

  yield fork(loadPatientTypes);

  yield fork(loadStatuses);
  yield fork(addStatusTemplate);
  yield fork(loadStatusTemplates);

  yield fork(loadSurveys);
  yield fork(addSurvey);
  yield fork(editSurvey);

  yield fork(loadLibrary);
  yield fork(addLibraryContent);
  yield fork(editLibraryContent);
  yield fork(loadLibraryTopics);
  yield fork(addTopic);

  yield fork(editMasterData);
  yield fork(addNewMasterData);
  yield fork(masterDataOnLoad);
  yield fork(deleteMasterData);

  yield fork(loadUserFacility);

  yield fork(loadClinics);
}
