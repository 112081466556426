import React, { Component } from "react";

class PositionAttribute extends Component {
  static getDisplayValue(av) {
    if (av.valuePosition) {
      let value =
        "Lat: " +
        av.valuePosition.latitude +
        ", Lng: " +
        av.valuePosition.latitude +
        ", Alt: " +
        av.valuePosition.altitude;
      return value;
    }
  }

  render() {
    return null;
  }
}

export default PositionAttribute;
