// @flow

import {
  DISMISS_ERROR,
  LOAD_STAFF,
  LOAD_STAFF_DONE,
  LOG_OUT,
  STAFF_DELETE,
  STAFF_DELETE_DONE,
  STAFF_DELETE_ERROR,
  STAFF_INVITE,
  STAFF_INVITE_DONE,
  STAFF_INVITE_ERROR,
  STAFF_RESET_LOADING
} from "../actions/actionTypes";

import { findWithAttr } from "../../utils/utils";

const initialState = {
  loading: false,
  // last page loaded
  page: 0,
  // is there need to load more, currently determined
  // to be false if last page listing returned no results
  loadMore: true,
  totalStaff: 0,
  list: [],
  inviteSuccess: false,
  error: false,
  errorMsg: "",
  deleteDone: false
};

const staffReducer = (state = initialState, action) => {
  let index;

  switch (action.type) {
    case STAFF_RESET_LOADING:
      return {
        ...state,
        loading: false,
        list: [],
        page: 0,
        loadMore: true,
        error: false,
        errorMsg: "",
        inviteSuccess: false,
        deleteDone: false
      };

    case LOAD_STAFF:
      return { ...state, loading: true, deleteDone: false };

    case STAFF_INVITE:
    case STAFF_DELETE:
      return { ...state, loading: true, deleteDone: false };

    case LOAD_STAFF_DONE:
      // console.log('ADMINS', action);
      const newState = {
        ...state,
        loading: false,
        page: action.page,
        loadMore: action.loadMore,
        totalStaff: action.totalStaff,
        list:
          action.page === 0
            ? action.list
            : state.list.slice(0).concat(action.list)
      };
      return newState;

    case DISMISS_ERROR:
      return { ...state, error: false, errorMsg: "" };

    case STAFF_INVITE_ERROR:
    case STAFF_DELETE_ERROR:
      return { ...state, loading: false, error: true, errorMsg: action.error };

    case STAFF_DELETE_DONE:
      let list = state.list.slice(0);
      let deleteId = action.id || -1;
      index = findWithAttr(list, "id", deleteId);
      if (index !== -1) {
        list.splice(index, 1);
        return {
          ...state,
          loading: false,
          list,
          error: false,
          errorMsg: "",
          deleteDone: true
        };
      }

      return {
        ...state,
        loading: false,
        error: false,
        errorMsg: "",
        deleteDone: true
      };

    case STAFF_INVITE_DONE:
      // console.log('STAFF INVITE DONE ', action);
      list = state.list.slice(0);

      const id = action.data.id || -1;
      index = findWithAttr(list, "id", id);

      // not found then return prev state
      if (index === -1)
        return {
          ...state,
          loading: false,
          list: [action.data].concat(list),
          inviteSuccess: true
        };

      // if found, replace whole object
      list[index] = action.data;

      return {
        ...state,
        loading: false,
        list: list,
        inviteSuccess: true
      };

    case LOG_OUT:
      return initialState;

    default:
      return state;
  }
};

export default staffReducer;
