import React from 'react';
import PropTypes from 'prop-types';

/**
 * External user as SVG Icon
 *
 * @param {object} props  Data passed to the component
 * @param {number} props.size  Width and height of the icon
 * @param {string} props.className  Additional className for the component
 * @param {object} props.style  Inline style object for the component
 * @returns {object} JSX of the icon
 *
 */
export default function ExternalUserIcon({size, className, style}) {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" className={`wxc-icon ${className}`} style={style}>
      <path d="M13.9063 14.0264V14.8436C13.9063 14.9679 13.9556 15.0872 14.0436 15.1751C14.1315 15.263 14.2507 15.3124 14.375 15.3124C14.4993 15.3124 14.6186 15.263 14.7065 15.1751C14.7944 15.0872 14.8438 14.9679 14.8438 14.8436V14.0264C14.8438 13.902 14.7944 13.7828 14.7065 13.6949C14.6186 13.607 14.4993 13.5576 14.375 13.5576C14.2507 13.5576 14.1315 13.607 14.0436 13.6949C13.9556 13.7828 13.9063 13.902 13.9063 14.0264Z" />
      <path d="M14.3749 16.8751C14.6338 16.8751 14.8437 16.6652 14.8437 16.4063C14.8437 16.1474 14.6338 15.9376 14.3749 15.9376C14.116 15.9376 13.9062 16.1474 13.9062 16.4063C13.9062 16.6652 14.116 16.8751 14.3749 16.8751Z" />
      <path d="M18.5864 16.8822L15.47 11.2559L15.4627 11.2437C15.352 11.0541 15.1934 10.897 15.0028 10.7881C14.8122 10.6792 14.5962 10.6225 14.3767 10.6236C14.1572 10.6247 13.9418 10.6837 13.7523 10.7944C13.5628 10.9052 13.4058 11.064 13.2971 11.2547L10.1703 16.87C10.0596 17.06 10.001 17.2758 10.0004 17.4957C9.99972 17.7156 10.0571 17.9318 10.1666 18.1224C10.2762 18.3131 10.4341 18.4715 10.6244 18.5816C10.8147 18.6918 11.0307 18.7498 11.2506 18.7498H17.4994C17.7181 18.7498 17.933 18.6924 18.1225 18.5834C18.3121 18.4744 18.4698 18.3175 18.5798 18.1285C18.6898 17.9395 18.7483 17.7249 18.7495 17.5062C18.7507 17.2875 18.6944 17.0724 18.5864 16.8822ZM11.2567 17.4889L14.3817 11.8712L17.4994 17.4999L11.2567 17.4889Z" />
      <path d="M12.1521 8.89943C11.85 8.75608 11.539 8.63255 11.2209 8.52964C11.8951 8.01306 12.3905 7.29813 12.6373 6.4854C12.8842 5.67266 12.8701 4.803 12.597 3.9987C12.3239 3.1944 11.8056 2.49591 11.115 2.00147C10.4243 1.50702 9.59608 1.24148 8.74668 1.24219C7.89729 1.2429 7.06948 1.50982 6.37966 2.00542C5.68984 2.50102 5.17271 3.20036 4.90099 4.00512C4.62926 4.80988 4.6166 5.67956 4.8648 6.49188C5.113 7.3042 5.60957 8.0183 6.28468 8.53376C3.38856 9.50126 1.25 12.0943 1.25 15.0573C1.25 17.6024 4.94688 18.6382 8.11218 18.7511C8.12012 18.7517 8.12745 18.7517 8.13538 18.7517C8.29903 18.7515 8.45604 18.687 8.57267 18.5722C8.68931 18.4574 8.75626 18.3015 8.75914 18.1378C8.76201 17.9742 8.70059 17.816 8.58806 17.6972C8.47554 17.5783 8.32089 17.5084 8.15735 17.5024C4.87915 17.3846 2.5 16.3567 2.5 15.0573C2.5 11.9769 5.36194 9.37493 8.75 9.37493C9.73994 9.37189 10.7176 9.59417 11.6089 10.0249C11.6828 10.0608 11.7631 10.0818 11.8452 10.0867C11.9272 10.0915 12.0094 10.0801 12.0871 10.0531C12.1647 10.0262 12.2363 9.98413 12.2976 9.92945C12.359 9.87477 12.409 9.80852 12.4447 9.7345C12.4804 9.66048 12.5012 9.58013 12.5058 9.49806C12.5105 9.416 12.4989 9.33383 12.4717 9.25626C12.4445 9.17869 12.4023 9.10724 12.3474 9.04601C12.2926 8.98478 12.2262 8.93497 12.1521 8.89943ZM5.93751 5.31237C5.93751 4.75611 6.10246 4.21234 6.4115 3.74982C6.72054 3.28731 7.15979 2.92683 7.67371 2.71395C8.18763 2.50108 8.75313 2.44539 9.2987 2.55391C9.84427 2.66243 10.3454 2.93029 10.7387 3.32363C11.1321 3.71696 11.3999 4.2181 11.5085 4.76367C11.617 5.30925 11.5613 5.87475 11.3484 6.38866C11.1355 6.90258 10.7751 7.34183 10.3125 7.65087C9.85004 7.95992 9.30627 8.12487 8.75001 8.12487C8.00434 8.12405 7.28944 7.82747 6.76217 7.3002C6.2349 6.77293 5.93832 6.05804 5.93751 5.31237Z" />
    </svg>
  );
}

ExternalUserIcon.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
  style: PropTypes.shape(),
};

ExternalUserIcon.defaultProps = {
  size: 24,
  className: '',
  style: {},
};
