import React from "react";
import classnames from "classnames";
import { validatePassword, validateUserName } from "../../utils/utils";
import userService from "../../services/userService";
import Mandatory from "../../Shared/Packages/UiAttributes/Onboarding/Mandatory";
import { Input } from "reactstrap";
import I18nProvider from "../../services/I18nProvider";
import Messages from "../../Shared/Messages";
import Text from "../../components/Text";
import { getUserNameError } from "../UserOnboarding/Utils";
import ModalPopup from "../../components/ModalPopup";
import { Link } from "react-router-dom";
const intl = I18nProvider.getIntlProvider();

export default class SignUpCredentialsForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { formErrors: {} };
  }

  validate = () => {
    const { username, password, confirmPassword, formErrors } = this.state;
    let usernameNotUnique = formErrors.usernameNotUnique;
    let nfe = {};
    if (usernameNotUnique) {
      nfe.usernameNotUnique = usernameNotUnique;
    } else if (!validateUserName(username)) {
      nfe.username = true;
    }
    if (!validatePassword(password)) {
      nfe.password = true;
    }
    if (confirmPassword !== password) {
      nfe.confirmPassword = true;
    }
    this.setState({ formErrors: nfe });
    return Object.keys(nfe).length < 1;
  };

  handleUserNameOnBlur = () => {
    const { username, formErrors } = this.state;
    let valid = validateUserName(username);
    formErrors.username = !valid;
    formErrors.usernameNotUnique = false;
    this.setState({ formErrors: { ...formErrors } });
    if (!valid) {
      return;
    }
    let validationData = {
      facilityCode: this.props.facilityCode,
      validationFields: ["USERNAME"],
      value: username
    };
    userService.validateSignUpField(validationData).then(res => {
      let response = res[0];
      if (!response) {
        formErrors.usernameNotUnique = false;
        this.setState({ formErrors: { ...formErrors } });
        return;
      }
      if (!response.success && response.code === "USERNAME_NOT_UNIQUE") {
        formErrors.usernameNotUnique = true;
        this.setState({ formErrors: { ...formErrors } });
      }
    });
  };

  handlePasswordOnBlur = () => {
    const { password, formErrors } = this.state;
    formErrors.password = !validatePassword(password);
    this.setState({ formErrors: { ...formErrors } });
  };

  handleConfirmPasswordOnBlur = () => {
    const { confirmPassword, password, formErrors } = this.state;
    formErrors.confirmPassword = confirmPassword !== password;
    this.setState({ formErrors: { ...formErrors } });
  };

  getExistingUserIdMessage = () => {
    let { loginUserIdAttrs } = this.props;
    let prefix = "";
    if (loginUserIdAttrs.email && loginUserIdAttrs.phone) {
      prefix = intl.formatMessage(Messages.email_phone_are);
    } else if (loginUserIdAttrs.email) {
      prefix = intl.formatMessage(Messages.email_is);
    } else if (loginUserIdAttrs.phone) {
      prefix = intl.formatMessage(Messages.phone_no_is);
    }

    return intl.formatMessage(Messages.email_ph_associated_already, {
      prefix,
      login: str => (
        <Link to={"/login"} className="cursor-pointer">
          {str}
        </Link>
      ),
      continue: str => (
        <a onClick={this.closeModal} className="cursor-pointer">
          {str}
        </a>
      ),
      newline: str => <div className="my-2">{str}</div>
    });
  };

  toggleViewPassword = () => {
    this.setState({ viewPassword: !this.state.viewPassword });
  };

  toggleViewConfirmPassword = () => {
    this.setState({ viewConfirmPassword: !this.state.viewConfirmPassword });
  };

  onUseEmailClicked = () => {
    let newUseEmail = !this.state.useEmail;
    if (newUseEmail) {
      let email = this.props.email ? this.props.email.toLowerCase() : "";
      this.setState({
        useEmail: newUseEmail,
        username: email
      });
      this.props.setCredentialsData("username", email);
    } else {
      if (this.props.username === this.props.email) {
        this.setState({
          useEmail: newUseEmail,
          username: ""
        });
        this.props.setCredentialsData("username", "");
      } else {
        this.setState({
          useEmail: newUseEmail
        });
      }
    }

    // let old = this.state.useEmail;
    // this.setState({
    //   useEmail: !old,
    //   username: !old
    //     ? this.props.email
    //       ? this.props.email.toLowerCase()
    //       : ""
    //     : ""
    // });
    //
    // if (!old) {
    //   this.props.setCredentialsData("username", this.props.email.toLowerCase());
    // }
  };

  getUserNameErrorMessage = (username, formErrors) => {
    if (formErrors.username) {
      return getUserNameError(username);
    } else if (formErrors.usernameNotUnique) {
      return intl.formatMessage(Messages.username_already_taken);
    }
  };

  closeModal = () => {
    this.setState({ userInfoModalClosed: true });
  };

  render() {
    let {
      username,
      password,
      confirmPassword,
      existingLoginUserId
    } = this.props;

    if (existingLoginUserId) {
      let { userInfoModalClosed } = this.state;
      if (userInfoModalClosed) {
        return (
          <ExistingUserMessage
            getMessage={this.getExistingUserIdMessage}
            compact
          />
        );
      } else {
        return (
          <ModalPopup
            onClose={this.closeModal}
            title={<Text content={Messages.wait_account} />}
          >
            <ExistingUserMessage getMessage={this.getExistingUserIdMessage} />
          </ModalPopup>
        );
      }
    }

    let { formErrors, viewPassword, viewConfirmPassword } = this.state;

    return (
      <div>
        <div className="row">
          <div className="col-12 my-1 pb-2" style={{ backgroundColor: "#fff" }}>
            <div className="d-flex flex-column">
              <label style={{ fontWeight: 500 }}>
                <Text content={Messages.user_name} />
                <Mandatory mandatory />
              </label>
              <input
                className={classnames(
                  formErrors.username || formErrors.usernameNotUnique
                    ? "is-obform-invalid is-invalid"
                    : "",
                  "form-control"
                )}
                type="search"
                value={username}
                onChange={e => {
                  this.setState({ username: e.target.value });
                  this.props.setCredentialsData("username", e.target.value);
                }}
                autoComplete="nope"
                onBlur={this.handleUserNameOnBlur}
                style={{ borderRadius: "4px" }}
              />
              <div className="ob-if invalid-feedback">
                {(formErrors.username || formErrors.usernameNotUnique) &&
                  this.getUserNameErrorMessage(username, formErrors)}
                {/*{formErrors.username && (*/}
                {/*  <span>*/}
                {/*    <Text content={Messages.set_valid_un} />*/}
                {/*  </span>*/}
                {/*)}*/}
                {/*{formErrors.usernameNotUnique && (*/}
                {/*  <span>*/}
                {/*    <Text content={Messages.username_already_taken} />*/}
                {/*  </span>*/}
                {/*)}*/}
              </div>
              <small>
                <Text content={Messages.user_name_ht} />
              </small>

              {this.props.email && (
                <div className="my-2 d-flex align-items-center">
                  <Input
                    type="checkbox"
                    className="ml-0 mr-2"
                    style={{ position: "relative", marginTop: "0px" }}
                    checked={this.state.useEmail}
                    onChange={this.onUseEmailClicked}
                  />
                  <Text content={Messages.email_as_username} />
                </div>
              )}
            </div>
          </div>
          <div className="col-12 my-1 pb-2" style={{ backgroundColor: "#fff" }}>
            <div className="d-flex flex-column">
              <label style={{ fontWeight: 500 }}>
                <Text content={Messages.password} />
                <Mandatory mandatory />
              </label>
              <div className="input-group">
                <input
                  className={classnames(
                    formErrors.password ? "is-obform-invalid is-invalid" : "",
                    "form-control"
                  )}
                  type={viewPassword ? "text" : "password"}
                  value={password}
                  onChange={e => {
                    this.setState({ password: e.target.value });
                    this.props.setCredentialsData("password", e.target.value);
                  }}
                  autoComplete="new-password"
                  onBlur={this.handlePasswordOnBlur}
                  style={{
                    borderRadius: "4px",
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0
                  }}
                />
                <div className="input-group-append">
                  <span
                    className="input-group-text cursor-pointer"
                    onClick={this.toggleViewPassword}
                    style={{
                      borderTopRightRadius: 4,
                      borderBottomRightRadius: 4
                    }}
                  >
                    <i
                      className={`fa ${
                        viewPassword ? "fa-eye-slash" : "fa-eye"
                      }`}
                    />
                  </span>
                </div>
              </div>
              {formErrors.password && (
                <div className="ob-if invalid-feedback d-block">
                  <Text content={Messages.set_valid_password} />
                </div>
              )}
              <small>
                <Text content={Messages.password_ht} />
              </small>
            </div>
          </div>
          <div className="col-12 my-1 pb-2" style={{ backgroundColor: "#fff" }}>
            <div className="d-flex flex-column">
              <label style={{ fontWeight: 500 }}>
                <Text content={Messages.confirm_password} />
                <Mandatory mandatory />
              </label>
              <div className="input-group">
                <input
                  className={classnames(
                    formErrors.confirmPassword
                      ? "is-obform-invalid is-invalid"
                      : "",
                    "form-control"
                  )}
                  type={viewConfirmPassword ? "text" : "password"}
                  value={confirmPassword}
                  onChange={e => {
                    this.setState({ confirmPassword: e.target.value });
                    this.props.setCredentialsData(
                      "confirmPassword",
                      e.target.value
                    );
                  }}
                  autoComplete="new-password"
                  onBlur={this.handleConfirmPasswordOnBlur}
                  style={{
                    borderRadius: "4px",
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0
                  }}
                />
                <div className="input-group-append">
                  <span
                    className="input-group-text cursor-pointer"
                    onClick={this.toggleViewConfirmPassword}
                    style={{
                      borderTopRightRadius: 4,
                      borderBottomRightRadius: 4
                    }}
                  >
                    <i
                      className={`fa ${
                        viewConfirmPassword ? "fa-eye-slash" : "fa-eye"
                      }`}
                    />
                  </span>
                </div>
              </div>
              {formErrors.confirmPassword && (
                <div className="ob-if invalid-feedback d-block">
                  <Text content={Messages.passwords_mustbe_same} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function ExistingUserMessage({ getMessage, loginUserId, compact }) {
  return (
    <div className="card" style={{ borderColor: "green" }}>
      <div className="card-body font-lg">
        <div className="d-flex align-items-center justify-content-between">
          {!compact && (
            <div className="d-flex justify-content-center align-items-center">
              <i className="fa fa-info-circle font-5xl text-info" />
            </div>
          )}
          <div className={compact ? "" : "mx-3"}>
            <div style={{ backgroundColor: "#fff" }}>{getMessage()}</div>
            {loginUserId && (
              <div className="my-2">
                <Text
                  content={Messages.forgot_userId}
                  data={{
                    loginUserId
                  }}
                />
              </div>
            )}

            <div className="mt-2">
              <div className="mb-1">
                <Text content={Messages.signup_conf_ins} />
              </div>

              <div className="d-flex justify-content-center my-2">
                <a href="https://itunes.apple.com/app/mypatientspace/id1443558006?mt=8">
                  <img
                    src="https://www.mypatientspace.com/assets/img/download-appstore.png"
                    alt="iPhone/iPad"
                    style={{ width: "135px", height: "40px" }}
                  />
                </a>

                <a href="https://play.google.com/store/apps/details?id=com.mypatientspace.mypatientspace">
                  <img
                    src="https://www.mypatientspace.com/assets/img/download-playstore.jpeg"
                    alt="Android"
                    style={{
                      width: "135px",
                      height: "40px",
                      marginLeft: "10px"
                    }}
                  />
                </a>
              </div>
            </div>

            <div className="mt-3 mb-2">
              <Text
                content={Messages.forgot_pwd_su}
                data={{
                  forgot: str => (
                    <Link to={"/forgot-password"} className="cursor-pointer">
                      {str}
                    </Link>
                  )
                }}
              />
            </div>

            <div className="my-2">
              <Text content={Messages.signup_alternative} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
