import React, { Component } from "react";
import MPSButton from "../../../components/Buttons/Buttons/MPSButton";

class QuestionTsDouble extends Component {
  addAnswer = () => {
    let { question, setData } = this.props;
    let { answers = [] } = question;
    answers = [...answers, { valueDouble: null }];
    setData(answers);
  };

  setData = (index, value) => {
    let { question, setData } = this.props;
    let { answers = [] } = question;
    answers[index] = {
      valueDouble: value
    };
    setData(answers);
  };

  render() {
    let { question } = this.props;
    let answers = question.answers;
    if (!answers || answers.length === 0) {
      answers = [{ valueDouble: null }];
    }

    return (
      <div>
        {answers.map((answer, index) => {
          return (
            <input
              value={answer && answer.valueDouble ? answer.valueDouble : ""}
              type="text"
              onChange={e => {
                this.setData(index, e.target.value);
              }}
              className="form-control mb-1"
            />
          );
        })}

        <MPSButton
          type="link"
          onClick={this.addAnswer}
          style={{ fontWeight: 500 }}
        >
          +
        </MPSButton>
      </div>
    );
  }
}

export default QuestionTsDouble;
