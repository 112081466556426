// @flow

import {
  onAcceptInvite,
  onLogout,
  onSetCredentials
} from "../../State/actions/user";
import { onResetLoading } from "../../State/actions/patients";
import Onboarding from "./Onboarding";
import { connect } from "react-redux";
import { parseURLQueryParams } from "../../utils/utils";

const mapDispatchToProps = {
  onAcceptInvite,
  onSetCredentials,
  onResetLoading,
  onLogout
};

const mapStateToProps = (state, ownProps) => {
  console.log("ownProps", state, ownProps);
  let spaceDetails = state.user.spaceDetails || {};
  let step = "invite";
  if (
    state.user.onboarding.inviteAccepted &&
    ownProps.match.path.indexOf("setCredentials") > -1 &&
    !spaceDetails.credentialsPresent
  )
    step = "setCredentials";

  if (
    state.user.onboarding.inviteAccepted &&
    ownProps.match.path.indexOf("credentials-present") > -1 &&
    spaceDetails.credentialsPresent
  )
    step = "credentialsPresent";

  return {
    loading: state.user.loading,
    spaceDetails: state.user.spaceDetails,
    error: state.user.onboarding.error,
    errorMsg: state.user.onboarding.errorMsg,
    inviteAccepted: state.user.onboarding.inviteAccepted,
    termsAccepted: state.user.onboarding.termsAccepted,
    privacyAccepted: state.user.onboarding.privacyAccepted,
    confirmationDone: state.user.onboarding.confirmationDone,
    credentialsSet: state.user.onboarding.credentialsSet,
    propsEmail: state.user.onboarding.email,
    propsPhone: state.user.onboarding.phone,
    step,
    confirmationCode:
      ownProps.match &&
      ownProps.match.params &&
      ownProps.match.params.confirmationCode
        ? ownProps.match.params.confirmationCode
        : "",
    inviteCode:
      ownProps.match &&
      ownProps.match.params &&
      ownProps.match.params.inviteCode
        ? ownProps.match.params.inviteCode
        : "",
    linkType:
      ownProps.location && ownProps.location.search
        ? parseURLQueryParams(ownProps.location.search).cp
        : null,
    challenge:
      ownProps.location && ownProps.location.search
        ? parseURLQueryParams(ownProps.location.search).challenge
        : null
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Onboarding);
