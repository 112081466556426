// @flow

import {
  ACCEPT_INVITE,
  ACCEPT_INVITE_DONE,
  ACCEPT_INVITE_ERROR,
  ACCEPT_TERMS,
  ACCEPT_TERMS_DONE,
  ACCEPT_TERMS_ERROR,
  CONFIRMATION_CODE,
  CONFIRMATION_CODE_DONE,
  CONFIRMATION_CODE_ERROR,
  FORCE_MFA,
  LOG_IN,
  LOG_IN_ERROR,
  LOG_IN_MFA_ERROR,
  LOG_IN_SUCCESS,
  LOG_OUT,
  REJECT_WITHINGS_INTEGRATION,
  RESET_LOADING_USER,
  RESET_LOG_IN_ERROR,
  RESET_LOG_IN_MFA_ERROR,
  SET_CREDENTIALS,
  SET_CREDENTIALS_DONE,
  SET_CREDENTIALS_ERROR,
  SET_FACILITY_DETAILS,
  SET_USER_NAME,
  SET_WEBEX_FOR_STAFF,
  UPDATE_AVAILABILITY_STATUS,
  UPDATE_PREFERRED_LOCALE,
  UPDATE_SPACE_INFO
} from "../actions/actionTypes";

import { REHYDRATE } from "redux-persist/es/constants";
import UserService from "../../services/userService";

const initialState = {
  loading: false,
  loggedIn: false,
  loginError: false,
  role: "",
  data: {},
  spaceDetails: {},
  onboarding: {
    inviteAccepted: false,
    termsAccepted: false,
    privacyAccepted: false,
    confirmationDone: false,
    credentialsSet: false,
    error: false,
    step: "invite",
    inviteCode: "",
    email: "",
    phone: "",
    userName: ""
  }
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_LOADING_USER:
      return {
        ...initialState,
        role: state.role,
        spaceDetails: { ...state.spaceDetails },
        loading: false,
        onboarding: {
          inviteAccepted: false,
          termsAccepted: false,
          confirmationDone: false,
          credentialsSet: false,
          error: false,
          errorMsg: "",
          step: "invite",
          inviteCode: "",
          email: "",
          phone: ""
        }
      };

    case ACCEPT_INVITE:
    case ACCEPT_TERMS:
    case CONFIRMATION_CODE:
    case SET_CREDENTIALS:
    case LOG_IN:
      return { ...state, loading: true };

    case ACCEPT_INVITE_DONE:
      const { email, phone, inviteCode } = action;
      return {
        ...state,
        loading: false,
        onboarding: {
          ...state.onboarding,
          inviteAccepted: true,
          error: false,
          email,
          phone,
          inviteCode
        },
        spaceDetails: action.data
      };

    case ACCEPT_TERMS_DONE:
      return {
        ...state,
        loading: false,
        onboarding: {
          ...state.onboarding,
          termsAccepted: true,
          error: false
        }
      };

    case CONFIRMATION_CODE_DONE:
      return {
        ...state,
        loading: false,
        onboarding: {
          ...state.onboarding,
          confirmationDone: true,
          error: false
        },
        spaceDetails: action.data
      };

    case SET_CREDENTIALS_DONE:
      return {
        ...state,
        loading: false,
        onboarding: {
          ...state.onboarding,
          credentialsSet: true,
          error: false
        }
      };

    case SET_USER_NAME:
      return {
        ...state,
        onboarding: {
          ...state.onboarding,
          userName: action.userName
        }
      };

    case ACCEPT_INVITE_ERROR:
    case ACCEPT_TERMS_ERROR:
    case CONFIRMATION_CODE_ERROR:
    case SET_CREDENTIALS_ERROR:
      return {
        ...state,
        onboarding: {
          ...state.onboarding,
          error: true,
          errorMsg: action.error
        },
        loading: false
      };

    case UPDATE_SPACE_INFO:
      return {
        ...state,
        data: action.data,
        role: action.data.role,
        provisionedFeatures: action.data.provisionedFeatures
      };

    case LOG_IN_SUCCESS:
      return {
        ...state,
        loading: false,
        loggedIn: true,
        data: action.data,
        token: action.token,
        role: action.data.role,
        provisionedFeatures: action.data.provisionedFeatures,
        logoutMessage: "",
        mfaRequired: false,
        mfaToken: null,
        mfaMechanism: null
      };

    case SET_FACILITY_DETAILS:
      return {
        ...state,
        facility: action.facility
      };

    case UPDATE_AVAILABILITY_STATUS:
      let data = { ...state.data };
      data.availabilityStatus = action.availabilityStatus;
      return { ...state, data: data };

    case UPDATE_PREFERRED_LOCALE:
      // locale: state.user.data.preferredLocale
      return {
        ...state,
        data: { ...state.data, preferredLocale: action.data.preferredLocale }
      };

    case REJECT_WITHINGS_INTEGRATION:
      return {
        ...state,
        data: {
          ...state.data,
          rejectedWithingsIntegration: true
        }
      };

    case LOG_IN_ERROR:
      return {
        ...state,
        loading: false,
        loggedIn: false,
        loginError: true,
        mfaRequired: false,
        errorMessage: action.errorMessage
      };

    case RESET_LOG_IN_ERROR:
      // console.log('LOG IN ERROR');
      return {
        ...state,
        loading: false,
        loggedIn: false,
        loginError: false,
        mfaRequired: false,
        errorMessage: ""
      };

    case RESET_LOG_IN_MFA_ERROR:
      return {
        ...state,
        loading: false,
        loggedIn: false,
        loginError: false,
        errorMessage: ""
      };

    case LOG_IN_MFA_ERROR:
      // console.log('LOG IN ERROR');
      return {
        ...state,
        loading: false,
        loggedIn: false,
        loginError: true,
        errorMessage: action.errorMessage
      };

    case FORCE_MFA:
      // console.log('LOG IN ERROR');
      return {
        ...state,
        loading: false,
        mfaRequired: true,
        mfaToken: action.token,
        mfaMechanism: action.mechanism
      };

    case SET_WEBEX_FOR_STAFF:
      return { ...state, webex: action.webex };

    case LOG_OUT:
      UserService.removeToken();
      return { ...initialState, logoutMessage: action.message };
    case REHYDRATE:
      // console.log('REHYDRATE', action.payload, process.env);
      if (action.payload && action.payload.user) {
        if (action.payload.user.loggedIn && action.payload.user.token) {
          console.log("TOKEN ", action.payload.user.token);
          // if logged in and has token setToken
          UserService.updateToken(action.payload.user.token);
        } else if (
          action.payload.user.onboarding &&
          action.payload.user.onboarding.inviteAccepted
        ) {
          // do not destroy
        } else {
          // not loggedIn or doesn't have a token
          action.payload.user = initialState;
        }

        return {
          ...state,
          ...action.payload.user,
          loading: false,
          role: action.payload.user.data.role,
          provisionedFeatures: action.payload.user.data.provisionedFeatures
        };
      }
      return state;

    default:
      return state;
  }
};

export default userReducer;
