import React from "react";
import { Calendar, Views } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import localizer from "react-big-calendar/lib/localizers/moment";
import moment from "moment";
import I18nProvider from "../../services/I18nProvider";
import Text from "../Text";
import Messages from "../../Shared/Messages";
import { getMobileOperatingSystem } from "../../Common/Onboarding/Utils";

let allViews = Object.keys(Views).map(k => Views[k]);
const globalizeLocalizer = localizer(moment);

class MPSCalendar extends React.PureComponent {
  constructor(props) {
    super(props);
    let minTime = props.minTime;
    if (!minTime) {
      minTime = new Date();
      minTime.setHours(0, 0, 0);
    }

    let maxTime = props.maxTime;
    if (!maxTime) {
      maxTime = new Date();
      maxTime.setHours(23, 0, 0);
    }
    this.state = {
      minTime,
      maxTime
    };
  }

  handleNavigate = (date, view, action) => {
    this.props.onNavigate(view, date);
  };

  onView = view => {
    this.props.onNavigate(view, this.props.currentDate || new Date());
  };

  handleSelectSlot = data => {
    let { start } = data;
    let { currentView } = this.props;
    if (currentView === Views.DAY) {
      this.setState({ currentDate: moment(start).toDate() });
      this.props.onSelectSlot(data);
    } else {
      let currentDate = moment(start).toDate();
      this.props.onNavigate(Views.DAY, currentDate);
    }
  };

  onShowMore = (events, date, noOfEvents) => {
    let currentDate = moment(date).toDate();
    this.props.onNavigate(Views.DAY, currentDate);
  };

  handleSelectEvent = event => {
    let { onSelectEvent } = this.props;
    onSelectEvent(event);
  };

  render() {
    let isMobile = getMobileOperatingSystem() != "unknown";

    let {
      events = [],
      eventTitleAccessor,
      selectable,
      eventTitleDayAccessor,
      selectedEvent,
      eventStyleGetter,
      agendaLength,
      views,
      step,
      components,
      currentView = isMobile ? Views.DAY : Views.MONTH,
      currentDate = moment().toDate()
    } = this.props;
    let { minTime, maxTime } = this.state;

    return (
      <Calendar
        length={agendaLength}
        events={events}
        selectable={selectable}
        onSelectEvent={this.handleSelectEvent}
        onSelectSlot={this.handleSelectSlot}
        views={views || allViews}
        step={step || 15}
        showMultiDayTimes
        date={currentDate}
        onNavigate={this.handleNavigate}
        onView={this.onView}
        localizer={globalizeLocalizer}
        view={currentView}
        onDrillDown={this.onDrillDown}
        onShowMore={this.onShowMore}
        titleAccessor={
          currentView === Views.MONTH
            ? eventTitleAccessor
            : eventTitleDayAccessor
            ? eventTitleDayAccessor
            : null
        }
        min={minTime}
        max={maxTime}
        selected={selectedEvent}
        eventPropGetter={currentView !== Views.AGENDA ? eventStyleGetter : null}
        rtl={!!I18nProvider.isRTLLocale()}
        scrollToTime={this.props.scrollToTime}
        components={components}
        resources={this.props.resources}
        resourceIdAccessor="resourceId"
        resourceTitleAccessor="resourceTitle"
        messages={{
          today: <Text content={Messages.today} />,
          previous: <Text content={Messages.back} />,
          next: <Text content={Messages.next} />,
          month: <Text content={Messages.month} />,
          week: <Text content={Messages.week} />,
          work_week: <Text content={Messages.work_week} />,
          day: <Text content={Messages.day} />,
          agenda: <Text content={Messages.agenda} />,
          noEventsInRange: <Text content={Messages.no_events} />,
          showMore: function showMore(total) {
            return <Text content={Messages.cal_more} data={{ total }} />;
          }
        }}
      />
    );
  }
}

export default MPSCalendar;
