import userService from "../../services/userService";
import moment from "moment";

export const POLLING_DURATION_IN_SECONDS = 600;
export const POLLING_DURATION_INTERVAL_IN_MS = 10000;
const PARTICIPANT_ERROR =
  "Waited for 10 minutes. Other participant did not join the call.";

export function doPollingToJoinCall(
  roomName,
  setData,
  pollingStart,
  handleError
) {
  userService
    .joinVideoRoomAuth(roomName)
    .then(response => {
      let { roomName, jwt: token, participants } = response;
      if (token) {
        setData({
          callNotReady: false,
          token,
          roomName,
          participants,
          loading: false
        });
      } else {
        setData({ callNotReady: true, loading: false, participants });
        setTimeout(() => {
          let diff = moment().diff(pollingStart, "second");
          if (diff <= POLLING_DURATION_IN_SECONDS) {
            doPollingToJoinCall(roomName, setData, pollingStart, handleError);
          } else {
            userService.unjoinVideoRoomAuth(roomName);
            setData({ error: PARTICIPANT_ERROR });
          }
        }, POLLING_DURATION_INTERVAL_IN_MS);
      }
    })
    .catch(handleError);
}

export default function isIOSChromeOrSafari() {
  var ua = window.navigator.userAgent;
  return ua.match("CriOS") || ua.match("FxiOS");
}
