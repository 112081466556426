import React from 'react';
import PropTypes from 'prop-types';

/**
 * Cancel SVG Icon
 *
 * @param {object} props  Data passed to the component
 * @param {number} props.size  Width and height of the icon
 * @param {string} props.className  Additional className for the component
 * @param {object} props.style  Inline style object for the component
 * @returns {object} JSX of the icon
 *
 */
export default function CancelIcon({size, className, style}) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={`wxc-icon ${className}`} style={style}>
      <path d="M13.0596 11.9998L19.2794 5.78001C19.349 5.71038 19.4042 5.6277 19.4419 5.53672C19.4796 5.44573 19.499 5.34822 19.499 5.24974C19.499 5.15126 19.4796 5.05374 19.4419 4.96276C19.4042 4.87177 19.349 4.7891 19.2793 4.71947C19.2097 4.64983 19.127 4.5946 19.036 4.55691C18.945 4.51923 18.8475 4.49983 18.7491 4.49983C18.6506 4.49984 18.5531 4.51924 18.4621 4.55692C18.3711 4.59461 18.2884 4.64985 18.2188 4.71949L11.999 10.9392L5.77929 4.71949C5.70979 4.64921 5.62709 4.59335 5.53594 4.55514C5.44478 4.51692 5.34697 4.49711 5.24813 4.49683C5.14929 4.49655 5.05137 4.51581 4.96 4.55351C4.86863 4.59121 4.78562 4.64659 4.71573 4.71648C4.64584 4.78638 4.59045 4.8694 4.55276 4.96077C4.51507 5.05214 4.49581 5.15006 4.4961 5.2489C4.49638 5.34774 4.5162 5.44555 4.55442 5.5367C4.59264 5.62786 4.6485 5.71055 4.71879 5.78004L10.9385 11.9998L4.71879 18.2195C4.64915 18.2892 4.59391 18.3718 4.55622 18.4628C4.51853 18.5538 4.49914 18.6513 4.49914 18.7498C4.49914 18.8483 4.51853 18.9458 4.55622 19.0368C4.59391 19.1278 4.64915 19.2105 4.71878 19.2801C4.78842 19.3497 4.87109 19.405 4.96207 19.4427C5.05306 19.4803 5.15057 19.4997 5.24906 19.4997C5.34754 19.4997 5.44505 19.4803 5.53604 19.4427C5.62702 19.405 5.70969 19.3497 5.77933 19.2801L11.9991 13.0603L18.2188 19.2801C18.3595 19.4207 18.5502 19.4997 18.7491 19.4997C18.948 19.4997 19.1387 19.4207 19.2794 19.2801C19.42 19.1395 19.499 18.9487 19.499 18.7498C19.499 18.5509 19.42 18.3602 19.2794 18.2195L13.0596 11.9998Z" fillOpacity="0.95" />
    </svg>
  );
}

CancelIcon.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
  style: PropTypes.shape(),
};

CancelIcon.defaultProps = {
  size: 24,
  className: '',
  style: {},
};
