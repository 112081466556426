import React from "react";
import { connect } from "react-redux";
import configurationService from "../../../../services/configurationService";
import { showToaster } from "../../../../State/actions/toaster";
import AttributesSelector from "./AttributesSelector";

class AttributesContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      attributes: []
    };
  }

  componentDidMount() {
    this.loadAttributes();
  }

  loadAttributes = () => {
    if (!this.props.noNewAddition) {
      this.setState({ loading: true });
      configurationService
        .fetchAppointmentAttributes(this.props.scope)
        .then(response => {
          let attributes = [];
          response.forEach(r => {
            if (r.editable) {
              attributes.push({
                ...r,
                label: r.code.display
              });
            }
          });
          this.setState({
            availableAttributes: attributes,
            loading: false
          });
        })
        .catch(e => {
          this.setState({ loading: false });
        });
    }
  };

  render() {
    let { availableAttributes } = this.state;
    return (
      <AttributesSelector
        availableAttributes={availableAttributes}
        attributes={this.props.attributes}
        saveAttributes={this.props.saveAttributes}
        noNewAddition={this.props.noNewAddition}
        hideReadOnly={this.props.hideReadOnly}
        disabled={this.props.disabled}
        containerStyle={this.props.containerStyle}
        containerClass={this.props.containerClass}
      />
    );
  }
}

const actions = {
  showToaster: showToaster
};

export default connect(
  null,
  actions
)(AttributesContainer);
