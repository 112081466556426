import { SHOW_PROGRESS_BAR, CLOSE_PROGRESS_BAR } from "../actions/actionTypes";

export const emptyToaster = {
  show: false,
  progress: 0
};

const uploadProgressReducer = (state = emptyToaster, action) => {
  switch (action.type) {
    case SHOW_PROGRESS_BAR:
      return { ...action.data };

    case CLOSE_PROGRESS_BAR:
      return emptyToaster;

    default:
      return emptyToaster;
  }
};

export default uploadProgressReducer;
