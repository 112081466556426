import React, { PureComponent } from "react";
import userService, { ONBOARDING_LOCALE } from "../../services/userService";
import UserOnboardingView from "./UserOnboardingView";
import Messages from "../../Shared/Messages";
import I18nProvider from "../../services/I18nProvider";
import StorageService from "../../services/storageService";
import storageService from "../../services/storageService";
const intl = I18nProvider.getIntlProvider();

function getStepForSignupFlow(facilityInfo, onboardingType) {
  return getStep(
    facilityInfo,
    onboardingType,
    onboardingType === "signup" || facilityInfo.usePrescreenFromOnboarding,
    facilityInfo.preValidationRequired
  );
}

function getStep(
  facilityInfo,
  onboardingType,
  usePrescreens,
  preValidationRequired
) {
  let step = "invite";
  let { credentialsPresent, challengeFields = [] } = facilityInfo;

  if (preValidationRequired) {
    return "preValidation";
  }

  if (usePrescreens) {
    if (facilityInfo.introductionText || facilityInfo.signupIntroLibraryId) {
      return "intro";
    } else if (facilityInfo.consentText) {
      return "consent";
    }
  }

  if (credentialsPresent) {
    if (onboardingType === "signup" && facilityInfo.consentText) {
      step = "credentials-present-message";
    } else {
      step = challengeFields.length === 0 ? "terms" : "invite-terms";
    }
  } else {
    step = challengeFields.length === 0 ? "set-credentials" : "invite";
  }
  return step;
}

class UserOnboardingContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      step: null,
      challengeFields: []
    };
  }

  onCompletePreValidation = () => {
    let { facilityInfo } = this.state;
    let { onboardingType, inviteCode } = this.props;
    userService.completePreValidationForInviteFlow(onboardingType, inviteCode);
    this.setState({
      step: getStep(
        facilityInfo,
        onboardingType,
        onboardingType === "signup" || facilityInfo.usePrescreenFromOnboarding,
        false
      )
    });
  };

  goToNextFrom = step => {
    let { facilityInfo } = this.state;
    let newStep = null;
    if (step === "intro") {
      userService.completeSignupIntroForInviteFlow(
        this.props.onboardingType,
        this.props.inviteCode
      );

      if (facilityInfo.consentText) {
        newStep = "consent";
      } else {
        newStep = getStep(
          facilityInfo,
          this.props.onboardingType,
          false,
          false
        );
      }

      StorageService.addToSessionStorage("ONBOARDING_STEP", newStep);
      window.location.reload();
      return;
    }
    if (step === "consent") {
      userService.completeSignupConsentForInviteFlow(
        this.props.onboardingType,
        this.props.inviteCode
      );
      newStep = getStep(facilityInfo, this.props.onboardingType, false, false);
    }
    if (step === "contactVerification") {
      newStep = "set-credentials";
    }
    this.setState({ step: newStep });
  };

  goBackFrom = step => {
    let { facilityInfo } = this.state;
    if (step === "consent") {
      if (facilityInfo.introductionText || facilityInfo.signupIntroLibraryId) {
        this.setState({ step: "intro" });
      }
    }
  };

  componentDidMount() {
    this.props.onLogout();

    this.props.onSetOnboardingUserName("");
    let { inviteCode, onboardingType, linkType } = this.props;
    if (this.props.facilityInfo) {
      this.setState(
        this.getStateFromFacilityInfo(this.props.facilityInfo, onboardingType)
      );
      return;
    }

    this.setState({ challengeLoading: true, confirmSuccessMessage: null });

    let api = null;
    if (onboardingType === "confirm") {
      api = userService.confirmEmail;
    } else if (onboardingType === "signup") {
      api = userService.fetchChallengeFieldsFromFacilityCode;
    } else {
      api = userService.fetchChallengeFields;
    }

    api(inviteCode, linkType)
      .then(facilityInfo => {
        if (!facilityInfo) {
          this.setState({ challengeLoading: false });
          return;
        }
        this.setState(
          this.getStateFromFacilityInfo(facilityInfo, onboardingType)
        );
      })
      .catch(e => {
        this.setState({
          challengeLoading: false,
          inviteError: true,
          inviteErrMessage: e.message,
          errorCode: e.errorCode
        });
      });
  }

  getStateFromFacilityInfo = (facilityInfo, onboardingType) => {
    let {
      credentialsPresent,
      challengeFields = [],
      email,
      patientDataProfile
      // defaultLocale
    } = facilityInfo;

    // if (defaultLocale) {
    //   storageService.addToSessionStorage(ONBOARDING_LOCALE, defaultLocale);
    // }

    if (onboardingType === "confirm") {
      return {
        step: "show-confirmation",
        challengeLoading: false,
        confirmSuccessMessage: facilityInfo.confirmSuccessMessage
      };
    } else {
      let step = StorageService.getFromSessionStorage("ONBOARDING_STEP");
      StorageService.removeFromSessionStorage("ONBOARDING_STEP");
      return {
        credentialsPresent,
        step: step || getStepForSignupFlow(facilityInfo, onboardingType),
        challengeLoading: false,
        facilityInfo,
        challengeFields: challengeFields,
        email: email,
        patientDataProfile
      };
    }
  };

  confirmIdentity = challengeFields => {
    this.setState({ error: false, errorMsg: "" });
    let step = this.state.step;
    // let acceptedTermsAndCondition = step === "terms" || step === "invite-terms";
    let acceptedTermsAndCondition = true;
    userService
      .onboardingConfirmIdentity(
        this.props.inviteCode,
        challengeFields,
        acceptedTermsAndCondition,
        this.props.onboardingType
      )
      .then(response => {
        let promise = null;
        if (this.props.onboardingType === "signup") {
          promise = userService.fetchOnboardingPatientDataTemplate(
            response.patientId,
            "url"
          );
        } else {
          promise = Promise.resolve(null);
        }

        promise.then(data => {
          let { facilityInfo } = this.props;
          if (step === "terms" || step === "invite-terms") {
            this.setState({ step: "credentials-present-message" });
          } else {
            if (response.credentialsPresent) {
              this.setState({ step: "credentials-present-message" });
            } else {
              let newState = {
                step:
                  this.props.onboardingType === "signup" &&
                  response.verifyContactDetails
                    ? "contactVerification"
                    : "set-credentials",
                patientId: response.patientId,
                defaultValueForLoginUserId: response.defaultValueForLoginUserId,
                email: response.email,
                phone: response.phoneNumber
              };
              if (this.props.onboardingType === "signup" && data) {
                newState.patientDataProfile = data;
              }
              this.setState(newState);
            }
          }
        });
      })
      .catch(e => {
        let message = e.message;
        if (e.errorCode === "mps.challenge.failed") {
          message = intl.formatMessage(Messages.verification_failed);
        }
        this.setState({ error: true, errorMsg: message });
      });
  };

  verifyContact = verificationData => {
    this.setState({ error: false, errorMsg: "" });
    let { challengeFields, patientId } = this.state;

    verificationData.patientId = patientId;
    userService
      .onboardingConfirmIdentity(
        this.props.inviteCode,
        [],
        true,
        this.props.onboardingType,
        true,
        verificationData
      )
      .then(response => {
        this.setState({
          step: "set-credentials",
          patientId: response.patientId,
          defaultValueForLoginUserId: response.defaultValueForLoginUserId,
          email: response.email,
          challengeFields
        });
      })
      .catch(e => {
        let message = e.message;
        if (e.errorCode === "mps.challenge.failed") {
          message = intl.formatMessage(Messages.verification_failed);
        }
        this.setState({ error: true, errorMsg: message });
      });
  };

  setCredentials = (username, password, attributes) => {
    this.setState({ submitting: true });
    userService
      .onboardingSetCredentials(
        this.props.inviteCode,
        username,
        password,
        true,
        this.props.onboardingType,
        this.state.patientId,
        attributes
      )
      .then(response => {
        this.setState({ submitting: false });
        this.props.onSetOnboardingUserName(username);
        this.props.onLogin(username, password, true, true);
      })
      .catch(e => this.setState({ submitting: false }));
  };

  render() {
    let {
      challengeLoading,
      facilityInfo,
      step,
      challengeFields = [],
      error,
      errorMsg,
      inviteError,
      inviteErrMessage,
      errorCode,
      email,
      phone,
      confirmSuccessMessage,
      defaultValueForLoginUserId,
      patientDataProfile,
      submitting
    } = this.state;

    let { onboardingType, linkType, selfSignup } = this.props;

    if (!step) {
      return null;
    }

    return (
      <UserOnboardingView
        onboardingType={onboardingType}
        loading={challengeLoading}
        error={error}
        errorMsg={errorMsg}
        step={step}
        facilityInfo={facilityInfo}
        challengeFields={challengeFields}
        confirmIdentity={this.confirmIdentity}
        setCredentials={this.setCredentials}
        inviteError={inviteError}
        inviteErrMessage={inviteErrMessage}
        errorCode={errorCode}
        history={this.props.history}
        email={email}
        phone={phone}
        linkType={linkType}
        selfSignup={selfSignup}
        confirmSuccessMessage={confirmSuccessMessage}
        goToNextFrom={this.goToNextFrom}
        goBackFrom={this.goBackFrom}
        defaultValueForLoginUserId={defaultValueForLoginUserId}
        verifyContact={this.verifyContact}
        onCompletePreValidation={this.onCompletePreValidation}
        patientDataProfile={patientDataProfile}
        submitting={submitting}
        inviteCode={this.props.inviteCode}
      />
    );
  }
}

export default UserOnboardingContainer;
