import React from "react";
import MapWithPinnedLocation from "../../components/Maps/MapWithPinnedLocation";

export default ({ ans }) => {
  if (
    ans &&
    ans.valueLocation &&
    ans.valueLocation.position &&
    ans.valueLocation.position.longitude
  ) {
    return (
      <MapWithPinnedLocation
        position={ans.valueLocation.position}
        style={{ height: "100px", width: "300px" }}
      />
    );
  } else {
    return null;
  }
};
