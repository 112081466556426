import { take, put, select } from "redux-saga/effects";

import {
  PATIENT_TYPES_LOAD,
  PATIENT_TYPES_LOAD_DONE
} from "../actions/actionTypes";

import PatientTypesService from "../../services/patientTypesService";

export function* loadPatientTypes() {
  while (true) {
    const action = yield take(PATIENT_TYPES_LOAD);

    // get current page and load more
    const patientTypes = yield select(state => state.patientTypes);
    // check if loadMore is true or already loading
    // console.log('load patient types', patientTypes);
    if (
      !patientTypes.loading &&
      "loadMore" in patientTypes &&
      !patientTypes.loadMore
    ) {
      // load more should not fire as loadMore is false
      return false;
    }
    // get page
    const page = patientTypes.page || 0;

    // start loading more

    // put will set loading flag
    yield put({ type: PATIENT_TYPES_LOAD });
    // load patients
    try {
      const result = yield PatientTypesService.load(page);
      // console.log('SAGE RESULT RETURNED ', result);
      yield put({
        type: PATIENT_TYPES_LOAD_DONE,
        list: result.data,
        page: page + 1,
        loadMore: result.loadMore
      });
    } catch (error) {
      yield put({
        type: PATIENT_TYPES_LOAD_DONE,
        list: [],
        page: page + 1,
        loadMore: false
      });
    }
  }
}
