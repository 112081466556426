import { take, put, select } from "redux-saga/effects";
import { LOAD_MEMBERS, MEMBERS_LOADED } from "../actions/actionTypes";

import PatientsService from "../../services/patientsService";

export function* loadMembers() {
  while (true) {
    const action = yield take(LOAD_MEMBERS);

    // get current page and load more
    const patients = yield select(state => state.patients);
    // check if loadMore is true or already loading
    console.log("load members", patients);
    if (
      !patients.membersLoading &&
      "membersLoadMore" in patients &&
      !patients.membersLoadMore
    ) {
      // load more should not fire as loadMore is false
      return false;
    }
    // get page
    const page = patients.membersPage || 0;

    // start loading more

    // put will set loading flag
    yield put({ type: LOAD_MEMBERS });
    // load patients
    try {
      const patientId = action.patientId;
      const result = yield PatientsService.loadMembers(patientId, page);
      console.log("SAGE RESULT RETURNED ", result);
      yield put({
        type: MEMBERS_LOADED,
        membersList: result.data,
        membersPage: page + 1,
        membersLoadMore: result.loadMore
      });
    } catch (error) {
      console.log("SAGE RESULT ERROR ", error);
      yield put({
        type: MEMBERS_LOADED,
        membersList: [],
        membersPage: page + 1,
        membersLoadMore: false
      });
      console.log("ERROR", error);
    }
  }
}
