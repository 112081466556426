import React, { PureComponent } from "react";
import SurveyRendererContainer from "../../../Shared/Surveys/renderer/SurveyRendererContainer";
import { getScreeningSurveyId } from "../../../utils/utils";
import MPSBarLoader from "../../../components/MPSBarLoader";
import { Container } from "reactstrap";
import { shouldAllowScreening } from "../../../StaffApp/Appointments/Clinics/Utils";
import AnonymousAppointmentView from "./AnonymousAppointmentView";
import FacilityLogo from "../../../Common/UserOnboarding/FacilityLogo";

class AnonymousAppointmentScreening extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.screeningRef = React.createRef();
  }

  onSubmission = () => {
    this.props.loadAppointmentScreening();
    this.setState({ submitted: true });
  };

  showScreeningView = () => {
    this.setState({ showScreening: true, submitted: false }, () => {
      this.screeningRef.current.scrollIntoView();
    });
  };

  closeScreeningView = () => {
    this.setState({ showScreening: false });
  };

  getView = () => {
    let { mrn, appointmentRefNumber, appointment, appTheme } = this.props;
    let { screening } = appointment;

    let screeningDone = screening && screening.screeningDate;
    let screeningSurveyId = getScreeningSurveyId(screening);
    let allowScreening = shouldAllowScreening(
      screening.effectivePeriodStartDate,
      screening.effectivePeriodEndDate
    );

    let { submitted, showScreening } = this.state;

    return (
      <div>
        {allowScreening || screeningDone ? (
          <AnonymousAppointmentView
            appointment={appointment}
            showScreeningView={this.showScreeningView}
            appTheme={appTheme}
            allowScreening={allowScreening}
            screeningDone={screeningDone}
            submitted={submitted}
            loadAppointmentScreening={this.props.loadAppointmentScreening}
          />
        ) : (
          <div className="text-center my-3">
            Sorry! screening is not allowed at the moment.
          </div>
        )}
        <hr />

        {!submitted && (
          <div ref={this.screeningRef}>
            {showScreening && (
              <>
                <div className="d-flex justify-content-end">
                  <a
                    className="btn-link cursor-pointer"
                    onClick={this.closeScreeningView}
                  >
                    <i className="fa fa-times" />{" "}
                  </a>
                </div>
                <h5 className="text-center mb-3">
                  Complete Your Appointment Screening
                </h5>

                <SurveyRendererContainer
                  artifactId={screeningSurveyId}
                  onComplete={this.refreshAppointment}
                  mrn={mrn}
                  appointmentRefNumber={appointmentRefNumber}
                  onSubmission={this.onSubmission}
                  anonymous
                  embedded
                />
              </>
            )}
          </div>
        )}
      </div>
    );
  };

  render() {
    let { loading, appointment, appTheme, error } = this.props;

    if (loading) {
      return (
        <div style={{ margin: "100px auto", width: 100 }}>
          <MPSBarLoader />
        </div>
      );
    }

    if (!appointment) {
      return (
        <div className="text-danger text-center my-4">
          {error || "Sorry! Looks like you have followed an invalid link."}
        </div>
      );
    }

    return (
      <div className="app flex-column">
        <Container>
          <div className="d-flex justify-content-center align-items-center mt-3">
            {appTheme && <FacilityLogo appTheme={appTheme} height="48px" />}
          </div>
        </Container>

        <Container className="my-3">{this.getView()}</Container>

        <Container>
          <div className="d-flex justify-content-center align-items-center mt-3">
            <img alt="myPatientSpace" src="/img/mps-logo.png" height={24} />
          </div>
        </Container>
      </div>
    );
  }
}

export default AnonymousAppointmentScreening;
