import { SHOW_TOASTER, CLOSE_TOASTER } from "../actions/actionTypes";

export const emptyToaster = {
  message: null,
  type: null,
  duration: 0,
  width: "350px"
};

const infoPanelReducer = (state = emptyToaster, action) => {
  switch (action.type) {
    case SHOW_TOASTER:
      return { ...action.data };

    case CLOSE_TOASTER:
      return { ...emptyToaster };

    default:
      return emptyToaster;
  }
};

export default infoPanelReducer;
