import React from "react";
import { connect } from "react-redux";
import MPSButton from "../../../components/Buttons/Buttons/MPSButton";

class StaffSelector extends React.PureComponent {
  state = {};

  toggleSelection = id => {
    let { selected = [] } = this.state;
    let index = selected.indexOf(id);
    if (index == -1) {
      selected.push(id);
    } else {
      selected.splice(index, 1);
    }
    this.setState({ selected: [...selected] });
  };

  addStaff = () => {
    let { selected = [] } = this.state;
    if (selected.length >= 1) {
      this.setState({ error: false });
      this.props.onSelectStaff(selected);
    } else {
      this.setState({ error: true });
    }
  };

  render() {
    let { staffList } = this.props;
    let { selected = [], error } = this.state;

    return (
      <div>
        {!!error && (
          <p className="text-danger">Please select at least one Staff</p>
        )}
        <div>
          {staffList.map(staff => {
            return (
              <div className="d-flex mb-2">
                <input
                  type="checkbox"
                  onChange={() => {
                    this.toggleSelection(staff.value);
                  }}
                  checked={selected.indexOf(staff.value) !== -1}
                />
                <div className="mx-2">{staff.name.text}</div>
              </div>
            );
          })}
        </div>
        <div className="my-2 d-flex justify-content-end">
          <MPSButton onClick={this.addStaff}>Add</MPSButton>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let sl = state.staff.list || [];
  let exclude = ownProps.exclude;
  let staffList = [];
  sl.forEach(s => {
    if (exclude.findIndex(es => es.staffId === s.staffId) === -1) {
      staffList.push({
        value: s.staffId,
        label: s.person.name ? s.person.name.text : "",
        name: s.person.name
      });
    }
  });
  return {
    staffList
  };
};

export default connect(
  mapStateToProps,
  null
)(StaffSelector);
