import React, { Component } from "react";
import Slider from "rc-slider";

class QuestionChoiceWithScale extends Component {
  getValue = () => {
    let { question } = this.props;
    let { answers = [] } = question;
    if (answers.length >= 1) {
      return answers[0].valueInteger;
    }

    return question.initialValue ? question.initialValue.valueInteger : 0;
  };

  render() {
    let { question, setData } = this.props;

    return (
      <div>
        <div className="d-flex">
          <div
            className="font-weight-bold"
            style={{
              marginRight: "10px"
            }}
          >
            {question.scale.minimum}
          </div>
          <Slider
            value={this.getValue()}
            defaultValue={
              question.initialValue ? question.initialValue.valueInteger : 0
            }
            min={question.scale.minimum}
            max={question.scale.maximum}
            step={question.scale.step}
            railStyle={{
              backgroundColor: !question.scale.gradientColors ? "grey" : null,
              backgroundImage: question.scale.gradientColors
                ? "linear-gradient(to right, " +
                  question.scale.gradientColors.join(",") +
                  ")"
                : null,
              height: 10
            }}
            onChange={value => {
              setData({
                valueInteger: value,
                score: value
              });
            }}
            handle={CustomHandle}
          />
          <div
            className="font-weight-bold"
            style={{
              marginLeft: "10px"
            }}
          >
            {question.scale.maximum}
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div>{question.scale.minimumValueDescription}</div>
          <div>{question.scale.maximumValueDescription}</div>
        </div>
      </div>
    );
  }
}

const handleStyle = {
  position: "absolute",
  transform: "translate(-50%, -50%)",
  cursor: "pointer",
  border: "2px solid #0878ab",
  borderRadius: "3px",
  background: "#0878ab",
  fontSize: "12px",
  textAlign: "center",
  fontWeight: 600,
  zIndex: 3,
  top: 10,
  minWidth: 18,
  color: "#fff"
};

const CustomHandle = props => {
  const style = Object.assign({ left: props.offset + "%" }, handleStyle);
  return <div style={style}>{props.value}</div>;
};

export default QuestionChoiceWithScale;
