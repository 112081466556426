import React from "react";
import WebexMeetingsWidget from "./../WebexMeetingsWidget";
import "./../widgets/widgets.css";
import userService from "../../../../services/userService";
import AddStaffParticipants from "../AddStaffParticipants";
import MPSBarLoader from "../../../MPSBarLoader";

class PatientWebexMeeting extends React.PureComponent {
  state = { invitedParticipants: [] };
  componentDidMount() {
    this.setState({ loading: true });
    let { sipAddress, roomNameAndUserId } = this.props;
    userService
      .getAccessToken("webex", roomNameAndUserId, this.props.auth)
      .then(response => {
        if (response != null && response.access_token != null) {
          this.setState({ accessToken: response.access_token, loading: false });
        }
      })
      .catch(e => {
        this.setState({ loading: false });
      });
  }

  endCall = () => {
    userService.endVideoCall({
      roomName: this.props.sipAddress,
      statusCallbackEvent: "room-ended"
    });
  };

  

  render() {
    let { accessToken, loading } = this.state;
    let { sipAddress } = this.props;

    if (loading) {
      return (
        <div style={{ margin: "100px auto", width: 100 }}>
          <MPSBarLoader />
        </div>
      );
    }

    if (accessToken == null) {
      return <div className="text-center">Authentication Required</div>;
    }

    let activeControls = [
      "mute-audio",
      "mute-video",
      "settings",
      "share-screen"
    ];
    if (this.props.isHost) {
      activeControls.push("member-roster");
    }
    activeControls.push("leave-meeting");

    let { invitedParticipants } = this.state;
    return (
      <div className="d-flex justify-content-center">
        <div style={{ width: "100%" }}>
          <WebexMeetingsWidget
            style={{
              height: "calc(100vh - 120px)",
              width: "100%"
            }}
            accessToken={accessToken}
            meetingDestination={sipAddress}
            className={`webex-meeting-widget-mps`}
            controls={function(e) {
              return e
                ? activeControls
                : ["mute-audio", "mute-video", "settings", "join-meeting"];
            }}
            addMoreParticipants={onAddStaff => (
              <AddStaffParticipants
                roomName={this.props.sipAddress}
                currentParticipants={invitedParticipants.concat(
                  this.props.participants || []
                )}
                provider="WEBEX"
                onAddStaff={invited => {
                  this.setState({
                    invitedParticipants: invitedParticipants.concat(invited)
                  });
                  onAddStaff();
                }}
              />
            )}
            isHost={this.props.isHost}
            endCall={this.endCall}
          />
        </div>
      </div>
    );
  }
}

export default PatientWebexMeeting;
