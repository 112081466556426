import { Card, CardBody } from "reactstrap";
import React from "react";
import Messages from "../../../Shared/Messages";
import Text from "../../../components/Text";

class JoinGroupCallConfirmationPage extends React.PureComponent {
  render() {
    let { staffName, slot, description } = this.props;
    return (
      <div className="app my-4">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center my-2">
            <div>
              <img
                alt="myPatientSpace"
                src="/img/logo-symbol.png"
                height={36}
              />
            </div>
          </div>
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "calc( 100vh - 85px)" }}
          >
            <div className="col-sm-12 col-md-8">
              <Card
                style={{
                  boxShadow: "0px 2px 8px rgba(0,0,0,0.5)",
                  borderRadius: "6px",
                  border: 0
                }}
              >
                <CardBody className="text-center">
                  <div className="font-lg">
                    <Text
                      content={Messages.joined_class_text}
                      data={{
                        description,
                        slot,
                        staffName
                      }}
                    />
                  </div>
                  <div className="font-lg mt-3">
                    <Text content={Messages.class_conf} />
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default JoinGroupCallConfirmationPage;
