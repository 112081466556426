import React from "react";

class JointsHumanoidDataSummary extends React.PureComponent {
  render() {
    let {
      valueHumanoids = [],
      setHumanoidScoreAsZero,
      readOnlyHumanoid
    } = this.props;

    if (setHumanoidScoreAsZero) {
      return (
        <div className="my-3 text-center">
          You have selected "No joints swollen or tender right now"
        </div>
      );
    }

    let both = 0,
      tender = 0,
      swollen = 0;

    valueHumanoids.forEach(s => {
      if (s.isSwollen && s.isTender) {
        both++;
      } else {
        if (s.isSwollen) {
          swollen++;
        }
        if (s.isTender) {
          tender++;
        }
      }
    });

    let isSwollenCount = !!swollen;
    let isTenderCount = !!tender;
    let isBothCount = !!both;

    return (
      <div className="my-3 ">
        {valueHumanoids.length >= 1 ? (
          <div className="d-flex flex-column justify-content-between mt-2">
            <div className="d-flex justify-content-between">
              <div>
                <h6>Details</h6>

                <div>
                  {valueHumanoids.map(spot => {
                    return (
                      <div>
                        <span style={{ fontWeight: 500 }}>
                          {spot.position.replace(/_/, " ")}
                        </span>
                        {": "}
                        <span>{spot.isTender ? "Tender" : ""}</span>
                        {spot.isTender && spot.isSwollen && (
                          <span>{" & "}</span>
                        )}
                        <span>{spot.isSwollen ? "Swollen" : ""}</span>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div>
                <h6>Summary</h6>
                <div>
                  {isTenderCount && <div>{`${tender} Tender Joints`}</div>}
                  {isSwollenCount && <div>{`${swollen} Swollen Joints`}</div>}
                  {isBothCount && (
                    <div>{`${both} Both Tender and Swollen Joints`}</div>
                  )}
                  <div>{`${valueHumanoids.length} Total Joints Affected`}</div>
                </div>
              </div>
            </div>

            {readOnlyHumanoid && (
              <div className="mt-3">
                <iframe
                  src={
                    "/JointsHumanoid.html?readOnly=true&data=" +
                    JSON.stringify(valueHumanoids)
                  }
                  style={{
                    border: 0,
                    height: "655px",
                    width: "100%"
                  }}
                />
              </div>
            )}
          </div>
        ) : (
          <React.Fragment>
            {!this.props.wip && (
              <div className="my-3 text-center">
                You have not selected any joints.
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default JointsHumanoidDataSummary;
