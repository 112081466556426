import React, { Component } from "react";
import PreviewWithMedia from "./PreviewWithMedia";
import PreviewWithExternalContent from "./PreviewWithExternalContent";
import MPSButton from "../../../components/Buttons/Buttons/MPSButton";
import HtmlContent from "./HTMLContent";
import { Thumbs } from "../../Statuses";
import ActionsRenderer from "../../../PatientApp/components/ActionsRenderer";

class ArticlePreview extends Component {
  render() {
    let article = this.props.article;
    if (!article) {
      return null;
    }

    let { embed, mps, mpsPackageId } = this.props;

    let type = article.type;
    let url = "/content-library/" + article.id + "/edit";
    if (mps) {
      url =
        "/packages/" +
        mpsPackageId +
        "/content-library/" +
        article.id +
        "/edit";
    }

    return (
      <div>
        {embed ? (
          <div className="d-flex justify-content-center">
            <h4>{article.title}</h4>
          </div>
        ) : (
          <div className="d-flex justify-content-between">
            <div />
            <h4>{article.title}</h4>

            <MPSButton
              size="sm"
              color="primary"
              className="btn-add"
              onClick={() => {
                this.props.history.push(url);
              }}
            >
              Edit
            </MPSButton>
          </div>
        )}

        <div>
          {type === "CONTENT_LIBRARY" && (
            <PreviewWithMedia
              article={article}
              height={this.props.height}
              width={this.props.width}
            />
          )}
          {type === "NO_CONTENT_LIBRARY" && (
            <div>
              <div className="d-flex justify-content-center">
                {article.contents && article.contents.length >= 1 && (
                  <Thumbs content={article.contents} imgClass="image-100" />
                )}
                {article.detailText && (
                  <div>
                    <HtmlContent html={article.detailText} />
                  </div>
                )}
              </div>
            </div>
          )}
          {type === "EXTERNAL_URL" && (
            <PreviewWithExternalContent article={article} />
          )}
        </div>

        {article.libraryRenditions && article.libraryRenditions.length > 0 && (
          <div>
            <span>Renditions:</span>
            <div className="inputHolder">
              {article.libraryRenditions.map(rendition => (
                <div>{rendition.format + " | " + rendition.fileName}</div>
              ))}
            </div>
          </div>
        )}

        <ActionsRenderer
          actions={article.actions}
          artifactType="Library"
          artifactId={article.id}
          artifactName={article.name}
          artifactTitle={article.title}
        />
      </div>
    );
  }
}

export default ArticlePreview;
