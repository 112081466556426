import React from "react";
import { POLLING_DURATION_IN_SECONDS } from "./Utils";

function toHHMMSS(secs) {
  secs = POLLING_DURATION_IN_SECONDS - secs;
  if (secs < 0) {
    secs = 0;
  }
  let sec_num = parseInt(secs, 10);
  let hours = Math.floor(sec_num / 3600);
  let minutes = Math.floor(sec_num / 60) % 60;
  let seconds = sec_num % 60;

  return [hours, minutes, seconds]
    .map(v => (v < 10 ? "0" + v : v))
    .filter((v, i) => v !== "00" || i > 0)
    .join(":");
}

class CallWaitTimer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { seconds: 0 };
  }

  componentDidMount() {
    let seconds = 0;
    setInterval(() => {
      seconds++;
      this.setState({ seconds });
    }, 1000);
  }

  render() {
    let { seconds } = this.state;

    return (
      <div className="d-flex justify-content-center align-items-center flex-column">
        <div>If they don’t join, this call will automatically end in</div>
        <div className="mt-3 d-flex justify-content-center">
          <div
            className="d-flex align-items-center justify-content-center"
            style={{
              fontWeight: 600,
              border: "2px solid rgb(45, 65, 95)",
              backgroundColor: "rgb(45, 65, 95)",
              borderRadius: "3px",
              minWidth: "100px",
              minHeight: "50px",
              fontSize: "26px",
              color: "snow"
            }}
          >
            {toHHMMSS(seconds)}
          </div>
        </div>
      </div>
    );
  }
}

export default CallWaitTimer;
