import React from "react";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import Select from "react-select";
import CountryCodes from "../../../Shared/FHIR/CountryCodes";
import classnames from "classnames";
import { doE164, validatePhoneNumber } from "../../../utils/phone";
import Text from "../../../components/Text";
import Messages from "../../../Shared/Messages";
import I18nProvider from "../../../services/I18nProvider";
const intl = I18nProvider.getIntlProvider();

const labelStyle = { fontWeight: 500, width: "100%" };
class NewAppointmentPhoneForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      phone: "",
      countryCode: "+353",
      showCP: !!props.phone,
      confirmPhone: ""
    };
  }

  validate = () => {
    const { phone, countryCode, confirmPhone, confirmCountryCode } = this.state;

    let errors = {};

    if (!phone) {
      errors.phone = true;
    } else {
      if (
        phone &&
        (!countryCode || !validatePhoneNumber(countryCode + " " + phone))
      ) {
        errors.phone = true;
      } else {
        if (
          doE164(countryCode, phone) !==
          doE164(confirmCountryCode, confirmPhone)
        ) {
          errors.confirmPhone = true;
        }
      }
    }

    this.setState({ errors });
    return {
      valid: !errors.phone && !errors.confirmPhone,
      values: { phone: doE164(countryCode, phone) }
    };
  };

  getPhoneInfo = () => {
    const telecom = this.validate();
    let valid = telecom.valid;
    if (valid) {
      let { phone } = telecom.values;
      return {
        phoneNumber: phone
      };
    }
  };

  disablePhonePaste = () => {
    const phoneInput = document.getElementById("veri-confirm-phone");
    if (phoneInput) {
      phoneInput.onpaste = function(e) {
        e.preventDefault();
      };
      phoneInput.focus();
    }
  };

  handleOnBlurConfirmPhone = () => {
    let {
      errors = {},
      phone,
      countryCode,
      confirmPhone,
      confirmCountryCode
    } = this.state;
    errors.confirmPhone = false;
    this.setState({ errors: { ...errors } });
    if (
      doE164(countryCode, phone) !== doE164(confirmCountryCode, confirmPhone)
    ) {
      errors.confirmPhone = true;
      this.setState({ errors: { ...errors } });
    }
  };

  onChangePhone = e => {
    this.setState({ phone: e.target.value });
  };

  onChangeCountryCode = cntrObj => {
    this.setState({ countryCode: cntrObj ? cntrObj.value : null });
  };

  onChangeConfirmPhone = e => {
    this.setState({ confirmPhone: e.target.value });
  };

  onChangeConfirmCountryCode = cntrObj => {
    this.setState({ confirmCountryCode: cntrObj ? cntrObj.value : null });
  };

  showConfirmPhone = () => {
    let show = !!this.state.phone;
    this.setState({ showCP: show }, () => {
      if (show) {
        this.disablePhonePaste();
      }
    });
  };

  render() {
    let {
      countryCode,
      confirmCountryCode,
      phone,
      confirmPhone,
      errors = {},
      showCP
    } = this.state;

    return (
      <div>
        <Row>
          <Col xs="12">
            <div className="mb-3">
              <div>
                <Text content={Messages.ph_for_appt} />
              </div>
            </div>
            <div className="row d-flex justify-content-center">
              <div className="col-sm-12 col-md-12">
                <FormGroup>
                  <div className="inputHolder d-flex flex-column">
                    <div className="d-flex flex-row">
                      <Select
                        value={countryCode}
                        onChange={this.onChangeCountryCode}
                        placeholder={intl.formatMessage(Messages.country_code)}
                        options={CountryCodes}
                        labelKey="country"
                        valueKey="value"
                        style={{
                          border: "1px solid #e0e6e8",
                          borderRadius: 0
                        }}
                      />
                      <Input
                        style={{ width: "60%" }}
                        value={phone}
                        onChange={this.onChangePhone}
                        className={classnames(
                          errors.phone ? "ml-1 is-invalid" : "ml-1"
                        )}
                        placeholder={intl.formatMessage(Messages.phone_number)}
                        autoComplete="nope"
                        onBlur={this.showConfirmPhone}
                      />
                    </div>
                    {errors.phone && !errors.email && (
                      <div className="d-flex invalid-feedback">
                        <Text content={Messages.set_valid_phone} />
                      </div>
                    )}
                  </div>
                </FormGroup>

                {showCP && (
                  <FormGroup>
                    <div className="inputHolder d-flex flex-column">
                      <Label style={labelStyle}>
                        <Text content={Messages.confirm_phone} />
                      </Label>
                      <div className="d-flex flex-row">
                        <Select
                          value={confirmCountryCode}
                          onChange={this.onChangeConfirmCountryCode}
                          placeholder={intl.formatMessage(
                            Messages.country_code
                          )}
                          options={CountryCodes}
                          labelKey="country"
                          valueKey="value"
                          style={{
                            border: "1px solid #e0e6e8",
                            borderRadius: 0
                          }}
                        />
                        <Input
                          id="veri-confirm-phone"
                          style={{ width: "60%" }}
                          value={confirmPhone}
                          onChange={this.onChangeConfirmPhone}
                          className={classnames(
                            errors.confirmPhone ? "ml-1 is-invalid" : "ml-1"
                          )}
                          placeholder={intl.formatMessage(
                            Messages.phone_number
                          )}
                          autoComplete="nope"
                          onBlur={this.handleOnBlurConfirmPhone}
                        />
                      </div>
                      {errors.confirmPhone && (
                        <div className="d-flex invalid-feedback">
                          <Text content={Messages.same_confirm_phone} />
                        </div>
                      )}
                    </div>
                  </FormGroup>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default NewAppointmentPhoneForm;
