import {
  LOAD_STATUSES,
  STATUSES_LOADED,
  LOAD_STATUS_TEMPLATES,
  STATUS_TEMPLATES_LOADED,
  STATUS_TEMPLATE_ADD,
  STATUS_TEMPLATE_ADD_DONE,
  STATUS_TEMPLATE_ADD_ERROR
} from "../actions/actionTypes";
import { put, select, take } from "redux-saga/effects";
import StatusesService from "../../services/statusesService";
import { ROLE_PATIENT } from "../../security/roles";
import PatientAppServices from "../../services/PatientAppServices";

export function* loadStatuses() {
  while (true) {
    const action = yield take(LOAD_STATUSES);

    // get current page and load more
    const statuses = yield select(state => state.statuses);
    const role = yield select(state => state.user.role);
    // check if loadMore is true or already loading
    // console.log('load statuses', statuses);
    if (!statuses.loading && "loadMore" in statuses && !statuses.loadMore) {
      // load more should not fire as loadMore is false
      return false;
    }
    // get page
    const page = statuses.page || 0;
    const patientId = action.patientId;
    // start loading more

    // put will set loading flag
    yield put({ type: LOAD_STATUSES, patientId: patientId });
    // load statuses
    try {
      let result = null;
      if (role === ROLE_PATIENT) {
        result = yield PatientAppServices.loadStatusMessages(page);
      } else {
        result = yield StatusesService.load(role, patientId, page);
      }
      // console.log('SAGE RESULT RETURNED ', result);
      yield put({
        type: STATUSES_LOADED,
        list: result.data,
        patientId: patientId,
        page: page + 1,
        loadMore: result.loadMore
      });
    } catch (error) {
      // console.log('SAGE RESULT ERROR ');
      yield put({
        type: STATUSES_LOADED,
        list: [],

        patientId: patientId,
        page: page + 1
      });
      // console.log('ERROR', error);
    }
  }
}

export function* addStatusTemplate() {
  while (true) {
    const action = yield take(STATUS_TEMPLATE_ADD);
    yield put({ type: STATUS_TEMPLATE_ADD });
    try {
      const result = yield StatusesService.addTemplate(action.data);
      yield put({
        type: STATUS_TEMPLATE_ADD_DONE,
        data: { templateId: result.id, templateTitle: result.title }
      });
      // goBack("/status-templates");
    } catch (error) {
      yield put({ type: STATUS_TEMPLATE_ADD_ERROR, error: error.toString() });
    }
  }
}

export function* loadStatusTemplates() {
  while (true) {
    const action = yield take(LOAD_STATUS_TEMPLATES);

    // get current page and load more
    const statuses = yield select(state => state.statuses);
    // check if loadMore is true or already loading
    // console.log('load statuses', statuses);
    if (
      !statuses.loading &&
      "loadMoreTemplates" in statuses &&
      !statuses.loadMoreTemplates
    ) {
      // load more should not fire as loadMore is false
      return false;
    }
    // get page
    const page = statuses.pageTemplates || 0;
    // start loading more

    // put will set loading flag
    yield put({ type: LOAD_STATUS_TEMPLATES });
    // load statuses
    try {
      const result = yield StatusesService.loadTemplates(
        page,
        false,
        action.patientTypeId
      );
      // console.log('SAGE RESULT RETURNED ', result);
      yield put({
        type: STATUS_TEMPLATES_LOADED,
        list: result.list,
        loadMore: result.loadMore,
        page: page + 1
      });
    } catch (error) {
      // console.log('SAGE RESULT ERROR ');
      yield put({
        type: STATUS_TEMPLATES_LOADED,
        list: [],
        loadMore: false,
        page: page + 1
      });
      // console.log('ERROR', error);
    }
  }
}
