import {
  STORE_CONSENT_DOCUMENT,
  REMOVE_CONSENT_DOCUMENT,
  LOG_OUT
} from "../actions/actionTypes";

import { REHYDRATE } from "redux-persist";

export const emptyConsentDocument = {
  consentPeriod: {},
  sections: [],
  requiresReview: false,
  requiresCompletionStep: false
};

const initialState = {
  consentDocument: emptyConsentDocument
};

const consentDocumentReducer = (state = initialState, action) => {
  switch (action.type) {
    case STORE_CONSENT_DOCUMENT:
      let consentDocument = state.consentDocument;

      return { ...state, consentDocument: { ...consentDocument, ...action.consentDocument } };

    case REMOVE_CONSENT_DOCUMENT:
      return { consentDocument: emptyConsentDocument };

    case REHYDRATE:
      if (action.payload && action.payload.consentDocument) {
        return action.payload.consentDocument;
      }
      return state;

    case LOG_OUT: {
      return initialState;
    }

    default:
      return state;
  }
};

export default consentDocumentReducer;
