import React from 'react';
import PropTypes from 'prop-types';

/**
 * Participant list filled SVG Icon
 *
 * @param {object} props  Data passed to the component
 * @param {number} props.size  Width and height of the icon
 * @param {string} props.className  Additional className for the component
 * @param {object} props.style  Inline style object for the component
 * @returns {object} JSX of the icon
 *
 */
export default function ParticipantListFilledIcon({size, className, style}) {
  return (
    <svg width={size} height={size} viewBox="0 0 27 28" xmlns="http://www.w3.org/2000/svg" className={`wxc-icon ${className}`} style={style}>
      <path d="M5.39636 5.51583C5.39628 4.42495 5.72487 3.35855 6.34057 2.45148C6.95628 1.54441 7.83146 0.83742 8.85542 0.419919C9.87937 0.00241855 11.0061 -0.106841 12.0932 0.105959C13.1802 0.318758 14.1787 0.84406 14.9625 1.61543C15.7462 2.3868 16.2799 3.36959 16.4961 4.43951C16.7123 5.50944 16.6013 6.61844 16.1771 7.62626C15.7529 8.63409 15.0346 9.49547 14.113 10.1015C13.1914 10.7075 12.108 11.0309 10.9996 11.0308C9.51406 11.0291 8.08984 10.4475 7.0394 9.41363C5.98896 8.37973 5.39807 6.97799 5.39636 5.51583Z" />
      <path d="M15.0761 19.3827C15.0258 19.2614 15 19.1313 15 19C15 18.8687 15.0258 18.7386 15.0761 18.6173C15.1263 18.4959 15.2 18.3857 15.2928 18.2928C15.3857 18.2 15.4959 18.1263 15.6173 18.0761C15.7386 18.0258 15.8687 18 16 18H26C26.2652 18 26.5196 18.1054 26.7071 18.2929C26.8946 18.4804 27 18.7348 27 19C27 19.2652 26.8946 19.5196 26.7071 19.7071C26.5196 19.8946 26.2652 20 26 20H16C15.8687 20 15.7386 19.9742 15.6173 19.924C15.4959 19.8737 15.3857 19.8 15.2928 19.7072C15.2 19.6143 15.1263 19.5041 15.0761 19.3827Z" />
      <path d="M16 22H26C26.2652 22 26.5196 22.1054 26.7071 22.2929C26.8946 22.4804 27 22.7348 27 23C27 23.2652 26.8946 23.5196 26.7071 23.7071C26.5196 23.8946 26.2652 24 26 24H16C15.7348 24 15.4804 23.8946 15.2929 23.7071C15.1054 23.5196 15 23.2652 15 23C15 22.7348 15.1054 22.4804 15.2929 22.2929C15.4804 22.1054 15.7348 22 16 22Z" />
      <path d="M26 26H16C15.7348 26 15.4804 26.1054 15.2929 26.2929C15.1054 26.4804 15 26.7348 15 27C15 27.2652 15.1054 27.5196 15.2929 27.7071C15.4804 27.8946 15.7348 28 16 28H26C26.2652 28 26.5196 27.8946 26.7071 27.7071C26.8946 27.5196 27 27.2652 27 27C27 26.7348 26.8946 26.4804 26.7071 26.2929C26.5196 26.1054 26.2652 26 26 26Z" />
      <path d="M12.0004 27C4.8215 27 0 25.1454 0 23.4115C0 17.671 5.38317 13 11.9996 13C15.2767 13 18.2514 14.1459 20.4194 16H16.4286C14.535 16 13 17.4845 13 19.3158C13 19.9974 13.2127 20.631 13.5774 21.1579C13.2127 21.6848 13 22.3184 13 23C13 23.6816 13.2127 24.3152 13.5774 24.8421C13.2127 25.369 13 26.0026 13 26.6842C13 26.7864 13.0048 26.8876 13.0142 26.9875C12.6818 26.9958 12.3438 27 12.0004 27Z" />
    </svg>
  );
}

ParticipantListFilledIcon.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
  style: PropTypes.shape(),
};

ParticipantListFilledIcon.defaultProps = {
  size: 24,
  className: '',
  style: {},
};
