import { FEATURES } from "../../services/featuresService";
import { connect } from "react-redux";
import React from "react";
import MPSButton from "../../components/Buttons/Buttons/MPSButton";
import { isStaff } from "../../security/roles";
import Text from "../../components/Text";
import Messages from "../Messages";
import I18nProvider from "../../services/I18nProvider";
import FeaturesService from "../../services/featuresService";

class StatusActions extends React.PureComponent {
  render() {
    let { status, onClick, canEdit, editFeatureFlag } = this.props;

    let editEnabled =
      canEdit && status.type !== "INTERACTION" && !status.isChannelMessage;

    if (this.props.statusType !== "STAFF_NOTES") {
      editFeatureFlag = true;
    }
    let isRTL = I18nProvider.isRTLLocale();

    if (
      status.detailMessage ||
      status.textMessage ||
      (status.contents && status.contents.length > 0) ||
      status.artifactAttached
    ) {
      return (
        <div
          style={
            isRTL
              ? {
                  position: "absolute",
                  left: 20,
                }
              : { position: "absolute", right: 5 }
          }
        >
          <MPSButton
            type="block"
            size={"sm"}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onClick(status, "open");
            }}
          >
            <Text content={Messages.see_more} />
          </MPSButton>
          {editEnabled && editFeatureFlag && (
            <button
              className="btn btn-outline-dark btn-sm mx-2"
              style={{ border: "1px solid #29363d" }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onClick(status, "edit");
              }}
            >
              <Text content={Messages.edit} />
            </button>
          )}
          {editEnabled && editFeatureFlag && (
            <button
              className="btn btn-outline-danger btn-sm"
              style={{ border: "1px solid #f86c6b" }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onClick(status, "delete");
              }}
            >
              <Text content={Messages.delete} />
            </button>
          )}
        </div>
      );
    }
    return null;
  }
}

const mapStateToProps = (state, ownProps) => {
  let pf = state.user.data.provisionedFeatures || [];
  let ed = pf.indexOf(FEATURES.EDIT_DELETE_STATUS_MESSAGE) !== -1;
  let editFeatureFlag = FeaturesService.canEditStaffNotes();
  let userRole = state.user.role;

  return {
    canEdit: ed && isStaff(userRole),
    editFeatureFlag: editFeatureFlag,
  };
};

export default connect(mapStateToProps)(StatusActions);
